// @flow
import { api, makePost } from '../utils/http-util';

const API_URL = {
    HAS_GRAVATAR: () => api`v1/general/has-gravatar`
};

// eslint-disable-next-line
export function hasGravatar(email: string) {
    return makePost(API_URL.HAS_GRAVATAR(), { email }).toPromise();
}
