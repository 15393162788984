// @flow
import React, { useEffect, useState, useRef } from 'react';
import 'date-fns';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Card,
    Typography,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { wrapActionCreators } from '../../utils/container-util';
import getNetDataReport from '../../api/reports';
import { getTunnels } from '../../api/tunnel-api';
import { getDevices } from '../../api/device-api';
import useUnmounted from '../../hooks/use-unmount';
import useElementSize from '../../hooks/element-size';
import useWindowSize from '../../hooks/window-size';
import { getRandomColor } from '../../utils/css-utils';
import colors from '../../constants/chart-color-palette';
import MainBarChart from './main-bar-chart';
import MainAreaChart from './main-area-chart';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        maxHeight: `calc(100vh - 64px)`,
        height: `calc(100vh - 64px)`,
        width: '100vw',
        padding: 20,
        alignItems: 'flex-start'
    },
    filters: {
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    formControl: {
        margin: 5
    },
    charts: {
        height: '100%',
        flex: 4,
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        overflowY: 'auto'
    },
    cardTop: {
        marginBottom: 10,
        height: 'fit-content'
    },
    cardOnBottom: {
        marginTop: 10,
        height: 'fit-content'
    }
};
const Usage = ({ classes, actions: {} }) => {
    const unMounted = useUnmounted();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [barChartData, setBarChartData] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);
    const [netDataType, setNetDataType] = useState('tunnels');
    const [devices, setDevices] = useState([]);
    const [tunnels, setTunnels] = useState([]);
    const [deviceId, setDeviceId] = useState('-1');
    const [tunnelId, setTunnelId] = useState('-1');
    const [startDate, setStartDate] = useState(
        Date.now() - 1000 * 60 * 60 * 24 * 30 * 3
    );
    const [byType] = useState('day');
    const [endtDate, setEndtDate] = useState(Date.now());
    const [advancedFiltersData, setAdvancedFiltersData] = useState([]);
    const [
        advancedFiltersSelectedData,
        setAdvancedFiltersSelectedData
    ] = useState([]);
    const chartContainerRef = useRef(null);
    const {
        elementHeight: chartContainerHeight,
        elementWidth: chartContainerWidth
    } = useElementSize(chartContainerRef && chartContainerRef.current);
    const { windowWidth } = useWindowSize();
    useEffect(
        () => {
            setLoading(true);
            const filters = [['startDate', startDate], ['endDate', endtDate]];
            if (deviceId !== '-1') {
                filters.push(['deviceId', deviceId]);
            }
            if (tunnelId !== '-1') {
                filters.push(['tunnelId', tunnelId]);
            }

            getNetDataReport(netDataType, filters, byType).subscribe(
                d => {
                    if (!unMounted.current) {
                        setData((d && d.data) || []);
                        setLoading(false);
                    }
                },
                () => {
                    if (!unMounted.current) {
                        setLoading(false);
                    }
                }
            );
        },
        [netDataType, deviceId, tunnelId, startDate, endtDate, byType]
    );
    useEffect(() => {
        getDevices().subscribe(d => {
            if (!unMounted.current) {
                setDevices((d && d.data && d.data.devices) || []);
            }
        });
    }, []);
    useEffect(() => {
        getTunnels().subscribe(d => {
            if (!unMounted.current) {
                setTunnels((d && d.data && d.data.tunnels) || []);
            }
        });
    }, []);

    useEffect(
        () => {
            if (!devices.length || !tunnels.length || !data.length) {
                setBarChartData([]);
                setLineChartData([]);
                setAdvancedFiltersData([]);
                setAdvancedFiltersSelectedData([]);
                return;
            }
            const target = netDataType === 'tunnels' ? tunnels : devices;
            let chartData = data.reduce((chartD, el) => {
                const current = target.find(t => t._id === el._id._id);
                const chartDataItem = chartD.find(d => d._id === current._id);
                if (chartDataItem) {
                    chartD.splice(chartD.indexOf(chartDataItem), 1, {
                        ...chartDataItem,
                        bytes: chartDataItem.bytes + Math.abs(el.usage)
                    });
                } else {
                    chartD.push({
                        _id: current._id,
                        bytes: el.usage,
                        //   unit: el._id[byType],
                        name: current.name,
                        deviceName: current.deviceName
                    });
                }
                return chartD;
            }, []);
            const lineData = data.map(d => ({
                _id: d._id._id,
                usage: d.usage,
                unit: new Date(
                    `${d._id.month}/${d._id.day}/${d._id.year}`
                ).getTime()
            }));
            const uniques = data
                .map(d => d._id._id)
                .filter((el, i, arr) => arr.indexOf(el) === i);
            const allDays = data
                .map(d =>
                    new Date(
                        `${d._id.month}/${d._id.day}/${d._id.year}`
                    ).getTime()
                )
                .sort((a, b) => a - b)
                .filter((el, i, arr) => arr.indexOf(el) === i);
            const finalLineData = allDays.map(d => {
                const res = {
                    unit: d
                };
                uniques.forEach((u, i) => {
                    const datum = lineData.find(
                        ld => ld._id === u && ld.unit === d
                    );
                    res[`${u}_usage`] = datum ? Math.abs(datum.usage) : 0;
                    res[`${u}_color`] = colors[i] || getRandomColor();
                });
                return res;
            });
            chartData = chartData.map(d => ({
                ...d,
                color: finalLineData.length
                    ? finalLineData[0][`${d._id}_color`]
                    : getRandomColor()
            }));
            setAdvancedFiltersData(
                [...chartData].sort((a, b) => b.bytes - a.bytes).slice(0, 5)
            );
            setAdvancedFiltersSelectedData(
                [...chartData].sort((a, b) => b.bytes - a.bytes).slice(0, 5)
            );
            setLineChartData(finalLineData);
            setBarChartData(chartData);
        },
        [data, devices, tunnels]
    );

    const onCheckbocChange = (e, id) => {
        const newData = [...advancedFiltersSelectedData];
        if (e.target.checked) {
            newData.push(advancedFiltersData.find(datum => datum._id === id));
        } else {
            const ind = newData.findIndex(datum => datum._id === id);
            newData.splice(ind, 1);
        }
        setAdvancedFiltersSelectedData(newData);
    };
    return (
        <div className={classes.root}>
            <div className={classes.filters}>
                <Card
                    style={{
                        padding: 5,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div>Basic filters</div>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="device">Usage per</InputLabel>
                        <Select
                            inputProps={{
                                id: 'device'
                            }}
                            value={netDataType}
                            onChange={e => setNetDataType(e.target.value)}
                        >
                            <MenuItem value="devices">Devices</MenuItem>
                            <MenuItem value="tunnels">Tunnels</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="device">Device</InputLabel>
                        <Select
                            inputProps={{
                                id: 'device'
                            }}
                            value={deviceId}
                            onChange={e => setDeviceId(e.target.value)}
                        >
                            <MenuItem key="-1" value="-1">
                                All
                            </MenuItem>
                            {devices.map(d => (
                                <MenuItem value={d._id} key={d._id}>
                                    {d.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="tunnel">Tunnel</InputLabel>
                        <Select
                            inputProps={{
                                id: 'tunnel'
                            }}
                            value={tunnelId}
                            onChange={e => setTunnelId(e.target.value)}
                        >
                            <MenuItem key="-1" value="-1">
                                All
                            </MenuItem>
                            {tunnels.map(t => (
                                <MenuItem value={t._id} key={t._id}>
                                    {t.name} / {t.deviceName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            className={classes.formControl}
                            margin="normal"
                            label="Start date"
                            value={startDate}
                            onChange={d => setStartDate(d.getTime())}
                        />
                        <DatePicker
                            className={classes.formControl}
                            margin="normal"
                            label="End date"
                            value={endtDate}
                            onChange={d => setEndtDate(d.getTime())}
                        />
                    </MuiPickersUtilsProvider>
                </Card>
                {!!advancedFiltersData.length && (
                    <Card
                        style={{
                            padding: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 5,
                            overflowY: 'auto'
                        }}
                    >
                        <div>Advanced filters</div>
                        <Typography>
                            {netDataType === 'tunnels' ? 'tunnels' : 'devices'}
                            {' with the most usage'}
                        </Typography>
                        <div
                            style={{
                                padding: '0 5px'
                            }}
                        >
                            {advancedFiltersData.map((d, i) => (
                                <FormControlLabel
                                    key={i}
                                    control={
                                        <Checkbox
                                            style={{
                                                color:
                                                    lineChartData[0][
                                                        `${d._id}_color`
                                                    ]
                                            }}
                                            checked={
                                                !!advancedFiltersSelectedData.find(
                                                    datum => datum._id === d._id
                                                )
                                            }
                                            onChange={e =>
                                                onCheckbocChange(e, d._id)
                                            }
                                        />
                                    }
                                    label={
                                        netDataType === 'tunnels'
                                            ? `${d.name}/${d.deviceName}`
                                            : d.name
                                    }
                                />
                            ))}
                        </div>
                    </Card>
                )}
            </div>
            <div className={classes.charts} ref={chartContainerRef}>
                {loading ? (
                    <div style={{ width: 40, margin: '20px auto' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {data.length ? (
                            <>
                                <Card className={classes.cardTop}>
                                    <MainBarChart
                                        width={
                                            windowWidth < 992
                                                ? chartContainerWidth - 20
                                                : chartContainerWidth / 2 - 20
                                        }
                                        height={chartContainerHeight / 2 - 20}
                                        data={barChartData}
                                        tunnels={tunnels}
                                        netDataType={netDataType}
                                    />
                                </Card>
                                <Card className={classes.cardTop}>
                                    <MainAreaChart
                                        width={
                                            windowWidth < 992
                                                ? chartContainerWidth - 20
                                                : chartContainerWidth / 2 - 20
                                        }
                                        height={chartContainerHeight / 2 - 20}
                                        data={lineChartData}
                                        devices={devices}
                                        tunnels={tunnels}
                                        netDataType={netDataType}
                                        areaData={
                                            netDataType === 'tunnels'
                                                ? tunnels
                                                : devices
                                        }
                                    />
                                </Card>
                            </>
                        ) : (
                            <div>No Data</div>
                        )}
                        {!!advancedFiltersSelectedData.length && (
                            <>
                                <Card className={classes.cardOnBottom}>
                                    <MainBarChart
                                        width={
                                            windowWidth < 992
                                                ? chartContainerWidth - 20
                                                : chartContainerWidth / 2 - 20
                                        }
                                        height={chartContainerHeight / 2 - 20}
                                        data={advancedFiltersSelectedData}
                                        tunnels={tunnels}
                                        netDataType={netDataType}
                                        animationDuration={300}
                                    />
                                </Card>
                                <Card className={classes.cardOnBottom}>
                                    <MainAreaChart
                                        width={
                                            windowWidth < 992
                                                ? chartContainerWidth - 20
                                                : chartContainerWidth / 2 - 20
                                        }
                                        height={chartContainerHeight / 2 - 20}
                                        data={lineChartData}
                                        devices={devices}
                                        tunnels={tunnels}
                                        netDataType={netDataType}
                                        areaData={advancedFiltersSelectedData}
                                        animationDuration={300}
                                    />
                                </Card>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
Usage.propTypes = {
    classes: PropTypes.object.isRequired,
    actions: PropTypes.shape({}).isRequired
};

const mapDispatchToProps = wrapActionCreators({});

export default withStyles(styles)(
    connect(
        null,
        mapDispatchToProps
    )(Usage)
);
