// @flow
import React from 'react';
import 'date-fns';
import PropTypes from 'prop-types';
import {
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ComposedChart,
    Cell
} from 'recharts';
import { bytesToSize } from '../../utils/helpers';

const MainBarChart = ({
    width = 600,
    height = 400,
    data,
    tunnels,
    netDataType,
    animationDuration = 1500
}) => {
    function withEllipsis(text) {
        if (text && text.length > 9) {
            return `${text.slice(0, 7)}...`;
        }
        return text;
    }
    const CustomizedAxisTickBar = ({ x, y, stroke, payload }) => (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={-10}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="rotate(-70)"
            >
                {withEllipsis(payload.value)}
            </text>
        </g>
    );
    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            const name =
                (payload &&
                    payload[0] &&
                    payload[0].payload &&
                    payload[0].payload.name) ||
                '';
            const usage = bytesToSize(
                (payload && payload[0] && payload[0].value) || 0
            );
            const deviceName =
                (payload &&
                    payload[0] &&
                    payload[0].payload &&
                    tunnels.find(t => t._id === payload[0].payload._id) &&
                    tunnels.find(t => t._id === payload[0].payload._id)
                        .deviceName) ||
                '';

            return (
                <div
                    style={{
                        backgroundColor: 'rgba(209, 209, 209, .9)',
                        fontSize: 12,
                        padding: 10,
                        borderRadius: 5
                    }}
                >
                    <p>{`${
                        netDataType === 'tunnels'
                            ? 'Tunnel name'
                            : 'Device name'
                    }: ${name}`}</p>
                    {netDataType === 'tunnels' && (
                        <p>{`Device name: ${deviceName}`}</p>
                    )}
                    <p>{`Usage: ${usage}`}</p>
                </div>
            );
        }

        return null;
    };
    const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y +
        height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y +
        height} ${x + width}, ${y + height}
          Z`;
    const TriangleBar = props => {
        const { fill, x, y, width, height } = props;

        return (
            <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />
        );
    };
    return (
        <ComposedChart
            width={width < 360 ? 360 : width}
            height={height}
            data={data}
            margin={{
                top: 10,
                right: 10,
                bottom: 10,
                left: 10
            }}
        >
            <CartesianGrid strokeDasharray="5 5" />
            <XAxis
                height={70}
                dataKey="name"
                tick={<CustomizedAxisTickBar />}
                interval={Math.floor(data.length / ((width - 80) / 25))}
            />
            <YAxis dataKey="bytes" tickFormatter={bytesToSize} />
            <Tooltip content={<CustomTooltip />} />
            <Bar
                dataKey="bytes"
                fill="#8884d8"
                shape={<TriangleBar />}
                animationDuration={animationDuration}
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
            </Bar>
            {/* <Bar dataKey="bytes" barSize={20} fill="#413ea0" /> */}
        </ComposedChart>
    );
};
MainBarChart.propTypes = {
    data: PropTypes.array.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    tunnels: PropTypes.array.isRequired,
    netDataType: PropTypes.string.isRequired,
    animationDuration: PropTypes.number
};

export default MainBarChart;
