// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { validateUsername } from '../../../utils/validations';

import { getGravatarUrl } from '../../../utils/user-utils';
import { hasGravatar } from '../../../api/general-api';

import styles from './styles';

import type { User } from '../../../types/user';

class GeneralOptions extends Component<
    {
        actions: {
            updateCurrentUser: (data: any) => void
        },
        onCancel: () => void,
        expanded: boolean,
        onExpansionPanelChange: () => void,
        classes: any,
        loggedInUser: User,
        updateLoading: boolean
    },
    {
        profilePic: string,
        user: User,
        error: {
            fullName: string,
            username: string
        }
    }
> {
    static propTypes = {
        actions: PropTypes.shape({
            updateCurrentUser: PropTypes.func.isRequired
        }).isRequired,
        expanded: PropTypes.bool,
        onExpansionPanelChange: PropTypes.func,
        onCancel: PropTypes.func.isRequired,
        loggedInUser: PropTypes.object.isRequired,
        updateLoading: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            profilePic: '',
            error: {
                fullName: '',
                username: ''
            },
            user: {
                ...props.loggedInUser
            }
        };
    }

    componentDidMount() {
        this.determineProfilePic();
    }

    componentDidUpdate(prevProps) {
        const { loggedInUser } = this.props;
        if (loggedInUser !== prevProps.loggedInUser) {
            this.setState(
                {
                    user: loggedInUser,
                    error: {
                        fullName: '',
                        username: ''
                    }
                },
                () => {
                    this.determineProfilePic();
                }
            );
        }
    }

    onKeyDown = event => {
        switch (event.keyCode) {
            case 13: {
                this.handleSaveButtonClick();
                break;
            }
            default:
                break;
        }
    };

    determineProfilePic() {
        const { user } = this.state;
        const { profilePic } = user;
        if (!profilePic) {
            this.setState({ profilePic: '' });
        } else {
            const { type, url } = profilePic;
            if (type === 'gravatar') {
                const { email } = user;
                hasGravatar(email)
                    .then(() => {
                        this.setState({
                            profilePic: getGravatarUrl(email, 200)
                        });
                    })
                    .catch(() => {
                        this.setState({ profilePic: '' });
                    });
            } else if (url) {
                this.setState({ profilePic: url });
            } else {
                this.setState({ profilePic: '' });
            }
        }
    }

    handleFullNameChange = e => {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                fullName: e.target.value
            }
        });
    };

    handleUsernameChange = e => {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                username: e.target.value
            }
        });
    };

    validateFields(setToState: boolean = true) {
        const { user } = this.state;
        const { fullName } = user;
        const { username } = user;
        let errors = {
            fullName: '',
            username: ''
        };
        let isValid = true;
        if (fullName.trim() === '') {
            isValid = false;
            errors.fullName = 'Please enter your full name';
        }

        const {
            isValid: isValidUserName,
            errors: userNameErrors
        } = validateUsername(username);
        isValid = isValid && isValidUserName;
        errors = {
            ...errors,
            ...userNameErrors
        };

        if (setToState && Object.keys(errors).length) {
            this.setState(errors);
        }

        return isValid;
    }

    handleSaveButtonClick = () => {
        const {
            user: { fullName, username }
        } = this.state;
        const {
            loggedInUser: { fullName: oldFullName, username: oldUsername },
            actions: { updateCurrentUser }
        } = this.props;
        if (this.validateFields()) {
            const data = {};
            if (fullName.trim() !== oldFullName.trim()) {
                data.fullName = fullName.trim();
            }
            if (username.trim() !== oldUsername.trim()) {
                data.username = username.trim();
            }
            if (Object.entries(data).length !== 0) {
                updateCurrentUser(data);
            }
        }
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    render() {
        const {
            classes,
            updateLoading,
            onCancel,
            expanded,
            onExpansionPanelChange
        } = this.props;
        const { user, profilePic, error } = this.state;

        return (
            <ExpansionPanel
                expanded={expanded}
                onChange={onExpansionPanelChange}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div className={classes.column33}>
                        <Typography className={classes.heading}>
                            General Options
                        </Typography>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                    <div className={classes.contentWrap}>
                        <div className={classes.avatarWrap}>
                            {profilePic ? (
                                <Avatar src={profilePic} />
                            ) : (
                                <Avatar>{user.fullName.charAt(0)}</Avatar>
                            )}
                        </div>
                        <div className={classes.infoWrap}>
                            <FormControl
                                className={classes.formControl}
                                required
                                error={!!error.fullName}
                            >
                                <InputLabel htmlFor="profile-fullName-field">
                                    Full Name
                                </InputLabel>
                                <Input
                                    id="profile-fullName-field"
                                    autoComplete="name"
                                    value={user.fullName}
                                    autoFocus
                                    onChange={this.handleFullNameChange}
                                    inputProps={this.inputProps}
                                />
                                {error.fullName && (
                                    <FormHelperText error>
                                        {error.fullName}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                className={classes.formControl}
                                required
                                error={!!error.username}
                            >
                                <InputLabel htmlFor="profile-username-field">
                                    Username
                                </InputLabel>
                                <Input
                                    id="profile-username-field"
                                    autoComplete="off"
                                    value={user.username}
                                    onChange={this.handleUsernameChange}
                                    inputProps={this.inputProps}
                                />
                                {error.username && (
                                    <FormHelperText error>
                                        {error.username}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </div>
                    </div>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                    <Button size="small" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        color="primary"
                        onClick={this.handleSaveButtonClick}
                        disabled={updateLoading}
                    >
                        Save
                    </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        );
    }
}

export default withStyles(styles)(GeneralOptions);
