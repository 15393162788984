import { useEffect, useRef } from 'react';

export default function useUnmounted() {
    const unMounted = useRef(false);
    useEffect(
        () => () => {
            unMounted.current = true;
        },
        []
    );
    return unMounted;
}
