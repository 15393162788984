// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';

import { DEVICE_TO_GROUP_MOVE } from '../../../constants/dragging-constants';

const dropSpec = {
    canDrop(props, monitor) {
        const {
            group: { _id }
        } = props;
        const device = monitor.getItem() || { group: {} };
        const {
            group: { _id: deviceGroupId }
        } = device;
        return _id !== deviceGroupId && _id !== '-1';
    },

    drop(props) {
        return props.group;
    }
};

function dropCollect(connect, monitor) {
    return {
        // Call this function inside render()
        // to let React DnD handle the drag events:
        connectDropTarget: connect.dropTarget(),
        // You can ask the monitor about the current drag state:
        isOver: monitor.isOver(),
        // isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop()
        // itemType: monitor.getItemType()
    };
}

export default (WrappedComponent: any) => {
    const C = props => {
        const { connectDropTarget, ...rest } = props;
        return connectDropTarget(
            <div>
                <WrappedComponent {...rest} />
            </div>
        );
    };

    C.displayName = `withDeviceDrop(${WrappedComponent.displayName ||
        WrappedComponent.name})`;
    C.WrappedComponent = WrappedComponent;
    C.propTypes = {
        connectDropTarget: PropTypes.func.isRequired,
        isOver: PropTypes.bool.isRequired,
        canDrop: PropTypes.bool.isRequired,
        ...WrappedComponent.propTypes
    };
    return DropTarget(DEVICE_TO_GROUP_MOVE, dropSpec, dropCollect)(C);
};
