// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden/Hidden';
import Fab from '@material-ui/core/Fab/Fab';
import AddIcon from '@material-ui/icons/Add';

import EmptyDevices from '../../containers/Devices/EmptyDevices';
import DeviceList from './DeviceList';
import LoadingPane from '../LoadingPane';
import AddDevice from '../../containers/Devices/Add';

const styles = () => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    addButton: {
        position: 'fixed',
        right: 16,
        bottom: 16
    },
    addButtonMobile: {
        position: 'fixed',
        right: 5,
        bottom: 5
    }
});

class Devices extends React.Component<
    {
        classes: { root: string },
        loggedInUser: { fullName: string, avatar: string },
        actions: {
            getDevices: () => void,
            updateDevice: () => void,
            getGroups: () => void
        },
        loggedInUser: any,
        devicesTourDetails: any,
        devices: any[],
        groups: any[],
        devicesUpdating: string[],
        totalCount: number,
        devicesLoading: boolean,
        devicesError: string
    },
    { value: number, anchorEl: any, showAddDeviceModal: boolean }
> {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            updateDevice: PropTypes.func.isRequired,
            getDevices: PropTypes.func.isRequired
        }).isRequired,
        loggedInUser: PropTypes.object.isRequired,
        devicesTourDetails: PropTypes.object.isRequired,
        devices: PropTypes.array.isRequired,
        statusesLoading: PropTypes.bool.isRequired,
        groups: PropTypes.array.isRequired,
        devicesUpdating: PropTypes.array.isRequired,
        totalCount: PropTypes.number.isRequired,
        devicesLoading: PropTypes.bool.isRequired,
        devicesError: PropTypes.string.isRequired
    };

    state = {
        showAddDeviceModal: false
    };

    closeAddDeviceModal = () => {
        this.setState({
            showAddDeviceModal: false
        });
    };

    handleShowAddDeviceModal = () => {
        this.setState({
            showAddDeviceModal: true
        });
    };

    renderContent = () => {
        const {
            devices,
            statusesLoading,
            totalCount,
            devicesLoading,
            devicesUpdating,
            groups,
            loggedInUser,
            devicesTourDetails,
            actions
        } = this.props;

        if (totalCount === 0 && !devicesLoading) {
            // no device in the DB , show Add new device page
            return <EmptyDevices onAddDevice={this.handleShowAddDeviceModal} />;
        }
        return (
            <LoadingPane loading={devicesLoading || groups.length === 0}>
                <DeviceList
                    actions={actions}
                    statusesLoading={statusesLoading}
                    loggedInUser={loggedInUser}
                    devices={devices}
                    groups={groups}
                    totalCount={totalCount}
                    devicesUpdating={devicesUpdating}
                    devicesTourDetails={devicesTourDetails}
                />
            </LoadingPane>
        );
    };

    render() {
        const { devicesError, classes } = this.props;
        const { showAddDeviceModal } = this.state;
        if (devicesError) {
            return null; // error page;
        }
        return (
            <div className={classes.contentWrapper}>
                {this.renderContent()}
                <Hidden mdUp>
                    <Fab
                        data-tut="reactour__devices__add__device__step"
                        color="primary"
                        aria-label="Add"
                        className={classes.addButtonMobile}
                        onClick={this.handleShowAddDeviceModal}
                    >
                        <AddIcon />
                    </Fab>
                </Hidden>
                <Hidden smDown>
                    <Fab
                        data-tut="reactour__devices__add__device__step"
                        color="primary"
                        aria-label="Add"
                        className={classes.addButton}
                        onClick={this.handleShowAddDeviceModal}
                    >
                        <AddIcon />
                    </Fab>
                </Hidden>
                {showAddDeviceModal && (
                    <AddDevice close={this.closeAddDeviceModal} />
                )}
            </div>
        );
    }
}

Devices.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Devices);
