// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import queryString from 'query-string';
import { createStructuredSelector } from 'reselect';
import wrapActionCreators from 'react-redux/es/utils/wrapActionCreators';
import OrganizationInvitation from '../../components/OrganizationInvitation';
import { getLoggedInUser } from '../../selectors/app-selectors';
import { setGlobalMessageError } from '../../actions/app-actions';

class OrganizationInvitationHOC extends React.Component<
    {
        location: any,
        actions: {
            setGlobalMessageError: any
        },
        history: any,
        loggedInUser: any
    },
    {
        token: string,
        initiallyAuthenticated: boolean
    }
> {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            initiallyAuthenticated: !!props.loggedInUser
        };
    }

    componentDidMount() {
        const {
            location: { search }
        } = this.props;
        const searchParams = queryString.parse(search || '');
        const tokenPart = searchParams.token;
        this.setState({
            token: tokenPart || ''
        });
    }

    render() {
        const { token, initiallyAuthenticated } = this.state;
        const { loggedInUser, history } = this.props;
        return (
            <OrganizationInvitation
                loggedInUser={loggedInUser}
                token={token}
                history={history}
                initiallyAuthenticated={initiallyAuthenticated}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser()
});

const mapDispatchToProps = wrapActionCreators({
    setGlobalMessageError
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OrganizationInvitationHOC)
);
