// @flow
import { api, makePost } from '../utils/http-util';

const API_URL = {
    GET_TERMINAL_TOKEN: (devideId: string, userId: string) =>
        api`v1/devices/${devideId}/users/${userId}/get-terminal-token`
};

export function getTerminalToken(deviceId: string, userId: string, data: any) {
    return makePost(API_URL.GET_TERMINAL_TOKEN(deviceId, userId), data);
}
