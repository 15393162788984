// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';

import { DEVICE_TO_GROUP_MOVE } from '../../../constants/dragging-constants';

/**
 * Implements the drag source contract.
 */
const dragSpec = {
    beginDrag(props) {
        return props.device;
    },

    canDrag(props) {
        const {
            device: { shared }
        } = props;
        return !shared;
    },

    endDrag(props, monitor) {
        if (!monitor.didDrop()) {
            // You can check whether the drop was successful
            // or if the drag ended but nobody handled the drop
            return;
        }

        // Read the original dragged item from getItem():
        const device = monitor.getItem();

        // You may also read the drop result from the drop target
        // that handled the drop, if it returned an object from
        // its drop() method.
        const group = monitor.getDropResult();

        // This is a good place to call some Flux action
        props.onDeviceDropToGroup(device, group);
    }
};

function dragCollect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    };
}

export default (WrappedComponent: any) => {
    const C = props => {
        const { connectDragSource, isDragging, ...rest } = props;
        return connectDragSource(
            <div>
                <WrappedComponent isDragging={isDragging} {...rest} />
            </div>,
            { dropEffect: 'copy' }
        );
    };

    C.displayName = `withDragToGroup(${WrappedComponent.displayName ||
        WrappedComponent.name})`;
    C.WrappedComponent = WrappedComponent;
    C.propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        isDragging: PropTypes.bool.isRequired,
        onDeviceDropToGroup: PropTypes.func.isRequired,
        ...WrappedComponent.propTypes
    };
    return DragSource(DEVICE_TO_GROUP_MOVE, dragSpec, dragCollect)(C);
};
