// eslint-disable-next-line no-unused-vars
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getLoggedInUser } from '../selectors/app-selectors';

const Authorities = props => {
    const { children, failback, loggedInUser } = props;
    const authKey = children.props['data-cmpauthkey'];
    const include =
        (loggedInUser &&
            loggedInUser.uiConfig &&
            loggedInUser.uiConfig.includes) ||
        [];
    const exclude = (loggedInUser &&
        loggedInUser.uiConfig &&
        loggedInUser.uiConfig.excludes) || ['*'];
    const renderComponent =
        (include.includes(authKey) || include.includes('*')) &&
        !(exclude.includes(authKey) || exclude.includes('*'));
    if (renderComponent) {
        return children;
    }
    return failback || null;
};

Authorities.propTypes = {
    loggedInUser: PropTypes.object.isRequired,
    failback: PropTypes.node
};

const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser()
});

export const getDefaultRoute = loggedInUser => {
    const possibleRoutes = ['devices', 'tunnels', 'users', 'usage'];
    const include =
        (loggedInUser &&
            loggedInUser.uiConfig &&
            loggedInUser.uiConfig.includes) ||
        [];
    const exclude = (loggedInUser &&
        loggedInUser.uiConfig &&
        loggedInUser.uiConfig.excludes) || ['*'];
    for (let i = 0; i < possibleRoutes.length; i += 1) {
        // must be synced with constants
        const isRouteAllowed =
            (include.includes(`routes_${possibleRoutes[i]}`) ||
                include.includes('*')) &&
            !(
                exclude.includes(`routes_${possibleRoutes[i]}`) ||
                exclude.includes('*')
            );
        if (isRouteAllowed) {
            return possibleRoutes[i];
        }
    }
    return '';
};

export const isComponentAllowed = (key, loggedInUser) => {
    const include =
        (loggedInUser &&
            loggedInUser.uiConfig &&
            loggedInUser.uiConfig.includes) ||
        [];
    const exclude = (loggedInUser &&
        loggedInUser.uiConfig &&
        loggedInUser.uiConfig.excludes) || ['*'];
    const isRouteAllowed =
        (include.includes(`${key}`) || include.includes('*')) &&
        !(exclude.includes(`${key}`) || exclude.includes('*'));

    if (isRouteAllowed) {
        return true;
    }
    return false;
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(Authorities)
);
