// @flow
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { wrapActionCreators } from '../../utils/container-util';
import {
    setGlobalMessageError,
    setGlobalMessageSuccess,
    setGlobalMessageCookies
} from '../../actions/app-actions';
import {
    getErrorMessage,
    getSuccessMessage,
    getCookiesMessage
} from '../../selectors/app-selectors';

import Message from '../../components/App/Message';

const mapStateToProps = createStructuredSelector({
    errorMessage: getErrorMessage(),
    successMessage: getSuccessMessage(),
    cookiesMessage: getCookiesMessage()
});

const mapDispatchToProps = wrapActionCreators({
    setGlobalMessageError,
    setGlobalMessageSuccess,
    setGlobalMessageCookies
});

const MessageWrapper = connect(
    mapStateToProps,
    mapDispatchToProps
)(Message);

export default MessageWrapper;
