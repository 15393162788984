// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { wrapActionCreators } from '../../../utils/container-util';
import {
    addDeviceLoading,
    getLastAddedDevice
} from '../../../selectors/devices-selectors';
import { addDevice } from '../../../actions/device-actions';
import AddDevice from '../../../components/Devices/Add';

const AddDeviceHOC = ({ actions, addDeviceLoading, close, addedDevice }) => (
    <AddDevice
        actions={actions}
        addDeviceLoading={addDeviceLoading}
        close={close}
        addedDevice={addedDevice}
    />
);

AddDeviceHOC.propTypes = {
    actions: PropTypes.shape({
        addDevice: PropTypes.func.isRequired
    }).isRequired,
    addDeviceLoading: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    addedDevice: PropTypes.object
};

AddDeviceHOC.defaultProps = {
    addedDevice: null
};

const mapStateToProps = createStructuredSelector({
    addDeviceLoading: addDeviceLoading(),
    addedDevice: getLastAddedDevice()
});

const mapDispatchToProps = wrapActionCreators({
    addDevice
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDeviceHOC);
