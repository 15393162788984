import { merge, of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { DELETE_DEVICE } from '../../constants/action-types';
import { deleteDevice } from '../../api/device-api';
import {
    setConnectivityStatus,
    setGlobalMessageError,
    setGlobalMessageSuccess
} from '../../actions/app-actions';
import {
    deleteDeviceLoading,
    deleteDeviceSuccess
} from '../../actions/device-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default (action$, state) =>
    action$.pipe(
        ofType(DELETE_DEVICE),
        map(action => action.payload),
        mergeMap(deviceId =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(deleteDeviceLoading({ id: deviceId, loading: true })),
                deleteDevice(deviceId).pipe(
                    mergeMap(() => {
                        const {
                            value: {
                                devices: { data: devices }
                            }
                        } = state;
                        const deletedDevice = devices.find(
                            device => device._id === deviceId
                        );
                        return of(
                            setConnectivityStatus({ loading: false }),
                            deleteDeviceLoading({
                                id: deviceId,
                                loading: false
                            }),
                            deleteDeviceSuccess(deviceId),
                            setGlobalMessageSuccess(
                                deletedDevice
                                    ? `${
                                          deletedDevice.name
                                      } deleted successfully.`
                                    : ''
                            )
                        );
                    }),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            deleteDeviceLoading({
                                id: deviceId,
                                loading: false
                            }),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            )
        )
    );
