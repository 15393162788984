// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const styles = () => ({
    paper: {
        width: '600px'
    }
});

class DeleteDeviceGroupModal extends Component<{
    fullScreen: boolean,
    close: () => void,
    group: any,
    actions: {
        deleteGroup: (_id: string) => void
    }
}> {
    static propTypes = {
        fullScreen: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired,
        group: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            deleteGroup: PropTypes.func.isRequired
        }).isRequired
    };

    onDelete = () => {
        const {
            group: { _id },
            actions: { deleteGroup }
        } = this.props;
        deleteGroup(_id);
    };

    render() {
        const { fullScreen, close } = this.props;
        return (
            <Dialog
                fullScreen={fullScreen}
                open
                onClose={close}
                aria-labelledby="delete-group-title"
            >
                <DialogTitle>Delete Group</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete group?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onDelete}
                    >
                        Delete
                    </Button>
                    <Button variant="contained" color="primary" onClick={close}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(DeleteDeviceGroupModal));
