import * as mqtt from 'mqtt';
import { getAuthToken } from './http-util';
import { MQTT_SERVER_URL, MQTT_ENV } from '../constants/api-constants';

let client;
const handlers = [];

const initializeClient = () => {
    client = mqtt.connect(
        MQTT_SERVER_URL,
        { keepalive: 60, username: getAuthToken(), password: 'UI' }
    );
    client.on('message', (t, message) => {
        handlers.forEach(f => f(t, message));
    });
};

export const addListener = listener => {
    handlers.push(listener);
};

export const removeListener = listener => {
    const index = handlers.indexOf(listener);
    if (index !== -1) {
        handlers.splice(index, 1);
    }
};

export const getClient = () => {
    if (!client) {
        initializeClient();
    }
    return client;
};

export const publish = (message, channel) => {
    const client = getClient();
    client.publish(channel, JSON.stringify(message));
};

export function subscribeToTopic(topic) {
    // argument topic must be the following types
    // '$user--${userId}/#' or '$users/#'
    const client = getClient();
    const realTopic = `${MQTT_ENV}/${topic}`;
    client.subscribe(realTopic);
}

export function unsubscribeFromTopic(topic) {
    const client = getClient();
    const realTopic = `${MQTT_ENV}/${topic}`;
    client.unsubscribe(realTopic);
}
