// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { wrapActionCreators } from '../../../utils/container-util';
import { updateDeviceLoading } from '../../../selectors/devices-selectors';
import { updateDevice, deleteDevice } from '../../../actions/device-actions';
import { removeCurrentUserFromDevice } from '../../../actions/user-device-actions';
import Device from '../../../components/Devices/Card/Device';
import withDragToGroup from '../../../components/Devices/Card/withDragToGroup';
import { setGlobalMessageError } from '../../../actions/app-actions';

const DraggableDevice = withDragToGroup(Device);

const DeviceCardHOC = ({
    actions,
    device,
    statusesLoading,
    onDeviceDropToGroup,
    isUpdating
}: {
    actions: {
        updateDevice: ({ _id: string, name: string }) => void,
        deleteDevice: (_id: string) => void,
        removeCurrentUserFromDevice: (deviceId: string) => void,
        setGlobalMessageError: (message: any) => void
    },
    isUpdating: boolean,
    onDeviceDropToGroup: (device: any, group: any) => void,
    device: any
}) => (
    <DraggableDevice
        statusesLoading={statusesLoading}
        onDeviceDropToGroup={onDeviceDropToGroup}
        actions={actions}
        device={device}
        isUpdating={isUpdating}
    />
);

DeviceCardHOC.propTypes = {
    actions: PropTypes.shape({
        updateDevice: PropTypes.func.isRequired,
        deleteDevice: PropTypes.func.isRequired,
        setGlobalMessageError: PropTypes.func.isRequired,
        removeCurrentUserFromDevice: PropTypes.func.isRequired
    }).isRequired,
    device: PropTypes.object.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    onDeviceDropToGroup: PropTypes.func.isRequired,
    statusesLoading: PropTypes.bool.isRequired
};

const mapStateToProps = createStructuredSelector({
    updateDeviceLoading: updateDeviceLoading()
});

const mapDispatchToProps = wrapActionCreators({
    updateDevice,
    deleteDevice,
    removeCurrentUserFromDevice,
    setGlobalMessageError
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceCardHOC);
