import { combineEpics } from 'redux-observable';
import getGroupsEpic from './get-groups';
import updateGroupsOrdering from './update-groups-ordering';
import addGroupEpic from './add-group';
import deleteGroupEpic from './delete-group';
import updateGroupEpic from './update-group';

export default combineEpics(
    getGroupsEpic,
    updateGroupsOrdering,
    addGroupEpic,
    updateGroupEpic,
    deleteGroupEpic
);
