// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import { createStructuredSelector } from 'reselect';

import ChangeDeviceOwner from '../../../components/Devices/ChangeDeviceOwner';
import { urlToToken } from '../../../utils/http-util';
import { getLoggedInUser } from '../../../selectors/app-selectors';
import { constructGlobalErrorMessage } from '../../../utils/global-message-util';
import { wrapActionCreators } from '../../../utils/container-util';
import { setGlobalMessageError } from '../../../actions/app-actions';
import decodeJwt from '../../../utils/jwt-utils';

class ChangeDeviceOwnerHOC extends React.Component<
    {
        history: any,
        location: any,
        actions: {
            setGlobalMessageError: any
        },
        loggedInUser: any
    },
    {
        newOwnerEmail: string,
        oldOwnerEmail: string,
        deviceName: string,
        token: string,
        initiallyAuthenticated: boolean
    }
> {
    constructor(props) {
        super(props);
        this.state = {
            newOwnerEmail: '',
            oldOwnerEmail: '',
            deviceName: '',
            token: '',
            initiallyAuthenticated: !!props.loggedInUser
        };
    }

    componentDidMount() {
        const {
            location: { search },
            history: { replace },
            actions: { setGlobalMessageError }
        } = this.props;
        const searchParams = queryString.parse(search || '');
        const tokenPart = searchParams.change_device_owner_token;

        try {
            const { data: dataFromToken } = decodeJwt(
                urlToToken(tokenPart),
                null,
                true
            );
            this.setState({
                oldOwnerEmail: dataFromToken.oldOwnerEmail,
                newOwnerEmail: dataFromToken.newOwnerEmail,
                deviceName: dataFromToken.deviceName,
                token: tokenPart || ''
            });
        } catch (e) {
            setGlobalMessageError(
                constructGlobalErrorMessage('The link was corrupted')
            );
            replace('/login');
        }
    }

    render() {
        const {
            newOwnerEmail,
            oldOwnerEmail,
            deviceName,
            token,
            initiallyAuthenticated
        } = this.state;
        const { loggedInUser } = this.props;

        return (
            <ChangeDeviceOwner
                newOwnerEmail={newOwnerEmail}
                oldOwnerEmail={oldOwnerEmail}
                deviceName={deviceName}
                loggedInUser={loggedInUser}
                token={token}
                initiallyAuthenticated={initiallyAuthenticated}
            />
        );
    }
}

ChangeDeviceOwnerHOC.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    actions: PropTypes.shape({
        setGlobalMessageError: PropTypes.func.isRequired
    }).isRequired,
    loggedInUser: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser()
});

const mapDispatchToProps = wrapActionCreators({
    setGlobalMessageError
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ChangeDeviceOwnerHOC)
);
