// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { wrapActionCreators } from '../../../../utils/container-util';

import { updateGroup } from '../../../../actions/group-actions';

import UpdateDeviceGroupModal from '../../../../components/Devices/Groups/Update';

const UpdateDeviceGroupHOC = ({
    actions,
    group,
    close
}: {
    actions: {
        updateGroup: (group: any) => void
    },
    group: any,
    close: () => void
}) => <UpdateDeviceGroupModal close={close} actions={actions} group={group} />;

UpdateDeviceGroupHOC.propTypes = {
    close: PropTypes.func.isRequired,
    actions: PropTypes.shape({
        deleteGroup: PropTypes.func.isRequired
    }).isRequired,
    group: PropTypes.object.isRequired
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = wrapActionCreators({
    updateGroup
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateDeviceGroupHOC);
