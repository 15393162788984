// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

const styles = () => ({
    sortableDeviceWrapper: {
        padding: '10px 05px 10px 15px',
        display: 'flex',
        marginBottom: '10px'
    },
    sortableDeviceName: {
        flex: '1',
        padding: '5px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    arrowButton: {
        padding: 3,
        cursor: 'pointer'
    }
});

class SortableDevice extends Component<{
    classes: {
        sortableDeviceWrapper: string,
        sortableDeviceName: string,
        arrowButton: string
    },
    index: number,
    name: string,
    moveDevice: (dragIndex: number, direction: string) => void,
    devicesLength: number
}> {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        moveDevice: PropTypes.func.isRequired,
        devicesLength: PropTypes.number.isRequired
    };

    onArrowDownClick = () => {
        const { index, moveDevice } = this.props;
        const direction = 'down';
        moveDevice(index, direction);
    };

    onArrowUpClick = () => {
        const { index, moveDevice } = this.props;
        const direction = 'up';
        moveDevice(index, direction);
    };

    render() {
        const { classes, name, index, devicesLength } = this.props;
        return (
            <Paper className={classes.sortableDeviceWrapper}>
                <div className={classes.sortableDeviceName}>{name}</div>
                <IconButton
                    disabled={index === 0}
                    className={classes.arrowButton}
                    onClick={this.onArrowUpClick}
                    aria-haspopup="true"
                >
                    <ArrowDropUp />
                </IconButton>
                <IconButton
                    disabled={index === devicesLength - 1}
                    className={classes.arrowButton}
                    onClick={this.onArrowDownClick}
                    aria-haspopup="true"
                >
                    <ArrowDropDown />
                </IconButton>
            </Paper>
        );
    }
}

export default withStyles(styles)(SortableDevice);
