import { merge, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { UPDATE_GROUP } from '../../constants/action-types';
import { updateGroup } from '../../api/group-api';
import {
    updateGroupLoading,
    updateGroupSuccess
} from '../../actions/group-actions';
import {
    setConnectivityStatus,
    setGlobalMessageError,
    setGlobalMessageSuccess
} from '../../actions/app-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default action$ =>
    action$.pipe(
        ofType(UPDATE_GROUP),
        map(action => action.payload),
        switchMap(group =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(updateGroupLoading({ id: group.id, loading: true })),
                updateGroup(group).pipe(
                    mergeMap(() =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            updateGroupLoading({
                                id: group.id,
                                loading: false
                            }),
                            updateGroupSuccess(group),
                            setGlobalMessageSuccess(
                                group
                                    ? `${group.name} updated successfully.`
                                    : ''
                            )
                        )
                    ),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            updateGroupLoading({
                                id: group.id,
                                loading: false
                            }),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            )
        )
    );
