// @flow
import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { TextField, FormHelperText } from '@material-ui/core';

import { duration } from 'moment';
import { getCouponCode, applyCouponCode } from '../../api/coupon-code';

const styles = () => ({
    dialogContent: {
        minWidth: 300,
        minHeight: 550
    }
});

const CouponCode = ({ classes, fullScreen, close, loggedInUser, actions }) => {
    const codeInfo = (loggedInUser.couponCode || [])[0];

    const expiredDate = codeInfo
        ? new Date(new Date() - codeInfo.duration).toLocaleDateString()
        : null;
    const [code, setCode] = useState(codeInfo ? codeInfo.code : '');
    const [durationString, setDurationString] = useState('');
    const [description, setDescription] = useState(
        codeInfo ? codeInfo.description : ''
    );
    const [error, setError] = useState('');
    const disableFields = !!codeInfo;

    const onApply = () => {
        applyCouponCode(code).subscribe(
            () => {
                actions.getLoggedInUserAction();
                close();
            },
            () => {
                setError('Invalid Coupon code');
            }
        );
    };
    const validate = async () => {
        getCouponCode(code).subscribe(
            res => {
                setDescription(res.data.description);
                setDurationString(
                    duration(res.data.duration, 'milliseconds').humanize()
                );
                setError('');
            },
            () => {
                setError('Invalid Coupon code');
            }
        );
    };
    return (
        <Dialog
            fullScreen={fullScreen}
            open
            classes={{
                paper: classes.dialogContent
            }}
            onClose={close}
            aria-labelledby="about-dialog-title"
        >
            <DialogTitle id="about-dialog-title">Add Coupon Code</DialogTitle>
            {!!error && (
                <FormHelperText
                    style={{ fontSize: 18, paddingLeft: 25, paddingBottom: 15 }}
                    error
                >
                    {error}
                </FormHelperText>
            )}
            <DialogContent
                style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
            >
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column'
                    }}
                >
                    <div>
                        <TextField
                            label="Coupon Code"
                            value={code}
                            disabled={disableFields}
                            onChange={e => {
                                setCode(e.target.value);
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1, display: 'flex' }} />
                        {!disableFields && (
                            <Button
                                style={{ marginTop: 15 }}
                                variant="contained"
                                color="inherit"
                                onClick={validate}
                            >
                                Validate
                            </Button>
                        )}
                    </div>
                    {expiredDate && (
                        <div style={{ marginTop: 10 }}>
                            <p>Expired date {expiredDate}</p>
                        </div>
                    )}
                    {!expiredDate && durationString && (
                        <div style={{ marginTop: 10 }}>
                            <p>
                                After activation code will be valid{' '}
                                {durationString}
                            </p>
                        </div>
                    )}
                    <div
                        style={{
                            flex: 1,
                            verticalAlign: 'middle',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <div>
                            <p>{description}</p>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => close()}
                >
                    Close
                </Button>
                {!disableFields && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onApply}
                    >
                        Apply
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
CouponCode.propTypes = {
    loggedInUser: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired
};
export default withMobileDialog()(withStyles(styles)(CouponCode));
