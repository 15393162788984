// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import CouponCode from '../../components/CouponCode';
import { getLoggedInUser } from '../../selectors/app-selectors';
import { wrapActionCreators } from '../../utils/container-util';
import * as allAction from '../../actions/authentication-actions';

const CouponCodeHOC = ({ loggedInUser, fullScreen, close, actions }) => (
    <CouponCode
        loggedInUser={loggedInUser}
        close={close}
        getLoggedInUserAction={actions}
        fullScreen={fullScreen}
    />
);

CouponCodeHOC.propTypes = {
    loggedInUser: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool
};
const mapDispatchToProps = wrapActionCreators({
    getLoggedInUserAction: allAction.getLoggedInUser
});
const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser()
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CouponCode);
