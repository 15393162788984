import { merge, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { push } from 'react-router-redux';

import { SIGNUP } from '../../constants/action-types';
import {
    setConnectivityStatus,
    setGlobalMessageError
} from '../../actions/app-actions';
import {
    signupLoading,
    signupSuccess
} from '../../actions/authentication-actions';
import { signup } from '../../api/authentication-api';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';
import browserHistory from '../../store/history';
import { saveAuthToken } from '../../utils/http-util';

export default action$ =>
    action$.pipe(
        ofType(SIGNUP),
        map(action => action.payload),
        switchMap(user =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(signupLoading(true)),
                signup(user).pipe(
                    mergeMap(({ data }) => {
                        const { user, authToken } = data;
                        const {
                            location: { state }
                        } = browserHistory;
                        let pathname = '/';
                        if (state && state.redirectTo) {
                            pathname = state.redirectTo;
                        }
                        saveAuthToken(authToken);
                        return of(
                            setConnectivityStatus({ loading: false }),
                            signupLoading(false),
                            signupSuccess(user),
                            push(pathname)
                        );
                    }),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            signupLoading(false),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            )
        )
    );
