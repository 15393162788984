// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { validate as validateEmail } from 'email-validator';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './styles';

import type { User } from '../../../types/user';

class EmailOptions extends Component<
    {
        actions: {
            updateCurrentUser: (data: any) => void
        },
        expanded: boolean,
        onExpansionPanelChange: () => void,
        onCancel: () => void,
        classes: any,
        loggedInUser: User,
        updateLoading: boolean
    },
    {
        user: User,
        error: {
            email: string
        }
    }
> {
    static propTypes = {
        actions: PropTypes.shape({
            updateCurrentUser: PropTypes.func.isRequired
        }).isRequired,
        expanded: PropTypes.bool,
        onExpansionPanelChange: PropTypes.func,
        onCancel: PropTypes.func.isRequired,
        loggedInUser: PropTypes.object.isRequired,
        updateLoading: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            error: {
                email: ''
            },
            user: {
                ...props.loggedInUser
            }
        };
    }

    componentDidUpdate(prevProps) {
        const { loggedInUser } = this.props;
        const { loggedInUser: oldLoggedInUser } = prevProps;
        if (loggedInUser !== oldLoggedInUser) {
            this.setState({
                user: loggedInUser,
                error: {
                    email: ''
                }
            });
        }
    }

    onKeyDown = event => {
        switch (event.keyCode) {
            case 13: {
                this.handleSaveButtonClick();
                break;
            }
            default:
                break;
        }
    };

    handleEmailChange = e => {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                email: e.target.value
            }
        });
    };

    validateFields(setToState: boolean = true) {
        const { user } = this.state;
        const { email } = user;
        const error = {
            email: ''
        };
        let isValid = true;

        if (email.trim() === '') {
            isValid = false;
            error.email = 'Please enter your email';
        } else if (!validateEmail(email)) {
            isValid = false;
            error.email = 'Invalid Email';
        }

        if (setToState && Object.keys(error).length) {
            this.setState({ error });
        }

        return isValid;
    }

    handleSaveButtonClick = () => {
        const {
            user: { email }
        } = this.state;
        const {
            loggedInUser: { email: oldEmail },
            actions: { updateCurrentUser }
        } = this.props;
        if (this.validateFields()) {
            const data = {};
            if (email.trim() !== oldEmail.trim()) {
                data.email = email.trim();
            }
            if (Object.entries(data).length !== 0) {
                updateCurrentUser(data);
            }
        }
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    render() {
        const {
            classes,
            onCancel,
            updateLoading,
            expanded,
            onExpansionPanelChange
        } = this.props;
        const { user, error } = this.state;

        return (
            <ExpansionPanel
                expanded={expanded}
                onChange={onExpansionPanelChange}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div className={classes.column33}>
                        <Typography className={classes.heading}>
                            Emails
                        </Typography>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.infoWrap}>
                    <FormControl
                        className={classes.formControl}
                        required
                        error={!!error.email}
                    >
                        <InputLabel htmlFor="profile-primary-email-field">
                            Primary Email
                        </InputLabel>
                        <Input
                            id="profile-primary-email-field"
                            autoComplete="email"
                            value={user.email}
                            type="email"
                            onChange={this.handleEmailChange}
                            inputProps={this.inputProps}
                        />
                        {error.email && (
                            <FormHelperText error>{error.email}</FormHelperText>
                        )}
                    </FormControl>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                    <Button size="small" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        color="primary"
                        onClick={this.handleSaveButtonClick}
                        disabled={updateLoading}
                    >
                        Save
                    </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        );
    }
}

export default withStyles(styles)(EmailOptions);
