// @flow

import { filter, find, findIndex } from 'lodash';
import { combineReducers } from 'redux';
import globalInitialState from './initial-state';
import {
    ADD_DEVICE_LOADING,
    ADD_DEVICE_SUCCESS,
    GET_DEVICES_ERROR,
    GET_DEVICES_LOADING,
    GET_DEVICES_SUCCESS,
    UPDATE_DEVICE_SUCCESS,
    UPDATE_DEVICE_LOADING,
    DELETE_DEVICE_SUCCESS,
    DELETE_DEVICE_LOADING,
    REMOVE_CURRENT_USER_FROM_DEVICE_SUCCESS,
    UPDATE_DEVICES_ORDERING_SUCCESS
} from '../constants/action-types';
import statuses from './devices-statuses-reducer';
import { singlePayloadReducer } from '../utils/reducer-util';

const initialState = globalInitialState.devices;

function data(state = initialState.data, action) {
    switch (action.type) {
        case GET_DEVICES_SUCCESS: {
            return action.payload.data.devices;
        }
        case ADD_DEVICE_SUCCESS: {
            return [action.payload, ...state];
        }
        case UPDATE_DEVICES_ORDERING_SUCCESS: {
            const orderedDevices = [...action.payload];
            return state.map(r => {
                const index = findIndex(orderedDevices, { _id: r._id });
                if (index !== -1) {
                    return { ...r, order: orderedDevices[index].order };
                }

                return { ...r };
            });
        }
        case REMOVE_CURRENT_USER_FROM_DEVICE_SUCCESS: {
            const { removedUserDevices } = action.payload;
            let newState = [...state];
            const idMapper = userDevice => userDevice.device._id;
            if (removedUserDevices && removedUserDevices.length) {
                const removedUserDeviceIds = removedUserDevices.map(idMapper);
                newState = filter(
                    newState,
                    ({ _id }) => removedUserDeviceIds.indexOf(_id) === -1
                );
            }

            const modified = newState.length !== state.length;
            return modified ? newState : state;
        }
        case UPDATE_DEVICE_SUCCESS: {
            const { _id } = action.payload;
            const data = [...state];
            let datum = find(data, { _id });
            if (!datum) {
                return state;
            }
            const indexOf = data.indexOf(datum);
            datum = {
                ...datum,
                ...action.payload
            };

            return [
                ...data.slice(0, indexOf),
                datum,
                ...data.slice(indexOf + 1)
            ];
        }
        case DELETE_DEVICE_SUCCESS: {
            const _id = action.payload;
            const data = [...state];
            const datum = find(data, { _id });
            if (!datum) {
                return state;
            }
            const indexOf = data.indexOf(datum);

            return [...data.slice(0, indexOf), ...data.slice(indexOf + 1)];
        }
        default:
            return state;
    }
}

function count(state = initialState.count, action) {
    switch (action.type) {
        case GET_DEVICES_SUCCESS: {
            return action.payload.data.devices.length;
        }
        case ADD_DEVICE_SUCCESS:
            return state + 1;
        default:
            return state;
    }
}

function lastAddedDeviceId(state = initialState.lastAddedDeviceId, action) {
    switch (action.type) {
        case ADD_DEVICE_SUCCESS: {
            return action.payload._id;
        }
        default:
            return state;
    }
}

function updateLoading(state = initialState.loading.update, action) {
    switch (action.type) {
        case UPDATE_DEVICE_LOADING: {
            const { id, loading } = action.payload;
            const currentIndex = state.indexOf(id);
            const currentlyLoading = state.indexOf(id) !== -1;
            if (loading && !currentlyLoading) {
                return [...state, id];
            }
            if (!loading && currentlyLoading) {
                const newState = [...state];
                newState.splice(currentIndex, 1);
                return newState;
            }
            return state;
        }

        default:
            return state;
    }
}

function deleteLoading(state = initialState.loading.remove, action) {
    switch (action.type) {
        case DELETE_DEVICE_LOADING: {
            const { id, loading } = action.payload;
            const currentIndex = state.indexOf(id);
            const currentlyLoading = state.indexOf(id) !== -1;
            if (loading && !currentlyLoading) {
                return [...state, id];
            }
            if (!loading && currentlyLoading) {
                const newState = [...state];
                newState.splice(currentIndex, 1);
                return newState;
            }
            return state;
        }

        default:
            return state;
    }
}

export default combineReducers({
    data,
    count,
    lastAddedDeviceId,
    loading: combineReducers({
        get: singlePayloadReducer(
            GET_DEVICES_LOADING,
            initialState.loading.get
        ),
        add: singlePayloadReducer(ADD_DEVICE_LOADING, initialState.loading.add),
        update: updateLoading,
        remove: deleteLoading
    }),
    error: combineReducers({
        get: singlePayloadReducer(GET_DEVICES_ERROR, initialState.error.get)
    }),
    statuses
});
