// @flow
import { combineReducers } from 'redux';
import globalInitialState from './initial-state';
import {
    UPDATE_TUNNEL_SEARCH,
    GET_LOGGED_IN_USER_SUCCESS,
    LOGIN_SUCCESS,
    GET_TUNNEL_STATUSES_SUCCES,
    UPDATE_TUNNEL_UI_FILTERS,
    UPDATE_TUNNEL_FILTER,
    RESET_ALL_TUNNEL_FILTERS
} from '../constants/action-types';
import {
    getLoggedInUserIdHash,
    constructLoggedInUserIdHash
} from '../utils/user-utils';

const initialState = globalInitialState.tunnels;

function searchQuery(state = initialState.searchQuery, action) {
    switch (action.type) {
        case UPDATE_TUNNEL_SEARCH:
            return action.payload;
        default:
            return state;
    }
}

function uiFilters(state = initialState.uiFilters, action) {
    switch (action.type) {
        case UPDATE_TUNNEL_UI_FILTERS: {
            const filterToUpdate = action.payload;
            const existingFilter = state.find(
                uiF => uiF.property === filterToUpdate.property
            );
            const index = state.indexOf(existingFilter);
            const newState = [...state];
            newState.splice(index, 1, filterToUpdate);
            return newState;
        }
        default:
            return state;
    }
}

function filters(state = initialState.filters, action) {
    switch (action.type) {
        case GET_LOGGED_IN_USER_SUCCESS:
        case LOGIN_SUCCESS: {
            const userIdHash =
                getLoggedInUserIdHash() ||
                constructLoggedInUserIdHash(action.payload._id);
            const storageFilters = localStorage.getItem(
                `tunnelFilters-${userIdHash}`
            );
            return storageFilters ? JSON.parse(storageFilters) : state;
        }
        case UPDATE_TUNNEL_FILTER: {
            const { property, value } = action.payload;
            let filter = state.filter(f => f.property === property)[0];
            const index = state.indexOf(filter);
            const newState = [...state];
            if (!filter) {
                filter = {
                    property,
                    values: [value]
                };
                newState.push(filter);
            } else {
                const valueIndex = filter.values.indexOf(value);
                if (valueIndex > -1) {
                    const newValues = [...filter.values];
                    newValues.splice(valueIndex, 1);
                    filter = {
                        ...filter,
                        values: newValues
                    };
                } else {
                    filter = {
                        ...filter,
                        values: [...filter.values, value]
                    };
                }
                newState[index] = filter;
            }
            const userIdHash = getLoggedInUserIdHash();
            localStorage.setItem(
                `tunnelFilters-${userIdHash}`,
                JSON.stringify(newState)
            );
            return newState;
        }
        default:
            return state;
    }
}

function statuses(state = initialState.statuses, action) {
    switch (action.type) {
        case GET_TUNNEL_STATUSES_SUCCES:
            return { ...state, data: action.payload };
        default:
            return state;
    }
}

const tunnelReducer = (state = initialState, action) => {
    if (action.type === RESET_ALL_TUNNEL_FILTERS) {
        const userIdHash = getLoggedInUserIdHash();
        localStorage.setItem(
            `tunnelFilters-${userIdHash}`,
            JSON.stringify(initialState.filters)
        );
        return {
            ...state,
            searchQuery: '',
            filters: initialState.filters,
            uiFilters: initialState.uiFilters
        };
    }
    return combineReducers({
        searchQuery,
        filters,
        uiFilters,
        statuses
    })(state, action);
};

export default tunnelReducer;
