import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { createStructuredSelector } from 'reselect';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import EditIcon from '@material-ui/icons/Edit';
import StartIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import RefreshIcon from '@material-ui/icons/Refresh';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { wrapActionCreators } from '../../../utils/container-util';
import Star from '../../shared/Star';
import Authorities from '../../../containers/Authorities';
import {
    DELETE_TUNNEL_ACTION_AUTH,
    EDIT_TUNNEL_ACTION_AUTH
} from '../../../constants/authorities';
import { stopTunnel, openTunnel } from '../../../api/tunnel-api';
import { setGlobalMessageError } from '../../../actions/app-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../../utils/global-message-util';
import { MQTT_ENV } from '../../../constants/api-constants';
import { getLoggedInUser } from '../../../selectors/app-selectors';
import {
    removeListener,
    addListener,
    subscribeToTopic
} from '../../../utils/mqtt';

const styles = () => ({
    wrapper: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        borderBottom: '1px dashed #ccc'
    },
    localPort: {
        width: 120,
        margin: '0 5px'
    },
    actions: {
        width: 192,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse'
    },
    urls: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline'
    },
    url: {
        textTransform: 'none'
    },
    tunnelActionIcon: {
        color: '#9c27b0'
    },
    disabledTunnelActionIcon: {
        color: '#ecb4f5'
    },
    circularIcon: {
        padding: 12
    }
});
const HttpTunnelItemMobile = ({
    classes,
    loggedInUser,
    tunnel,
    tunnel: {
        id,
        dashUrl,
        favourite,
        slashUrl,
        localPort,
        status: initialStatus,
        deviceId
    },
    tunnelEdit,
    tunnelDelete,
    handleFavouriteClick,
    actions: { setGlobalMessageError },
    doRefresh
}) => {
    const [doStart, setDoStart] = useState(false);
    const [doStop, setDoStop] = useState(false);
    const [
        changeTunnelStateInProcess,
        setChangeTunnelStateInProcess
    ] = useState(false);
    const [status, setStatus] = useState(initialStatus);
    const [tunnelActionsAnchorEl, setTunnelActionsAnchorEl] = useState(null);
    const subscribeToTopicCallback = messageActionName => {
        const fn = (t, message) => {
            const action = JSON.parse(message);
            if (action && action.type === 'NOTIFY_USER') {
                const { payload } = action;
                const { tunnelId, action: actionName } = payload;
                if (
                    tunnelId === id &&
                    actionName === messageActionName &&
                    t === `${MQTT_ENV}/user--${loggedInUser._id}/server`
                ) {
                    setChangeTunnelStateInProcess(false);
                    removeListener(fn);
                }
            }
        };
        addListener(fn);
        const topic = `user--${loggedInUser._id}/server`;
        subscribeToTopic(topic);
    };
    useEffect(
        () => {
            if (doRefresh === id) {
                setDoStart(true);
            }
        },
        [doRefresh]
    );
    useEffect(
        () => {
            let canceled = false;
            setTunnelActionsAnchorEl(null);
            if (doStop) {
                setChangeTunnelStateInProcess(true);
                stopTunnel(id, {
                    deviceId,
                    type: 'http'
                }).subscribe(
                    res => {
                        if (!canceled) {
                            setDoStop(false);
                            setStatus('stopped');
                        }
                        if (res && res.data && res.data.tunnel) {
                            subscribeToTopicCallback('stop_tunnel');
                        } else {
                            setGlobalMessageError(`Can't stop tunnel`);
                            setChangeTunnelStateInProcess(false);
                        }
                    },
                    error => {
                        setDoStop(false);
                        setGlobalMessageError(
                            constructGlobalErrorMessage(
                                parseErrorMessage(error)
                            )
                        );
                    }
                );
            }
            return () => {
                canceled = true;
            };
        },
        [doStop]
    );

    useEffect(
        () => {
            let canceled = false;
            setTunnelActionsAnchorEl(null);
            if (doStart) {
                setChangeTunnelStateInProcess(true);
                openTunnel({
                    deviceId,
                    tunnelId: id,
                    options: tunnel.options
                }).subscribe(
                    res => {
                        if (!canceled) {
                            setDoStart(false);
                            setStatus('started');
                        }
                        if (res && res.data && res.data.tunnel) {
                            subscribeToTopicCallback('start_tunnel');
                        } else {
                            setGlobalMessageError(`Can't start tunnel`);
                            setChangeTunnelStateInProcess(false);
                        }
                    },
                    error => {
                        setDoStart(false);
                        setGlobalMessageError(
                            constructGlobalErrorMessage(
                                parseErrorMessage(error)
                            )
                        );
                    }
                );
            }
            return () => {
                canceled = true;
            };
        },
        [doStart]
    );
    const handleMenuClick = event => {
        setTunnelActionsAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setTunnelActionsAnchorEl(null);
    };

    return (
        <div className={classes.wrapper}>
            <Star
                onChange={() => handleFavouriteClick(tunnel)}
                active={favourite}
            />
            <div className={classes.localPort}>
                <span>localhost: &nbsp;</span>
                {localPort}
            </div>
            <div style={{ flex: 1 }} />
            <div className={classes.actions}>
                <div>
                    {!changeTunnelStateInProcess ? (
                        <IconButton
                            aria-label="More"
                            aria-owns={
                                tunnelActionsAnchorEl
                                    ? 'tunnel-actions-menu'
                                    : null
                            }
                            aria-haspopup="true"
                            className={classes.moreActionsButton}
                            onClick={handleMenuClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    ) : (
                        <div className={classes.circularIcon}>
                            <CircularProgress size={20} />
                        </div>
                    )}
                    <Menu
                        id="tunnel-actions-menu"
                        anchorEl={tunnelActionsAnchorEl}
                        open={Boolean(tunnelActionsAnchorEl)}
                        onClose={handleMenuClose}
                    >
                        {status === 'started' && (
                            <MenuItem>
                                <a
                                    rel="noopener noreferrer"
                                    className={classes.generatedNameItem}
                                    target="_blank"
                                    href={`https://${dashUrl}`}
                                >
                                    <LinkIcon
                                        className={classes.tunnelActionIcon}
                                    />
                                </a>
                            </MenuItem>
                        )}
                        {false && (
                            <MenuItem>
                                <a
                                    rel="noopener noreferrer"
                                    className={classes.generatedNameItem}
                                    target="_blank"
                                    href={`https://${slashUrl}`}
                                >
                                    <LinkIcon
                                        className={classes.tunnelActionIcon}
                                    />
                                </a>
                            </MenuItem>
                        )}
                        {(status === 'stopped' || !status) && (
                            <MenuItem onClick={() => setDoStart(true)}>
                                <StartIcon
                                    style={{ margin: '0 auto' }}
                                    className={classes.tunnelActionIcon}
                                />
                            </MenuItem>
                        )}
                        {status === 'started' && (
                            <MenuItem onClick={() => setDoStop(true)}>
                                <StopIcon
                                    style={{ margin: '0 auto' }}
                                    className={classes.tunnelActionIcon}
                                />
                            </MenuItem>
                        )}
                        {status === 'started' && (
                            <MenuItem onClick={() => setDoStart(true)}>
                                <RefreshIcon
                                    style={{ margin: '0 auto' }}
                                    className={classes.tunnelActionIcon}
                                />
                            </MenuItem>
                        )}
                        <Authorities>
                            <MenuItem
                                data-cmpauthkey={EDIT_TUNNEL_ACTION_AUTH}
                                onClick={() => tunnelEdit(tunnel)}
                            >
                                <EditIcon
                                    style={{ margin: '0 auto' }}
                                    className={classes.tunnelActionIcon}
                                />
                            </MenuItem>
                        </Authorities>
                        <Authorities>
                            <MenuItem
                                data-cmpauthkey={DELETE_TUNNEL_ACTION_AUTH}
                                onClick={() => tunnelDelete(tunnel)}
                            >
                                <DeleteIcon
                                    style={{ margin: '0 auto' }}
                                    className={classes.tunnelActionIcon}
                                />
                            </MenuItem>
                        </Authorities>
                    </Menu>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser()
});

const mapDispatchToProps = wrapActionCreators({
    setGlobalMessageError
});

HttpTunnelItemMobile.propTypes = {
    classes: PropTypes.object.isRequired,
    loggedInUser: PropTypes.object.isRequired,
    tunnel: PropTypes.shape({
        id: PropTypes.string.isRequired,
        deviceId: PropTypes.string.isRequired,
        dashUrl: PropTypes.string.isRequired,
        favourite: PropTypes.bool.isRequired,
        slashUrl: PropTypes.string.isRequired,
        localPort: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired
    }).isRequired,
    tunnelEdit: PropTypes.func.isRequired,
    tunnelDelete: PropTypes.func.isRequired,
    handleFavouriteClick: PropTypes.func.isRequired,
    actions: PropTypes.shape({
        setGlobalMessageError: PropTypes.func.isRequired
    }).isRequired,
    doRefresh: PropTypes.string.isRequired
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(HttpTunnelItemMobile)
);
