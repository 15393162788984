import { merge, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { TOGGLE_TWO_FACTOR_AUTH } from '../../constants/action-types';
import {
    setConnectivityStatus,
    setGlobalMessageError
} from '../../actions/app-actions';
import { toggleTwoFactorAuth } from '../../api/authentication-api';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';
import {
    toggleTwoFactorAuthLoading,
    updateCurrentUserSuccess
} from '../../actions/authentication-actions';

export default action$ =>
    action$.pipe(
        ofType(TOGGLE_TWO_FACTOR_AUTH),
        map(action => action.payload),
        switchMap(enabled => {
            return merge(
                of(setConnectivityStatus({ loading: true })),
                of(toggleTwoFactorAuthLoading(true)),
                toggleTwoFactorAuth(enabled).pipe(
                    mergeMap(({ data }) =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            toggleTwoFactorAuthLoading(false),
                            updateCurrentUserSuccess({
                                twoFactorAuth: {
                                    ...data
                                }
                            })
                        )
                    ),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            toggleTwoFactorAuthLoading(false),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            );
        })
    );
