import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Loader from './Loader';

const styles = () => ({
    root: {
        padding: '10px'
    }
});

const LoadingPane = ({ classes, children, loading, wrapperClass }) => {
    if (loading) {
        return (
            <div className={classes.root}>
                <Loader />
            </div>
        );
    }
    return <div className={wrapperClass}>{children}</div>;
};

LoadingPane.defaultProps = {
    wrapperClass: ''
};

LoadingPane.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool.isRequired,
    wrapperClass: PropTypes.string
};

export default withStyles(styles)(LoadingPane);
