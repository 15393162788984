// @flow
import { api, makeGet, makePost, makeDelete } from '../utils/http-util';

const API_URL = {
    GET_DEVICE_USERS: id => api`v1/devices/${id}/users`,
    ADD_OR_REMOVE_USERS_TO_DEVICE: id => api`v1/devices/${id}/users`,
    HAS_TUNNEL_SETTINGS: (deviceId, user) =>
        api`v1/devices/${deviceId}/users/${user}/has-tunnel-settings`,
    TUNNEL_SETTINGS: (deviceId, user) =>
        api`v1/devices/${deviceId}/users/${user}/tunnel-settings`
};

export function getDeviceUsers(id: string) {
    return makeGet(API_URL.GET_DEVICE_USERS(id));
}

export function addOrRemoveUsersToDevice(
    deviceId: string,
    removedUsers?: string[],
    emails?: string[]
) {
    return makePost(API_URL.ADD_OR_REMOVE_USERS_TO_DEVICE(deviceId), {
        removedUsers,
        emails
    });
}

export function hasTunnelSettingsForType(
    deviceId: string,
    userId: string,
    type: string
) {
    return makePost(API_URL.HAS_TUNNEL_SETTINGS(deviceId, userId), {
        type
    });
}

export function replaceOrCreateTunnelSettingsForType(
    deviceId: string,
    userId: string,
    config: { type: string }
) {
    return makePost(API_URL.TUNNEL_SETTINGS(deviceId, userId), config);
}

export function removeTunnelSettingsForType(
    deviceId: string,
    userId: string,
    type: string
) {
    return makeDelete(API_URL.TUNNEL_SETTINGS(deviceId, userId), {
        type
    });
}
