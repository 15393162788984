import { combineEpics } from 'redux-observable';
import loginEpic from './login-epic';
import getActiveUserEpic from './get-logged-in-user-epic';
import signupEpic from './signup-epic';
import logoutEpic from './logout-epic';
import changeCurrentUserPasswordEpic from './change-current-user-password-epic';
import updateCurrentUserEpic from './update-current-user-epic';
import toggleTwoFactorAuthEpic from './toggle-two-factor-auth-epic';
import regenerateTwoFactorAuthQrEpic from './regenerate-two-factor-auth-qr-epic';
import changeOrganizationEpics from './change-organization';
import signupActivateOrgUserEpicEpics from './signup-activate-orguser-epic';

export default combineEpics(
    loginEpic,
    getActiveUserEpic,
    signupEpic,
    logoutEpic,
    changeCurrentUserPasswordEpic,
    updateCurrentUserEpic,
    toggleTwoFactorAuthEpic,
    regenerateTwoFactorAuthQrEpic,
    changeOrganizationEpics,
    signupActivateOrgUserEpicEpics
);
