// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

const styles = () => ({
    sortableGroupWrapper: {
        padding: '10px 05px 10px 15px',
        display: 'flex',
        marginBottom: '10px'
    },
    sortableGroupName: {
        flex: '1',
        padding: '5px'
    },
    arrowButton: {
        padding: 3,
        cursor: 'pointer'
    }
});

class SortableGroup extends Component<{
    classes: {
        sortableGroupWrapper: string,
        sortableGroupName: string,
        arrowButton: string
    },
    index: number,
    name: string,
    _id: string,
    moveGroup: (dragIndex: number, direction: string) => void,
    groupsLength: number
}> {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
        moveGroup: PropTypes.func.isRequired,
        groupsLength: PropTypes.number.isRequired
    };

    onArrowDownClick = () => {
        const { index, moveGroup } = this.props;
        const direction = 'down';
        moveGroup(index, direction);
    };

    onArrowUpClick = () => {
        const { index, moveGroup } = this.props;
        const direction = 'up';
        moveGroup(index, direction);
    };

    render() {
        const { classes, name, index, groupsLength, _id } = this.props;
        return (
            <Paper className={classes.sortableGroupWrapper}>
                <div className={classes.sortableGroupName}>{name}</div>
                <IconButton
                    disabled={index === 0 || _id === '-1'}
                    className={classes.arrowButton}
                    onClick={this.onArrowUpClick}
                    aria-haspopup="true"
                >
                    <ArrowDropUp />
                </IconButton>
                <IconButton
                    disabled={index === groupsLength - 2 || _id === '-1'}
                    className={classes.arrowButton}
                    onClick={this.onArrowDownClick}
                    aria-haspopup="true"
                >
                    <ArrowDropDown />
                </IconButton>
            </Paper>
        );
    }
}

export default withStyles(styles)(SortableGroup);
