// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import PasswordRecovery from '../../../components/Authentication/PasswordRecovery';

import { passwordRecovery } from '../../../api/authentication-api';
import { wrapActionCreators } from '../../../utils/container-util';
import {
    setGlobalMessageError,
    setGlobalMessageSuccess
} from '../../../actions/app-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../../utils/global-message-util';

class PasswordRecoveryHOC extends React.Component<
    {
        actions: {
            setGlobalMessageError: any,
            setGlobalMessageSuccess: any
        }
    },
    {
        passwordRecoveryLoading: boolean,
        passwordRecoverySuccess: boolean
    }
> {
    state = {
        passwordRecoveryLoading: false,
        passwordRecoverySuccess: false
    };

    handlePasswordRecovery = (email: string) => {
        const {
            actions: { setGlobalMessageError, setGlobalMessageSuccess }
        } = this.props;
        this.setState({
            passwordRecoveryLoading: true,
            passwordRecoverySuccess: false
        });
        passwordRecovery(email).subscribe(
            () => {
                setGlobalMessageSuccess(
                    'Password Recovery Link (valid for two days.)\n' +
                        '                                    Sent To Your Email.'
                );
                this.setState({
                    passwordRecoveryLoading: false,
                    passwordRecoverySuccess: true
                });
            },
            err => {
                let message = 'Something went wrong';
                if (err) {
                    message = parseErrorMessage(err);
                }
                setGlobalMessageError(constructGlobalErrorMessage(message));
                this.setState({
                    passwordRecoveryLoading: false
                });
            }
        );
    };

    render() {
        const { passwordRecoveryLoading, passwordRecoverySuccess } = this.state;
        return (
            <PasswordRecovery
                handlePasswordRecovery={this.handlePasswordRecovery}
                passwordRecoveryLoading={passwordRecoveryLoading}
                passwordRecoverySuccess={passwordRecoverySuccess}
            />
        );
    }
}

PasswordRecoveryHOC.propTypes = {
    actions: PropTypes.shape({
        setGlobalMessageError: PropTypes.func.isRequired,
        setGlobalMessageSuccess: PropTypes.func.isRequired
    }).isRequired
};

const mapDispatchToProps = wrapActionCreators({
    setGlobalMessageError,
    setGlobalMessageSuccess
});

export default connect(
    null,
    mapDispatchToProps
)(PasswordRecoveryHOC);
