import { merge, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { ADD_OR_REMOVE_USERS_TO_DEVICE } from '../../constants/action-types';
import { addOrRemoveUsersToDevice } from '../../api/user-device-api';
import {
    setConnectivityStatus,
    setGlobalMessageError
} from '../../actions/app-actions';
import {
    addOrRemoveUsersToDeviceLoading,
    addOrRemoveUsersToDeviceSuccess
} from '../../actions/user-device-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default action$ =>
    action$.pipe(
        ofType(ADD_OR_REMOVE_USERS_TO_DEVICE),
        map(action => action.payload),
        switchMap(({ id, removedUsers, emails }) =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(addOrRemoveUsersToDeviceLoading(true)),
                addOrRemoveUsersToDevice(id, removedUsers, emails).pipe(
                    mergeMap(({ data }) =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            addOrRemoveUsersToDeviceLoading(false),
                            addOrRemoveUsersToDeviceSuccess(data)
                        )
                    ),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            addOrRemoveUsersToDeviceLoading(false),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            )
        )
    );
