import React, { useEffect, useState } from 'react';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { createStructuredSelector } from 'reselect';
import TunnelItem from '../../components/shared/tunnelItem';
import TunnelItemMobile from '../../components/shared/tunnelItemMobile';

import { selectDevicesStatusesData } from '../../selectors/devices-selectors';

const TunnelItemHOC = ({
    tunnel: tunnelFromProps,
    tunnelDelete,
    reloadingTunnelsInProcess,
    stoppingTunnelsInProcess,
    devicesStatuses
}) => {
    const [tunnel, setTunnel] = useState(tunnelFromProps);

    useEffect(
        () => {
            setTunnel(tunnelFromProps);
        },
        [tunnelFromProps]
    );

    const onTunnelReady = tunnel => {
        setTunnel(tunnel.data);
    };

    return (
        <div>
            <Hidden mdUp>
                <TunnelItemMobile
                    tunnel={tunnel}
                    onTunnelReady={onTunnelReady}
                    tunnelDelete={tunnelDelete}
                    reloadingTunnelsInProcess={reloadingTunnelsInProcess}
                    stoppingTunnelsInProcess={stoppingTunnelsInProcess}
                    devicesStatuses={devicesStatuses}
                />
            </Hidden>
            <Hidden smDown>
                <TunnelItem
                    tunnel={tunnel}
                    onTunnelReady={onTunnelReady}
                    tunnelDelete={tunnelDelete}
                    reloadingTunnelsInProcess={reloadingTunnelsInProcess}
                    stoppingTunnelsInProcess={stoppingTunnelsInProcess}
                    devicesStatuses={devicesStatuses}
                />
            </Hidden>
        </div>
    );
};

TunnelItemHOC.propTypes = {
    tunnel: PropTypes.object.isRequired,
    tunnelDelete: PropTypes.func,
    reloadingTunnelsInProcess: PropTypes.bool,
    stoppingTunnelsInProcess: PropTypes.bool,
    devicesStatuses: PropTypes.object.isRequired
};
// selectDevicesStatusesData
const mapStateToProps = createStructuredSelector({
    devicesStatuses: selectDevicesStatusesData()
});

export default connect(
    mapStateToProps,
    null
)(TunnelItemHOC);
