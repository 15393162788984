import React from 'react';

import { Mouse as GuacMouse } from 'apache-guacamole-common-js';

export default (WrappedComponent: any) => {
    class ClientWrapper extends React.Component<{}, {}> {
        onGuacMouseDownEvent = mouseState => {
            if (mouseState.left) {
                this.mouseLeftKeyDownState = { ...mouseState };
            }
            if (mouseState.right) {
                this.mouseRightKeyDownState = { ...mouseState };
            }
            this.guac.sendMouseState(mouseState);
        };

        onGuacMouseUpEvent = mouseState => {
            if (this.mouseLeftKeyDownState) {
                this.mouseLeftKeyDownState = null;
            }
            if (this.mouseRightKeyDownState) {
                if (navigator.clipboard) {
                    navigator.clipboard
                        .readText()
                        .then(text => {
                            this.guac.setClipboard(text);
                            this.guac.sendMouseState(mouseState);
                        })
                        .catch(err => {});
                } else {
                    this.guac.sendMouseState(mouseState);
                }
                this.mouseRightKeyDownState = null;
            } else {
                this.guac.sendMouseState(mouseState);
            }
            // this.guac.sendMouseState(mouseState);
        };

        onGuacMouseMoveEvent = origMoveState => {
            if (navigator.clipboard) {
                this.guac.sendMouseState(origMoveState);
            } else if (this.mouseLeftKeyDownState) {
                const upState = {
                    ...origMoveState,
                    left: false
                };
                this.guac.sendMouseState(upState);
                // Magic here, don't DELETE these two calls, it don't work with one :)
                this.guac.sendMouseState(this.mouseLeftKeyDownState);
                this.guac.sendMouseState(this.mouseLeftKeyDownState);
                this.guac.sendMouseState({ ...origMoveState, left: true });
            } else {
                this.guac.sendMouseState(origMoveState);
            }
        };

        setupGuacMouse() {
            const element = this.guac.getDisplay().getElement();

            this.guacMouse = new GuacMouse(element);

            this.guacMouse.onmousedown = this.onGuacMouseDownEvent;
            this.guacMouse.onmouseup = this.onGuacMouseUpEvent;
            this.guacMouse.onmousemove = this.onGuacMouseMoveEvent;
        }

        onGuacClientReady = guac => {
            this.guac = guac;
            this.setupGuacMouse();
        };

        render() {
            return (
                <WrappedComponent
                    ignoreMouse={true}
                    onGuacClientReady={this.onGuacClientReady}
                    {...this.props}
                />
            );
        }
    }

    ClientWrapper.WrappedComponent = WrappedComponent;
    return ClientWrapper;
};
