import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import PasswordField from '../../../components/PasswordField';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh'
    },
    card: {
        maxWidth: 345
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    fontSizeH1: {
        fontSize: '2rem'
    },
    settingsTabs: {
        margin: '25px 0 15px 0'
    },
    contentBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        height: '310px',
        paddingTop: '20px'
    },
    subHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '10px',
        alignItems: 'center'
    },
    authenticationMethodWrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        padding: '0 15px',
        marginTop: '8px',
        marginBottom: '8px'
    },
    authenticationMethodLabel: {
        padding: '10px',
        flex: '2'
    },
    authenticationMethodSelect: {
        flex: '3'
    }
});

class SSHSettings extends React.Component<
    {
        classes: {
            card: string,
            formControl: string,
            root: string,
            settingsTabs: string,
            contentBody: string,
            subHeader: string,
            authenticationMethodWrapper: string,
            authenticationMethodLabel: string,
            authenticationMethodSelect: string
        },
        closable: boolean,
        hasConfig: boolean,
        onDataReady: (data: any) => void,
        onCloseClick: () => void
    },
    {
        authentication: {
            username: string,
            password: string,
            privateKey: string,
            passphrase: string
        },
        settingsActiveTab: string,
        authenticationMethod: string,
        display: {
            colorScheme: string,
            fontName: string,
            fontSize: string
        },
        saveConfig: boolean
    }
> {
    static propTypes = {
        classes: PropTypes.shape({
            card: PropTypes.string.isRequired,
            formControl: PropTypes.string.isRequired,
            root: PropTypes.string.isRequired,
            settingsTabs: PropTypes.string.isRequired,
            contentBody: PropTypes.string.isRequired,
            subHeader: PropTypes.string.isRequired,
            authenticationMethodWrapper: PropTypes.string.isRequired,
            authenticationMethodLabel: PropTypes.string.isRequired,
            authenticationMethodSelect: PropTypes.string.isRequired
        }).isRequired,
        onDataReady: PropTypes.func.isRequired,
        onCloseClick: PropTypes.func,
        closable: PropTypes.bool,
        hasConfig: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            authentication: {
                username: '',
                password: '',
                privateKey: '',
                passphrase: ''
            },
            settingsActiveTab: 'authentication',
            authenticationMethod: 'password',
            display: {
                colorScheme: 'gray-black',
                fontName: '',
                fontSize: 12
            },
            saveConfig: !!props.hasConfig
        };
    }

    onKeyDown = event => {
        switch (event.keyCode) {
            case 13: {
                this.handleOKClick();
                break;
            }
            default:
                break;
        }
    };

    handleSettingsActiveTabChange = (event, value) => {
        this.setState({
            settingsActiveTab:
                value === 0 ? 'authentication' : 'displaySettings'
        });
    };

    handleAuthenticationMethodChange = event => {
        this.setState({
            authenticationMethod: event.target.value
        });
    };

    handleUsernameChange = (event: any) => {
        const { authentication } = this.state;
        this.setState({
            authentication: {
                ...authentication,
                username: event.target.value
            }
        });
    };

    handlePasswordChange = (value: string) => {
        const { authentication } = this.state;
        this.setState({
            authentication: {
                ...authentication,
                password: value,
                privateKey: '',
                passphrase: ''
            }
        });
    };

    handlePrivateKeyChange = (event: any) => {
        const { authentication } = this.state;
        this.setState({
            authentication: {
                ...authentication,
                privateKey: event.target.value,
                password: ''
            }
        });
    };

    handlePassphraseChange = (value: string) => {
        const { authentication } = this.state;
        this.setState({
            authentication: {
                ...authentication,
                passphrase: value,
                password: ''
            }
        });
    };

    handleColorSchemeChange = (event: any) => {
        const { display } = this.state;
        this.setState({
            display: {
                ...display,
                colorScheme: event.target.value
            }
        });
    };

    handleFontNameChange = (event: any) => {
        const { display } = this.state;
        this.setState({
            display: {
                ...display,
                fontName: event.target.value
            }
        });
    };

    handleFontSizeChange = (event: any) => {
        const { display } = this.state;
        this.setState({
            display: {
                ...display,
                fontSize: event.target.value
            }
        });
    };

    handleSaveConfigChange = event => {
        this.setState({ saveConfig: event.target.checked });
    };

    handleOKClick = () => {
        const {
            authentication: { password, username, privateKey, passphrase },
            display: { colorScheme, fontName, fontSize },
            saveConfig,
            authenticationMethod
        } = this.state;

        const { onDataReady } = this.props;

        let args = {
            saveConfig
        };
        if (username.trim() !== '') {
            args = {
                ...args,
                username
            };
        }
        if (authenticationMethod === 'password') {
            args = {
                ...args,
                password
            };
        } else {
            args = {
                ...args,
                'private-key': privateKey,
                passphrase
            };
        }
        if (colorScheme.trim() !== '') {
            args = {
                ...args,
                'color-scheme': colorScheme
            };
        }
        if (fontName.trim() !== '') {
            args = {
                ...args,
                'font-name': fontName
            };
        }
        if (fontSize) {
            args = {
                ...args,
                'font-size': fontSize
            };
        }
        onDataReady(args);
    };

    handleCloseClick = () => {
        const { onCloseClick } = this.props;
        if (onCloseClick) {
            onCloseClick();
        }
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    colorSchemeInputProps = {
        id: 'ssh-terminal-settings-color-scheme'
    };

    fontSizeInputProps = {
        onKeyDown: this.onKeyDown,
        min: 1
    };

    render() {
        const {
            classes: {
                card,
                root,
                settingsTabs,
                contentBody,
                formControl,
                authenticationMethodWrapper,
                authenticationMethodLabel,
                authenticationMethodSelect,
                fontSizeH1
            },
            closable
        } = this.props;

        const {
            authentication: { username, password, privateKey, passphrase },
            settingsActiveTab,
            authenticationMethod,
            display: { colorScheme, fontName, fontSize },
            saveConfig
        } = this.state;

        return (
            <form className={root} autoComplete="off">
                <Card className={card}>
                    <CardContent>
                        <Typography type="h1" color="inherit" variant="h1" classes={{
                            h1: fontSizeH1
                        }}>
                            SSH settings
                        </Typography>
                        <Tabs
                            value={
                                settingsActiveTab === 'authentication' ? 0 : 1
                            }
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            onChange={this.handleSettingsActiveTabChange}
                            className={settingsTabs}
                        >
                            <Tab label="Authentication" />
                            <Tab label="Display Settings" />
                        </Tabs>
                        {settingsActiveTab === 'authentication' && (
                            <div className={contentBody}>
                                <div className={authenticationMethodWrapper}>
                                    <div className={authenticationMethodLabel}>
                                        Method
                                    </div>
                                    <Select
                                        onChange={
                                            this
                                                .handleAuthenticationMethodChange
                                        }
                                        className={authenticationMethodSelect}
                                        value={authenticationMethod}
                                    >
                                        <MenuItem value="password">
                                            Password
                                        </MenuItem>
                                        <MenuItem value="privateKey">
                                            Private Key
                                        </MenuItem>
                                    </Select>
                                </div>
                                <FormControl className={formControl}>
                                    <InputLabel htmlFor="username">
                                        Username
                                    </InputLabel>
                                    <Input
                                        id="ssh-terminal-settings-username"
                                        autoComplete="off"
                                        value={username}
                                        onChange={this.handleUsernameChange}
                                        inputProps={this.inputProps}
                                    />
                                </FormControl>
                                {authenticationMethod === 'password' && (
                                    <PasswordField
                                        isPasswordRequired={false}
                                        label="Password"
                                        type="ssh-terminal-settings-password"
                                        autoComplete="off"
                                        password={password}
                                        onChange={this.handlePasswordChange}
                                        onEnter={this.handleOKClick}
                                        className={formControl}
                                    />
                                )}
                                {authenticationMethod === 'privateKey' && (
                                    <FormControl className={formControl}>
                                        <InputLabel htmlFor="privateKey">
                                            Private key
                                        </InputLabel>
                                        <Input
                                            id="ssh-terminal-settings-private-key"
                                            multiline
                                            autoComplete="off"
                                            rows="3"
                                            value={privateKey}
                                            onChange={
                                                this.handlePrivateKeyChange
                                            }
                                        />
                                    </FormControl>
                                )}
                                {authenticationMethod === 'privateKey' && (
                                    <PasswordField
                                        isPasswordRequired={false}
                                        label="Passphrase"
                                        autoComplete="off"
                                        type="ssh-terminal-settings-passphrase"
                                        password={passphrase}
                                        onChange={this.handlePassphraseChange}
                                        onEnter={this.handleOKClick}
                                        className={formControl}
                                    />
                                )}
                            </div>
                        )}
                        {settingsActiveTab === 'displaySettings' && (
                            <div className={contentBody}>
                                <FormControl className={formControl}>
                                    <InputLabel htmlFor="colorScheme">
                                        Color scheme
                                    </InputLabel>
                                    <Select
                                        value={colorScheme}
                                        onChange={this.handleColorSchemeChange}
                                        inputProps={this.colorSchemeInputProps}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="black-white">
                                            black-white
                                        </MenuItem>
                                        <MenuItem value="gray-black">
                                            gray-black
                                        </MenuItem>
                                        <MenuItem value="green-black">
                                            green-black
                                        </MenuItem>
                                        <MenuItem value="white-black">
                                            white-black
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl className={formControl}>
                                    <InputLabel htmlFor="fontName">
                                        Font name
                                    </InputLabel>
                                    <Input
                                        id="ssh-terminal-settings-font-name"
                                        value={fontName}
                                        onChange={this.handleFontNameChange}
                                        inputProps={this.inputProps}
                                    />
                                </FormControl>
                                <FormControl className={formControl}>
                                    <InputLabel htmlFor="fontSize">
                                        Font size
                                    </InputLabel>
                                    <Input
                                        id="ssh-terminal-settings-font-size"
                                        value={fontSize}
                                        type="number"
                                        onChange={this.handleFontSizeChange}
                                        inputProps={this.fontSizeInputProps}
                                    />
                                </FormControl>
                            </div>
                        )}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={saveConfig}
                                    onChange={this.handleSaveConfigChange}
                                    value="saveConfig"
                                />
                            }
                            label="Save Configuration (all configs will be encrypted)"
                        />
                    </CardContent>
                    <CardActions>
                        <Button
                            size="small"
                            color="primary"
                            onClick={this.handleOKClick}
                        >
                            OK
                        </Button>
                        {closable && (
                            <Button
                                size="small"
                                color="primary"
                                onClick={this.handleCloseClick}
                            >
                                Close
                            </Button>
                        )}
                    </CardActions>
                </Card>
            </form>
        );
    }
}

export default withStyles(styles)(SSHSettings);
