import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { isAuthenticated, isEmailVerified } from '../selectors/app-selectors';
// import ActivateAccount from './Users/ActivateAccount';

const styles = () => ({
    authenticatedRoot: {
        flex: '1 1 0%',
        display: 'flex'
    },
    unAuthenticatedRoot: {
        display: 'flex',
        flex: '1 1 0%',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

export default (
    options = {
        authenticatedGuard: true
    }
) => Component => {
    function WithAuthGuard(props) {
        const { authenticatedGuard } = options;
        const { isAuthenticated, location } = props;
        const { classes, ...propsWithOutClasses } = props;

        if (authenticatedGuard) {
            if (isAuthenticated) {
                return (
                    <div className={classes.authenticatedRoot}>
                        <Component {...propsWithOutClasses} />
                    </div>
                );
            }
            return (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { redirectTo: location }
                    }}
                />
            );
        }

        if (!isAuthenticated) {
            return (
                <div className={classes.unAuthenticatedRoot}>
                    <Component {...propsWithOutClasses} />
                </div>
            );
        }
        return <Redirect to="/" />;
    }

    WithAuthGuard.propTypes = {
        location: PropTypes.shape({
            search: PropTypes.string.isRequired
        }).isRequired,
        classes: PropTypes.object.isRequired,
        isAuthenticated: PropTypes.bool.isRequired
    };

    const mapStateToProps = createStructuredSelector({
        isEmailVerified: isEmailVerified(),
        isAuthenticated: isAuthenticated()
    });

    WithAuthGuard.displayName = `WithAuthGuard(${Component.displayName ||
        Component.name})`;
    return withRouter(
        connect(
            mapStateToProps,
            null
        )(withStyles(styles)(WithAuthGuard))
    );
};
