// @flow
import { of } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';

import { getTerminalToken } from '../../api/terminal-api';
import {
    hasTunnelSettingsForType as hasTunnelSettingsForTypeApi,
    replaceOrCreateTunnelSettingsForType as replaceOrCreateTunnelSettingsForTypeApi,
    removeTunnelSettingsForType as removeTunnelSettingsForTypeApi
} from '../../api/user-device-api';
import { setGlobalMessageError } from '../../actions/app-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';
import getStore from '../../store/get-store';

export function loadToken(
    type: string,
    deviceId: string,
    userId: string,
    settings: {}
) {
    const data = {
        connection: {
            type,
            settings
        }
    };

    return getTerminalToken(deviceId, userId, data);
}

export function hasTunnelSettingsForType(
    type: string,
    deviceId: string,
    userId: string
) {
    return hasTunnelSettingsForTypeApi(deviceId, userId, type).pipe(
        map(({ data }) => data),
        catchError(error => {
            const store = getStore();
            store.dispatch(
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                )
            );
            return of(false);
        })
    );
}

export function replaceOrCreateTunnelSettingsForType(
    type: string,
    deviceId: string,
    userId: string,
    data: {}
) {
    return replaceOrCreateTunnelSettingsForTypeApi(deviceId, userId, {
        type,
        ...data
    }).pipe(
        mapTo(true),
        catchError(error => {
            const store = getStore();
            store.dispatch(
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                )
            );
            return of(false);
        })
    );
}

export function removeTunnelSettingsForType(
    type: string,
    deviceId: string,
    userId: string
) {
    return removeTunnelSettingsForTypeApi(deviceId, userId, type).pipe(
        mapTo(true),
        catchError(error => {
            const store = getStore();
            store.dispatch(
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                )
            );
            return of(false);
        })
    );
}
