// @flow
import {
    api,
    makeGet,
    makePost,
    makePatch,
    makeDelete
} from '../utils/http-util';

const API_URL = {
    GET_DEVICES: () => api`v1/devices`,
    ADD_DEVICE: () => api`v1/devices`,
    UPDATE_DEVICE: id => api`v1/devices/${id}`,
    DELETE_DEVICE: id => api`v1/devices/${id}`,
    GET_DEVICE_USERS: id => api`v1/devices/${id}/users`,
    ADD_OR_REMOVE_USERS_TO_DEVICE: id => api`v1/devices/${id}/users`,
    UPDATE_DEVICES_ORDERING: () => api`v1/devices/orders`,
    CHANGE_DEVICE_OWNER: id => api`v1/devices/${id}/change-device-owner`,
    CHANGE_DEVICE_OWNER_APPROVE: () =>
        api`v1/devices/change-device-owner-approve`,
    GET_DEVICE_TUNNELS: deviceId => api`v1/devices/${deviceId}/tunnels`,
    GET_DEVICES_STATUSES: () => api`v1/devices/statuses`
};

export function getDevices() {
    return makeGet(API_URL.GET_DEVICES());
}

export function addDevice(device: any) {
    return makePost(API_URL.ADD_DEVICE(), device);
}

export function updateDevice(device: any) {
    return makePatch(API_URL.UPDATE_DEVICE(device._id), device);
}

export function deleteDevice(id: string) {
    return makeDelete(API_URL.DELETE_DEVICE(id));
}

export function getDeviceUsers(id: string) {
    return makeGet(API_URL.GET_DEVICE_USERS(id));
}

export function updateDevicesOrdering(devices: any[]) {
    return makePost(API_URL.UPDATE_DEVICES_ORDERING(), devices);
}

export function addOrRemoveUsersToDevice(
    deviceId: string,
    removedUsers?: string[],
    emails?: string[]
) {
    return makePost(API_URL.ADD_OR_REMOVE_USERS_TO_DEVICE(deviceId), {
        removedUsers,
        emails
    });
}

export function changeDeviceOwner(
    deviceId: string,
    email: string,
    tunnelsToMove: string[]
) {
    return makePost(API_URL.CHANGE_DEVICE_OWNER(deviceId), {
        email,
        tunnelsToMove
    });
}

export function changeDeviceOwnerApprove(
    token: string,
    validationNumbers: string,
    headers: any
) {
    return makePost(
        API_URL.CHANGE_DEVICE_OWNER_APPROVE(),
        {
            token,
            validationNumbers
        },
        headers
    );
}

export function getDeviceTunnels(deviceId: string) {
    return makeGet(API_URL.GET_DEVICE_TUNNELS(deviceId));
}

export function getDevicesStatuses() {
    return makeGet(API_URL.GET_DEVICES_STATUSES());
}
