// @flow

import { combineReducers } from 'redux';
import globalInitialState from './initial-state';

const initialState = globalInitialState.users;

function data(state = initialState.data, action) {
    // if (action.type === '...') {
    //     return [];
    // }
    return state;
}

export default combineReducers({
    data
});
