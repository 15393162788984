import { createAction } from 'redux-actions';
import {
    UPDATE_TUNNEL_SEARCH,
    RESET_ALL_TUNNEL_FILTERS,
    GET_TUNNEL_STATUSES,
    GET_TUNNEL_STATUSES_SUCCES,
    UPDATE_TUNNEL_UI_FILTERS,
    UPDATE_TUNNEL_FILTER
} from '../constants/action-types';

export const updateTunnelSearch = createAction(UPDATE_TUNNEL_SEARCH);
export const resetAllTunnelFilters = createAction(RESET_ALL_TUNNEL_FILTERS);
export const updateTunnelUiFilters = createAction(UPDATE_TUNNEL_UI_FILTERS);

export const updateTunnelFilter = createAction(UPDATE_TUNNEL_FILTER);

export const getTunnelStatuses = createAction(GET_TUNNEL_STATUSES);
export const getTunnelStatusesSuccess = createAction(
    GET_TUNNEL_STATUSES_SUCCES
);
