// @flow
import type { InitialState } from '../types/initial-state';
import { GLOBAL_COOKIE_MESSAGE } from '../constants/message-constants';

const initialState: InitialState = {
    global: {
        loading: {
            login: false,
            signup: false,
            updatingCurrentUser: false,
            changingPassword: false,
            toggleTwoFactorAuth: false,
            regenerateTwoFactorAuthQR: false
        },
        message: {
            cookies: localStorage.getItem('cookiesMessageShowed')
                ? ''
                : GLOBAL_COOKIE_MESSAGE,
            error: '',
            success: ''
        },
        insufficientlyAuthenticationDetails: [],
        loggedInUser: null,
        devicesTourDetails: {
            visibility: true,
            step: 0
        },
        tunnelsTourDetails: {
            visibility: true,
            step: 0
        }
    },
    users: {
        data: []
    },
    userDevices: {
        loading: {
            get: false,
            add: false,
            remove: false,
            update: false
        },
        data: []
    },
    devices: {
        statuses: {
            data: {},
            loading: false
        },
        loading: {
            get: false,
            add: false,
            update: [],
            remove: []
        },
        error: {
            get: ''
        },
        data: [],
        count: 0,
        lastAddedDeviceId: null
    },
    groups: {
        data: []
    },
    tunnels: {
        searchQuery: '',
        filters: [
            {
                property: 'type',
                values: ['http', 'custom', 'ssh', 'rdp', 'vnc', 'proxy']
            }
        ],
        uiFilters: [
            { property: 'tunnelStatus', value: 'all' },
            { property: 'deviceStatus', value: 'all' }
        ],
        statuses: {
            data: []
        }
    }
};

export default initialState;
