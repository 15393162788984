import { merge, of, iif } from 'rxjs';
import { switchMap, map, filter, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { push } from 'react-router-redux';
import { LOGIN } from '../../constants/action-types';
import {
    setConnectivityStatus,
    setGlobalMessageError
} from '../../actions/app-actions';
import {
    loginLoading,
    loginSuccess,
    insufficientlyAuthenticationDetails
} from '../../actions/authentication-actions';
import { login } from '../../api/authentication-api';
import browserHistory from '../../store/history';
import { saveAuthToken } from '../../utils/http-util';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default action$ =>
    action$.pipe(
        ofType(LOGIN),
        map(action => action.payload),
        filter(data => !!data.email && !!data.password),
        switchMap(({ email, password, twoFactorAuthStr, redirect }) =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(loginLoading(true)),
                login(email, password, twoFactorAuthStr).pipe(
                    mergeMap(({ data }) => {
                        if (!data) {
                            throw new Error('Invalid response');
                        }
                        const { user, authToken } = data;
                        saveAuthToken(authToken);
                        if (!redirect) {
                            return of(
                                setConnectivityStatus({ loading: false }),
                                loginSuccess(user),
                                loginLoading(false)
                            );
                        }
                        const {
                            location: { state }
                        } = browserHistory;
                        let pathname = '/';
                        if (state && state.redirectTo) {
                            pathname = state.redirectTo;
                        }

                        return of(
                            setConnectivityStatus({ loading: false }),
                            loginSuccess(user),
                            insufficientlyAuthenticationDetails([]),
                            loginLoading(false),
                            push(pathname)
                        );
                    }),
                    catchError(error => {
                        const actions = [
                            setConnectivityStatus({ loading: false }),
                            loginLoading(false),
                            insufficientlyAuthenticationDetails(
                                error &&
                                    error.response &&
                                    error.response.details
                            )
                        ];
                        return iif(
                            () => error.status !== 412,
                            of(
                                ...actions,
                                setGlobalMessageError(
                                    constructGlobalErrorMessage(
                                        parseErrorMessage(error)
                                    )
                                )
                            ),
                            of(...actions)
                        );
                    })
                )
            )
        )
    );
