// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import GeneralOptions from '../../containers/Profile/GeneralOptions';
import EmailOptions from '../../containers/Profile/EmailOptions';
import PasswordOptions from '../../containers/Profile/PasswordOptions';

import styles from './styles';
import TwoFactorAuthenticationOptions from '../../containers/Profile/TwoFactorAuthenticationOptions';

class Profile extends Component<
    {
        classes: any,
        fullScreen: boolean,
        close: () => void
    },
    {
        expanded:
            | ''
            | 'general'
            | 'email'
            | 'password'
            | 'twoFactorAuthentication'
    }
> {
    static propTypes = {
        fullScreen: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired
    };

    state = {
        expanded: 'general'
    };

    onGeneralExpansionPanelChange = (event, expanded) => {
        this.setState({
            expanded: expanded ? 'general' : ''
        });
    };

    onEmailExpansionPanelChange = (event, expanded) => {
        this.setState({
            expanded: expanded ? 'email' : ''
        });
    };

    onPasswordExpansionPanelChange = (event, expanded) => {
        this.setState({
            expanded: expanded ? 'password' : ''
        });
    };

    onTwoFactorAuthenticationChange = (event, expanded) => {
        this.setState({
            expanded: expanded ? `twoFactorAuthentication` : ''
        });
    };

    render() {
        const { fullScreen, close, classes } = this.props;

        const { expanded } = this.state;

        return (
            <Dialog
                fullScreen={fullScreen}
                open
                onClose={close}
                classes={{
                    paper: classes.paper
                }}
                aria-labelledby="profile-dialog-title"
            >
                <DialogTitle id="profile-dialog-title">Profile</DialogTitle>
                <DialogContent>
                    <div className={classes.contentWrap}>
                        <GeneralOptions
                            onCancel={close}
                            expanded={expanded === 'general'}
                            onExpansionPanelChange={
                                this.onGeneralExpansionPanelChange
                            }
                        />
                        <EmailOptions
                            onCancel={close}
                            expanded={expanded === 'email'}
                            onExpansionPanelChange={
                                this.onEmailExpansionPanelChange
                            }
                        />
                        <PasswordOptions
                            onCancel={close}
                            type="account"
                            expanded={expanded === 'password'}
                            title="Password Options"
                            onExpansionPanelChange={
                                this.onPasswordExpansionPanelChange
                            }
                        />
                        <TwoFactorAuthenticationOptions
                            onCancel={close}
                            type="account"
                            expanded={expanded === 'twoFactorAuthentication'}
                            title="Two Factor Authentication Options"
                            onExpansionPanelChange={
                                this.onTwoFactorAuthenticationChange
                            }
                        />
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(Profile));
