import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3
    }
});

const Loader = ({ classes }) => (
    <div className={classes.root}>
        <LinearProgress mode="query" />
        <br />
        <LinearProgress color="secondary" mode="query" />
    </div>
);

Loader.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Loader);
