// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const styles = () => ({
    paper: {
        width: '600px'
    },
    colorRed: {
        color: 'red'
    }
});

const ProxyModal = props => {
    const {
        fullScreen,
        close,
        proxyConfig: {
            tunnel: {
                engine: { address },
                clientPort
            }
        }
    } = props;
    return (
        <Dialog fullScreen={fullScreen} open onClose={close}>
            <DialogContent>
                <Typography>
                    Your tunnel to device will be available in few seconds
                    through following address:
                </Typography>
                <Typography>
                    Address: <strong>{address}</strong>
                </Typography>
                <Typography>
                    Port: <strong>{clientPort}</strong>
                </Typography>
                <Typography>
                    Please configure your browser using this credentials.
                </Typography>
                <br />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
ProxyModal.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    proxyConfig: PropTypes.object.isRequired
};
export default withMobileDialog()(withStyles(styles)(ProxyModal));
