// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { wrapActionCreators } from '../../utils/container-util';
import { signupActivateOrgUser } from '../../actions/authentication-actions';
import { signupInProgress } from '../../selectors/app-selectors';
import Signup from '../Authentication/Signup';
import { urlToToken } from '../../utils/http-util';

const SignupHOC = ({ actions, signupInProgress, token, newOwnerEmail }) => (
    <Signup
        actions={actions}
        token={urlToToken(token)}
        newOwnerEmail={newOwnerEmail}
        signupInProgress={signupInProgress}
    />
);

SignupHOC.propTypes = {
    actions: PropTypes.shape({
        signup: PropTypes.func.isRequired
    }).isRequired,
    token: PropTypes.string.isRequired,
    newOwnerEmail: PropTypes.string.isRequired,
    signupInProgress: PropTypes.bool.isRequired
};

const mapStateToProps = createStructuredSelector({
    signupInProgress: signupInProgress()
});

const mapDispatchToProps = wrapActionCreators({
    signup: signupActivateOrgUser
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignupHOC);
