// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import styles from './styles';

class ProxyOptions extends Component<
    {
        updateFixedProxyConfig: (checked: boolean) => any,
        getFixedProxyConfig: () => any,
        expanded: boolean,
        onExpansionPanelChange: () => void,
        classes: any,
        onCancel: () => void
    },
    {
        fixedProxyConfig: boolean,
        updateProxyLoading: boolean
    }
> {
    static propTypes = {
        updateFixedProxyConfig: PropTypes.func.isRequired,
        getFixedProxyConfig: PropTypes.func.isRequired,
        expanded: PropTypes.bool,
        onExpansionPanelChange: PropTypes.func,
        onCancel: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            fixedProxyConfig: false,
            updateProxyLoading: false
        };
        props.getFixedProxyConfig().subscribe(option => {
            if (option && option.data && option.data.profileConfigs) {
                this.setState({
                    fixedProxyConfig: option.data.profileConfigs.fixedProxy
                });
            }
        });
    }

    handleSaveButtonClick = () => {
        const { fixedProxyConfig } = this.state;
        const { updateFixedProxyConfig, onCancel } = this.props;
        this.setState({ updateProxyLoading: true });
        updateFixedProxyConfig(fixedProxyConfig).subscribe(() => {
            this.setState({ updateProxyLoading: false });
            onCancel();
        });
    };

    changePasswordForm = null;

    handleFixedProxyConfigChange = event => {
        this.setState({ fixedProxyConfig: event.target.checked });
    };

    render() {
        const {
            classes,
            onCancel,
            expanded,
            onExpansionPanelChange
        } = this.props;
        const { fixedProxyConfig, updateProxyLoading } = this.state;

        return (
            <ExpansionPanel
                expanded={expanded}
                onChange={onExpansionPanelChange}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div className={classes.column33}>
                        <Typography className={classes.heading}>
                            Proxy Options
                        </Typography>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.contentWrap}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={fixedProxyConfig}
                                onChange={this.handleFixedProxyConfigChange}
                                value="fixedProxyConfig"
                            />
                        }
                        label="Fixed Proxy"
                    />
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                    <Button size="small" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        color="primary"
                        onClick={this.handleSaveButtonClick}
                        disabled={updateProxyLoading}
                    >
                        Save
                    </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        );
    }
}

export default withStyles(styles)(ProxyOptions);
