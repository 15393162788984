// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const styles = () => ({
    formControl: {
        width: 180
    }
});

class UpdateDeviceGroupModal extends Component<
    {
        classes: {
            formControl: string
        },
        fullScreen: boolean,
        close: () => void,
        group: any,
        actions: {
            updateGroup: (group: any) => void
        }
    },
    {
        groupName: string
    }
> {
    constructor(props) {
        super(props);
        this.state = {
            groupName: (props && props.group && props.group.name) || ''
        };
    }

    static propTypes = {
        fullScreen: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired,
        group: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            updateGroup: PropTypes.func.isRequired
        }).isRequired
    };

    onUpdate = () => {
        const {
            group: { _id },
            actions: { updateGroup }
        } = this.props;
        const { groupName } = this.state;
        updateGroup({ _id, name: groupName });
    };

    handleChange = event => {
        this.setState({ groupName: event.target.value });
    };

    onKeyDown = event => {
        switch (event.keyCode) {
            case 13: {
                this.onUpdate();
                break;
            }
            default:
                break;
        }
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    render() {
        const { classes, fullScreen, close } = this.props;
        const { groupName } = this.state;
        return (
            <Dialog
                fullScreen={fullScreen}
                open
                onClose={close}
                aria-labelledby="delete-group-title"
            >
                <DialogTitle>Update Group</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormControl
                            className={classes.formControl}
                            required
                            aria-describedby="group-name-field"
                        >
                            <InputLabel htmlFor="group-name-field">
                                Group Name
                            </InputLabel>
                            <Input
                                id="group-name-field"
                                autoFocus
                                value={groupName}
                                onChange={this.handleChange}
                                inputProps={this.inputProps}
                            />
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onUpdate}
                    >
                        Update
                    </Button>
                    <Button variant="contained" color="primary" onClick={close}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(UpdateDeviceGroupModal));
