import { merge, of } from 'rxjs';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { GET_LOGGED_IN_USER } from '../../constants/action-types';
import { getLoggedInUser } from '../../api/authentication-api';
import { setConnectivityStatus } from '../../actions/app-actions';
import {
    getLoggedInUserLoading,
    getLoggedInUserSuccess,
    getLoggedInUserError,
    logout
} from '../../actions/authentication-actions';
import { parseErrorMessage } from '../../utils/global-message-util';

export default action$ =>
    action$.pipe(
        ofType(GET_LOGGED_IN_USER),
        switchMap(() =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(getLoggedInUserLoading(true)),
                getLoggedInUser().pipe(
                    mergeMap(user => {
                        const { data } = user;
                        return of(
                            setConnectivityStatus({ loading: false }),
                            getLoggedInUserSuccess(data),
                            getLoggedInUserLoading(false)
                        );
                    }),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            getLoggedInUserError(parseErrorMessage(error)),
                            getLoggedInUserLoading(false),
                            logout()
                        )
                    )
                )
            )
        )
    );
