import { of, Observable } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { push } from 'react-router-redux';

import { INIT_APP } from '../../constants/action-types';
import {
    getLoggedInUser,
    logoutSuccess
} from '../../actions/authentication-actions';
import { getAuthToken } from '../../utils/http-util';
import browserHistory from '../../store/history';
import { UNAUTHENTICATED_URLS } from '../../constants/route-constants';

const authToken$ = Observable.create(observer => {
    const authToken = getAuthToken();
    if (!authToken) {
        observer.error();
    } else {
        observer.next();
    }
    observer.complete();
});

export default action$ =>
    action$.pipe(
        ofType(INIT_APP),
        switchMap(() => authToken$),
        switchMap(() => of(getLoggedInUser(true))),
        catchError(() => {
            const {
                location: { pathname, search }
            } = browserHistory;
            const newLocation = {};
            const redirectUrl = '/login';
            if (UNAUTHENTICATED_URLS.indexOf(pathname.toLowerCase()) > -1) {
                // keep the pathname, without the state
                return of(logoutSuccess());
            }
            // after login redirect to whenever user wants
            newLocation.state = {
                redirectTo: pathname
            };
            newLocation.pathname = redirectUrl + search;
            return of(logoutSuccess(), push(newLocation));
        })
    );
