import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
    card: {
        maxWidth: 345,
        width: 345
    },
    media: {
        height: 180,
        backgroundSize: '160px 190px'
    },
    mediaVertical: {
        backgroundSize: '160px 190px'
    },
    mediaHorizontal: {
        backgroundSize: '240px 150px'
    }
});

const EmptyLists = ({
    classes,
    messageTitle,
    message,
    buttons,
    image,
    mediaType
}) => (
    <Card className={classes.card}>
        <CardMedia
            className={classNames(classes.media, {
                [classes.mediaVertical]: mediaType === 'vertical',
                [classes.mediaHorizontal]: mediaType === 'horizontal'
            })}
            image={image}
        />
        <CardContent>
            {messageTitle && (
                <Typography type="headline" component="h2">
                    {messageTitle}
                </Typography>
            )}
            {message && <Typography component="p">{message}</Typography>}
        </CardContent>
        {buttons && buttons.length > 0 && <CardActions>{buttons}</CardActions>}
    </Card>
);

EmptyLists.propTypes = {
    classes: PropTypes.object.isRequired,
    messageTitle: PropTypes.string,
    message: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired, // only name of thumbnail if in assets
    buttons: PropTypes.arrayOf(PropTypes.element),
    mediaType: PropTypes.string
};

EmptyLists.defaultProps = {
    messageTitle: '',
    buttons: []
};

export default withStyles(styles)(EmptyLists);
