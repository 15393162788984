// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const styles = () => ({
    paper: {
        width: '600px'
    }
});

const DeleteModal = ({
    fullScreen,
    close,
    onDelete,
    dialogContextContent,
    yesButtonContent,
    title
}: {
    fullScreen: boolean,
    close: () => void,
    onDelete: () => void,
    dialogContextContent: any,
    yesButtonContent: any,
    title: any
}) => (
    <Dialog
        fullScreen={fullScreen}
        open
        onClose={close}
        aria-labelledby="delete-device-title"
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{dialogContextContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="primary" onClick={onDelete}>
                {yesButtonContent}
            </Button>
            <Button variant="contained" color="primary" onClick={close}>
                Close
            </Button>
        </DialogActions>
    </Dialog>
);

DeleteModal.propTypes = {
    dialogContextContent: PropTypes.node,
    yesButtonContent: PropTypes.node,
    title: PropTypes.node,
    fullScreen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

DeleteModal.defaultProps = {
    title: 'Delete Device',
    dialogContextContent: 'Are you sure you want to delete device?',
    yesButtonContent: 'Delete'
};

export default withMobileDialog()(withStyles(styles)(DeleteModal));
