// @flow
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { changeDeviceOwnerApprove } from '../../../api/device-api';
import { wrapActionCreators } from '../../../utils/container-util';
import { setGlobalMessageError } from '../../../actions/app-actions';
import GetSendedDevice from '../../../components/Devices/GetSendedDevice';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../../utils/global-message-util';

class GetSendedDeviceHoc extends React.Component<
    {
        oldOwnerEmail: string,
        deviceName: string,
        actions: {
            setGlobalMessageError: any
        },
        token: string
    },
    {
        getNewDeviceLoading: boolean,
        getNewDeviceSuccess: boolean
    }
> {
    state = {
        getNewDeviceLoading: false,
        getNewDeviceSuccess: false
    };

    handleGetDeviceClick = validationNumbers => {
        const {
            token,
            actions: { setGlobalMessageError }
        } = this.props;
        changeDeviceOwnerApprove(token, validationNumbers).subscribe(
            () => {
                this.setState({
                    getNewDeviceLoading: false,
                    getNewDeviceSuccess: true
                });
            },
            err => {
                let message = 'Something went wrong';
                if (err) {
                    message = parseErrorMessage(err);
                }
                setGlobalMessageError(constructGlobalErrorMessage(message));
                this.setState({
                    getNewDeviceLoading: false
                });
            }
        );
    };

    render() {
        const { oldOwnerEmail, deviceName } = this.props;
        const { getNewDeviceLoading, getNewDeviceSuccess } = this.state;

        return (
            <GetSendedDevice
                getNewDeviceLoading={getNewDeviceLoading}
                getNewDeviceSuccess={getNewDeviceSuccess}
                handleGetDeviceClick={this.handleGetDeviceClick}
                oldOwnerEmail={oldOwnerEmail}
                deviceName={deviceName}
            />
        );
    }
}

GetSendedDeviceHoc.propTypes = {
    oldOwnerEmail: PropTypes.string.isRequired,
    deviceName: PropTypes.string.isRequired,
    actions: PropTypes.shape({
        setGlobalMessageError: PropTypes.func.isRequired
    }).isRequired,
    token: PropTypes.string.isRequired
};
const mapDispatchToProps = wrapActionCreators({
    setGlobalMessageError
});

export default connect(
    null,
    mapDispatchToProps
)(GetSendedDeviceHoc);
