// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import TwoFactorAuthenticationOptions from '../../../components/Profile/TwoFactorAuthenticationOptions';

import { wrapActionCreators } from '../../../utils/container-util';

import {
    regenerateTwoFactorAuthQR,
    toggleTwoFactorAuth
} from '../../../actions/authentication-actions';

import {
    selectToggleTwoFactorAuthLoading,
    selectRegenerateTwoFactorAuthQRLoading,
    getLoggedInUser
} from '../../../selectors/app-selectors';

const TwoFactorAuthenticationOptionsHOC = props => (
    <TwoFactorAuthenticationOptions {...props} />
);

TwoFactorAuthenticationOptionsHOC.propTypes = {
    actions: PropTypes.shape({
        toggleTwoFactorAuth: PropTypes.func.isRequired,
        regenerateTwoFactorAuthQR: PropTypes.func.isRequired
    }).isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    expanded: PropTypes.bool,
    onExpansionPanelChange: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    toggleTwoFactorAuthLoading: PropTypes.bool.isRequired,
    regenerateTwoFactorAuthQRLoading: PropTypes.bool.isRequired,
    loggedInUser: PropTypes.object.isRequired
};

const mapStateToProps = createStructuredSelector({
    toggleTwoFactorAuthLoading: selectToggleTwoFactorAuthLoading(),
    regenerateTwoFactorAuthQRLoading: selectRegenerateTwoFactorAuthQRLoading(),
    loggedInUser: getLoggedInUser()
});

const mapDispatchToProps = wrapActionCreators({
    toggleTwoFactorAuth,
    regenerateTwoFactorAuthQR
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TwoFactorAuthenticationOptionsHOC);
