// @flow
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import PasswordField from '../PasswordField';

export default class ChangePasswordForm extends Component<
    {
        onEnter: () => void,
        onChange: ({
            oldPassword: string,
            newPassword: string,
            confirmNewPassword: string
        }) => void,
        type: string,
        isPasswordRequired: boolean
    },
    {
        error: {
            oldPassword: string,
            newPassword: string,
            confirmNewPassword: string
        },
        oldPassword: string,
        newPassword: string,
        confirmNewPassword: string
    }
> {
    static propTypes = {
        type: PropTypes.string.isRequired,
        isPasswordRequired: PropTypes.bool,
        onEnter: PropTypes.func,
        onChange: PropTypes.func
    };

    static defaultProps = {
        isPasswordRequired: true
    };

    state = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        error: {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        }
    };

    handleOldPasswordChange = (value: string) => {
        const { onChange } = this.props;
        this.setState({
            oldPassword: value
        });
        if (onChange) {
            onChange({
                oldPassword: value,
                newPassword: this.state.newPassword,
                confirmNewPassword: this.state.confirmNewPassword
            });
        }
    };

    handleNewPasswordChange = (value: string) => {
        const { onChange } = this.props;
        this.setState({
            newPassword: value
        });
        if (onChange) {
            onChange({
                newPassword: value,
                oldPassword: this.state.oldPassword,
                confirmNewPassword: this.state.confirmNewPassword
            });
        }
    };

    handleConfirmNewPasswordChange = (value: string) => {
        const { onChange } = this.props;
        this.setState({
            confirmNewPassword: value
        });
        if (onChange) {
            onChange({
                confirmNewPassword: value,
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword
            });
        }
    };

    validate = (setToState: boolean = true) => {
        const { oldPassword, newPassword, confirmNewPassword } = this.state;
        const { isPasswordRequired } = this.props;
        const error = {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        };
        let isValid = true;

        if (isPasswordRequired && oldPassword.trim() === '') {
            isValid = false;
            error.oldPassword = 'Please enter password';
        } else if (isPasswordRequired && oldPassword.length < 8) {
            isValid = false;
            error.oldPassword = 'Passwords should be at least 8 chars';
        }

        if (isPasswordRequired && newPassword.trim() === '') {
            isValid = false;
            error.newPassword = 'Please enter password';
        } else if (isPasswordRequired && newPassword.length < 8) {
            isValid = false;
            error.newPassword = 'Passwords should be at least 8 chars';
        }

        if (confirmNewPassword !== newPassword) {
            isValid = false;
            error.confirmNewPassword =
                "Password and confirm password don't match";
        }

        if (setToState && Object.keys(error).length) {
            this.setState({ error });
        }
        return isValid;
    };

    render() {
        const { isPasswordRequired, onEnter, type } = this.props;
        const {
            oldPassword,
            newPassword,
            confirmNewPassword,
            error
        } = this.state;

        return (
            <Fragment>
                <PasswordField
                    isPasswordRequired={isPasswordRequired}
                    label="Old password"
                    type={`${type}-old-password`}
                    autoComplete="current-password"
                    error={error.oldPassword}
                    password={oldPassword}
                    onChange={this.handleOldPasswordChange}
                    onEnter={onEnter}
                />
                <PasswordField
                    isPasswordRequired={isPasswordRequired}
                    label="New password"
                    type={`${type}-new-password`}
                    autoComplete="new-password"
                    error={error.newPassword}
                    password={newPassword}
                    onChange={this.handleNewPasswordChange}
                    onEnter={onEnter}
                />
                <PasswordField
                    isPasswordRequired={isPasswordRequired}
                    label="Confirm new password"
                    type={`${type}-confirm-new-password`}
                    autoComplete="new-password"
                    error={error.confirmNewPassword}
                    password={confirmNewPassword}
                    onChange={this.handleConfirmNewPasswordChange}
                    onEnter={onEnter}
                />
            </Fragment>
        );
    }
}
