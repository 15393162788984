// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Login from '../../../components/Authentication/Login';

import { wrapActionCreators } from '../../../utils/container-util';
import { login } from '../../../actions/authentication-actions';
import {
    loginInProcess,
    insufficientlyAuthenticationDetails
} from '../../../selectors/app-selectors';

const LoginHOC = ({
    actions,
    loginInProgress,
    insufficientlyAuthenticationDetails,
    mode,
    newOwnerEmail
}) => (
    <Login
        actions={actions}
        loginInProgress={loginInProgress}
        insufficientlyAuthenticationDetails={
            insufficientlyAuthenticationDetails
        }
        mode={mode}
        newOwnerEmail={newOwnerEmail}
    />
);

LoginHOC.propTypes = {
    actions: PropTypes.shape({
        login: PropTypes.func.isRequired
    }).isRequired,
    loginInProgress: PropTypes.bool.isRequired,
    insufficientlyAuthenticationDetails: PropTypes.array,
    mode: PropTypes.string,
    newOwnerEmail: PropTypes.string
};

const mapStateToProps = createStructuredSelector({
    loginInProgress: loginInProcess(),
    insufficientlyAuthenticationDetails: insufficientlyAuthenticationDetails()
});

const mapDispatchToProps = wrapActionCreators({
    login
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginHOC);
