// @flow
import React from 'react';
import PropTypes from 'prop-types';

import SetupInstructions from './SetupInstructions';

const AddDevice = ({ close }: { close: () => void }) => (
    <SetupInstructions close={close} />
);

AddDevice.propTypes = {
    close: PropTypes.func.isRequired
};

export default AddDevice;
