// @flow
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import WithHelmet from '../WithHelmet';
import withToken from './withToken';
import withTunnel from './withTunnel';
import VNCTerminal from './VNCTerminal';
import SSHTerminal from './SSHTerminal';
import RDPTerminal from './RDPTerminal';
import { MAIN_TITLE } from '../../constants/api-constants';

import tunnelinVNCTerminalImage from '../../assets/images/tunnelin_vnc.jpg';
import tunnelinSSHTerminalImage from '../../assets/images/tunnelin_ssh.jpg';
import tunnelinRDPTerminalImage from '../../assets/images/tunnelin_rdp.jpg';

const VNCTerminalHOC = withTunnel()(
    withToken({
        type: 'vnc',
        defaultSettings: { autoretry: 5 }
    })(VNCTerminal)
);
const SSHTerminalHOC = withTunnel()(withToken({ type: 'ssh' })(SSHTerminal));

const RDPTerminalHOC = withTunnel()(withToken({ type: 'rdp' })(RDPTerminal));

const Terminal = () => (
    <Switch>
        <Route
            path="/terminal/vnc/:deviceId/:deviceName"
            render={({
                match: {
                    params: { deviceId, deviceName }
                }
            }) => {
                const VNCTerminalWithHelmet = WithHelmet({
                    title: `${MAIN_TITLE} VNC to ${deviceName}`,
                    applyTitlePrefix: false,
                    meta: [
                        { name: 'og:url', content: `/terminal/vnc/${deviceId}/${deviceName}` },
                    ],
                    image: {
                        src: tunnelinVNCTerminalImage,
                        width: 1200,
                        height: 630,
                        type: 'image/jpeg'
                    },
                    description: `Tunnel In - VNC terminal into ${deviceName}. Our terminals not require any plugins to be installed and they work across all the browsers, giving the best experience.`
                })(VNCTerminalHOC);
                return <VNCTerminalWithHelmet
                    tunnelType="vnc"
                    deviceId={deviceId}
                    deviceName={deviceName}
                />
            }}
        />
        <Route
            path="/terminal/ssh/:deviceId/:deviceName"
            render={({
                match: {
                    params: { deviceId, deviceName }
                }
            }) => {
                const SSHTerminalWithHelmet = WithHelmet({
                    title: `${MAIN_TITLE} SSH to ${deviceName}`,
                    applyTitlePrefix: false,
                    meta: [
                        { name: 'og:url', content: `/terminal/ssh/${deviceId}/${deviceName}` },
                    ],
                    image: {
                        src: tunnelinSSHTerminalImage,
                        width: 1200,
                        height: 630,
                        type: 'image/jpeg'
                    },
                    description: `Tunnel In - SSH terminal into ${deviceName}. Our terminals not require any plugins to be installed and they work across all the browsers, giving the best experience.`
                })(SSHTerminalHOC);
                return <SSHTerminalWithHelmet
                    tunnelType="ssh"
                    deviceId={deviceId}
                    deviceName={deviceName}
                />
            }}
        />
        <Route
            path="/terminal/rdp/:deviceId/:deviceName"
            render={({
                match: {
                    params: { deviceId, deviceName }
                }
            }) => {
                const RDPTerminalWithHelmet = WithHelmet({
                    title: `${MAIN_TITLE} RDP to ${deviceName}`,
                    applyTitlePrefix: false,
                    meta: [
                        { name: 'og:url', content: `/terminal/rdp/${deviceId}/${deviceName}` },
                    ],
                    image: {
                        src: tunnelinRDPTerminalImage,
                        width: 1200,
                        height: 630,
                        type: 'image/jpeg'
                    },
                    description: `Tunnel In - RDP terminal into ${deviceName}. Our terminals not require any plugins to be installed and they work across all the browsers, giving the best experience.`
                })(RDPTerminalHOC);
                return <RDPTerminalWithHelmet
                    tunnelType="rdp"
                    deviceId={deviceId}
                    deviceName={deviceName}
                />
            }}
        />
    </Switch>
);

export default withRouter(Terminal);
