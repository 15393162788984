import { createAction } from 'redux-actions';
import {
    ADD_GROUP,
    ADD_GROUP_LOADING,
    ADD_GROUP_SUCCESS,
    DELETE_GROUP,
    DELETE_GROUP_LOADING,
    DELETE_GROUP_SUCCESS,
    GET_GROUPS,
    GET_GROUPS_LOADING,
    GET_GROUPS_SUCCESS,
    UPDATE_GROUPS_ORDERING,
    UPDATE_GROUPS_ORDERING_LOADING,
    UPDATE_GROUPS_ORDERING_SUCCESS,
    UPDATE_GROUP,
    UPDATE_GROUP_LOADING,
    UPDATE_GROUP_SUCCESS
} from '../constants/action-types';

export const getGroups = createAction(GET_GROUPS);
export const getGroupsLoading = createAction(GET_GROUPS_LOADING);
export const getGroupsSuccess = createAction(GET_GROUPS_SUCCESS);

export const updateGroupsOrdering = createAction(UPDATE_GROUPS_ORDERING);
export const updateGroupsOrderingLoading = createAction(
    UPDATE_GROUPS_ORDERING_LOADING
);
export const updateGroupsOrderingSuccess = createAction(
    UPDATE_GROUPS_ORDERING_SUCCESS
);

export const addGroup = createAction(ADD_GROUP);
export const addGroupLoading = createAction(ADD_GROUP_LOADING);
export const addGroupSuccess = createAction(ADD_GROUP_SUCCESS);

export const deleteGroup = createAction(DELETE_GROUP);
export const deleteGroupLoading = createAction(DELETE_GROUP_LOADING);
export const deleteGroupSuccess = createAction(DELETE_GROUP_SUCCESS);

export const updateGroup = createAction(UPDATE_GROUP);
export const updateGroupLoading = createAction(UPDATE_GROUP_LOADING);
export const updateGroupSuccess = createAction(UPDATE_GROUP_SUCCESS);
