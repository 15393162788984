// @flow
import React from 'react';
import { Link } from '@material-ui/core';

// eslint-disable-next-line
export function parseErrorMessage(error: any) {
    if (error.status === 403) {
        return (
            <div>
                In the Demo version, this functionality is restricted. <br />
                <Link color="secondary" href="https://app.tunnelin.com/signup">
                    Register
                </Link>{' '}
                to use full functionality
            </div>
        );
    }
    return (
        (error.response && error.response.error) ||
        ((error.status === 0 || error.status >= 500) &&
            `Something went wrong${
                !navigator.onLine ? ', check internet connection' : ''
            }`) ||
        (error.name === 'TypeError' && 'Something went wrong') ||
        error.message
    );
}

// eslint-disable-next-line
export function constructGlobalErrorMessage(error: any) {
    if (typeof error === 'string') {
        return error;
    }
    if (error.length === 0) {
        return '';
    }
    if (error.length === 1) {
        return error[0].message;
    }
    if (error.length > 1) {
        return (
            <ul>
                {error.map(err => {
                    const index = error.indexOf(err);
                    return <li key={index}>{err.message}</li>;
                })}
            </ul>
        );
    }
    return error;
}
