// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ShareModal from '../../../components/Devices/Modal/ShareModal';

import {
    getDeviceUsers,
    addOrRemoveUsersToDevice,
    clearDeviceUsers
} from '../../../actions/user-device-actions';

import {
    selectDeviceUsersLoading,
    selectDeviceUsersData,
    selectUserDeviceAddingOrRemoving
} from '../../../selectors/user-device-selectors';

import { wrapActionCreators } from '../../../utils/container-util';

import type { UserDevice } from '../../../types/user-device';

class ShareModelHOC extends Component<
    {
        deviceUsers: UserDevice[],
        deviceId: string,
        deviceUsersLoading: boolean,
        addingOrRemovingUsersToDevice: boolean,
        actions: {
            getDeviceUsers: (deviceId: string) => void,
            addOrRemoveUsersToDevice: () => void
        }
    },
    {}
> {
    static propTypes = {
        deviceUsers: PropTypes.array.isRequired,
        deviceId: PropTypes.string.isRequired,
        deviceUsersLoading: PropTypes.bool.isRequired,
        addingOrRemovingUsersToDevice: PropTypes.bool.isRequired,
        actions: PropTypes.shape({
            getDeviceUsers: PropTypes.func.isRequired,
            addOrRemoveUsersToDevice: PropTypes.func.isRequired
        }).isRequired
    };

    componentDidMount() {
        const {
            actions: { getDeviceUsers },
            deviceId
        } = this.props;
        clearDeviceUsers(deviceId);
        getDeviceUsers(deviceId);
    }

    render() {
        const {
            actions: { addOrRemoveUsersToDevice },
            ...rest
        } = this.props;
        return (
            <ShareModal
                {...rest}
                addOrRemoveUsersToDevice={addOrRemoveUsersToDevice}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    deviceUsersLoading: selectDeviceUsersLoading(),
    deviceUsers: selectDeviceUsersData(),
    addingOrRemovingUsersToDevice: selectUserDeviceAddingOrRemoving()
});

const mapDispatchToProps = wrapActionCreators({
    getDeviceUsers,
    addOrRemoveUsersToDevice
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShareModelHOC);
