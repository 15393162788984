// @flow

import deepPurple from '@material-ui/core/colors/deepPurple';

export default (theme: any) => ({
    root: {
        width: '100vw'
    },
    flexGrow: {
        flex: 1
        // marginLeft: theme.spacing.unit,
        // marginRight: theme.spacing.unit
    },
    selectedList: {
        fontSize: '16px',
        textTransform: 'capitalize'
    },
    toolbar: {
        minHeight: '64px',
        height: '64px',
        paddingLeft: '10px',
        paddingRight: '18px'
    },
    purpleAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepPurple[500]
    },
    avatarButton: {
        color: '#fff'
    },
    drawerPaper: {
        position: 'relative',
        flex: 1
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '0 8px 0 16px',
        minHeight: '64px',
        height: '64px'
    },
    flex1: {
        flex: 1
    }
});
