// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { validate as validateEmail } from 'email-validator/index';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';

const styles = () => ({
    dialogContent: {
        width: '470px'
    },
    // formControl: {
    //     margin: theme.spacing.unit
    // },
    successText: {
        fontSize: 14,
        margin: '10px 0',
        lineHeight: '20px'
    },
    colorGreen: {
        color: '#61bd4f'
    },
    tunnelsToOwnership: {
        display: 'flex',
        flexDirection: 'column',
        margin: 10
    },
    tunnelToOwnershipItem: {
        display: 'flex',
        flexDirection: 'row'
    },
    selectTunnelCheckbox: {
        padding: 0
    },
    selectTunnelName: {
        padding: 5
    },
    actionsWrapper: {
        marginRight: 16,
        marginLeft: 16
    }
});

class ChangeDeviceOwnerModal extends Component<
    {
        classes: {
            formControl: string,
            actionsWrapper: string,
            dialogContent: string,
            successText: string,
            colorGreen: string
        },
        close: () => void,
        handleChangeDeviceOwner: (email: string) => void,
        changeDeviceOwnerLoading: boolean,
        changeDeviceOwnerSuccess: string,
        tunnels: any[],
        getTunnelsError: string,
        getTunnelsLoading: boolean
    },
    {
        email: string,
        emailError: string,
        tunnelsToMove: any[]
    }
> {
    state = {
        email: '',
        emailError: '',
        tunnelsToMove: []
    };

    handleChangeOwner = () => {
        const { email, tunnelsToMove } = this.state;
        const { handleChangeDeviceOwner } = this.props;
        if (validateEmail(email)) {
            this.setState({
                emailError: ''
            });
            handleChangeDeviceOwner(email, tunnelsToMove);
        } else {
            this.setState({
                emailError: 'Please Enter a Valid Email'
            });
        }
    };

    onClose = () => {
        const { close } = this.props;
        close();
    };

    onEmailChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    onKeyDown = event => {
        const { email } = this.state;
        if (validateEmail(email)) {
            this.setState({
                emailError: ''
            });
        }
        switch (event.keyCode) {
            case 13: {
                this.handleChangeOwner();
                break;
            }
            default:
                break;
        }
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    addTunnelIdToOwnership = id => {
        const { tunnelsToMove } = this.state;
        this.setState({
            tunnelsToMove: [...tunnelsToMove, id]
        });
    };

    removeTunnelIdToOwnership = id => {
        const { tunnelsToMove } = this.state;
        const newIds = [...tunnelsToMove];
        const indexOfRemoved = newIds.indexOf(id);
        if (indexOfRemoved > -1) {
            newIds.splice(indexOfRemoved, 1);
            this.setState({
                tunnelsToMove: newIds
            });
        }
    };

    renderTunnelItem = t => {
        const { name, id } = t;
        const { classes } = this.props;
        const { tunnelsToMove } = this.state;
        return (
            <div className={classes.tunnelToOwnershipItem} key={id}>
                <Checkbox
                    className={classes.selectTunnelCheckbox}
                    checked={tunnelsToMove.indexOf(id) > -1}
                    onChange={event => {
                        if (event.target.checked) {
                            this.addTunnelIdToOwnership(id);
                        } else {
                            this.removeTunnelIdToOwnership(id);
                        }
                    }}
                    value={id}
                />
                <div className={classes.selectTunnelName}>{name}</div>
            </div>
        );
    };

    render() {
        const {
            close,
            classes,
            changeDeviceOwnerLoading,
            changeDeviceOwnerSuccess,
            tunnels,
            getTunnelsError,
            getTunnelsLoading
        } = this.props;
        const { email, emailError } = this.state;
        return (
            <Dialog
                open
                classes={{ paper: classes.dialogContent }}
                onClose={close}
                aria-labelledby="change-device-owner"
            >
                <DialogTitle id="change-device-owner">
                    Change Device Owner
                </DialogTitle>
                <DialogContent>
                    {!getTunnelsError && (
                        <div>
                            <FormControl
                                fullWidth
                                margin="dense"
                                className={classes.formControl}
                                error={!!emailError}
                                aria-describedby="change-device-owner-email"
                            >
                                <InputLabel htmlFor="change-device-owner-email">
                                    Email Address
                                </InputLabel>
                                <Input
                                    id="change-device-owner-email"
                                    autoComplete="email"
                                    autoFocus
                                    type="email"
                                    value={email}
                                    onChange={this.onEmailChange('email')}
                                    inputProps={this.inputProps}
                                />
                                {emailError && (
                                    <FormHelperText error>
                                        {emailError}
                                    </FormHelperText>
                                )}
                                {!!changeDeviceOwnerSuccess && (
                                    <div className={classes.successText}>
                                        <div className={classes.colorGreen}>
                                            Success!
                                        </div>
                                        New owner needs 4 digit verification
                                        code below to get the device. <br />{' '}
                                        Verification code:{' '}
                                        <strong>
                                            {changeDeviceOwnerSuccess}
                                        </strong>
                                    </div>
                                )}
                            </FormControl>
                        </div>
                    )}
                    {!!tunnels.length && !changeDeviceOwnerSuccess && (
                        <div style={{ margin: '8px 0px' }}>
                            <div>
                                Please Select Tunnels to move with device.
                            </div>
                            <div className={classes.tunnelsToOwnership}>
                                {tunnels.map(t => this.renderTunnelItem(t))}
                            </div>
                            <FormHelperText>
                                NOTE: Not selected tunnels will be deleted once
                                new owner accept the device.
                            </FormHelperText>
                        </div>
                    )}
                </DialogContent>
                <DialogActions className={classes.actionsWrapper}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleChangeOwner}
                        disabled={
                            changeDeviceOwnerLoading ||
                            !!getTunnelsError ||
                            getTunnelsLoading ||
                            !!changeDeviceOwnerSuccess
                        }
                    >
                        Change Owner
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(ChangeDeviceOwnerModal));
