// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { wrapActionCreators } from '../../../../utils/container-util';

import { makeGroupAsDefault } from '../../../../actions/users-actions';

import ChangeDefaultGroupModal from '../../../../components/Devices/Groups/Default';

const ChangeDefaultGroupHOC = ({
    actions,
    group,
    close
}: {
    actions: {
        makeGroupAsDefault: (_id: string) => void
    },
    group: any,
    close: () => void
}) => <ChangeDefaultGroupModal close={close} actions={actions} group={group} />;

ChangeDefaultGroupHOC.propTypes = {
    close: PropTypes.func.isRequired,
    actions: PropTypes.shape({
        makeGroupAsDefault: PropTypes.func.isRequired
    }).isRequired,
    group: PropTypes.object.isRequired
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = wrapActionCreators({
    makeGroupAsDefault
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeDefaultGroupHOC);
