// @flow
import React, { useState } from 'react';
import Tour from 'reactour';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button/Button';
import Fab from '@material-ui/core/Fab/Fab';
import AddIcon from '@material-ui/icons/Add';
import Menu from '@material-ui/core/Menu/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton/IconButton';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import StartIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import RefreshIcon from '@material-ui/icons/Refresh';
import classNames from 'classnames';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TunnelItem from '../../containers/shared/tunnelItem';
import EmptyTunnels from '../../containers/Tunnels/EmptyTunnels';
import AddOrEditTunnel from './Modal/AddOrEditTunnel';
import Authorities from '../../containers/Authorities';
import { ADD_TUNNEL_ACTION_AUTH } from '../../constants/authorities';
import Filters from './Filters/Filters';
import { getNoFilters } from '../../selectors/tunnels-selectors';
import { wrapActionCreators } from '../../utils/container-util';
import { resetAllTunnelFilters } from '../../actions/tunnel-actions';
import FilterIcon from '../../assets/svg/filter.svg';
import {
    setTunnelsTourVisibility,
    setTunnelsTourStep
} from '../../actions/app-actions';
import { getTunnelsTourDetails } from '../../selectors/app-selectors';

const styles = theme => ({
    tunnelsPage: {
        width: '100vw',
        maxHeight: 'calc(100vh - 64px)',
        height: 'calc(100vh - 64px)',
        flexDirection: 'column'
    },
    formControl: {
        margin: '0px 5px'
    },
    tunnelPageWrapper: {
        flex: 1,
        overflowY: 'auto',
        overflowScrolling: 'touch',
        WebkitOverflowScrolling: 'touch',
        padding: '20px',
        maxWidth: 1200,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    tunnelItemsWrapper: {
        maxWidth: 1200,
        margin: '0 auto',
        padding: '20px 0',
        display: 'flex',
        flexDirection: 'column'
    },
    tunnelItemsHeader: {
        flexDirection: 'row',
        display: 'flex',
        margin: '0',
        padding: '10px 0px',
        alignItems: 'center',
        backgroundColor: '#fafafa',
        fontSize: 18
    },
    tunnelItemsHeaderMobile: {
        fontSize: 15
    },
    columnHeader: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '5px 0px 5px 5px'
    },
    headerTunnelName: {
        flex: 2
    },
    headerDeviceName: {
        flex: 2
    },
    headerDeviceNameMobile: {
        flex: 3
    },
    headerUrl: {
        flex: 3
    },
    headerLocalPort: {
        width: 100
    },
    headerLocalPortMobile: {
        width: 50
    },
    headerActions: {
        width: 200,
        textAlign: 'center'
    },
    tunnelsHeaderActionsMobileIcon: {
        width: 34
    },
    tunnelItemsBody: {
        flex: 1,
        overflow: 'overlay',
        marginRight: '-20px',
        paddingRight: '20px',
        [theme.breakpoints.down('md')]: {
            paddingRight: 0,
            marginRight: '0px !important'
        }
    },
    noTunnelsFound: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 0px'
    },
    noTunnelsFoundText: {
        fontWeight: 'bold',
        marginBottom: 10
    },
    resetButton: {
        marginBottom: 10
    },
    addButton: {
        position: 'fixed',
        right: 16,
        bottom: 16,
        [theme.breakpoints.down('md')]: {
            right: 5,
            bottom: 5
        }
    },
    tunnelPageHeader: {
        display: 'flex',
        flexDirection: 'column'
    },
    blankSpace: {
        height: 35
    },
    tunnelsHeaderActionsIcon: {
        marginRight: 8
    },
    mask: {
        color: 'rgba(211,211,211, .6)'
    },
    tourStart: {
        height: 0,
        width: 0,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

const Tunnels = ({
    classes,
    allTunnelsCount,
    tunnels = [],
    getTunnelsError,
    getTunnelsLoading,
    tunnelDelete,
    onTunnelReady,
    stoppingTunnelsInProcess,
    reloadingTunnelsInProcess,
    onStopAllRunningTunnelsDone,
    onReloadAllRunningTunnelsDone,
    onStopAllRunningTunnels,
    onReloadAllRunningTunnels,
    noFilters,
    actions: {
        resetAllTunnelFilters,
        setTunnelsTourStep,
        setTunnelsTourVisibility
    },
    tunnelsTourDetails
}) => {
    const [showAddTunnelModal, setShowAddTunnelModal] = useState(false);
    const [
        tunnelsHeaderActionsAnchorEl,
        setTunnelsHeaderActionsAnchorEl
    ] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [dialogMode, setDialogMode] = useState('');

    const closeAddTunnelModal = () => {
        setShowAddTunnelModal(false);
    };

    const renderTunnelItem = tunnel => {
        const { id } = tunnel;

        return (
            <div key={id}>
                <TunnelItem
                    tunnel={tunnel}
                    isItemInTunnelsPage={true}
                    tunnelDelete={tunnelDelete}
                    reloadingTunnelsInProcess={reloadingTunnelsInProcess}
                    stoppingTunnelsInProcess={stoppingTunnelsInProcess}
                />
            </div>
        );
    };

    const handleShowAddTunnelModal = () => {
        setShowAddTunnelModal(true);
    };

    const stopAllRunningTunnelsClick = () => {
        setTunnelsHeaderActionsAnchorEl(null);
        setShowConfirmDialog(true);
        setDialogMode('stop');
    };

    const reloadAllRunningTunnelsClick = () => {
        setTunnelsHeaderActionsAnchorEl(null);
        setShowConfirmDialog(true);
        setDialogMode('reload');
    };

    const handleTunnelsHeaderMenuClick = event => {
        setTunnelsHeaderActionsAnchorEl(event.currentTarget);
    };

    const handleTunnelsHeaderMenuClose = () => {
        setTunnelsHeaderActionsAnchorEl(null);
    };

    const doDialogAction = () => {
        if (dialogMode === 'stop') {
            onStopAllRunningTunnels();
            setTimeout(() => {
                onStopAllRunningTunnelsDone();
            }, 0);
        } else if (dialogMode === 'reload') {
            onReloadAllRunningTunnels();
            setTimeout(() => {
                onReloadAllRunningTunnelsDone();
            }, 0);
        }
        setShowConfirmDialog(false);
    };

    const closeTour = () => {
        setTunnelsTourVisibility(false);
    };

    const getTourCurrentStep = currentStep => {
        if (tunnelsTourDetails.step !== currentStep)
            setTunnelsTourStep(currentStep);
    };

    const noResults = !getTunnelsLoading && tunnels.length === 0 && !noFilters;
    const showHeader = !(!getTunnelsLoading && !allTunnelsCount);
    const header = (
        <div className={classes.tunnelPageHeader}>
            <Filters />
            <Hidden smDown>
                <div className={classes.tunnelItemsHeader}>
                    <div
                        className={classNames(classes.columnHeader, {
                            [classes.headerTunnelName]: true
                        })}
                    >
                        Tunnel Name
                    </div>
                    <div
                        className={classNames(classes.columnHeader, {
                            [classes.headerDeviceName]: true
                        })}
                    >
                        Device Name
                    </div>
                    <div
                        className={classNames(classes.columnHeader, {
                            [classes.headerUrl]: true
                        })}
                    >
                        Connection properties
                    </div>
                    <div
                        className={classNames(classes.columnHeader, {
                            [classes.headerLocalPort]: true
                        })}
                    >
                        Local port
                    </div>
                    <div
                        className={classNames(classes.columnHeader, {
                            [classes.headerActions]: true
                        })}
                    >
                        Actions
                    </div>
                    <IconButton
                        className={classes.tunnelsHeaderActionsIcon}
                        aria-label="More"
                        aria-owns={
                            tunnelsHeaderActionsAnchorEl
                                ? 'tunnels-header-actions-menu'
                                : null
                        }
                        aria-haspopup="true"
                        onClick={handleTunnelsHeaderMenuClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="tunnels-header-actions-menu"
                        anchorEl={tunnelsHeaderActionsAnchorEl}
                        open={Boolean(tunnelsHeaderActionsAnchorEl)}
                        onClose={handleTunnelsHeaderMenuClose}
                    >
                        <MenuItem onClick={reloadAllRunningTunnelsClick}>
                            Reload all running tunnels
                        </MenuItem>
                        <MenuItem onClick={stopAllRunningTunnelsClick}>
                            Stop all running tunnels
                        </MenuItem>
                    </Menu>
                </div>
            </Hidden>
            <Hidden mdUp>
                <div
                    className={classNames(classes.tunnelItemsHeader, {
                        [classes.tunnelItemsHeaderMobile]: true
                    })}
                >
                    <div
                        className={classNames(classes.columnHeader, {
                            [classes.headerTunnelName]: true
                        })}
                    >
                        Tunnel Name
                    </div>
                    <div
                        className={classNames(classes.columnHeader, {
                            [classes.headerDeviceNameMobile]: true
                        })}
                    >
                        Device Name
                    </div>
                    <div
                        className={classNames(classes.columnHeader, {
                            [classes.headerLocalPortMobile]: true
                        })}
                    >
                        Port
                    </div>
                    <IconButton
                        className={classes.tunnelsHeaderActionsMobileIcon}
                        aria-label="More"
                        aria-owns={
                            tunnelsHeaderActionsAnchorEl
                                ? 'tunnels-header-actions-menu'
                                : null
                        }
                        aria-haspopup="true"
                        onClick={handleTunnelsHeaderMenuClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="tunnels-header-actions-menu"
                        anchorEl={tunnelsHeaderActionsAnchorEl}
                        open={Boolean(tunnelsHeaderActionsAnchorEl)}
                        onClose={handleTunnelsHeaderMenuClose}
                    >
                        <MenuItem onClick={reloadAllRunningTunnelsClick}>
                            Reload all running tunnels
                        </MenuItem>
                        <MenuItem onClick={stopAllRunningTunnelsClick}>
                            Stop all running tunnels
                        </MenuItem>
                    </Menu>
                </div>
            </Hidden>
        </div>
    );
    const confirmDialogContent = (
        <>
            <DialogTitle>
                {dialogMode === 'stop' ? 'Stop tunnels' : 'Reload tunnels'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure want to{' '}
                    {dialogMode === 'stop' ? 'stop all ' : 'reload all '}{' '}
                    running tunnels?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={doDialogAction}
                >
                    Ok
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowConfirmDialog(false)}
                >
                    Close
                </Button>
            </DialogActions>
        </>
    );

    const tourConfig = [
        {
            selector: '[data-tut="tunnels__step_main"]',
            content: () => (
                <>
                    <b>Tunnel Dashboard </b> - here you can see and manage your
                    tunnels.
                </>
            ),
            style: {
                backgroundColor: '#9c27b0',
                color: '#FFFFFF'
            }
        },
        {
            selector: '[data-tut="tunnels__step_buttons"]',
            // eslint-disable-next-line react/display-name
            content: () => (
                <div>
                    <p
                    // style={{
                    //     border: 'red',
                    //     borderStyle: 'solid',
                    //     borderWidth: 5,
                    //     padding: 7,
                    //     marginLeft: -11
                    // }}
                    >
                        Actions available for each tunnel:
                    </p>
                    <p>
                        <IconButton>
                            <StartIcon style={{ color: '#fff' }} />
                        </IconButton>
                        Start Tunnel
                    </p>
                    <p>
                        <IconButton>
                            <StopIcon style={{ color: '#fff' }} />
                        </IconButton>
                        Stop Tunnel
                    </p>
                    <p>
                        <IconButton>
                            <RefreshIcon style={{ color: '#fff' }} />
                        </IconButton>
                        Refresh Tunnel
                    </p>
                    <p>
                        <IconButton>
                            <EditIcon style={{ color: '#fff' }} />
                        </IconButton>
                        Edit Tunnel
                    </p>
                    <p>
                        <IconButton>
                            <DeleteIcon style={{ color: '#fff' }} />
                        </IconButton>
                        Delete Tunnel
                    </p>
                </div>
            ),
            style: {
                backgroundColor: '#9c27b0',
                color: '#FFFFFF'
            }
        },
        {
            selector: '[data-tut="tunnels__step_filters"]',
            content: `You can filter the tunnel list by name, type, device status, and tunnel status.`,
            style: {
                backgroundColor: '#9c27b0',
                color: '#FFFFFF'
            }
        }
    ];

    return (
        <LoadingOverlay
            active={
                false && (stoppingTunnelsInProcess || reloadingTunnelsInProcess)
            }
            spinner
            text={
                dialogMode === 'stop'
                    ? 'Stopping running tunnels...'
                    : 'Reloading running tunnels...'
            }
        >
            <div data-tut="tunnels__step_main">
                {!getTunnelsError && (
                    <div className={classes.tunnelsPage}>
                        {showHeader ? (
                            <>
                                <div className={classes.tunnelPageWrapper}>
                                    <Tour
                                        onRequestClose={closeTour}
                                        steps={tourConfig}
                                        isOpen={tunnelsTourDetails.visibility}
                                        maskClassName={classes.mask}
                                        getCurrentStep={getTourCurrentStep}
                                        startAt={tunnelsTourDetails.step}
                                        showNumber={false}
                                        showButtons={true}
                                        showCloseButton={false}
                                        showNavigationNumber={false}
                                        disableInteraction={false}
                                        maskSpace={10}
                                        rounded={15}
                                        accentColor="gray"
                                        showNavigation={false}
                                        lastStepNextButton={
                                            <Button
                                                style={{
                                                    border: '1px solid',
                                                    borderColor: '#fff'
                                                }}
                                                variant="contained"
                                                disabled={true}
                                                color="primary"
                                            >
                                                Next
                                            </Button>
                                        }
                                        nextButton={
                                            <div style={{ width: 145 }}>
                                                <Button
                                                    style={{
                                                        width: 100,
                                                        border: '1px solid',
                                                        borderColor: '#fff'
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        }
                                        prevButton={
                                            <Button
                                                style={{
                                                    border: '1px solid',
                                                    borderColor: '#fff'
                                                }}
                                                variant="contained"
                                                color="primary"
                                            >
                                                Previous
                                            </Button>
                                        }
                                    />
                                    {header}
                                    <div className={classes.tunnelItemsBody}>
                                        {noResults && (
                                            <div
                                                className={
                                                    classes.noTunnelsFound
                                                }
                                            >
                                                <span
                                                    className={
                                                        classes.noTunnelsFoundText
                                                    }
                                                >
                                                    No tunnels found
                                                </span>
                                                <Button
                                                    className={
                                                        classes.resetButton
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={
                                                        resetAllTunnelFilters
                                                    }
                                                >
                                                    Reset filters
                                                </Button>
                                            </div>
                                        )}
                                        {tunnels.map(tunnel =>
                                            renderTunnelItem(tunnel)
                                        )}
                                        <Hidden mdUp>
                                            <div
                                                className={classes.blankSpace}
                                            />
                                        </Hidden>
                                    </div>
                                    {allTunnelsCount !== tunnels.length && (
                                        <div
                                            style={{
                                                marginTop: 10,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Hidden smDown>
                                                <img
                                                    style={{
                                                        width: 14,
                                                        height: 14,
                                                        marginRight: 5
                                                    }}
                                                    src={FilterIcon}
                                                    alt="React Logo"
                                                />
                                                <div>
                                                    You have{' '}
                                                    <span
                                                        style={{
                                                            color: '#9c27b0'
                                                        }}
                                                    >{`${allTunnelsCount -
                                                        tunnels.length}`}</span>{' '}
                                                    filtered tunnel
                                                    {allTunnelsCount -
                                                        tunnels.length >
                                                        1 && 's'}
                                                    .
                                                </div>
                                                {!!tunnels.length && (
                                                    <Button
                                                        size="small"
                                                        style={{
                                                            marginLeft: 5
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={
                                                            resetAllTunnelFilters
                                                        }
                                                    >
                                                        Reset filters
                                                    </Button>
                                                )}
                                            </Hidden>
                                            <Hidden mdUp>
                                                <img
                                                    style={{
                                                        width: 14,
                                                        height: 14,
                                                        marginRight: 5
                                                    }}
                                                    src={FilterIcon}
                                                    alt="React Logo"
                                                />
                                                <div>
                                                    <span
                                                        style={{
                                                            color: '#9c27b0'
                                                        }}
                                                    >{`${allTunnelsCount -
                                                        tunnels.length}`}</span>{' '}
                                                    filtered tunnel
                                                    {allTunnelsCount -
                                                        tunnels.length >
                                                        1 && 's'}
                                                    .
                                                </div>
                                                {!!tunnels.length && (
                                                    <Button
                                                        size="small"
                                                        style={{
                                                            marginLeft: 5
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={
                                                            resetAllTunnelFilters
                                                        }
                                                    >
                                                        Reset
                                                    </Button>
                                                )}
                                            </Hidden>
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <EmptyTunnels
                                onAddTunnel={handleShowAddTunnelModal}
                            />
                        )}
                    </div>
                )}
                <Authorities>
                    <Fab
                        data-cmpauthkey={ADD_TUNNEL_ACTION_AUTH}
                        data-tut="reactour__tunnels__step2"
                        color="primary"
                        aria-label="Add"
                        className={classes.addButton}
                        onClick={handleShowAddTunnelModal}
                    >
                        <AddIcon />
                    </Fab>
                </Authorities>
                {showAddTunnelModal && (
                    <AddOrEditTunnel
                        onTunnelReady={onTunnelReady}
                        close={closeAddTunnelModal}
                    />
                )}
            </div>
            {showConfirmDialog && (
                <>
                    <Hidden smDown>
                        <Dialog
                            open
                            onClose={() => setShowConfirmDialog(false)}
                            aria-labelledby="delete-device-title"
                        >
                            {confirmDialogContent}
                        </Dialog>
                    </Hidden>{' '}
                    <Hidden mdUp>
                        <Dialog
                            fullScreen
                            open
                            onClose={() => setShowConfirmDialog(false)}
                            aria-labelledby="delete-device-title"
                        >
                            {confirmDialogContent}
                        </Dialog>
                    </Hidden>
                </>
            )}
        </LoadingOverlay>
    );
};

Tunnels.propTypes = {
    classes: PropTypes.object.isRequired,
    allTunnelsCount: PropTypes.number,
    tunnels: PropTypes.array,
    getTunnelsError: PropTypes.string.isRequired,
    getTunnelsLoading: PropTypes.bool.isRequired,
    tunnelDelete: PropTypes.func.isRequired,
    onTunnelReady: PropTypes.func.isRequired,
    stoppingTunnelsInProcess: PropTypes.bool.isRequired,
    reloadingTunnelsInProcess: PropTypes.bool.isRequired,
    onStopAllRunningTunnels: PropTypes.func.isRequired,
    onReloadAllRunningTunnels: PropTypes.func.isRequired,
    onStopAllRunningTunnelsDone: PropTypes.func.isRequired,
    onReloadAllRunningTunnelsDone: PropTypes.func.isRequired,
    noFilters: PropTypes.bool.isRequired,
    tunnelsTourDetails: PropTypes.object.isRequired,
    actions: PropTypes.shape({
        resetAllTunnelFilters: PropTypes.func.isRequired,
        setTunnelsTourStep: PropTypes.func.isRequired,
        setTunnelsTourVisibility: PropTypes.func.isRequired
    })
};

const mapStateToProps = createStructuredSelector({
    noFilters: getNoFilters(),
    tunnelsTourDetails: getTunnelsTourDetails()
});

const mapDispatchToProps = wrapActionCreators({
    resetAllTunnelFilters,
    setTunnelsTourVisibility,
    setTunnelsTourStep
});

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Tunnels)
);
