import { validate as emailValidator } from 'email-validator';

export function validateFullname(fullName) {
    let isValid = true;
    const errors = {
        fullNameError: ''
    };
    if (!fullName || !fullName.trim()) {
        isValid = false;
        errors.fullNameError = 'Please enter your full name';
    }
    return {
        isValid,
        errors
    };
}

export function validateEmail(email) {
    let isValid = true;
    const errors = {
        emailError: ''
    };
    if (!email || !email.trim()) {
        isValid = false;
        errors.emailError = 'Please enter your email';
    } else if (!emailValidator(email)) {
        isValid = false;
        errors.emailError = 'Invalid Email';
    }
    return {
        isValid,
        errors
    };
}

export function validateUsername(username) {
    let isValid = true;
    const errors = {
        usernameError: ''
    };
    const regex = /^[a-z][a-z0-9]+$/i;
    if (!username || !username.trim()) {
        isValid = false;
        errors.usernameError = 'Please enter Username';
    } else if (username.trim().length < 5 || !regex.test(username.trim())) {
        isValid = false;
        errors.usernameError =
            'Username should be minimum 5 characters, only numbers and letters with first letter character.';
    }
    return {
        isValid,
        errors
    };
}

export function isValidLocalPort(localPort) {
    const parsedLocalPort = parseFloat(localPort);
    let isValid = true;
    if (
        !Number.isInteger(parsedLocalPort) ||
        parsedLocalPort < 0 ||
        parsedLocalPort > 65535
    ) {
        isValid = false;
    }
    return isValid;
}

export function isValidTunnelName(name) {
    const nameRegEx = /^[a-z0-9_-]*$/i;
    let isValid = true;
    if (!nameRegEx.test(name.trim())) {
        isValid = false;
    }
    return isValid;
}
