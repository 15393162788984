export default (theme: any) => ({
    paper: {
        width: '600px'
    },
    contentWrap: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch'
    },
    avatarWrap: {
        width: '100px'
    },
    infoWrap: {
        flex: '1 1 0%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between'
    },
    column33: {
        flexBasis: '33.33%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    details: {
        alignItems: 'center'
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});
