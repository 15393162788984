import React from 'react';
import { createStyles, WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {
    AutoSizer,
    Column,
    Index,
    RowMouseEventHandlerParams,
    SortDirection,
    Table,
    TableCellProps,
    TableCellRenderer,
    TableHeaderProps
} from 'react-virtualized';
import type { IGridColumn } from '../../../interface/Grid';

const styles = (theme: Theme) =>
    createStyles({
        table: {
            fontFamily: theme.typography.fontFamily,
            '& *': {
                outline: 'none'
            }
        },
        flexContainer: {
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box'
        },
        tableRow: {
            cursor: 'pointer'
        },
        tableRowHover: {
            '&:hover': {
                backgroundColor: theme.palette.grey[200]
            }
        },
        tableCell: {
            width: '100%',
            flex: 1,
            [theme.breakpoints.down('sm')]: {
                padding: '2px 4px!important'
            },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            '& *': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }
        },
        noClick: {
            cursor: 'initial'
        }
    });

interface IMuiVirtualizedTableProps {
    rowCount: number;
    columns: IGridColumn[];
    headerHeight?: number;
    rowHeight?: number;
    onRowClick: (info: RowMouseEventHandlerParams) => void;
    rowClassName?: string | ((info: Index) => string);
    sort?: () => void;
    rowGetter: (index: { index: number }) => any;
}

const MuiVirtualizedTable = ({
    classes,
    columns,
    rowCount,
    headerHeight = 56,
    rowHeight = 56,
    onRowClick,
    rowClassName,
    sort,
    rowGetter
}: WithStyles<typeof styles> & IMuiVirtualizedTableProps) => {
    const getRowClassName = ({ index }: Index) =>
        classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick !== null
        });
    const cellRenderer = ({
        cellData,
        columnIndex
    }: {
        cellData: any,
        columnIndex: number | null
    }) => (
        <TableCell
            component="div"
            className={classNames(classes.tableCell, classes.flexContainer, {
                [classes.noClick]: onRowClick == null
            })}
            variant="body"
            style={{ height: rowHeight }}
            align={
                (columnIndex !== null && columns[columnIndex].numeric) || false
                    ? 'right'
                    : 'left'
            }
        >
            {cellData}
        </TableCell>
    );
    const headerRenderer = ({
        label,
        columnIndex,
        dataKey,
        sortBy,
        sortDirection = 'ASC'
    }: { columnIndex: number } & TableHeaderProps) => {
        const direction: { ASC: 'asc', DESC: 'desc' } = {
            [SortDirection.ASC]: 'asc',
            [SortDirection.DESC]: 'desc'
        };

        const inner =
            !columns[columnIndex].disableSort && sort !== null ? (
                <TableSortLabel
                    active={dataKey === sortBy}
                    direction={direction[sortDirection]}
                >
                    {label}
                </TableSortLabel>
            ) : (
                label
            );

        return (
            <TableCell
                component="div"
                className={classNames(
                    classes.tableCell,
                    classes.flexContainer,
                    classes.noClick
                )}
                variant="head"
                style={{ height: headerHeight }}
                align={columns[columnIndex].numeric || false ? 'right' : 'left'}
            >
                {inner}
            </TableCell>
        );
    };
    return (
        <AutoSizer>
            {({ height, width }) => (
                <Table
                    className={classes.table}
                    height={height}
                    width={width}
                    rowCount={rowCount}
                    rowClassName={getRowClassName}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    onRowClick={onRowClick}
                    rowGetter={rowGetter}
                >
                    {columns.map(
                        (
                            {
                                cellContentRenderer = null,
                                className,
                                dataKey,
                                ...other
                            },
                            index
                        ) => {
                            let renderer: TableCellRenderer;
                            if (cellContentRenderer !== null) {
                                renderer = (
                                    cellRendererProps: TableCellProps
                                ) =>
                                    cellRenderer({
                                        cellData: cellContentRenderer(
                                            cellRendererProps
                                        ),
                                        columnIndex: index
                                    });
                            } else {
                                renderer = cellRenderer;
                            }

                            return (
                                <Column
                                    key={dataKey}
                                    headerRenderer={(
                                        headerProps: TableHeaderProps
                                    ) =>
                                        headerRenderer({
                                            ...headerProps,
                                            columnIndex: index
                                        })
                                    }
                                    className={classNames(
                                        classes.flexContainer,
                                        className
                                    )}
                                    cellRenderer={renderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            );
                        }
                    )}
                </Table>
            )}
        </AutoSizer>
    );
};

export default withStyles(styles)(MuiVirtualizedTable);
