// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import classNames from 'classnames';

const styles = () => ({
    tab: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    isOver: {
        border: '1px dashed black'
    },
    tabContent: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    circleIcon: {
        fontSize: 13,
        marginBottom: 1
    },
    colorGreen: {
        fill: '#61bd4f'
    },
    colorYellow: {
        fill: '#f2d600'
    },
    colorRed: {
        fill: '#eb5a46'
    },
    deviceCountText: {
        fontSize: 10,
        color: 'black',
        marginLeft: -1,
        marginRight: 2,
        marginBottom: 1
    }
});

class TabContent extends React.Component<{
    classes: {
        tab: string,
        isOver: string,
        tabContent: string,
        circleIcon: string,
        colorGreen: string,
        colorYellow: string,
        colorRed: string,
        deviceCountText: string
    },
    group: any,
    isOver: boolean,
    canDrop: boolean
}> {
    static propTypes = {
        classes: PropTypes.shape({
            tab: PropTypes.string.isRequired,
            isOver: PropTypes.string.isRequired,
            tabContent: PropTypes.string.isRequired,
            circleIcon: PropTypes.string.isRequired,
            colorGreen: PropTypes.string.isRequired,
            colorYellow: PropTypes.string.isRequired,
            colorRed: PropTypes.string.isRequired,
            deviceCountText: PropTypes.string.isRequired
        }).isRequired,
        group: PropTypes.object.isRequired,
        isOver: PropTypes.bool.isRequired,
        canDrop: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isOver: false,
        canDrop: true
    };

    render() {
        const {
            statusesCountInGroup: { onlineCount, awayCount, offlineCount },
            group: { name },
            classes
        } = this.props;
        return (
            <div className={classes.tab}>
                <div className={classes.tabContent}>{name}</div>
                {!!onlineCount && (
                    <>
                        <CircleIcon
                            className={classNames(
                                classes.colorGreen,
                                classes.circleIcon
                            )}
                        />{' '}
                        <span className={classes.deviceCountText}>
                            {onlineCount}
                        </span>
                    </>
                )}
                {!!awayCount && (
                    <>
                        <CircleIcon
                            className={classNames(
                                classes.colorYellow,
                                classes.circleIcon
                            )}
                        />{' '}
                        <span className={classes.deviceCountText}>
                            {awayCount}
                        </span>
                    </>
                )}
                {!!offlineCount && (
                    <>
                        <CircleIcon
                            className={classNames(
                                classes.colorRed,
                                classes.circleIcon
                            )}
                        />{' '}
                        <span className={classes.deviceCountText}>
                            {offlineCount}
                        </span>
                    </>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(TabContent);
