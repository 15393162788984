// @flow

import { combineReducers } from 'redux';
import { filter, isEqual } from 'lodash';

import globalInitialState from './initial-state';
import {
    GET_DEVICE_USERS_SUCCESS,
    CLEAR_DEVICE_USERS,
    ADD_OR_REMOVE_USERS_TO_DEVICE_LOADING,
    ADD_OR_REMOVE_USERS_TO_DEVICE_SUCCESS,
    REMOVE_CURRENT_USER_FROM_DEVICE_LOADING,
    REMOVE_CURRENT_USER_FROM_DEVICE_SUCCESS
} from '../constants/action-types';

const initialState = globalInitialState.userDevices;

function data(state = initialState.data, action) {
    switch (action.type) {
        case GET_DEVICE_USERS_SUCCESS: {
            return action.payload.data;
        }
        case REMOVE_CURRENT_USER_FROM_DEVICE_SUCCESS:
        case ADD_OR_REMOVE_USERS_TO_DEVICE_SUCCESS: {
            const { addedUserDevices, removedUserDevices } = action.payload;
            let newState = [...state];
            if (addedUserDevices && addedUserDevices.length) {
                newState = [...newState, ...addedUserDevices];
            }
            const idMapper = userDevice => userDevice._id;
            if (removedUserDevices && removedUserDevices.length) {
                const removedUserDeviceIds = removedUserDevices.map(idMapper);
                newState = filter(
                    newState,
                    ({ _id }) => removedUserDeviceIds.indexOf(_id) === -1
                );
            }

            const modified = !isEqual(
                newState.map(idMapper),
                state.map(idMapper)
            );
            return modified ? newState : state;
        }
        case CLEAR_DEVICE_USERS: {
            return filter(
                state,
                ({ device: { _id } }) => _id !== action.payload
            );
        }
        default:
            return state;
    }
}

function loading(state = initialState.loading, action) {
    switch (action.type) {
        case ADD_OR_REMOVE_USERS_TO_DEVICE_LOADING: {
            return {
                ...state,
                add: action.payload,
                remove: action.payload
            };
        }
        case REMOVE_CURRENT_USER_FROM_DEVICE_LOADING: {
            return {
                ...state,
                remove: action.payload
            };
        }
        default:
            return state;
    }
}

export default combineReducers({
    data,
    loading
});
