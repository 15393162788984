// @flow
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    content: {
        padding: '20px'
    },
    contentBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    actionsBar: {
        display: 'flex',
        flexDirection: 'row',
        padding: '5px 0px'
    },
    flex1: {
        flex: '1'
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    passwordRecoveryButton: {
        // margin: theme.spacing.unit
        marginRight: '16px'
    },
    button: {
        marginLeft: 20,
        marginRight: 20
    }
});

class SetNewPassword extends React.Component<
    {
        classes: {
            root: string,
            content: string,
            contentBody: string,
            actionsBar: string,
            flex1: string,
            formControl: string,
            button: string,
            passwordRecoveryButton: string
        },
        handleSetNewPasswordPassword: (newPassword: string) => void,
        changePasswordInProgress: boolean,
        changePasswordSuccess: boolean,
        replace: (path: string) => void,
        userFullName: string,
        getMeError: string
    },
    {
        newPassword: string,
        showNewPassword: boolean,
        confirmNewPassword: string,
        showConfirmNewPassword: boolean,
        setNewPasswordError: string
    }
> {
    state = {
        newPassword: '',
        showNewPassword: false,
        confirmNewPassword: '',
        showConfirmNewPassword: false,
        setNewPasswordError: ''
    };

    handleClickShowNewPassword = () => {
        const { showNewPassword } = this.state;
        this.setState({ showNewPassword: !showNewPassword });
    };

    handleClickShowConfirmNewPassword = () => {
        const { showConfirmNewPassword } = this.state;
        this.setState({
            showConfirmNewPassword: !showConfirmNewPassword
        });
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleNewPasswordChange = prop => event => {
        this.setState({ [prop]: event.target.value, setNewPasswordError: '' });
    };

    handleConfirmNewPasswordChange = prop => event => {
        this.setState({ [prop]: event.target.value, setNewPasswordError: '' });
    };

    handleGoToLogin = () => {
        const { replace } = this.props;
        replace('/login');
        window.location.reload();
    };

    handleSaveNewPasswordClick = () => {
        let isValid = true;
        const { newPassword, confirmNewPassword } = this.state;
        if (!newPassword || !confirmNewPassword) {
            isValid = false;
            this.setState({
                setNewPasswordError: 'Please enter all password fields'
            });
        } else if (newPassword !== confirmNewPassword) {
            isValid = false;
            this.setState({
                setNewPasswordError: 'Passwords does not match'
            });
        } else if (newPassword.length < 8) {
            isValid = false;
            this.setState({
                setNewPasswordError: 'Passwords should be at least 8 chars'
            });
        }
        if (isValid) {
            const { handleSetNewPasswordPassword } = this.props;
            handleSetNewPasswordPassword(newPassword);
        }
    };

    onKeyDown = event => {
        switch (event.keyCode) {
            case 13: {
                this.handleSaveNewPasswordClick();
                break;
            }
            default:
                break;
        }
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    render() {
        const {
            classes,
            changePasswordInProgress,
            changePasswordSuccess,
            userFullName,
            getMeError
        } = this.props;
        const {
            newPassword,
            showNewPassword,
            confirmNewPassword,
            showConfirmNewPassword,
            setNewPasswordError
        } = this.state;
        return (
            <Card className={classes.root}>
                <CardContent className={classes.content}>
                    <Typography type="title" color="inherit">
                        {!getMeError ? (
                            <div>
                                <p>Hi {userFullName}.</p>
                                <p>Please set your new password.</p>
                            </div>
                        ) : (
                            <div>Set New Password</div>
                        )}
                    </Typography>
                    <div className={classes.contentBody}>
                        <FormControl required className={classes.formControl}>
                            <InputLabel htmlFor="new-password-field">
                                Set New Password
                            </InputLabel>
                            <Input
                                id="new-password-field"
                                type={showNewPassword ? 'text' : 'password'}
                                autoComplete="aff"
                                value={newPassword}
                                onChange={this.handleNewPasswordChange(
                                    'newPassword'
                                )}
                                inputProps={this.inputProps}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={
                                                this.handleClickShowNewPassword
                                            }
                                            tabIndex="-1"
                                            onMouseDown={
                                                this.handleMouseDownPassword
                                            }
                                        >
                                            {showNewPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl required className={classes.formControl}>
                            <InputLabel htmlFor="confirm-new-password-field">
                                Confirm New Password
                            </InputLabel>
                            <Input
                                id="confirm-new-password-field"
                                type={
                                    showConfirmNewPassword ? 'text' : 'password'
                                }
                                autoComplete="aff"
                                value={confirmNewPassword}
                                onChange={this.handleConfirmNewPasswordChange(
                                    'confirmNewPassword'
                                )}
                                inputProps={this.inputProps}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={
                                                this
                                                    .handleClickShowConfirmNewPassword
                                            }
                                            tabIndex="-1"
                                            onMouseDown={
                                                this.handleMouseDownPassword
                                            }
                                        >
                                            {showConfirmNewPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    {!!setNewPasswordError && (
                        <FormHelperText error>
                            {setNewPasswordError}
                        </FormHelperText>
                    )}
                </CardContent>
                <CardActions className={classes.actionsBar}>
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={this.handleGoToLogin}
                    >
                        Go To Login
                    </Button>
                    <div className={classes.flex1} />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            !!setNewPasswordError ||
                            changePasswordSuccess ||
                            changePasswordInProgress ||
                            !!getMeError
                        }
                        className={classes.passwordRecoveryButton}
                        onClick={this.handleSaveNewPasswordClick}
                    >
                        Save
                    </Button>
                </CardActions>
            </Card>
        );
    }
}

SetNewPassword.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        formControl: PropTypes.string.isRequired
    }).isRequired,
    handleSetNewPasswordPassword: PropTypes.func.isRequired,
    changePasswordInProgress: PropTypes.bool.isRequired,
    changePasswordSuccess: PropTypes.bool.isRequired,
    replace: PropTypes.func.isRequired,
    userFullName: PropTypes.string.isRequired,
    getMeError: PropTypes.string.isRequired
};

export default withStyles(styles)(SetNewPassword);
