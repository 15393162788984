// @flow
import { api, makeGet, makePost, makePatch } from '../utils/http-util';

const API_URL = {
    SIGNUP: () => api`v1/signup`,
    SIGNUP_ACTIVATE_ORG_USER: () => api`v1/signup-org-user`,
    SIGNUP_FOR_GET_DEVICE: () => api`v1/signup-get-device`,
    LOGIN: () => api`v1/login`,
    LOGOUT: () => api`v1/logout`,
    GET_LOGGED_IN_USER: () => api`v1/me`,
    CHANGE_PASSWORD: () => api`v1/me/change-password`,
    SET_NEW_PASSWORD: () => api`v1/me/set-new-password`,
    CHANGE_ADD_DEVICE_PASSWORD: () => api`v1/me/change-add-device-password`,
    PASSWORD_RECOVERY: () => api`v1/password-recovery`,
    GET_SUGGESTED_USERNAME: () => api`v1/get-suggested-username`,
    CHECK_USERNAME: () => api`v1/username-exists`,
    VERIFY_EMAIL: () => api`v1/verify-email`,
    TOGGLE_TWO_FACTOR_AUTH: () => api`v1/toggle-two-factor-authentication`,
    CHANGE_ORGANIZATION: orgId => api`v1/users/change-organization/${orgId}`,
    REGENERATE_TWO_FACTOR_AUTHENTICATION_QR: () =>
        api`v1/regenerate-two-factor-authentication-qr`
};

export function login(
    email: string,
    password: string,
    twoFactorAuthStr: string = ''
) {
    return makePost(API_URL.LOGIN(), { email, password, twoFactorAuthStr });
}

export function loginForGetDevice(email: string, password: string, headers) {
    return makePost(API_URL.LOGIN(), { email, password }, headers);
}

export function signup(data: {
    fullName: string,
    email: string,
    username: string,
    password: string,
    confirmPassword: string
}) {
    return makePost(API_URL.SIGNUP(), data);
}

export function signupActivateOrgUser(data: {
    fullName: string,
    email: string,
    username: string,
    password: string,
    confirmPassword: string,
    token: string
}) {
    return makePost(API_URL.SIGNUP_ACTIVATE_ORG_USER(), data);
}

export function signupForGetDevice(
    data: {
        fullName: string,
        email: string,
        username: string,
        password: string,
        confirmPassword: string
    },
    headers
) {
    return makePost(API_URL.SIGNUP_FOR_GET_DEVICE(), data, headers);
}

export function getSuggestedUsername(body: {
    fullName?: string,
    email?: string
}) {
    return makePost(API_URL.GET_SUGGESTED_USERNAME(), body);
}

export function checkUsernameExisting(body: { userName?: string }) {
    return makePost(API_URL.CHECK_USERNAME(), body);
}

export function logout() {
    return null;
}

export function getLoggedInUser(headers) {
    return makeGet(API_URL.GET_LOGGED_IN_USER(), headers);
}

export function updateCurrentUser(body: {
    email?: string,
    fullName?: string,
    defaultGroup?: string
}) {
    return makePatch(API_URL.GET_LOGGED_IN_USER(), body);
}

export function changePassword(oldPassword: string, newPassword: string) {
    return makePost(API_URL.CHANGE_PASSWORD(), {
        oldPassword,
        newPassword
    });
}

export function changeCurrentUserAddDevicePassword(
    oldPassword: string,
    newPassword: string
) {
    return makePost(API_URL.CHANGE_ADD_DEVICE_PASSWORD(), {
        oldPassword,
        newPassword
    }).toPromise();
}

export function passwordRecovery(email: string) {
    return makePost(API_URL.PASSWORD_RECOVERY(), { email });
}

export function setNewPasswordWithRecoveryToken(newPassword: string, headers) {
    return makePost(
        API_URL.SET_NEW_PASSWORD(),
        {
            newPassword
        },
        headers
    );
}

export function verifyEmail(headers) {
    return makePost(API_URL.VERIFY_EMAIL(), null, headers);
}

export function toggleTwoFactorAuth(enabled) {
    return makePost(API_URL.TOGGLE_TWO_FACTOR_AUTH(), { enabled });
}
export function regenerateTwoFactorAuthQr() {
    return makePost(API_URL.REGENERATE_TWO_FACTOR_AUTHENTICATION_QR());
}
export function changeOrg(id) {
    return makePost(API_URL.CHANGE_ORGANIZATION(id));
}
