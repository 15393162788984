import { merge, of, iif } from 'rxjs';
import { switchMap, map, filter, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { CHANGE_ORGANIZATION } from '../../constants/action-types';
import {
    setConnectivityStatus,
    setGlobalMessageError
} from '../../actions/app-actions';
import {
    loginLoading,
    insufficientlyAuthenticationDetails
} from '../../actions/authentication-actions';
import { changeOrg } from '../../api/authentication-api';

import { saveAuthToken } from '../../utils/http-util';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default action$ =>
    action$.pipe(
        ofType(CHANGE_ORGANIZATION),
        map(action => action.payload),
        filter(data => data.organizationId),
        switchMap(({ organizationId }) =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(loginLoading(true)),
                changeOrg(organizationId).pipe(
                    mergeMap(({ data }) => {
                        if (!data) {
                            throw new Error('Invalid response');
                        }
                        const { authToken } = data;
                        saveAuthToken(authToken);
                        window.location.reload();
                    }),
                    catchError(error => {
                        const actions = [
                            setConnectivityStatus({ loading: false }),
                            loginLoading(false),
                            insufficientlyAuthenticationDetails(
                                error &&
                                    error.response &&
                                    error.response.details
                            )
                        ];
                        return iif(
                            () => error.status !== 412,
                            of(
                                ...actions,
                                setGlobalMessageError(
                                    constructGlobalErrorMessage(
                                        parseErrorMessage(error)
                                    )
                                )
                            ),
                            of(...actions)
                        );
                    })
                )
            )
        )
    );
