// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import SortableDevice from './SortableDevice';

class OrderDevice extends Component<
    {
        sortableDevices: any[],
        fullScreen: boolean,
        actions: {
            updateDevicesOrdering: (devices: any[]) => void
        },
        close: () => void
    },
    {
        sortableDevices: any[],
        canSave: boolean
    }
> {
    static propTypes = {
        sortableDevices: PropTypes.array.isRequired,
        actions: PropTypes.shape({
            updateDevicesOrdering: PropTypes.func.isRequired
        }).isRequired,
        fullScreen: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        const { sortableDevices } = props;
        const canSave = false;
        this.state = {
            sortableDevices,
            canSave
        };
    }

    handleSave = () => {
        const {
            actions: { updateDevicesOrdering },
            close
        } = this.props;
        const { sortableDevices, canSave } = this.state;
        const orderedDevices = sortableDevices.map(({ _id, owner }, order) => ({
            deviceId: _id,
            userId: owner,
            order
        }));
        if (canSave) {
            updateDevicesOrdering(orderedDevices);
            close();
        }
    };

    updateCanSave = (newDevices: any[]) => {
        const { sortableDevices } = this.props;
        for (let i = 0; i < newDevices.length; i += 1) {
            if (newDevices[i] !== sortableDevices[i]) {
                this.setState({
                    canSave: true
                });
                return;
            }
        }
        this.setState({
            canSave: false
        });
    };

    moveDevice = (dragIndex: number, direction: string) => {
        const dropIndex = direction === 'up' ? dragIndex - 1 : dragIndex + 1;
        const { sortableDevices } = this.state;
        const dragDevice = sortableDevices[dragIndex];
        const newDevices = sortableDevices.slice();
        newDevices.splice(dragIndex, 1); // removing what you are dragging.
        newDevices.splice(dropIndex, 0, dragDevice); // inserting it into hoverIndex.
        this.setState({ sortableDevices: newDevices });
        this.updateCanSave(newDevices);
    };

    renderSortableDevice = (device, i) => {
        const { name, _id } = device;
        const devicesLength = this.props.sortableDevices.length;
        return (
            <SortableDevice
                devicesLength={devicesLength}
                key={_id}
                index={i}
                name={name}
                moveDevice={this.moveDevice}
            />
        );
    };

    render() {
        const { fullScreen, close } = this.props;
        const { sortableDevices, canSave } = this.state;
        return (
            <Dialog fullScreen={fullScreen} open onClose={close}>
                <DialogContent>
                    <h2>Order devices by clicking on arrows</h2>
                    <div>
                        {sortableDevices.map((device, i) =>
                            this.renderSortableDevice(device, i)
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSave}
                        disabled={!canSave}
                    >
                        Save
                    </Button>
                    <Button variant="contained" color="primary" onClick={close}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withMobileDialog()(OrderDevice);
