import { merge, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { REMOVE_CURRENT_USER_FROM_DEVICE } from '../../constants/action-types';
import { addOrRemoveUsersToDevice } from '../../api/user-device-api';
import {
    setConnectivityStatus,
    setGlobalMessageError
} from '../../actions/app-actions';
import {
    removeCurrentUserFromDeviceLoading,
    removeCurrentUserFromDeviceSuccess
} from '../../actions/user-device-actions';

import { getLoggedInUser } from '../../selectors/app-selectors';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default (action$, state$) =>
    action$.pipe(
        ofType(REMOVE_CURRENT_USER_FROM_DEVICE),
        map(action => action.payload),
        switchMap(deviceId => {
            const state = state$.value;
            const currentUser = getLoggedInUser()(state);
            const removedUsers = [currentUser._id];
            return merge(
                of(setConnectivityStatus({ loading: true })),
                of(removeCurrentUserFromDeviceLoading(true)),
                addOrRemoveUsersToDevice(deviceId, removedUsers).pipe(
                    mergeMap(({ data }) =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            removeCurrentUserFromDeviceLoading(false),
                            removeCurrentUserFromDeviceSuccess(data)
                        )
                    ),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            removeCurrentUserFromDeviceLoading(false),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            );
        })
    );
