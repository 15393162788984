import jwt from 'jwt-simple';

export default function decodeJwt(token, key, noVerify) {
    try {
        const data = jwt.decode(token, key, noVerify);
        return { data };
    } catch (error) {
        return { error };
    }
}
