import { createAction } from 'redux-actions';
import {
    ADD_DEVICE,
    ADD_DEVICE_LOADING,
    ADD_DEVICE_SUCCESS,
    GET_DEVICES,
    GET_DEVICES_ERROR,
    GET_DEVICES_LOADING,
    GET_DEVICES_SUCCESS,
    UPDATE_DEVICE_SUCCESS,
    DEVICE_SOCKET_MESSAGE,
    UPDATE_DEVICE,
    UPDATE_DEVICE_LOADING,
    DELETE_DEVICE_SUCCESS,
    DELETE_DEVICE,
    DELETE_DEVICE_LOADING,
    UPDATE_DEVICES_ORDERING,
    UPDATE_DEVICES_ORDERING_LOADING,
    UPDATE_DEVICES_ORDERING_SUCCESS,
    UPDATE_DEVICES_ORDERING_ERROR,
    GET_DEVICES_STATUSES,
    GET_DEVICES_STATUSES_LOADING,
    GET_DEVICES_STATUSES_SUCCESS
} from '../constants/action-types';

export const getDevices = createAction(GET_DEVICES);
export const getDevicesLoading = createAction(GET_DEVICES_LOADING);
export const getDevicesSuccess = createAction(GET_DEVICES_SUCCESS);
export const getDevicesError = createAction(GET_DEVICES_ERROR);

export const updateDevicesOrdering = createAction(UPDATE_DEVICES_ORDERING);
export const updateDevicesOrderingLoading = createAction(
    UPDATE_DEVICES_ORDERING_LOADING
);
export const updateDevicesOrderingSuccess = createAction(
    UPDATE_DEVICES_ORDERING_SUCCESS
);
export const updateDevicesOrderingError = createAction(
    UPDATE_DEVICES_ORDERING_ERROR
);

export const addDevice = createAction(ADD_DEVICE);
export const addDeviceLoading = createAction(ADD_DEVICE_LOADING);
export const addDeviceSuccess = createAction(ADD_DEVICE_SUCCESS);

export const updateDevice = createAction(UPDATE_DEVICE);
export const updateDeviceLoading = createAction(UPDATE_DEVICE_LOADING);
export const updateDeviceSuccess = createAction(UPDATE_DEVICE_SUCCESS);

export const deleteDevice = createAction(DELETE_DEVICE);
export const deleteDeviceLoading = createAction(DELETE_DEVICE_LOADING);
export const deleteDeviceSuccess = createAction(DELETE_DEVICE_SUCCESS);

export const getStatuses = createAction(GET_DEVICES_STATUSES);
export const getStatusesLoading = createAction(GET_DEVICES_STATUSES_LOADING);
export const getStatusesSuccess = createAction(GET_DEVICES_STATUSES_SUCCESS);

export const socketMessageReceive = createAction(DEVICE_SOCKET_MESSAGE);
