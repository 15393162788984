// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import ProxyOptions from '../../containers/Settings/ProxyOptions';

import styles from './styles';

class Settings extends Component<
    {
        classes: any,
        fullScreen: boolean,
        close: () => void
    },
    {
        expanded: '' | 'proxy'
    }
> {
    static propTypes = {
        fullScreen: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired
    };

    state = {
        expanded: 'proxy'
    };

    onProxyOptionsPanelChange = (event, expanded) => {
        this.setState({
            expanded: expanded ? 'proxy' : ''
        });
    };

    render() {
        const { fullScreen, close, classes } = this.props;

        const { expanded } = this.state;

        return (
            <Dialog
                fullScreen={fullScreen}
                open
                onClose={close}
                classes={{
                    paper: classes.paper
                }}
                aria-labelledby="profile-dialog-title"
            >
                <DialogTitle id="profile-dialog-title">Settings</DialogTitle>
                <DialogContent>
                    <div className={classes.contentWrap}>
                        <ProxyOptions
                            onCancel={close}
                            expanded={expanded === 'proxy'}
                            onExpansionPanelChange={
                                this.onProxyOptionsPanelChange
                            }
                        />
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(Settings));
