// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { withStyles } from '@material-ui/core/styles';
import { clearAuthToken } from '../../../utils/http-util';
import GetSendedDevice from '../../../containers/Devices/GetSendedDevice';
import SignUpOrLoginForGetDevice from '../../../containers/Devices/SignUpOrLoginForGetDevice';
import ActivateAccount from '../../../containers/Users/ActivateAccount';

const styles = theme => ({
    wrapper: {
        flex: '1 1 0%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 500,
        marginTop: 50
    },
    content: {
        padding: '20px'
    },
    actionsBar: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 0px',
        height: '40px',
        alignItems: 'inherit'
    },
    flex1: {
        flex: '1'
    },
    mainActions: {
        display: 'flex',
        flexDirection: 'row',
        height: '36px'
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    titleText: {
        marginTop: 20
    },
    colorGreen: {
        color: '#61bd4f'
    }
});

class ChangeDeviceOwner extends React.Component<
    {
        classes: {
            wrapper: string,
            root: string,
            content: string,
            actionsBar: string,
            flex1: string,
            mainActions: string,
            formControl: string,
            titleText: string,
            colorGreen: string
        },
        oldOwnerEmail: string,
        newOwnerEmail: string,
        deviceName: string,
        loggedInUser: any,
        history: { replace: (path: string) => void },
        token: string,
        initiallyAuthenticated: boolean
    },
    {
        readyForGetDevice: boolean
    }
> {
    state = {
        readyForGetDevice: false
    };

    handleChangeAccountClick = () => {
        clearAuthToken();
        window.location.reload();
    };

    handleGetDeviceWithCurrentAccClick = () => {
        this.setState({ readyForGetDevice: true });
    };

    handleHomeBtnClick = () => {
        const { history } = this.props;
        history.replace('/');
        window.location.reload();
    };

    render() {
        const {
            classes,
            oldOwnerEmail,
            deviceName,
            loggedInUser,
            token,
            newOwnerEmail,
            initiallyAuthenticated
        } = this.props;
        const { readyForGetDevice } = this.state;
        return (
            <div className={classes.wrapper}>
                {loggedInUser &&
                    loggedInUser.emailVerified &&
                    initiallyAuthenticated &&
                    !readyForGetDevice && (
                        <Card className={classes.root}>
                            <CardContent className={classes.content}>
                                <Typography type="title" color="inherit">
                                    Change Device Owner
                                </Typography>
                                <div>
                                    <p>
                                        User with {oldOwnerEmail} email wants to
                                        give you ({newOwnerEmail}) `{deviceName}
                                        ` device.
                                    </p>
                                    <p>
                                        You are logged in as{' '}
                                        {loggedInUser.fullName} (
                                        {loggedInUser.email})
                                    </p>
                                    <p>
                                        Do you want to get device with current
                                        account?
                                    </p>
                                </div>
                            </CardContent>
                            <CardActions className={classes.actionsBar}>
                                <div className={classes.mainActions}>
                                    <div className={classes.flex1} />
                                    <Button
                                        color="primary"
                                        onClick={this.handleHomeBtnClick}
                                    >
                                        Home
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={this.handleChangeAccountClick}
                                    >
                                        Change Account
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={
                                            this
                                                .handleGetDeviceWithCurrentAccClick
                                        }
                                    >
                                        Get Device
                                    </Button>
                                </div>
                            </CardActions>
                        </Card>
                    )}
                {loggedInUser &&
                    loggedInUser.emailVerified &&
                    (!initiallyAuthenticated || readyForGetDevice) && (
                        <GetSendedDevice
                            token={token}
                            oldOwnerEmail={oldOwnerEmail}
                            deviceName={deviceName}
                        />
                    )}
                {loggedInUser && !loggedInUser.emailVerified && (
                    <ActivateAccount />
                )}
                {!loggedInUser && (
                    <SignUpOrLoginForGetDevice
                        token={token}
                        oldOwnerEmail={oldOwnerEmail}
                        newOwnerEmail={newOwnerEmail}
                        deviceName={deviceName}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(ChangeDeviceOwner));
