// @flow
import React from 'react';
import 'date-fns';
import PropTypes from 'prop-types';
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    AreaChart,
    Area
} from 'recharts';
import { bytesToSize } from '../../utils/helpers';
import { lightenDarkenColor } from '../../utils/css-utils';

const MainAreaChart = ({
    width = 600,
    height = 400,
    data,
    tunnels,
    devices,
    netDataType,
    areaData,
    animationDuration = 1500
}) => {
    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div
                    style={{
                        backgroundColor: 'rgba(200, 200, 200, .9)',
                        fontSize: 12,
                        padding: 10,
                        borderRadius: 5
                    }}
                >
                    <div style={{ marginTop: 5, fontSize: 20 }}>
                        {new Date(label).toLocaleDateString()}
                    </div>
                    {!!payload && payload.length > 10 && (
                        <div>
                            {netDataType === 'tunnels' ? 'tunnels' : 'devices'}{' '}
                            with the most usage
                        </div>
                    )}
                    {payload &&
                        payload
                            .sort(
                                (a, b) => (!!a && !!b && b.value - a.value) || 0
                            )
                            .slice(0, Math.ceil((height - 62) / 18))
                            .map((p, i) => {
                                const target =
                                    netDataType === 'tunnels'
                                        ? tunnels
                                        : devices;
                                const current = target.find(
                                    t =>
                                        t._id ===
                                        (p &&
                                            p.dataKey &&
                                            p.dataKey.split('_')[0])
                                );
                                return (
                                    <div
                                        key={i}
                                        style={{
                                            color: p.color,
                                            fontWeight: 'bold',
                                            margin: '2px 0'
                                        }}
                                    >{`${(current && current.name) ||
                                        ''} / ${(current &&
                                        current.deviceName) ||
                                        ''} : ${bytesToSize(
                                        p.value || 0
                                    )}`}</div>
                                );
                            })}
                </div>
            );
        }

        return null;
    };
    const CustomizedAxisTick = ({ x, y, stroke, payload }) => (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={-10}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="rotate(-70)"
            >
                {new Date(payload.value).toLocaleDateString(undefined, {
                    month: '2-digit',
                    day: '2-digit',
                    year: '2-digit'
                })}
            </text>
        </g>
    );

    return (
        <AreaChart
            width={width < 360 ? 360 : width}
            height={height}
            data={data}
            margin={{
                top: 10,
                right: 10,
                bottom: 10,
                left: 10
            }}
        >
            <Tooltip content={<CustomTooltip />} />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                height={70}
                tick={<CustomizedAxisTick />}
                interval={Math.floor(data.length / ((width - 80) / 25))}
                dataKey="unit"
                // tickCount={10}
            />
            <YAxis tickFormatter={bytesToSize} />
            <Tooltip />
            {areaData.map((current, i) => {
                const stroke =
                    (data && data[0] && data[0][`${current._id}_color`]) || '';
                const fill = lightenDarkenColor(stroke, 20);
                return (
                    <Area
                        animationDuration={animationDuration}
                        key={current._id}
                        type="monotone"
                        dataKey={`${current._id}_usage`}
                        stroke={stroke}
                        fill={fill}
                        // activeDot={{ r: 8 }}
                    />
                );
            })}
        </AreaChart>
    );
};
MainAreaChart.propTypes = {
    data: PropTypes.array.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    tunnels: PropTypes.array.isRequired,
    devices: PropTypes.array.isRequired,
    areaData: PropTypes.array.isRequired,
    netDataType: PropTypes.string.isRequired,
    animationDuration: PropTypes.number
};

export default MainAreaChart;
