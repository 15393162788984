// @flow
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Card from '@material-ui/core/Card';
import SignUpLink from '@material-ui/core/Link';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import { validate as validateEmail } from 'email-validator';
import { Hidden } from '@material-ui/core';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    content: {
        padding: '20px'
    },
    contentBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    actionsBar: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 20px',
        height: '80px',
        alignItems: 'inherit'
    },
    flex1: {
        flex: '1'
    },
    fontSizeH1: {
        fontSize: '2rem'
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    loginButton: {
        // margin: theme.spacing.unit
        // marginRight: '16px'
    },
    mainActions: {
        display: 'flex',
        flexDirection: 'row',
        height: '36px',
        margin: 0
    },
    passwordRecoveryLink: {
        padding: '0',
        fontSize: '11px',
        height: '30px',
        minHeight: '30px',
        // marginRight: '16px',
        marginTop: '4px'
    },
    passwordRecoveryLinkWrapper: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0
    }
});

const Login = ({
    classes,
    actions: { login },
    loginInProgress,
    insufficientlyAuthenticationDetails,
    mode
}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [twoFactorAuthStr, setTwoFactorAuthStr] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [twoFactorAuthStrError, setTwoFactorAuthStrError] = useState('');
    const [showTwoFactorAuthField, setShowTwoFactorAuthField] = useState(false);

    useEffect(
        () => {
            if (
                insufficientlyAuthenticationDetails.indexOf(
                    'twoFactorAuthStr'
                ) > -1
            ) {
                setShowTwoFactorAuthField(true);
            }
        },
        [insufficientlyAuthenticationDetails]
    );

    const handleEmailChange = event => {
        setEmail(event.target.value);
    };
    const handlePasswordChange = event => {
        setPassword(event.target.value);
    };
    const handleTwoFactorAuthStrChange = event => {
        setTwoFactorAuthStr(event.target.value);
    };
    const handleMouseDownPassword = event => {
        event.preventDefault();
    };
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const validateFormFields = (setToState = true) => {
        const errors = {
            emailError: '',
            passwordError: '',
            twoFactorAuthStrError: ''
        };
        let isValid = true;
        if (email.trim() === '') {
            isValid = false;
            errors.emailError = 'Please enter your email';
        } else if (!validateEmail(email)) {
            isValid = false;
            errors.emailError = 'Invalid Email';
        }
        if (password.trim() === '') {
            isValid = false;
            errors.passwordError = 'Please enter password';
        } else if (password.length < 8) {
            isValid = false;
            errors.passwordError = 'Passwords should be at least 8 chars';
        }
        if (showTwoFactorAuthField) {
            if (twoFactorAuthStr.trim() === '') {
                isValid = false;
                errors.twoFactorAuthStrError = 'Please enter digits';
            } else if (twoFactorAuthStr.trim().length !== 6) {
                isValid = false;
                errors.twoFactorAuthStrError =
                    'Two factor authentitaction should be 6 digits';
            }
        }
        if (setToState) {
            setEmailError(errors.emailError);
            setPasswordError(errors.passwordError);
            setTwoFactorAuthStrError(errors.twoFactorAuthStrError);
        }
        return isValid;
    };
    const handleLoginButtonClick = () => {
        if (validateFormFields()) {
            const data = {
                email,
                password,
                twoFactorAuthStr,
                redirect: true
            };
            if (mode) {
                data.redirect = false;
            }
            login(data);
        }
    };

    const onKeyDown = event => {
        switch (event.keyCode) {
            case 13: {
                handleLoginButtonClick();
                break;
            }
            default:
                break;
        }
    };

    const inputProps = {
        onKeyDown
    };

    return (
        <>
            <Card className={classes.root}>
                <CardContent className={classes.content}>
                    <Typography type="h1" color="inherit" variant="h1" classes={{
                        h1: classes.fontSizeH1
                    }}>
                        Sign in
                    </Typography>
                    <div className={classes.contentBody}>
                        {!showTwoFactorAuthField && (
                            <>
                                <FormControl
                                    className={classes.formControl}
                                    required
                                    error={!!emailError}
                                >
                                    <InputLabel htmlFor="login-email-field">
                                        Email
                                    </InputLabel>
                                    <Input
                                        id="login-email-field"
                                        autoComplete="email"
                                        value={email}
                                        autoFocus
                                        type="email"
                                        onChange={handleEmailChange}
                                        inputProps={inputProps}
                                    />
                                    {emailError && (
                                        <FormHelperText error>
                                            {emailError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    required
                                    className={classes.formControl}
                                    error={!!passwordError}
                                >
                                    <InputLabel htmlFor="login-password-field">
                                        Password
                                    </InputLabel>
                                    <Input
                                        id="login-password-field"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        autoComplete="current-password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        inputProps={inputProps}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                    tabIndex="-1"
                                                    onMouseDown={
                                                        handleMouseDownPassword
                                                    }
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {passwordError && (
                                        <FormHelperText error>
                                            {passwordError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </>
                        )}
                        {showTwoFactorAuthField && (
                            <FormControl
                                className={classes.formControl}
                                error={!!twoFactorAuthStrError}
                            >
                                <InputLabel htmlFor="login-two-factor-auth-field">
                                    Two Factor Auth
                                </InputLabel>
                                <Input
                                    autoFocus
                                    id="login-two-factor-auth-field"
                                    value={twoFactorAuthStr}
                                    type="text"
                                    onChange={handleTwoFactorAuthStrChange}
                                    inputProps={inputProps}
                                />
                                {twoFactorAuthStrError && (
                                    <FormHelperText error>
                                        {twoFactorAuthStrError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    </div>
                </CardContent>
                <CardActions className={classes.actionsBar}>
                    <div className={classes.mainActions}>
                        {mode !== 'forDeviceOwnership' &&
                            !showTwoFactorAuthField && (
                                <Button
                                    color="primary"
                                    disabled={loginInProgress}
                                    component={props => (
                                        <Link to="/signup" {...props} />
                                    )}
                                >
                                    Sign Up
                                </Button>
                            )}
                        {showTwoFactorAuthField && (
                            <Button
                                color="primary"
                                disabled={loginInProgress}
                                onClick={() => {
                                    setShowTwoFactorAuthField(false);
                                    setTwoFactorAuthStr('');
                                }}
                            >
                                Back
                            </Button>
                        )}
                        <div className={classes.flex1} />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={loginInProgress}
                            className={classes.loginButton}
                            onClick={handleLoginButtonClick}
                        >
                            Login
                        </Button>
                    </div>
                    {mode !== 'forDeviceOwnership' && !showTwoFactorAuthField && (
                        <div className={classes.passwordRecoveryLinkWrapper}>
                            <div className={classes.flex1} />
                            <Button
                                color="primary"
                                disabled={loginInProgress}
                                className={classes.passwordRecoveryLink}
                                component={props => (
                                    <Link to="/passwordrecovery" {...props} />
                                )}
                            >
                                Forgot Password?
                            </Button>
                        </div>
                    )}
                </CardActions>
            </Card>
            <Hidden mdUp>
                <div
                    style={{
                        position: 'absolute',
                        right: 15,
                        bottom: 10,
                        zIndex: 1500
                    }}
                >
                    <SignUpLink href="https://tunnelin.com/">
                        Tunnelin
                    </SignUpLink>{' '}
                    © 2020 All Right Reserved
                </div>
            </Hidden>
            <Hidden smDown>
                <div
                    style={{
                        position: 'absolute',
                        right: 25,
                        bottom: 10,
                        zIndex: 1500
                    }}
                >
                    <SignUpLink href="https://tunnelin.com/">
                        Tunnelin
                    </SignUpLink>{' '}
                    © 2020 All Right Reserved
                </div>
            </Hidden>
        </>
    );
};

Login.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        formControl: PropTypes.string.isRequired
    }).isRequired,
    actions: PropTypes.shape({
        login: PropTypes.func.isRequired
    }).isRequired,
    loginInProgress: PropTypes.bool.isRequired,
    insufficientlyAuthenticationDetails: PropTypes.array.isRequired,
    mode: PropTypes.string
};

export default withStyles(styles)(Login);
