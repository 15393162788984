// @flow
import React from 'react';
import Tour from 'reactour';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { sortBy } from 'lodash';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Toolbar from '@material-ui/core/Toolbar';
import NextIcon from '@material-ui/icons/NavigateNext';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Device from '../../containers/Devices/Card/Device';
import AddDeviceGroupHOC from '../../containers/Devices/Groups/Add';
import DeleteDeviceGroupHOC from '../../containers/Devices/Groups/Delete';
import UpdateDeviceGroupHOC from '../../containers/Devices/Groups/Update';
import ChangeDefaultGroupHOC from '../../containers/Devices/Groups/Default';
import TabContent from './Groups/TabContent';
import withDeviceDrop from './Groups/withDeviceDrop';

import OrderDevicesHOC from '../../containers/Devices/Order/index';
import OrderGroupsHOC from '../../containers/Devices/Groups/Order/index';
import { getLoggedInUserIdHash } from '../../utils/user-utils';
import TunnelParamsModal from './Modal/TunnelParamsModal';

const DroppableTabContent = withDeviceDrop(TabContent);

const styles = theme => ({
    root: {
        display: 'flex',
        // display: 'grid',
        flexDirection: 'column',
        maxHeight: `calc(100vh - 64px)`,
        height: `calc(100vh - 64px)`,
        width: '100vw'
    },
    deviceWrapper: {
        minWidth: 340
    },
    listWrapper: {
        // flex: 1,
        maxHeight: 'calc(100vh - 128px)',
        display: 'grid',
        gridGap: '20px',
        gridTemplateColumns: `repeat(auto-fill, minmax(340px,1fr))`,
        boxSizing: 'border-box',
        padding: '20px 17px',
        overflowY: 'auto',
        overflowScrolling: 'touch',
        WebkitOverflowScrolling: 'touch'
    },
    flexGrow: {
        flex: '1 1 auto'
    },
    groupsFormControl: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: 120,
        width: 120
    },
    addGroupBtn: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        cursor: 'pointer'
    },
    tabs: {
        flex: 1
    },
    toolbar: {
        width: '100vw',
        boxSizing: 'border-box'
    },
    dropDownMenuItem: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 264
    },
    mask: {
        color: 'rgba(211,211,211, .4)'
    },

    tourStart: {
        height: 0,
        width: 0,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

class DeviceList extends React.Component<
    {
        classes: {
            root: string,
            deviceWrapper: string,
            toolbar: string,
            flexGrow: string,
            listWrapper: string,
            tabs: string,
            groupsFormControl: string,
            addGroupBtn: string,
            dropDownMenuItem: string,
            mask: string,
            tourStart: string
        },
        actions: {
            updateDevice: ({ _id: string, group: string }) => void,
            getDevices: () => void,
            setDevicesTourStep: (step: number | null) => void,
            setDevicesTourVisibility: (value: boolean) => void
        },
        statusesLoading: boolean,
        loggedInUser: any,
        devices: any[],
        groups: any[],
        devicesUpdating: string[],
        devicesTourDetails: any
    },
    {
        showOrderDevicesModal: boolean,
        showAddGroupModal: boolean,
        showOrderGroupsModal: boolean,
        showUpdateGroupModal: boolean,
        showDeleteGroupModal: boolean,
        showChangeDefaultGroupModal: boolean,
        selectedGroupValue: number,
        anchorEl: any,
        openModal: string,
        deviceForHTTPTunnels: any
    }
> {
    static propTypes = {
        devicesTourDetails: PropTypes.object.isRequired,
        loggedInUser: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        devices: PropTypes.array.isRequired,
        groups: PropTypes.array.isRequired,
        devicesUpdating: PropTypes.array.isRequired,
        statusesLoading: PropTypes.bool.isRequired
    };

    userIdHash = getLoggedInUserIdHash();

    constructor(props) {
        super(props);
        this.state = {
            showOrderDevicesModal: false,
            showAddGroupModal: false,
            showOrderGroupsModal: false,
            showUpdateGroupModal: false,
            showDeleteGroupModal: false,
            showChangeDefaultGroupModal: false,
            selectedGroupValue: this.getSelectedGroupValue() || 0,
            anchorEl: null,
            openModal: '',
            deviceForHTTPTunnels: null
        };
    }

    componentDidUpdate(prevProps) {
        const {
            groups: oldGroups,
            loggedInUser: oldLoggedInUser,
            devices,
            actions: { getDevices }
        } = prevProps;

        const { groups, loggedInUser } = this.props;

        const selectedGroupValue = this.getSelectedGroupValue();

        if (oldLoggedInUser.defaultGroup !== loggedInUser.defaultGroup) {
            this.setState({ showChangeDefaultGroupModal: false });
        }

        if (oldGroups.length > groups.length) {
            const diff = [];

            oldGroups.forEach(oldGroup => {
                if (groups.indexOf(oldGroup) < 0) {
                    diff.push(oldGroup);
                }
            });

            groups.forEach(group => {
                if (oldGroups.indexOf(group)) {
                    diff.push(group);
                }
            });

            const removedGroup = diff[0];
            // eslint-disable-next-line
            for (const device of devices) {
                if ((device.group && device.group._id) === removedGroup._id) {
                    getDevices();
                    break;
                }
            }

            this.setState({ selectedGroupValue, showDeleteGroupModal: false });
        } else if (oldGroups.length < groups.length) {
            this.setState({ showAddGroupModal: false });
        } else {
            for (let i = 0; i < groups.length; i += 1) {
                if (groups[i] && oldGroups[i] && groups[i] !== oldGroups[i]) {
                    this.setState({
                        selectedGroupValue,
                        showUpdateGroupModal: false
                    });
                    break;
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.emitInterval) {
            clearInterval(this.emitInterval);
        }
    }

    getSelectedGroupValue = () => {
        const { groups, loggedInUser, devices } = this.props;
        const { defaultGroup } = loggedInUser;
        let selectedGroupValue = 0;
        // const storageActiveGroupId = localStorage.getItem('activeGroupId');
        const storageActiveGroupId = localStorage.getItem(
            `activeGroupId-${this.userIdHash}`
        );
        if (storageActiveGroupId) {
            const groupForSwitch = groups.find(
                group => group._id === storageActiveGroupId
            );
            if (groupForSwitch) {
                return groups.indexOf(groupForSwitch);
            }
        }

        if (defaultGroup) {
            const groupForSwitch = groups.find(
                group => group._id === defaultGroup
            );
            const defaultGroupIndex = groups.indexOf(groupForSwitch);
            if (devices.find(d => d.group === defaultGroup)) {
                selectedGroupValue = defaultGroupIndex;
                localStorage.setItem(
                    `activeGroupId-${this.userIdHash}`,
                    defaultGroup
                );
                return selectedGroupValue;
            }
        }

        for (let i = 0; i < groups.length; i += 1) {
            if (
                devices.find(
                    d =>
                        d.group === groups[i]._id ||
                        (d.group && d.group._id) === groups[i]._id
                )
            ) {
                selectedGroupValue = i;
                localStorage.setItem(
                    `activeGroupId-${this.userIdHash}`,
                    groups[i]._id
                );
                return selectedGroupValue;
            }
        }
        if (groups.length > 0)
            localStorage.setItem(
                `activeGroupId-${this.userIdHash}`,
                groups[0]._id
            );
        return selectedGroupValue;
    };

    onDeviceGroupAdd = () => {};

    // eslint-disable-next-line
    onDeviceDropToGroup = (device, group) => {
        const {
            actions: { updateDevice }
        } = this.props;
        const { _id } = device;
        updateDevice({ _id, group: group._id });
    };

    handleShowOrderDevicesModal = () => {
        this.setState({
            showOrderDevicesModal: true
        });
        this.handleMenuClose();
    };

    handleShowAddGroupModal = () => {
        this.setState({ openModal: 'showAddGroupModal' });
        this.handleMenuClose();
    };

    handleShowOrderGroupsModal = () => {
        this.setState({
            showOrderGroupsModal: true
        });
        this.handleMenuClose();
    };

    handleEditGroup = () => {
        this.setState({ showUpdateGroupModal: true });
        this.handleMenuClose();
    };

    handleDeleteGroup = () => {
        const { selectedGroupValue } = this.state;
        const { groups } = this.props;
        // eslint-disable-next-line
        const selectedGroup = groups[selectedGroupValue];
        // TODo...
        this.setState({ showDeleteGroupModal: true });
        this.handleMenuClose();
    };

    handleChangeDefaultGroup = () => {
        this.setState({ showChangeDefaultGroupModal: true });
        this.handleMenuClose();
    };

    openModal = () => {
        const { openModal } = this.state;
        if (openModal) {
            this.setState({ [openModal]: true });
        }
    };

    handleCloseOrderDevicesModal = () => {
        this.setState({ showOrderDevicesModal: false, openModal: '' });
    };

    handleCloseAddGroupModal = () => {
        this.setState({ showAddGroupModal: false, openModal: '' });
    };

    handleCloseOrderGroupsModal = () => {
        this.setState({ showOrderGroupsModal: false, openModal: '' });
    };

    handleCloseUpdateDeviceGroupModal = () => {
        this.setState({ showUpdateGroupModal: false, openModal: '' });
    };

    handleCloseDeleteDeviceGroupModal = () => {
        this.setState({ showDeleteGroupModal: false, openModal: '' });
    };

    handleCloseChangeDefaultGroupModal = () => {
        this.setState({ showChangeDefaultGroupModal: false, openModal: '' });
    };

    handleCloseHTTPTunnelsModal = () => {
        this.setState({ deviceForHTTPTunnels: null });
    };

    emitInterval = null;

    handleGroupTabChange = (event, value) => {
        const { groups } = this.props;
        this.setState({
            selectedGroupValue: value
        });
        localStorage.setItem(
            `activeGroupId-${this.userIdHash}`,
            groups[value]._id
        );
    };

    handleMenuClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    renderDevice = device => {
        const { classes, devicesUpdating, statusesLoading } = this.props;
        const { _id } = device;
        const isUpdating = devicesUpdating.indexOf(_id) !== -1;
        return (
            <div className={classes.deviceWrapper} key={_id}>
                <Device
                    statusesLoading={statusesLoading}
                    device={device}
                    isUpdating={isUpdating}
                    onDeviceDropToGroup={this.onDeviceDropToGroup}
                />
            </div>
        );
    };

    renderGroup = group => {
        const { name, id } = group;
        return (
            <MenuItem value={id} key={id}>
                {name}
            </MenuItem>
        );
    };

    determineAvailability = d => {
        const newDate = Date.now();
        const { socketData: { lastSeen } = {} } = d;
        if (!lastSeen) {
            return 'offlineCount';
        }
        const diffMs = newDate - lastSeen;
        const diffMins = Math.round(diffMs / (1000 * 60));
        if (diffMins > 10) {
            return 'offlineCount';
        }
        if (diffMins > 3) {
            return 'awayCount';
        }
        return 'onlineCount';
    };

    renderGroupTab = group => {
        const { _id } = group;
        const { devices } = this.props;
        const statusesCountInGroup = {
            onlineCount: 0,
            awayCount: 0,
            offlineCount: 0
        };

        let currentGroupDevices = devices;

        if (_id) {
            currentGroupDevices = devices.filter(
                device =>
                    (device.group && device.group._id) === _id ||
                    device.group === _id
            );
            currentGroupDevices = sortBy(currentGroupDevices, 'order');
        }
        if (_id === '-1' && currentGroupDevices.length === 0) {
            return null;
        }
        currentGroupDevices.forEach(d => {
            const st = this.determineAvailability(d);
            statusesCountInGroup[st] += 1;
        });

        return (
            <Tab
                label={
                    <DroppableTabContent
                        group={group}
                        statusesCountInGroup={statusesCountInGroup}
                    />
                }
                key={_id}
            />
        );
    };

    closeTour = () => {
        const {
            actions: { setDevicesTourVisibility, setDevicesTourStep },
            devicesTourDetails: { step }
        } = this.props;
        if (step === 4) {
            // this.setState({
            //     deviceForHTTPTunnels: null
            // });
            setDevicesTourStep(step - 1);
        }
        setDevicesTourVisibility(false);
    };

    getTourCurrentStep = currentStep => {
        const {
            actions: { setDevicesTourStep, setDevicesTourVisibility },
            devicesTourDetails: { step, visibility },
            devices
        } = this.props;
        const { deviceForHTTPTunnels } = this.state;
        if (currentStep === 4) {
            if (!deviceForHTTPTunnels && step !== currentStep && visibility) {
                const device = devices.find(d =>
                    d.name.toLowerCase().includes('raspberry')
                );
                if (device) {
                    setDevicesTourVisibility(false);
                    this.setState({
                        deviceForHTTPTunnels: device
                    });
                } else if (step > currentStep) {
                    setDevicesTourStep(currentStep - 1);
                } else if (step < currentStep) {
                    setDevicesTourStep(currentStep + 1);
                }
            }
        } else if (step !== currentStep) {
            this.setState({
                deviceForHTTPTunnels: null
            });
            setDevicesTourStep(currentStep);
        }
    };

    tourConfig = [
        {
            selector: '[data-tut="reactour__devices__main__step"]',
            content: () => (
                <div style={{ flex: 1, flexDirection: 'column' }}>
                    <b> Device dashboard</b> - here you can monitor and manage
                    your devices, create groups and assign devices to the groups
                </div>
            ),
            stepInteraction: false,
            position: [350, 20], // position: 'top',
            style: {
                backgroundColor: '#9c27b0',
                color: '#FFFFFF'
            }
        },
        {
            selector: '[data-tut="reactour__devices__rdp__step"]',
            content: () => (
                <div>
                    <p>
                        <b>For Windows PCs, the RDP option will be available</b>{' '}
                        - by clicking on the RDP button you can access your
                        remote device directly in the browser or if you want to
                        use the RDP client you are comfortable with you can get
                        required configuration by clicking on the small arrow.
                    </p>
                    <p>
                        {'  '}
                        Note: For RDP access you need to configure your device.
                        {'  '}
                        <a
                            style={{ color: '#fff' }}
                            target="_blanc"
                            href="https://docs.microsoft.com/en-us/windows-server/remote/remote-desktop-services/clients/remote-desktop-allow-access#how-to-enable-remote-desktop"
                        >
                            See how to configure it here
                        </a>
                        .
                    </p>
                </div>
            ),
            style: {
                backgroundColor: '#9c27b0',
                color: '#FFFFFF'
            }
        },
        {
            selector: '[data-tut="reactour__devices__sshandvnc__step"]',

            content: () => (
                <div>
                    <p>
                        <b>
                            For Mac and Linux, SSH and VNC options will be
                            available
                        </b>{' '}
                        - by clicking on these buttons you can access your
                        remote device directly in the browser or if you want to
                        use the client you are comfortable with you can get
                        required configuration by clicking on the small arrow.
                    </p>
                    <p>
                        {'  '}
                        Note: You should enable and configure appropriate
                        services for your devices.
                        {'  '}
                    </p>
                    <p>
                        Here is how to configure VNC service for{' '}
                        <a
                            style={{ color: '#fff' }}
                            target="_blanc"
                            href="https://support.apple.com/guide/mac-help/turn-screen-sharing-on-or-off-mh11848/mac"
                        >
                            Mac OS
                        </a>{' '}
                        and{' '}
                        <a
                            style={{ color: '#fff' }}
                            target="_blanc"
                            href="https://www.digitalocean.com/community/tutorials/how-to-install-and-configure-vnc-on-ubuntu-18-04"
                        >
                            Linux Ubuntu
                        </a>
                        .
                    </p>

                    <p />
                </div>
            ),
            style: {
                backgroundColor: '#9c27b0',
                color: '#FFFFFF'
            }
        },
        {
            selector: '[data-tut="reactour__devices__https__step"]',
            content: () => (
                <>
                    By clicking the <b>HTTPS</b> button you can securely expose
                    your local webservers to the whole globe. This option also
                    allows you to protect your sites with Tunnel In password
                    page.
                </>
            ),
            style: {
                backgroundColor: '#9c27b0',
                color: '#FFFFFF'
            }
        },
        {
            selector:
                '[data-tut="reactour__devices__https__tunnels__modal__step"]',
            content: `Here are URL of HTTPS tunnels you have already created.`,
            style: {
                backgroundColor: '#9c27b0',
                color: '#FFFFFF'
            }
        },
        {
            selector: '[data-tut="reactour__devices__add__device__step"]',
            content: () => (
                <>
                    To add more devices:
                    <ol>
                        <li> Click on the '+' button</li>
                        <li> Download appropriate version of the agent</li>
                        <li>Install it on your device</li>
                    </ol>
                </>
            ),
            style: {
                backgroundColor: '#9c27b0',
                color: '#FFFFFF'
            }
        },
        {
            selector: '[data-tut="reactour__devices__goto__tunnels__step"]',
            style: {
                backgroundColor: '#9c27b0',
                color: '#FFFFFF'
            },
            content: () => (
                <div>
                    Choose{' '}
                    <Button
                        color="primary"
                        component={props => <Link to="/tunnels" {...props} />}
                        style={{ color: '#fff' }}
                    >
                        TUNNELS
                    </Button>
                    tab to go to the page where you can create and manage your
                    tunnels. For each tunnel, you can configure the firewall to
                    achieve your desired security level i.e limit access to
                    tunnels by time, IP address, or connections count.
                </div>
            )
        }
    ];

    render() {
        const {
            showOrderDevicesModal,
            selectedGroupValue,
            showAddGroupModal,
            showOrderGroupsModal,
            showUpdateGroupModal,
            showDeleteGroupModal,
            showChangeDefaultGroupModal,
            anchorEl,
            deviceForHTTPTunnels
        } = this.state;
        const {
            classes,
            devices,
            groups,
            loggedInUser,
            devicesTourDetails
        } = this.props;

        const selectedGroup = groups[selectedGroupValue] || {};
        const { name, _id } = selectedGroup;

        const { defaultGroup } = loggedInUser;

        const selectedGroupIsDefault = defaultGroup === _id;

        let filteredDevices = devices;

        if (_id) {
            filteredDevices = devices.filter(
                device =>
                    (device.group && device.group._id) === _id ||
                    device.group === _id
            );
            filteredDevices = sortBy(filteredDevices, 'order');
        }
        return (
            <div
                className={classNames(classes.root, {
                    devicesMain: true
                })}
            >
                <Tour
                    onRequestClose={this.closeTour}
                    maskClassName={classes.mask}
                    steps={this.tourConfig}
                    isOpen={devicesTourDetails.visibility}
                    getCurrentStep={this.getTourCurrentStep}
                    startAt={devicesTourDetails.step}
                    showNumber={false}
                    showButtons={true}
                    showCloseButton={false}
                    showNavigationNumber={false}
                    disableInteraction={false}
                    maskSpace={10}
                    rounded={15}
                    accentColor="gray"
                    showNavigation={false}
                    lastStepNextButton={
                        <Button
                            style={{
                                border: '1px solid',
                                borderColor: '#fff'
                            }}
                            variant="contained"
                            disabled={true}
                            color="primary"
                        >
                            Next
                        </Button>
                    }
                    nextButton={
                        <div style={{ width: 145 }}>
                            <Button
                                style={{
                                    width: 100,
                                    border: '1px solid',
                                    borderColor: '#fff'
                                }}
                                endIcon={<NextIcon />}
                                variant="contained"
                                color="primary"
                            >
                                Next
                            </Button>
                        </div>
                    }
                    prevButton={
                        <Button
                            style={{ border: '1px solid', borderColor: '#fff' }}
                            variant="contained"
                            color="primary"
                        >
                            Previous
                        </Button>
                    }
                />
                <Toolbar className={classes.toolbar}>
                    <Tabs
                        className={classes.tabs}
                        value={selectedGroupValue}
                        onChange={this.handleGroupTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {groups.map(group => this.renderGroupTab(group))}
                    </Tabs>
                    <IconButton
                        aria-label="More"
                        aria-owns={anchorEl ? 'device-list-options-menu' : null}
                        aria-haspopup="true"
                        onClick={this.handleMenuClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="device-list-options-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleMenuClose}
                        onExited={this.openModal}
                    >
                        {/* <MenuItem onClick={this.handleShowAddDeviceModal}> */}
                        {/* Add Device */}
                        {/* </MenuItem> */}
                        <MenuItem
                            onClick={this.handleShowOrderDevicesModal}
                            disabled={filteredDevices.length < 2}
                        >
                            <div className={classes.dropDownMenuItem}>
                                Order Devices
                            </div>
                        </MenuItem>
                        <MenuItem onClick={this.handleShowAddGroupModal}>
                            <div className={classes.dropDownMenuItem}>
                                Add Group
                            </div>
                        </MenuItem>
                        <MenuItem onClick={this.handleShowOrderGroupsModal}>
                            <div className={classes.dropDownMenuItem}>
                                Order Groups
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={this.handleDeleteGroup}
                            disabled={selectedGroupIsDefault}
                        >
                            <div className={classes.dropDownMenuItem}>
                                Delete {name}
                            </div>
                        </MenuItem>
                        <MenuItem onClick={this.handleEditGroup}>
                            <div className={classes.dropDownMenuItem}>
                                Edit {name}
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={this.handleChangeDefaultGroup}
                            disabled={selectedGroupIsDefault}
                        >
                            <div className={classes.dropDownMenuItem}>
                                Make {name} as Default
                            </div>
                        </MenuItem>
                    </Menu>
                </Toolbar>
                <div
                //    data-tut="reactour__devices__main__step"
                //   className={classes.tourStart}
                />
                <div
                    className={classes.listWrapper}
                    data-tut="reactour__devices__main__step"
                >
                    {filteredDevices.map(device => this.renderDevice(device))}
                </div>
                {showOrderDevicesModal && (
                    <OrderDevicesHOC
                        devices={filteredDevices}
                        close={this.handleCloseOrderDevicesModal}
                    />
                )}
                {showAddGroupModal && (
                    <AddDeviceGroupHOC
                        close={this.handleCloseAddGroupModal}
                        add={this.onDeviceGroupAdd}
                        groupsLength={groups.length}
                    />
                )}
                {showOrderGroupsModal && (
                    <OrderGroupsHOC
                        groups={groups}
                        close={this.handleCloseOrderGroupsModal}
                    />
                )}
                {showUpdateGroupModal && (
                    <UpdateDeviceGroupHOC
                        close={this.handleCloseUpdateDeviceGroupModal}
                        group={selectedGroup}
                    />
                )}
                {showDeleteGroupModal && (
                    <DeleteDeviceGroupHOC
                        close={this.handleCloseDeleteDeviceGroupModal}
                        group={selectedGroup}
                    />
                )}
                {showChangeDefaultGroupModal && (
                    <ChangeDefaultGroupHOC
                        close={this.handleCloseChangeDefaultGroupModal}
                        group={selectedGroup}
                    />
                )}
                {deviceForHTTPTunnels && (
                    <TunnelParamsModal
                        close={this.handleCloseHTTPTunnelsModal}
                        deviceId={deviceForHTTPTunnels._id}
                        deviceName={deviceForHTTPTunnels.name}
                    />
                )}
            </div>
        );
    }
}

export default withStyles(styles)(DeviceList);
