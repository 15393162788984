// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PasswordOptions from '../../../components/Profile/PasswordOptions';

import { wrapActionCreators } from '../../../utils/container-util';

import { changeCurrentUserPassword as changePassword } from '../../../actions/authentication-actions';

import { selectChangeCurrentUserPasswordLoading } from '../../../selectors/app-selectors';

const PasswordOptionsHOC = props => <PasswordOptions {...props} />;

PasswordOptionsHOC.propTypes = {
    actions: PropTypes.shape({
        changePassword: PropTypes.func.isRequired
    }).isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    expanded: PropTypes.bool,
    onExpansionPanelChange: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    changePasswordLoading: PropTypes.bool.isRequired
};

const mapStateToProps = createStructuredSelector({
    changePasswordLoading: selectChangeCurrentUserPasswordLoading()
});

const mapDispatchToProps = wrapActionCreators({
    changePassword
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordOptionsHOC);
