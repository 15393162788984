import React, { useState } from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

const styles = theme => ({
    linuxCommand: {
        fontWeight: 'bold',
        cursor: 'pointer',
        color: theme.palette.primary['500']
    }
});
const ClipboardCopy = ({ text, value, classes: { linuxCommand } }) => {
    const [copied, setCopied] = useState(false);
    const v = value || text;
    return (
        <Tooltip
            title={copied ? 'Copied' : 'Copy to clipboard'}
            placement="right"
        >
            <CopyToClipboard
                text={v}
                onCopy={() => setCopied(true)}
                className={linuxCommand}
            >
                <span style={{ wordBreak: 'break-all' }}>{text}</span>
            </CopyToClipboard>
        </Tooltip>
    );
};
ClipboardCopy.prototype = {
    text: PropTypes.string.isRequired,
    value: PropTypes.string,
    mobileText: PropTypes.string,
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ClipboardCopy);
