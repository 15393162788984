// @flow
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = () => ({});

class About extends React.Component<{
    classes: any,
    fullScreen: boolean,
    close: () => void
}> {
    static propTypes = {
        fullScreen: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired
    };

    onClose = () => {
        const { close } = this.props;
        close();
    };

    render() {
        const { fullScreen, close, classes } = this.props;
        return (
            <Dialog
                fullScreen={fullScreen}
                open
                classes={{
                    paper: classes.dialogContent
                }}
                onClose={close}
                aria-labelledby="about-dialog-title"
            >
                <DialogTitle id="about-dialog-title">About</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        App Version: {process.env.REACT_APP_VERSION}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(About));
