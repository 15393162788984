import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import PasswordField from '../../../components/PasswordField';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh'
    },
    card: {
        width: 550,
        maxWidth: 600
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    formControlCheckbox: {
        display: 'block',
        flexDirection: 'column',
        alignItems: 'stretch'
    },
    fontSizeH1: {
        fontSize: '2rem'
    },
    formControlCheckboxHelperText: {
        marginTop: 0,
        marginBottom: 0
    },
    settingsTabs: {
        margin: '25px 0 15px 0'
    },
    contentBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        height: '270px',
        // paddingTop: '20px',
        overflowY: 'auto',
        marginLeft: -14,
        paddingLeft: 14,
        marginRight: -14,
        paddingRight: 14,
        maxWidth: 534
    },
    subHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '10px',
        alignItems: 'center'
    }
});

const performanceFlagsCmps = [
    {
        name: 'enableWallpaper',
        label: 'Enable Wallpaper',
        description:
            'If checked, enables rendering of the desktop wallpaper. By default, wallpaper will be disabled, such that unnecessary bandwidth need not be spent redrawing the desktop.'
    },
    {
        name: 'enableTheming',
        label: 'Enable Theming',
        description:
            'If checked, enables use of theming of windows and controls. By default, theming within RDP sessions is disabled.'
    },
    {
        name: 'enableFontSmoothing',
        label: 'Enable Font Smoothing',
        description:
            'If checked, text will be rendered with smooth edges. Text over RDP is rendered with rough edges by default, as this reduces the number of colors used by text, and thus reduces the bandwidth required for the connection.'
    },
    {
        name: 'enableFullWindowDrag',
        label: 'Enable full window drag',
        description:
            'If checked, the contents of windows will be displayed as windows are moved. By default, the RDP server will only draw the window border while windows are being dragged.'
    },
    {
        name: 'enableDesktopComposition',
        label: 'Enable desktop composition',
        description:
            'If checked, graphical effects such as transparent windows and shadows will be allowed. By default, such effects, if available, are disabled.'
    },
    {
        name: 'enableMenuAnimations',
        label: 'Enable menu animations',
        description:
            'If checked, menu open and close animations will be allowed. Menu animations are disabled by default.'
    }
];

class RDPSettings extends React.Component<
    {
        classes: {
            card: string,
            formControl: string,
            root: string,
            contentBody: string,
            settingsTabs: string,
            formControlCheckbox: string,
            formControlCheckboxHelperText: string,
            subHeader: string
        },
        closable: boolean,
        hasConfig: boolean,
        onDataReady: (data: any) => void,
        onCloseClick: () => void
    },
    {
        authentication: {
            username: string,
            password: string,
            privateKey: string,
            passphrase: string
        },
        settingsActiveTab: 'authentication' | 'performanceFlags',
        display: {
            colorScheme: string,
            fontName: string,
            fontSize: string
        },
        performanceFlags: {
            enableWallpaper: boolean,
            enableTheming: boolean,
            enableFontSmoothing: boolean,
            enableFullWindowDrag: boolean,
            enableDesktopComposition: boolean,
            enableMenuAnimations: boolean
        },
        saveConfig: boolean
    }
> {
    static propTypes = {
        classes: PropTypes.shape({
            card: PropTypes.string.isRequired,
            formControl: PropTypes.string.isRequired,
            root: PropTypes.string.isRequired,
            contentBody: PropTypes.string.isRequired,
            formControlCheckbox: PropTypes.string.isRequired,
            formControlCheckboxHelperText: PropTypes.string.isRequired,
            settingsTabs: PropTypes.string.isRequired,
            subHeader: PropTypes.string.isRequired
        }).isRequired,
        onDataReady: PropTypes.func.isRequired,
        onCloseClick: PropTypes.func,
        closable: PropTypes.bool,
        hasConfig: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            authentication: {
                username: '',
                password: ''
            },
            settingsActiveTab: 'authentication',
            performanceFlags: {
                enableWallpaper: true,
                enableTheming: true,
                enableFontSmoothing: true,
                enableFullWindowDrag: true,
                enableDesktopComposition: true,
                enableMenuAnimations: true
            },
            saveConfig: !!props.hasConfig
        };
    }

    onKeyDown = event => {
        switch (event.keyCode) {
            case 13: {
                this.handleOKClick();
                break;
            }
            default:
                break;
        }
    };

    handleSettingsActiveTabChange = (event, value) => {
        this.setState({
            settingsActiveTab:
                value === 0 ? 'authentication' : 'performanceFlags'
        });
    };

    handleUsernameChange = (event: any) => {
        const { authentication } = this.state;
        this.setState({
            authentication: {
                ...authentication,
                username: event.target.value
            }
        });
    };

    handlePasswordChange = (value: string) => {
        const { authentication } = this.state;
        this.setState({
            authentication: {
                ...authentication,
                password: value
            }
        });
    };

    handleSaveConfigChange = event => {
        this.setState({ saveConfig: event.target.checked });
    };

    handleOKClick = () => {
        const {
            authentication: { password, username },
            performanceFlags: {
                enableTheming,
                enableMenuAnimations,
                enableFullWindowDrag,
                enableFontSmoothing,
                enableDesktopComposition,
                enableWallpaper
            },
            saveConfig
        } = this.state;
        const { onDataReady } = this.props;
        let args = {
            saveConfig,
            'enable-wallpaper': enableWallpaper,
            'enable-theming': enableTheming,
            'enable-font-smoothing': enableFontSmoothing,
            'enable-full-window-drag': enableFullWindowDrag,
            'enable-desktop-composition': enableDesktopComposition,
            'enable-menu-animations': enableMenuAnimations
        };
        if (username.trim() !== '') {
            args = {
                ...args,
                username
            };
        }
        if (password.trim() !== '') {
            args = {
                ...args,
                password
            };
        }

        onDataReady(args);
    };

    handleCloseClick = () => {
        const { onCloseClick } = this.props;
        if (onCloseClick) {
            onCloseClick();
        }
    };

    handlePerformanceFlagChange = name => event => {
        const { performanceFlags } = this.state;
        this.setState({
            performanceFlags: {
                ...performanceFlags,
                [name]: event.target.checked
            }
        });
    };

    renderPerformanceFlagCmp = (performanceFlagCmp, index) => {
        const {
            classes: {
                formControl,
                formControlCheckbox,
                formControlCheckboxHelperText
            }
        } = this.props;

        const { performanceFlags } = this.state;
        return (
            <FormControl
                key={index}
                className={`${formControl} ${formControlCheckbox}`}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={performanceFlags[performanceFlagCmp.name]}
                            onChange={this.handlePerformanceFlagChange(
                                performanceFlagCmp.name
                            )}
                            value={performanceFlagCmp.name}
                        />
                    }
                    label={performanceFlagCmp.label}
                />
                <FormHelperText className={formControlCheckboxHelperText}>
                    {performanceFlagCmp.description}
                </FormHelperText>
            </FormControl>
        );
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    colorSchemeInputProps = {
        id: 'rdp-terminal-settings-color-scheme'
    };

    fontSizeInputProps = {
        onKeyDown: this.onKeyDown,
        min: 1
    };

    render() {
        const {
            classes: { card, root, settingsTabs, contentBody, formControl, fontSizeH1 },
            closable
        } = this.props;

        const {
            authentication: { username, password },
            settingsActiveTab,
            saveConfig
        } = this.state;

        return (
            <form className={root} autoComplete="off">
                <Card className={card}>
                    <CardContent>
                        <Typography type="h1" color="inherit" variant="h1" classes={{
                            h1: fontSizeH1
                        }}>
                            RDP settings
                        </Typography>
                        <Tabs
                            value={
                                settingsActiveTab === 'authentication' ? 0 : 1
                            }
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            onChange={this.handleSettingsActiveTabChange}
                            className={settingsTabs}
                        >
                            <Tab label="Authentication" />
                            {/* <Tab label="Display Settings" /> */}
                            <Tab label="Performance Flags" />
                        </Tabs>
                        {settingsActiveTab === 'authentication' && (
                            <div className={contentBody}>
                                <FormControl className={formControl}>
                                    <InputLabel htmlFor="username">
                                        Username
                                    </InputLabel>
                                    <Input
                                        id="rdp-terminal-settings-username"
                                        autoComplete="off"
                                        value={username}
                                        onChange={this.handleUsernameChange}
                                        inputProps={this.inputProps}
                                    />
                                </FormControl>
                                <PasswordField
                                    isPasswordRequired={false}
                                    label="Password"
                                    type="rdp-terminal-settings-password"
                                    autoComplete="off"
                                    password={password}
                                    onChange={this.handlePasswordChange}
                                    onEnter={this.handleOKClick}
                                    className={formControl}
                                />
                            </div>
                        )}
                        {settingsActiveTab === 'performanceFlags' && (
                            <div className={contentBody}>
                                {performanceFlagsCmps.map(
                                    (performanceFlagCmp, index) =>
                                        this.renderPerformanceFlagCmp(
                                            performanceFlagCmp,
                                            index
                                        )
                                )}
                            </div>
                        )}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={saveConfig}
                                    onChange={this.handleSaveConfigChange}
                                    value="saveConfig"
                                />
                            }
                            label="Save Configuration (all configs will be encrypted)"
                        />
                    </CardContent>
                    <CardActions>
                        <Button
                            size="small"
                            color="primary"
                            onClick={this.handleOKClick}
                        >
                            OK
                        </Button>
                        {closable && (
                            <Button
                                size="small"
                                color="primary"
                                onClick={this.handleCloseClick}
                            >
                                Close
                            </Button>
                        )}
                    </CardActions>
                </Card>
            </form>
        );
    }
}

export default withStyles(styles)(RDPSettings);
