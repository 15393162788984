// @flow
import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import TextField from '@material-ui/core/es/TextField/TextField';
import DialogActions from '@material-ui/core/es/DialogActions/DialogActions';
import Button from '@material-ui/core/es/Button/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: 5,
        marginRight: 5
    },
    dialogActions: {
        padding: '13px 20px',
        margin: 0
    },
    menu: {
        width: 200
    }
};
const AddOrganization = ({ classes, close, onSave }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const handleNameChange = event => {
        setName(event.target.value);
    };
    const handleDescriptionChange = event => {
        setDescription(event.target.value);
    };
    const handleSave = () => {
        close();
        onSave(name, description);
    };
    return (
        <Dialog
            fullScreen={false}
            open
            onClose={close}
            classes={{
                paper: classes.paper
            }}
            aria-labelledby="profile-dialog-title"
        >
            <DialogTitle id="profile-dialog-title">
                Add Organization
            </DialogTitle>
            <DialogContent className={classes.paper}>
                <div className={classes.contentWrap}>
                    <form
                        className={classes.container}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            label="Name"
                            className={classes.textField}
                            value={name}
                            onChange={handleNameChange}
                            margin="normal"
                        />
                        <TextField
                            label="Description"
                            className={classes.textField}
                            onChange={handleDescriptionChange}
                            value={description}
                            margin="normal"
                        />
                    </form>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
                <Button onClick={close} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
AddOrganization.propTypes = {
    classes: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};
export default withMobileDialog()(withStyles(styles)(AddOrganization));
