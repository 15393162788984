export const BASE_DOMAIN = process.env.REACT_APP_API_URL;

export const TERMINAL_URL = process.env.REACT_APP_TERMINAL_URL;

export const MQTT_SERVER_URL = process.env.REACT_APP_MQTT_SERVER_URL;

export const MQTT_ENV = process.env.REACT_APP_MQTT_ENV;

export const PYTHON_CLIENT_URL = process.env.REACT_APP_PYTHON_CLIENT_URL;

export const MAIN_TITLE = process.env.REACT_APP_MAIN_TITLE || 'Tunnel In -';
