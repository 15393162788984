// @flow
import {
    api,
    makeDelete,
    makeGet,
    makePost,
    makePut
} from '../utils/http-util';

const API_URL = {
    ADD_TUNNEL: () => api`v1/tunnel/start/`,
    UPDATE_TUNNEL: id => api`v1/tunnel/${id}`,
    SSH: id => api`v1/tunnel/ssh/start/${id}`,
    PROXY: id => api`v1/tunnel/proxy/start/${id}`,
    VNC: id => api`v1/tunnel/vnc/start/${id}`,
    RDP: id => api`v1/tunnel/rdp/start/${id}`,
    GET_TUNNELS: query => api`v1/tunnels?${query}`,
    GET_DEVICE_HTTP_TUNNELS: deviceId =>
        api`v1/tunnels?filters[0][property]=type&filters[0][value]=http&filters[1][property]=deviceId&filters[1][value]=${deviceId}`,
    DELETE_CUSTOM_TUNNEL: id => api`v1/tunnel/${id}`,
    STOP_TUNNEL: id => api`v1/tunnel/stop/${id}`,
    STOP_TUNNELS: () => api`v1/tunnels/stop`,
    RELOAD_TUNNELS: () => api`v1/tunnels/reload`,
    GET_TUNNELS_STATUSES: () => api`v1/tunnels/statuses`
};

export function openSSHTunnel(id, data) {
    return makePost(API_URL.SSH(id), data);
}

export function openTunnel(data) {
    return makePost(API_URL.ADD_TUNNEL(), data);
}

export function stopTunnel(id, data) {
    return makePost(API_URL.STOP_TUNNEL(id), data);
}

export function stopAllRunningTunnels() {
    return makePost(API_URL.STOP_TUNNELS(), {});
}

export function reloadAllRunningTunnels() {
    return makePost(API_URL.RELOAD_TUNNELS(), {});
}

export function addTunnel(data: any) {
    return makePost(API_URL.ADD_TUNNEL(), data);
}

export function updateTunnel(tunnelId, data) {
    return makePut(API_URL.UPDATE_TUNNEL(tunnelId), data);
}

export function openVNCTunnel(id, data) {
    return makePost(API_URL.VNC(id), data);
}

export function openRDPTunnel(id, data) {
    return makePost(API_URL.RDP(id), data);
}

export function openProxyTunnel(id, data) {
    return makePost(API_URL.PROXY(id), data);
}

export function getTunnels(search, filters = []) {
    // let search = 'tunnelName' and filters = [{ property: "type", values: ['http', 'rdp'] }]
    // query wiil be 'search=tunnelName&filters[0][property]=type&filters[0][value][0]=http&filters[0][value][1]=rdp&'
    let query = '';
    if (search) {
        query += `search=${search}&`;
    }
    const map = filters.reduce((map, f) => {
        const { property } = f;
        if (!map[property]) {
            // eslint-disable-next-line
            map[property] = [];
        }
        const values = f.values || [];
        for (let i = 0; i < values.length; i += 1) {
            const v = values[i];
            if (map[property].indexOf(v) === -1) {
                map[property].push(v);
            }
        }
        return map;
    }, {});
    let q = '';
    let count = 0;
    // eslint-disable-next-line
    for (const property in map) {
        // eslint-disable-next-line
        if (map.hasOwnProperty(property)) {
            const values = map[property];
            if (!values || values.length === 0) {
                // eslint-disable-next-line
                continue;
            }
            q += `filters[${count}][property]=${property}&`;
            for (let i = 0; i < values.length; i += 1) {
                const v = values[i];
                q += `filters[${count}][value][${i}]=${v}&`;
            }
            // q += '&';
            count += 1;
        }
    }
    query += q;
    if (query.charAt(query.length - 1) === '&')
        query = query.slice(0, query.length - 1);
    return makeGet(API_URL.GET_TUNNELS(query));
}

export function getDeviceHttpTunnels(deviceId) {
    return makeGet(API_URL.GET_DEVICE_HTTP_TUNNELS(deviceId));
}

export function deleteCustomTunnel(id) {
    return makeDelete(API_URL.DELETE_CUSTOM_TUNNEL(id));
}
export function getTunnelStatuses() {
    return makeGet(API_URL.GET_TUNNELS_STATUSES());
}
