import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { push } from 'react-router-redux';
import { LOGOUT_LOADING } from '../../constants/action-types';
import { logoutSuccess } from '../../actions/authentication-actions';
import { clearAuthToken } from '../../utils/http-util';

export default action$ =>
    action$.pipe(
        ofType(LOGOUT_LOADING),
        switchMap(() => {
            clearAuthToken();
            return of(logoutSuccess(), push('/login'));
        })
    );
