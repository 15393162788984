// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import SortableGroup from './SortableGroups';

const styles = () => ({
    paper: {
        width: '600px'
    },
    formControl: {
        width: 180
    }
});

class OrderGroups extends Component<
    {
        classes: {
            formControl: string
        },
        fullScreen: boolean,
        actions: {
            updateGroupsOrdering: (groups: any[]) => void
        },
        groups: any[],
        close: () => void
    },
    {
        groups: any[],
        canSave: boolean
    }
> {
    static propTypes = {
        fullScreen: PropTypes.bool.isRequired,
        actions: PropTypes.shape({
            updateGroupsOrdering: PropTypes.func.isRequired
        }).isRequired,
        groups: PropTypes.array.isRequired,
        close: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        const { groups } = props;
        const canSave = false;
        this.state = {
            groups,
            canSave
        };
    }

    handleSave = () => {
        const {
            actions: { updateGroupsOrdering },
            close
        } = this.props;
        const { groups, canSave } = this.state;
        const orderedGroups = groups.map(({ _id }, order) => ({
            _id,
            order
        }));
        for (let i = 0; i < orderedGroups.length; i += 1) {
            if (orderedGroups[i]._id === '-1') {
                orderedGroups.splice(i, 1);
                break;
            }
        }
        if (canSave) {
            updateGroupsOrdering(orderedGroups);
            close();
        }
    };

    moveGroup = (dragIndex: number, direction: string) => {
        const dropIndex = direction === 'up' ? dragIndex - 1 : dragIndex + 1;
        const { groups } = this.state;
        const dragGroup = groups[dragIndex];
        const newGroups = groups.slice();
        newGroups.splice(dragIndex, 1); // removing what you are dragging.
        newGroups.splice(dropIndex, 0, dragGroup); // inserting it into hoverIndex.
        this.setState({ groups: newGroups });
        this.updateCanSave(newGroups);
    };

    updateCanSave = (newGroups: any[]) => {
        const { groups } = this.props;
        for (let i = 0; i < newGroups.length; i += 1) {
            if (newGroups[i] !== groups[i]) {
                this.setState({
                    canSave: true
                });
                return;
            }
        }
        this.setState({
            canSave: false
        });
    };

    renderSortableGroup = (group, i) => {
        const { name, _id } = group;
        const groupsLength = this.props.groups.length;
        return (
            <SortableGroup
                groupsLength={groupsLength}
                key={_id}
                _id={_id}
                index={i}
                name={name}
                moveGroup={this.moveGroup}
            />
        );
    };

    render() {
        const { fullScreen, close } = this.props;
        const { groups, canSave } = this.state;
        return (
            <Dialog fullScreen={fullScreen} open onClose={close}>
                <DialogContent>
                    <h2>Order groups by clicking on arrows</h2>
                    <div>
                        {groups.map((group, i) =>
                            this.renderSortableGroup(group, i)
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSave}
                        disabled={!canSave}
                    >
                        Save
                    </Button>
                    <Button variant="contained" color="primary" onClick={close}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(OrderGroups));
