import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Hidden } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import AddOrEditTunnelForm from './AddOrEditTunnelForm';
import { wrapActionCreators } from '../../../utils/container-util';
import { selectDevices } from '../../../selectors/devices-selectors';
import { getDevices } from '../../../actions/device-actions';
import {
    INFO_TEXT_ABOUT_TUNNEL_RELOAD,
    DEFAULT_LOCAL_PORTS
} from '../../../constants/tunnels';
import {
    validateNameField,
    validateDeviceField,
    validateTypeField,
    validateLocalPortField,
    validateIpAddress
} from '../../../utils/tunnel-validations';
import {
    parseErrorMessage,
    constructGlobalErrorMessage
} from '../../../utils/global-message-util';
import { setGlobalMessageError } from '../../../actions/app-actions';
import { addTunnel, updateTunnel, getTunnels } from '../../../api/tunnel-api';

const styles = () => ({
    dialog: {
        '@media (min-width: 600px)': {
            minHeight: '550px',
            minWidth: '600px'
            // maxWidth: '550px'
        }
        // '@media (min-height: 450px)': {
        //     height: '450px'
        // }
    },
    dialogContent: {
        // padding: '0',
        paddingTop: '0!important',
        padding: '0px 24px',
        minHeight: '215px',
        height: '215px',
        display: 'flex',
        flexDirection: 'column'
    },
    addOrEditTunnelForm: {
        padding: '0px 24px'
    },
    dialogActions: {
        padding: '13px 20px',
        margin: 0
    },
    addButton: {
        margin: '0 4px'
    },
    infoAboutReloadTunnelWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    infoAboutReloadTunnelText: {
        marginLeft: 5,
        fontSize: 14,
        width: 250
    },
    infoAboutReloadTunnelTextMobile: {
        marginLeft: 5,
        fontSize: 14
    }
});

const AddOrEditTunnel = ({
    classes,
    fullScreen,
    close,
    onTunnelReady,
    tunnel,
    devices,
    handleStartClick,
    deviceAvailabilityStatus,
    actions: { getDevices, setGlobalMessageError }
}) => {
    const withStartAction =
        tunnel &&
        tunnel.status === 'online' &&
        deviceAvailabilityStatus === 'started';

    const [allTunnels, setAllTunnels] = useState([]);
    useEffect(() => {
        let canceled = false;
        getTunnels().subscribe(
            data => {
                if (data && data.data && data.data.tunnels && !canceled) {
                    setAllTunnels(data.data.tunnels);
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
        return () => {
            canceled = true;
        };
    }, []);

    useEffect(() => {
        if (!devices || devices.length === 0) {
            getDevices();
        }
    }, []);
    const firewall =
        (tunnel && tunnel.options && tunnel.options.firewall) || {};

    const [name, setName] = useState((tunnel && tunnel.name) || '');

    const [deviceId, setDeviceId] = useState((tunnel && tunnel.deviceId) || '');

    const [type, setType] = useState((tunnel && tunnel.type) || '');

    const [localPort, setLocalPort] = useState(
        (tunnel && tunnel.localPort && tunnel.localPort.toString()) || ''
    );

    const [handleHTTP, setHandleHTTP] = useState(
        (tunnel && tunnel.handleHTTP) || false
    );

    const [connectionsRateLimiting, setConnectionsRateLimiting] = useState(
        !!firewall.connectionsRateLimiting
    );
    const [
        initializationTimeLimiting,
        setInitializationTimeLimiting
    ] = useState(!!firewall.initializationTimeLimiting);
    const [allowConnectionsFrom, setAllowConnectionsFrom] = useState(
        Array.isArray(firewall.allowConnectionsFrom)
            ? 'custom'
            : firewall.allowConnectionsFrom || ''
    );
    const [ipAddresses, setIpAddresses] = useState(
        Array.isArray(firewall.allowConnectionsFrom)
            ? firewall.allowConnectionsFrom
            : ['']
    );
    const [autoClose, setAutoClose] = useState(!!firewall.autoClose);
    const [nameError, setNameError] = useState('');

    const [deviceIdError, setDeviceIdError] = useState('');

    const [typeError, setTypeError] = useState('');

    const [localPortError, setLocalPortError] = useState('');

    const [ipAddressErrors, setIpAddressErrors] = useState(
        Array.isArray(allowConnectionsFrom)
            ? new Array(allowConnectionsFrom.length).fill(null)
            : [null]
    );
    const val = (((tunnel || {}).options || {}).auth || {}).password || '';
    const [showPassword, setShowPassword] = useState(!!val);
    const [tunnelPassword, setTunnelPassword] = useState(val);
    const validateLocalPort = port => {
        let err = validateLocalPortField(port);
        if (!err && deviceId) {
            const tunnelsOnSelectedDevice = allTunnels.filter(
                t => t.deviceId === deviceId
            );
            const tunnelOnTheSamePort = tunnelsOnSelectedDevice.find(
                t =>
                    String(t.localPort) === String(port) &&
                    (tunnel ? t._id !== tunnel._id : true)
            );
            if (tunnelOnTheSamePort) {
                err = `Port "${port}" in use by "${
                    tunnelOnTheSamePort.name
                }" tunnel`;
            }
        }
        return err;
    };

    const validateName = (name, t = type) => {
        let err = validateNameField(name);
        if (!err) {
            const httpTunnels = allTunnels.filter(t => t.type === 'http');
            const tunnelWithTheSameName = httpTunnels.find(
                t => t.name === name && (tunnel ? t._id !== tunnel._id : true)
            );
            if (tunnelWithTheSameName && t === 'http') {
                err = `This url already assigned to other tunnel.`;
            }
        }
        return err;
    };

    const validateForm = () => {
        let isValid = true;
        const nError = validateName(name);
        setNameError(nError);
        const dError = validateDeviceField(deviceId);
        setDeviceIdError(dError);
        const tError = validateTypeField(type);
        setTypeError(tError);
        const lError = validateLocalPort(localPort);
        setLocalPortError(lError);
        if (allowConnectionsFrom === 'custom' && ipAddressErrors.find(e => e)) {
            isValid = false;
        }
        return isValid && !nError && !dError && !tError && !lError;
    };

    const onAddClick = () => {
        if (validateForm()) {
            const { options } = tunnel || {};
            const allowConnectionsFromData =
                allowConnectionsFrom === 'custom'
                    ? ipAddresses
                    : allowConnectionsFrom || 'anywhere';
            const tunnelId = tunnel && tunnel.id;
            const favourite = Boolean(tunnel && tunnel.favourite);
            const data = {
                name,
                localPort,
                handleHTTP: type === 'http' ? handleHTTP : false,
                deviceId,
                type,
                options: {
                    ...options,
                    auth: { password: tunnelPassword },
                    firewall: {
                        allowConnectionsFrom: allowConnectionsFromData,
                        connectionsRateLimiting,
                        initializationTimeLimiting: initializationTimeLimiting
                            ? 180
                            : 0,
                        autoClose
                    }
                }
            };
            if (!tunnel) {
                addTunnel(data).subscribe(
                    d => {
                        onTunnelReady(d);
                        close();
                    },
                    err => {
                        let message = 'Something went wrong';
                        if (err) message = parseErrorMessage(err);
                        setGlobalMessageError(
                            constructGlobalErrorMessage(message)
                        );
                    }
                );
            } else {
                updateTunnel(tunnelId, {
                    ...data,
                    tunnelId,
                    favourite
                }).subscribe(
                    d => {
                        onTunnelReady(d);
                        if (
                            tunnel.status === 'started' &&
                            deviceAvailabilityStatus === 'online'
                        ) {
                            handleStartClick();
                        }
                        close();
                    },
                    err => {
                        let message = 'Something went wrong';
                        if (err) message = parseErrorMessage(err);
                        setGlobalMessageError(
                            constructGlobalErrorMessage(message)
                        );
                    }
                );
            }
        }
    };

    const onNameChange = e => {
        const { value } = e.target;
        setName(value);
        setNameError(validateName(value));
    };

    const onDeviceIdChange = e => {
        const { value } = e.target;
        setDeviceId(value);
        setDeviceIdError(validateDeviceField(value));
    };

    const onTypeChange = e => {
        const { value } = e.target;
        setType(value);
        setTypeError(validateTypeField(value));
        if (value === 'http') {
            setConnectionsRateLimiting(false);
            setInitializationTimeLimiting(false);
            setAllowConnectionsFrom('anywhere');
            setHandleHTTP(false);
            setAutoClose(false);
            setLocalPort(DEFAULT_LOCAL_PORTS[value]);
            if (localPort) {
                setLocalPortError(
                    validateLocalPort(DEFAULT_LOCAL_PORTS[value])
                );
            }
        } else if (
            value === 'rdp' ||
            value === 'ssh' ||
            value === 'vnc' ||
            value === 'proxy' ||
            value === 'custom'
        ) {
            setConnectionsRateLimiting(true);
            setInitializationTimeLimiting(true);
            setAllowConnectionsFrom('myIp');
            setHandleHTTP(false);
            setAutoClose(true);
            setLocalPort(DEFAULT_LOCAL_PORTS[value]);
            if (!(value === 'custom' && !localPort)) {
                setLocalPortError(
                    validateLocalPort(DEFAULT_LOCAL_PORTS[value])
                );
            }
        }
        if ((type === 'http' || value === 'http') && name) {
            setNameError(validateName(name, value));
        }
    };

    const onLocalPortChange = e => {
        const { value } = e.target;
        setLocalPort(value);
        setLocalPortError(validateLocalPort(value));
    };

    const onIpAddressesChange = (e, index) => {
        const newIpAddresses = [...ipAddresses];
        const newIpAddressErrors = [...ipAddressErrors];
        const isValidIpAddress = validateIpAddress(e.target.value);
        newIpAddresses.splice(index, 1, e.target.value);
        newIpAddressErrors.splice(index, 1, !isValidIpAddress);
        setIpAddresses(newIpAddresses);
        setIpAddressErrors(newIpAddressErrors);
    };

    const onAddIpAddress = () => {
        const newIpAddresses = [...ipAddresses];
        const newIpAddressErrors = [...ipAddressErrors];
        newIpAddresses.push('');
        newIpAddressErrors.push(null);
        setIpAddresses(newIpAddresses);
        setIpAddressErrors(newIpAddressErrors);
    };
    const onRemoveIpAddress = i => {
        const newIpAddresses = [...ipAddresses];
        const newIpAddressErrors = [...ipAddressErrors];
        newIpAddresses.splice(i, 1);
        newIpAddressErrors.splice(i, 1);
        setIpAddresses(newIpAddresses);
        setIpAddressErrors(newIpAddressErrors);
    };

    const formData = {
        name,
        onNameChange,
        devices,
        deviceId,
        onDeviceIdChange,
        type,
        onTypeChange,
        localPort,
        onLocalPortChange,
        handleHTTP,
        setHandleHTTP,
        connectionsRateLimiting,
        setConnectionsRateLimiting,
        initializationTimeLimiting,
        setInitializationTimeLimiting,
        allowConnectionsFrom,
        setAllowConnectionsFrom,
        ipAddresses,
        onIpAddressesChange,
        autoClose,
        setAutoClose,
        nameError,
        deviceIdError,
        typeError,
        localPortError,
        ipAddressErrors,
        onAddIpAddress,
        onRemoveIpAddress,
        tunnelPassword,
        showPassword,
        setTunnelPassword,
        setShowPassword
    };

    return (
        <Dialog
            classes={{
                paper: classes.dialog
            }}
            fullScreen={fullScreen}
            open
            onClose={close}
        >
            <DialogTitle>
                {tunnel ? 'Edit Tunnel' : 'Create Tunnel'}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <div className={classes.addOrEditTunnelForm}>
                    <AddOrEditTunnelForm
                        formData={formData}
                        mode={tunnel ? 'edit' : 'add'}
                    />
                </div>
                {withStartAction && (
                    <Hidden mdUp>
                        <>
                            <div style={{ flex: 1 }} />
                            <div
                                className={classes.infoAboutReloadTunnelWrapper}
                            >
                                <InfoIcon style={{ color: '#9c27b0' }} />{' '}
                                <div
                                    className={
                                        classes.infoAboutReloadTunnelTextMobile
                                    }
                                >
                                    {INFO_TEXT_ABOUT_TUNNEL_RELOAD}
                                </div>
                            </div>
                        </>
                    </Hidden>
                )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                {withStartAction && (
                    <Hidden smDown>
                        <div className={classes.infoAboutReloadTunnelWrapper}>
                            <InfoIcon style={{ color: '#9c27b0' }} />{' '}
                            <div className={classes.infoAboutReloadTunnelText}>
                                {INFO_TEXT_ABOUT_TUNNEL_RELOAD}
                            </div>
                        </div>
                    </Hidden>
                )}
                <div style={{ flex: 1 }} />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onAddClick}
                    className={classes.addButton}
                >
                    {tunnel
                        ? withStartAction
                            ? 'Save and Reload'
                            : 'Save'
                        : 'Add'}
                </Button>
                <Button variant="contained" color="primary" onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddOrEditTunnel.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    onTunnelReady: PropTypes.func.isRequired,
    tunnel: PropTypes.object,
    devices: PropTypes.array,
    handleStartClick: PropTypes.func,
    deviceAvailabilityStatus: PropTypes.string,
    actions: PropTypes.shape({
        getDevices: PropTypes.func.isRequired,
        setGlobalMessageError: PropTypes.func.isRequired
    })
};

const mapStateToProps = createStructuredSelector({
    devices: selectDevices()
});

const mapDispatchToProps = wrapActionCreators({
    getDevices,
    setGlobalMessageError
});

export default withMobileDialog()(
    withStyles(styles)(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddOrEditTunnel)
    )
);
