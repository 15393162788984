// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { wrapActionCreators } from '../../../../utils/container-util';

import { addGroup } from '../../../../actions/group-actions';

import AddDeviceGroup from '../../../../components/Devices/Groups/Add';

const AddDeviceGroupHOC = ({ close, actions: { addGroup }, groupsLength }) => (
    <AddDeviceGroup close={close} add={addGroup} groupsLength={groupsLength} />
);

AddDeviceGroupHOC.propTypes = {
    close: PropTypes.func.isRequired,
    actions: PropTypes.shape({
        addGroup: PropTypes.func.isRequired
    }).isRequired,
    groupsLength: PropTypes.number.isRequired
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = wrapActionCreators({
    addGroup
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDeviceGroupHOC);
