import { combineEpics } from 'redux-observable';
import getDevicesEpic from './get-devices';
import addDeviceEpic from './add-device';
import updateDeviceEpic from './update-device';
import deleteDeviceEpic from './delete-device';
import getDeviceUsersEpic from './get-device-users';
import getStatusesEpic from './get-statuses';
import addOrRemoveUsersToDeviceEpic from './add-or-remove-users-to-device';
import removeCurrentUserFromDeviceEpic from './remove-current-user-from-device';
import updateDevicesOrdering from './update-devices-ordering';

export default combineEpics(
    getDevicesEpic,
    getStatusesEpic,
    addDeviceEpic,
    updateDeviceEpic,
    deleteDeviceEpic,
    getDeviceUsersEpic,
    addOrRemoveUsersToDeviceEpic,
    removeCurrentUserFromDeviceEpic,
    updateDevicesOrdering
);
