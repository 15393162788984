// @flow
import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import FormControl from '@material-ui/core/es/FormControl';
import InputLabel from '@material-ui/core/es/InputLabel';
import Select from '@material-ui/core/es/Select';
import Input from '@material-ui/core/es/Input';
import { withRouter } from 'react-router';
import { Tooltip } from '@material-ui/core';
import Profile from '../../Profile';

import Settings from '../../Settings';
import About from '../../About';

import { getGravatarUrl } from '../../../utils/user-utils';
import { hasGravatar } from '../../../api/general-api';

import styles from './styles';

import type { User } from '../../../types/user';
import { createOrganization } from '../../../api/organization-api';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../../utils/global-message-util';
import AddOrganization from '../../OrganizationUsers/AddOrganization';
import Authorities from '../../../containers/Authorities';
import {
    ROUTES_DEVICES_AUTH,
    ROUTES_USERS_AUTH,
    ROUTES_TUNNELS_AUTH,
    ROUTES_USAGE_AUTH
} from '../../../constants/authorities';
import CouponCode from '../../CouponCode';

type HeaderProps = {
    classes: {
        root: string,
        flexGrow: string,
        selectedList: string,
        toolbar: string,
        purpleAvatar: string,
        avatarButton: string,
        drawerPaper: string,
        drawerHeader: string,
        flex1: string,
        menuButton: string
    },
    loggedInUser: User,
    actions: {
        logout: () => void
    },
    tunnelsTourDetails: any,
    devicesTourDetails: any
};
class OptimizedHeader extends React.Component<
    HeaderProps,
    {
        anchorEl: any,
        profilePic: string,
        modal: string,
        openDrawer: boolean,
        currentMenu: string
    }
> {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        loggedInUser: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            logout: PropTypes.func.isRequired,
            changeOrganization: PropTypes.func.isRequired,
            setGlobalMessageError: PropTypes.func.isRequired,
            setDevicesTourVisibility: PropTypes.func.isRequired,
            setTunnelsTourVisibility: PropTypes.func.isRequired
        }).isRequired,
        menuTitle: PropTypes.string.isRequired,
        history: PropTypes.shape({
            replace: PropTypes.func.isRequired
        }).isRequired,
        tunnelsTourDetails: PropTypes.object.isRequired,
        devicesTourDetails: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        const currentMenu = props.menuTitle;
        this.state = {
            anchorEl: null,
            profilePic: '',
            modal: '',
            openDrawer: false,
            currentMenu
        };
    }

    componentDidMount() {
        const { loggedInUser } = this.props;
        this.determineProfilePic(loggedInUser);
    }

    componentDidUpdate(prevProps: HeaderProps) {
        const { loggedInUser, menuTitle } = this.props;
        if (loggedInUser !== prevProps.loggedInUser) {
            this.determineProfilePic(loggedInUser);
        }
        if (prevProps.menuTitle !== menuTitle) {
            this.setState({ currentMenu: menuTitle });
        }
    }

    determineProfilePic(user) {
        const { profilePic } = user;
        if (!profilePic) {
            this.setState({ profilePic: '' });
        } else {
            const { type, url } = profilePic;
            if (type === 'gravatar') {
                const { email } = user;
                hasGravatar(email)
                    .then(({ data }) => {
                        if (data) {
                            this.setState({
                                profilePic: getGravatarUrl(email)
                            });
                        } else {
                            this.setState({ profilePic: '' });
                        }
                    })
                    .catch(() => {
                        this.setState({ profilePic: '' });
                    });
            } else if (url) {
                this.setState({ profilePic: url });
            } else {
                this.setState({ profilePic: '' });
            }
        }
    }

    handleAvatarClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleAvatarMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleProfileClick = () => {
        this.handleAvatarMenuClose();
        this.setState({ modal: 'profile' });
    };

    handleSettingsClick = () => {
        this.handleAvatarMenuClose();
        this.setState({ modal: 'settings' });
    };

    handleAddOrganizationClick = () => {
        this.handleAvatarMenuClose();
        this.setState({ modal: 'addorganization' });
    };

    handleUsageClick = () => {
        const { history } = this.props;
        this.handleAvatarMenuClose();
        history.replace('/usage');
    };

    handleManageUsersClick = () => {
        const { history } = this.props;
        this.handleAvatarMenuClose();
        history.replace('/users');
    };

    handleAboutClick = () => {
        this.handleAvatarMenuClose();
        this.setState({ modal: 'about' });
    };

    handleApplyCouponCodeClick = () => {
        this.handleAvatarMenuClose();
        this.setState({ modal: 'addCode' });
    };

    handleChangeOrgClick = event => {
        const { actions } = this.props;
        actions.changeOrganization({
            organizationId: event.target.value
        });
    };

    handleLogoutClick = () => {
        this.handleAvatarMenuClose();
        const {
            actions: { logout }
        } = this.props;
        logout();
    };

    handleModalClose = () => {
        this.setState({ modal: '' });
    };

    handleDrawerOpen = () => {
        this.setState({ openDrawer: true });
    };

    handleDrawerClose = () => {
        this.setState({ openDrawer: false });
    };

    handleMainMenuChange = currentMenu => {
        const { history } = this.props;
        if (currentMenu === 'help') {
            window.open('https://tunnelin.com/blog', '_blank');
        }
        history.replace(`/${currentMenu}`);
        this.setState({ currentMenu, openDrawer: false });
    };

    addOrganization = (name, description) => {
        const {
            actions: { changeOrganization, setGlobalMessageError }
        } = this.props;
        createOrganization({
            name,
            description
        }).subscribe(
            data => {
                if (data && data.data) {
                    changeOrganization({
                        organizationId: data.data._id
                    });
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
    };

    handleContinueTourClick = () => {
        const {
            actions: { setDevicesTourVisibility, setTunnelsTourVisibility }
        } = this.props;
        const { currentMenu } = this.state;
        this.setState({ openDrawer: false });
        if (currentMenu.toLowerCase() === 'devices')
            setDevicesTourVisibility(true);
        if (currentMenu.toLowerCase() === 'tunnels')
            setTunnelsTourVisibility(true);
    };

    render() {
        const {
            currentMenu,
            openDrawer,
            anchorEl,
            profilePic,
            modal
        } = this.state;
        const {
            classes,
            loggedInUser,
            tunnelsTourDetails,
            devicesTourDetails
        } = this.props;
        const { fullName, orgs } = loggedInUser;
        const codeInfo = ((loggedInUser.couponCode || [])[0] || {}).name;
        let avatarCmp;
        if (profilePic) {
            avatarCmp = (
                <IconButton
                    disableRipple
                    aria-owns={anchorEl ? 'header-avatar-menu' : null}
                    aria-haspopup="true"
                    onClick={this.handleAvatarClick}
                    className={classes.avatarButton}
                >
                    <Avatar src={profilePic} />
                </IconButton>
            );
        } else {
            avatarCmp = (
                <Avatar className={classes.purpleAvatar}>
                    <Button
                        aria-owns={anchorEl ? 'header-avatar-menu' : null}
                        aria-haspopup="true"
                        onClick={this.handleAvatarClick}
                        className={classes.avatarButton}
                    >
                        {fullName.charAt(0)}
                    </Button>
                </Avatar>
            );
        }

        const drawer = (
            <Drawer
                variant="temporary"
                anchor="left"
                open={openDrawer}
                classes={{
                    paper: classes.drawerPaper
                }}
                disableEnforceFocus
            >
                <div className={classes.drawerHeader}>
                    <Typography type="title" color="inherit">
                        TunnelIn
                    </Typography>
                    <Typography
                        className={classes.selectedList}
                        color="inherit"
                    >
                        &nbsp;- {currentMenu}
                    </Typography>
                    <div className={classes.flex1} />
                    <IconButton onClick={this.handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <Authorities>
                    <MenuItem
                        data-cmpauthkey={ROUTES_DEVICES_AUTH}
                        onClick={() => this.handleMainMenuChange('devices')}
                    >
                        Devices
                    </MenuItem>
                </Authorities>
                <Authorities>
                    <MenuItem
                        data-cmpauthkey={ROUTES_TUNNELS_AUTH}
                        onClick={() => this.handleMainMenuChange('tunnels')}
                    >
                        Tunnels
                    </MenuItem>
                </Authorities>
                <MenuItem onClick={() => this.handleMainMenuChange('help')}>
                    Help
                </MenuItem>
                <Divider />
                {!(!loggedInUser || !orgs || orgs.length < 2) && (
                    <FormControl style={{ margin: 10 }}>
                        <InputLabel htmlFor="age-helper">
                            Organization
                        </InputLabel>
                        <Select
                            value={orgs.find(f => f.default)._id}
                            onChange={this.handleChangeOrgClick}
                            input={<Input name="age" id="age-helper" />}
                        >
                            {orgs.map(r => (
                                <MenuItem key={r._id} value={r._id}>
                                    {r.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <Divider />
                <MenuItem
                    component="a"
                    href="https://app.tunnelin.com/signup"
                    target="_blank"
                >
                    Get started
                </MenuItem>
                {((currentMenu &&
                    currentMenu.toLowerCase() === 'devices' &&
                    !devicesTourDetails.visibility) ||
                    (currentMenu &&
                        currentMenu.toLowerCase() === 'tunnels' &&
                        !tunnelsTourDetails.visibility)) && (
                    <MenuItem onClick={this.handleContinueTourClick}>
                        Continue tour
                    </MenuItem>
                )}
            </Drawer>
        );

        return (
            <div>
                <AppBar
                    className={classes.root}
                    position="static"
                    color="default"
                >
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classes.menuButton}
                            data-tut="reactour__devices__goto__tunnels__step"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography type="title" color="inherit">
                            TunnelIn
                        </Typography>
                        <Typography
                            className={classes.selectedList}
                            color="inherit"
                        >
                            &nbsp;- {currentMenu}
                        </Typography>
                        <div className={classes.flexGrow} />
                        {avatarCmp}
                        {codeInfo && (
                            <Tooltip
                                title={`You have activated ${codeInfo} code`}
                                placement="top"
                            >
                                <DoneSharpIcon
                                    style={{ width: 15, height: 20 }}
                                />
                            </Tooltip>
                        )}
                        <Menu
                            id="header-avatar-menu"
                            anchorEl={anchorEl}
                            open={!!anchorEl}
                            onClose={this.handleAvatarMenuClose}
                        >
                            <MenuItem onClick={this.handleProfileClick}>
                                Profile
                            </MenuItem>
                            <MenuItem onClick={this.handleSettingsClick}>
                                Settings
                            </MenuItem>
                            <Authorities>
                                <MenuItem
                                    data-cmpauthkey={ROUTES_USERS_AUTH}
                                    onClick={this.handleManageUsersClick}
                                >
                                    Manage users
                                </MenuItem>
                            </Authorities>
                            <Authorities>
                                <MenuItem
                                    data-cmpauthkey="add.organization.action"
                                    onClick={this.handleAddOrganizationClick}
                                >
                                    Add Organization
                                </MenuItem>
                            </Authorities>
                            <Authorities data-cmpauthkey={ROUTES_USAGE_AUTH}>
                                <MenuItem onClick={this.handleUsageClick}>
                                    Usage
                                </MenuItem>
                            </Authorities>
                            <MenuItem onClick={this.handleApplyCouponCodeClick}>
                                Apply Coupon Code
                                {codeInfo && (
                                    <Tooltip
                                        title={`You have activated ${codeInfo} code`}
                                        placement="top"
                                    >
                                        <DoneSharpIcon
                                            style={{ width: 15, height: 20 }}
                                        />
                                    </Tooltip>
                                )}
                            </MenuItem>
                            <MenuItem onClick={this.handleAboutClick}>
                                About
                            </MenuItem>
                            <MenuItem onClick={this.handleLogoutClick}>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                    {modal === 'profile' && (
                        <Profile close={this.handleModalClose} />
                    )}
                    {modal === 'settings' && (
                        <Settings close={this.handleModalClose} />
                    )}
                    {modal === 'about' && (
                        <About close={this.handleModalClose} />
                    )}
                    {modal === 'addCode' && (
                        <CouponCode
                            fullScreen={true}
                            close={this.handleModalClose}
                        />
                    )}
                    {modal === 'addorganization' && (
                        <AddOrganization
                            close={this.handleModalClose}
                            onSave={this.addOrganization}
                        />
                    )}
                </AppBar>
                {drawer}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(OptimizedHeader));
