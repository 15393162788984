// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { updateDevicesOrdering } from '../../../actions/device-actions';
import { wrapActionCreators } from '../../../utils/container-util';
import OrderDevice from '../../../components/Devices/Order';

const OrderDevicesHOC = ({
    actions,
    close,
    devices
}: {
    actions: {
        updateDevicesOrdering: (devices: any[]) => void
    },
    close: () => void,
    devices: any[]
}) => <OrderDevice actions={actions} sortableDevices={devices} close={close} />;

OrderDevicesHOC.propTypes = {
    actions: PropTypes.shape({
        updateDevicesOrdering: PropTypes.func.isRequired
    }).isRequired,
    devices: PropTypes.array.isRequired,
    close: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = wrapActionCreators({
    updateDevicesOrdering
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderDevicesHOC);
