import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EmptyLists from '../../components/EmptyLists';

import image from '../../assets/images/tunnel.jpg';

const styles = () => ({
    root: {
        display: 'flex',
        height: `calc(100vh - 64px)`,
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    },

    flexGrow: {
        flex: '1 1 auto'
    },

    addModalContent: {
        position: 'absolute',
        width: 500,
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        backgroundColor: '#fff'
    }
});

class EmptyTunnels extends Component {
    handleAddClick = () => {
        const { onAddTunnel } = this.props;
        if (onAddTunnel) {
            onAddTunnel();
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <EmptyLists
                    buttons={[
                        <div className={classes.flexGrow} key="0" />,
                        <IconButton
                            key="1"
                            color="primary"
                            aria-label="add"
                            onClick={this.handleAddClick}
                        >
                            <AddIcon />
                        </IconButton>
                    ]}
                    message="You do not seem to have any tunnel yet!"
                    messageTitle=""
                    image={image}
                    mediaType="horizontal"
                />
            </div>
        );
    }
}

EmptyTunnels.propTypes = {
    classes: PropTypes.object.isRequired,
    onAddTunnel: PropTypes.func
};

export default withStyles(styles)(EmptyTunnels);
