// @flow
import { api, makeGet } from '../utils/http-util';

const API_URL = {
    GET_ORG_ROLES: () => api`v1/roles/organization`
};

export default function getOrganizationRoles() {
    return makeGet(API_URL.GET_ORG_ROLES());
}
