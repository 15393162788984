import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
    FormControl,
    InputLabel,
    Input,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    Hidden
} from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { wrapActionCreators } from '../../../utils/container-util';
import {
    getFilters,
    getUiFilters,
    getSearchQuery
} from '../../../selectors/tunnels-selectors';
import {
    updateTunnelUiFilters,
    updateTunnelFilter,
    updateTunnelSearch
} from '../../../actions/tunnel-actions';
import { ALL_TUNNEL_TYPES } from '../../../constants/tunnels';

const styles = () => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 5px'
    },
    sectionRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '5px 0',
        '@media (max-width: 959px)': {
            flexDirection: 'column'
        }
    },
    uiFilters: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media (max-width: 959px)': {
            width: '100%'
        }
    }
});

const Filters = ({
    classes,
    filters,
    uiFilters,
    searchQuery,
    actions: { updateTunnelFilter, updateTunnelUiFilters, updateTunnelSearch }
}) => {
    // console.log('filters render');
    const existingFilters = useMemo(
        () =>
            filters.filter(f => f.property === 'type').map(f => f.values)[0] ||
            [],
        [filters]
    );
    // const [searchValue, setSearchValue] = useState(searchQuery);
    // const updateSearch = debounce(() => {
    //     console.log(searchValue);
    //     updateTunnelSearch(searchValue);
    // }, 3000);
    return (
        <div className={classes.wrapper} data-tut="tunnels__step_filters">
            <div className={classes.sectionRow}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="search-field">Search</InputLabel>
                    <Input
                        id="search-field"
                        value={searchQuery}
                        onChange={e => {
                            updateTunnelSearch(e.target.value);
                        }}
                    />
                </FormControl>
            </div>
            <div className={classes.sectionRow}>
                <Hidden smDown>
                    Filters:{' '}
                    {ALL_TUNNEL_TYPES.map(type => (
                        <FormControlLabel
                            key={type}
                            control={
                                <Checkbox
                                    checked={existingFilters.indexOf(type) > -1}
                                    onChange={() =>
                                        updateTunnelFilter({
                                            property: 'type',
                                            value: type
                                        })
                                    }
                                    value={type}
                                />
                            }
                            label={type}
                        />
                    ))}
                    <div className={classes.uiFilters}>
                        <FormControl style={{ width: 120, marginLeft: 10 }}>
                            <InputLabel htmlFor="devices">Devices</InputLabel>
                            <Select
                                inputProps={{
                                    id: 'devices'
                                }}
                                value={
                                    uiFilters.find(
                                        f => f.property === 'deviceStatus'
                                    ).value
                                }
                                onChange={e =>
                                    updateTunnelUiFilters({
                                        property: 'deviceStatus',
                                        value: e.target.value
                                    })
                                }
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="online">Online</MenuItem>
                                <MenuItem value="away">Away</MenuItem>
                                <MenuItem value="offline">Offline</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: 120, marginLeft: 10 }}>
                            <InputLabel htmlFor="tunnels">Tunnels</InputLabel>
                            <Select
                                inputProps={{
                                    id: 'tunnels'
                                }}
                                value={
                                    uiFilters.find(
                                        f => f.property === 'tunnelStatus'
                                    ).value
                                }
                                onChange={e =>
                                    updateTunnelUiFilters({
                                        property: 'tunnelStatus',
                                        value: e.target.value
                                    })
                                }
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="started">Started</MenuItem>
                                <MenuItem value="stopped">Stopped</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Hidden>
                <Hidden mdUp>
                    <FormControl fullWidth>
                        <Select
                            multiple
                            value={existingFilters}
                            input={<Input id="filters-checkbox" />}
                            renderValue={() => existingFilters.join(', ')}
                        >
                            {ALL_TUNNEL_TYPES.map(type => (
                                <MenuItem key={type} value={type}>
                                    <FormControlLabel
                                        style={{ width: '100%' }}
                                        control={
                                            <Checkbox
                                                checked={
                                                    existingFilters.indexOf(
                                                        type
                                                    ) > -1
                                                }
                                                onChange={() => {
                                                    updateTunnelFilter({
                                                        property: 'type',
                                                        value: type
                                                    });
                                                }}
                                                value={type}
                                            />
                                        }
                                        label={type}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className={classes.uiFilters}>
                        <FormControl style={{ width: 150 }}>
                            <Select
                                inputProps={{
                                    id: 'devices'
                                }}
                                value={
                                    uiFilters.find(
                                        f => f.property === 'deviceStatus'
                                    ).value
                                }
                                onChange={e =>
                                    updateTunnelUiFilters({
                                        property: 'deviceStatus',
                                        value: e.target.value
                                    })
                                }
                            >
                                <MenuItem value="all">All devices</MenuItem>
                                <MenuItem value="online">
                                    Online devices
                                </MenuItem>
                                <MenuItem value="away">Away devices</MenuItem>
                                <MenuItem value="offline">
                                    Offline devices
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: 150 }}>
                            <Select
                                inputProps={{
                                    id: 'tunnels'
                                }}
                                value={
                                    uiFilters.find(
                                        f => f.property === 'tunnelStatus'
                                    ).value
                                }
                                onChange={e =>
                                    updateTunnelUiFilters({
                                        property: 'tunnelStatus',
                                        value: e.target.value
                                    })
                                }
                            >
                                <MenuItem value="all">All tunnels</MenuItem>
                                <MenuItem value="started">
                                    Started tunnels
                                </MenuItem>
                                <MenuItem value="stopped">
                                    Stopped tunnels
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Hidden>
            </div>
        </div>
    );
};

Filters.propTypes = {
    classes: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    uiFilters: PropTypes.array.isRequired,
    searchQuery: PropTypes.string.isRequired,
    actions: PropTypes.shape({
        updateTunnelFilter: PropTypes.func.isRequired,
        updateTunnelUiFilters: PropTypes.func.isRequired,
        updateTunnelSearch: PropTypes.func.isRequired
    })
};

const mapStateToProps = createStructuredSelector({
    filters: getFilters(),
    uiFilters: getUiFilters(),
    searchQuery: getSearchQuery()
});

const mapDispatchToProps = wrapActionCreators({
    updateTunnelFilter,
    updateTunnelUiFilters,
    updateTunnelSearch
});

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Filters)
);
