import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/StarTwoTone';

const styles = () => ({
    root: {
        width: 24,
        cursor: 'pointer',
        color: '#7F7F7F'
    },
    active: {
        color: '#9c27b0'
    }
});

function Star(props) {
    const { classes, active, onChange } = props;

    function handleClick() {
        if (onChange) {
            onChange();
        }
    }

    return (
        <StarIcon
            onClick={handleClick}
            className={classNames(classes.root, {
                [classes.active]: Boolean(active)
            })}
        />
    );
}
Star.propTypes = {
    classes: PropTypes.object.isRequired,
    active: PropTypes.bool,
    onChange: PropTypes.func
};

export default withStyles(styles)(Star);
