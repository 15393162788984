import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import StartIcon from '@material-ui/icons/PlayArrow';

import LinkIcon from '@material-ui/icons/Link';
import StopIcon from '@material-ui/icons/Stop';
import HelpIcon from '@material-ui/icons/HelpOutline';

import withMobileDialog from '@material-ui/core/withMobileDialog';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Menu from '@material-ui/core/Menu/Menu';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';
import {
    subscribeToTopic,
    unsubscribeFromTopic,
    removeListener,
    addListener
} from '../../utils/mqtt';
import { getLoggedInUser } from '../../selectors/app-selectors';
import AddOrEditTunnel from '../Tunnels/Modal/AddOrEditTunnel';
import Star from './Star';
import ProxyTunnelInfo from '../Tunnels/Modal/ProxyTunnelInfo';
import store from '../../store/get-store';
import { setGlobalMessageError } from '../../actions/app-actions';
import { MQTT_ENV } from '../../constants/api-constants';
import { bytesToSize } from '../../utils/helpers';
import { getTunnelStatuses } from '../../selectors/tunnels-selectors';
import Authorities from '../../containers/Authorities';
import {
    EDIT_TUNNEL_ACTION_AUTH,
    DELETE_TUNNEL_ACTION_AUTH
} from '../../constants/authorities';
import { updateTunnel, openTunnel, stopTunnel } from '../../api/tunnel-api';
import useUnmounted from '../../hooks/use-unmount';

const { dispatch } = store;

const styles = () => ({
    tunnelItem: {
        borderBottom: '1px dashed #cccccc'
    },
    itemWrapper: {
        flexDirection: 'row',
        display: 'flex',
        marginTop: 2,
        fontSize: 14,
        alignItems: 'center'
    },
    tunnelItemLeftPartBottom: {
        display: 'flex',
        paddingLeft: 5,
        marginBottom: 2
    },
    tooltipText: {
        fontSize: '11px'
    },
    generatedNameItem: {
        padding: '5px',
        textTransform: 'none'
    },
    tunnelName: {
        flex: 2,
        padding: '5px 0 5px 5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden'
    },
    tunnelNameInner: {
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    favouriteWrapper: {
        marginRight: 5
    },
    deviceName: {
        flex: 3,
        padding: '5px 0 5px 5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden'
    },
    deviceNameInner: {
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    port: {
        padding: '5px 0 5px 5px',
        width: 50,
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    tunnelActionIcon: {
        cursor: 'pointer',
        color: '#9c27b0'
    },
    moreActionsButton: {
        padding: 5
    },
    circularIcon: {
        padding: '5px 5px 6px 6px',
        width: 34
    },
    colorGreen: {
        fill: '#61bd4f'
    },
    colorYellow: {
        fill: '#f2d600'
    },
    colorRed: {
        fill: '#eb5a46'
    },
    lastSeenIcon: {
        fontSize: 12
    }
});

const TunnelItemMobile = ({
    classes,
    tunnel,
    tunnel: {
        _id,
        id,
        name,
        localPort,
        handleHTTP,
        deviceId,
        type,
        favourite,
        dashUrl,
        slashUrl,
        deviceName,
        status,
        startTime,
        stopTime,
        options
    } = {},
    tunnelDelete,
    onTunnelReady,
    loggedInUser,
    reloadingTunnelsInProcess,
    stoppingTunnelsInProcess,
    devicesStatuses,
    tunnelsStatuses
}) => {
    const unMounted = useUnmounted();
    const [showProxyTunnelInfo, setShowProxyTunnelInfo] = useState(false);
    const [proxyTunnelInfoMessage, setProxyTunnelInfoMessage] = useState('');
    const [tunnelActionsAnchorEl, setTunnelActionsAnchorEl] = useState(null);
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(
        false
    );
    const [editTunnel, setEditTunnel] = useState(false);
    const [
        changeTunnelStateInProcess,
        setChangeTunnelStateInProcess
    ] = useState(false);
    const [doRestartAfterEditing, setDoRestartAfterEditing] = useState(false);
    const tunnelUsageData = useMemo(
        () => {
            let result = '';
            const currentTunnelStatus = tunnelsStatuses.find(
                ts => ts._id === _id
            );
            const { rx_bytes: rxBytes, tx_bytes: txBytes } =
                (currentTunnelStatus &&
                    currentTunnelStatus.info &&
                    currentTunnelStatus.info.networks &&
                    currentTunnelStatus.info.networks.eth0) ||
                {};
            if (
                (Number(rxBytes) || rxBytes === 0) &&
                (Number(txBytes) || txBytes === 0)
            ) {
                result = bytesToSize(Number(rxBytes) + Number(txBytes));
            }
            return result;
        },
        [tunnelsStatuses]
    );
    const lastSeen = useMemo(
        () => {
            let ls;
            try {
                const statuses = devicesStatuses[tunnel.deviceId];
                if (statuses) {
                    const data = JSON.parse(statuses);
                    ls = data ? data.lastSeen : undefined;
                }
            } catch {}
            return ls;
        },
        [devicesStatuses, tunnel.deviceId]
    );

    const determineAvailability = lastSeen => {
        const newDate = Date.now();

        if (!lastSeen) {
            return 'offline';
        }

        const diffMs = newDate - lastSeen;
        const diffMins = Math.round(diffMs / (1000 * 60));
        if (diffMins > 10) {
            return 'offline';
        }
        if (diffMins > 3) {
            return 'away';
        }
        return 'online';
    };

    const availabilityStatus = useMemo(
        () => (lastSeen ? determineAvailability(lastSeen) : 'offline'),
        [lastSeen]
    );

    const lastSeenIndicatorClassName = useMemo(
        () => {
            switch (availabilityStatus) {
                case 'online':
                    return classes.colorGreen;
                case 'away':
                    return classes.colorYellow;
                default:
                    return classes.colorRed;
            }
        },
        [availabilityStatus]
    );
    const lastSeenIndicatorTooltipMessage = useMemo(
        () => (lastSeen ? moment(lastSeen).fromNow() : 'offline'),
        [lastSeen]
    );

    useEffect(
        () => () => {
            const topic = `user--${loggedInUser._id}/server`;
            unsubscribeFromTopic(topic);
        },
        [loggedInUser]
    );

    const handleEditClick = () => {
        setEditTunnel(true);
        setTunnelActionsAnchorEl(null);
    };

    const handleFavouriteClick = () => {
        updateTunnel(id, {
            name,
            localPort,
            handleHTTP,
            deviceId,
            type,
            favourite: !favourite
        }).subscribe(
            tunnel => onTunnelReady(tunnel),
            () => {
                dispatch(setGlobalMessageError('Something went wrong.'));
            }
        );
    };

    const handleMenuClose = () => {
        setTunnelActionsAnchorEl(null);
    };

    const handleProxyTunnelConfigurationInfoClick = () => {
        setShowProxyTunnelInfo(true);
        setProxyTunnelInfoMessage(
            'Proxy settings are fixed for all devices. You can change this option from Settings page.'
        );
        handleMenuClose();
    };

    const handleDeleteClick = () => {
        setOpenDeleteConfirmDialog(true);
        handleMenuClose();
    };

    const handleDelete = () => {
        tunnelDelete(tunnel.id);
    };

    const closeDeleteTunnelConfirmation = () => {
        setOpenDeleteConfirmDialog(false);
    };

    const closeEditTunnelModal = () => {
        setEditTunnel(false);
    };

    const closeProxyTunnelInfo = () => {
        setShowProxyTunnelInfo(false);
        setProxyTunnelInfoMessage('');
        handleMenuClose();
    };

    const subscribeToTopicCallback = (editedTunnel, messageActionName) => {
        const fn = (t, message) => {
            const action = JSON.parse(message);
            if (action && action.type === 'NOTIFY_USER') {
                const { payload } = action;
                const { tunnelId, action: actionName } = payload;
                if (
                    tunnelId === id &&
                    actionName === messageActionName &&
                    t === `${MQTT_ENV}/user--${loggedInUser._id}/server`
                ) {
                    onTunnelReady({ data: { ...editedTunnel } });
                    if (!unMounted.current) {
                        setChangeTunnelStateInProcess(false);
                    }
                    removeListener(fn);
                }
            }
        };
        addListener(fn);
        const topic = `user--${loggedInUser._id}/server`;
        subscribeToTopic(topic);
    };

    const handleStartClick = () => {
        setTunnelActionsAnchorEl(null);
        setChangeTunnelStateInProcess(true);
        openTunnel({
            tunnelId: id,
            options,
            deviceId
        }).subscribe(
            res => {
                if (res && res.data && res.data.tunnel) {
                    subscribeToTopicCallback(res.data.tunnel, 'start_tunnel');
                } else {
                    dispatch(setGlobalMessageError("Can't start tunnel"));
                    if (!unMounted.current) {
                        setChangeTunnelStateInProcess(false);
                    }
                }
            },
            () => {
                if (!unMounted.current) {
                    setChangeTunnelStateInProcess(false);
                }
            }
        );
    };

    useEffect(
        () => {
            if (doRestartAfterEditing) {
                setDoRestartAfterEditing(false);
                handleStartClick();
            }
        },
        [doRestartAfterEditing]
    );

    const handleStopClick = () => {
        setTunnelActionsAnchorEl(null);
        setChangeTunnelStateInProcess(true);
        stopTunnel(id, { deviceId, type }).subscribe(
            res => {
                if (res && res.data && res.data.tunnel) {
                    subscribeToTopicCallback(res.data.tunnel, 'stop_tunnel');
                } else {
                    dispatch(setGlobalMessageError("Can't stop tunnel"));
                    if (!unMounted.current) {
                        setChangeTunnelStateInProcess(false);
                    }
                }
            },
            () => {
                if (!unMounted.current) {
                    setChangeTunnelStateInProcess(false);
                }
            }
        );
    };
    useEffect(
        () => {
            if (
                reloadingTunnelsInProcess &&
                status === 'started' &&
                availabilityStatus === 'online'
            ) {
                handleStartClick();
            }
            if (
                stoppingTunnelsInProcess &&
                status === 'started' &&
                availabilityStatus === 'online'
            ) {
                handleStopClick();
            }
        },
        [
            reloadingTunnelsInProcess,
            stoppingTunnelsInProcess,
            status,
            availabilityStatus
        ]
    );
    const handleMenuClick = event => {
        setTunnelActionsAnchorEl(event.currentTarget);
    };

    const restartTunnel = () => {
        setDoRestartAfterEditing(true);
    };

    return (
        <div className={classes.tunnelItem}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}
            >
                <div className={classes.itemWrapper}>
                    <div className={classes.tunnelName}>
                        <div className={classes.favouriteWrapper}>
                            <Star
                                onChange={handleFavouriteClick}
                                active={favourite}
                            />
                        </div>
                        <div className={classes.tunnelNameInner}>{name}</div>
                    </div>
                    {deviceName && (
                        <div className={classes.deviceName}>
                            <Tooltip
                                title={lastSeenIndicatorTooltipMessage}
                                placement="bottom"
                            >
                                <CircleIcon
                                    fontSize="small"
                                    className={`${lastSeenIndicatorClassName} ${
                                        classes.lastSeenIcon
                                    }`}
                                />
                            </Tooltip>
                            <div className={classes.deviceNameInner}>
                                {deviceName}
                            </div>
                        </div>
                    )}
                    {!deviceName && (
                        <div className={classes.deviceName}>
                            {type === 'proxy' ? (
                                <Tooltip
                                    title={
                                        <div className={classes.tooltipText}>
                                            Proxy settings are fixed for all
                                            devices. You can change this option
                                            from Settings page.
                                        </div>
                                    }
                                    placement="right"
                                    className={classes.deviceNameInner}
                                >
                                    <div className={classes.deviceNameInner}>
                                        All Devices
                                    </div>
                                </Tooltip>
                            ) : (
                                <div className={classes.deviceNameInner}>
                                    Not Specified
                                </div>
                            )}
                        </div>
                    )}
                    <div className={classes.port}>{localPort}</div>
                    <div>
                        {!changeTunnelStateInProcess ? (
                            <IconButton
                                aria-label="More"
                                aria-owns={
                                    tunnelActionsAnchorEl
                                        ? 'tunnel-actions-menu'
                                        : null
                                }
                                aria-haspopup="true"
                                className={classes.moreActionsButton}
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        ) : (
                            <div className={classes.circularIcon}>
                                <CircularProgress size={20} />
                            </div>
                        )}
                        <Menu
                            id="tunnel-actions-menu"
                            anchorEl={tunnelActionsAnchorEl}
                            open={Boolean(tunnelActionsAnchorEl)}
                            onClose={handleMenuClose}
                        >
                            {type === 'http' && status === 'started' && (
                                <MenuItem>
                                    <a
                                        rel="noopener noreferrer"
                                        className={classes.generatedNameItem}
                                        target="_blank"
                                        href={`https://${dashUrl}`}
                                    >
                                        <LinkIcon
                                            className={classes.tunnelActionIcon}
                                        />
                                    </a>
                                </MenuItem>
                            )}
                            {type === 'http' && false && (
                                <MenuItem onClick={handleStartClick}>
                                    <a
                                        rel="noopener noreferrer"
                                        className={classes.generatedNameItem}
                                        target="_blank"
                                        href={`https://${slashUrl}`}
                                    >
                                        <LinkIcon
                                            className={classes.tunnelActionIcon}
                                        />
                                    </a>
                                </MenuItem>
                            )}
                            {(status === 'stopped' || !status) && (
                                <MenuItem
                                    disabled={availabilityStatus !== 'online'}
                                    onClick={handleStartClick}
                                >
                                    <StartIcon
                                        style={{ margin: '0 auto' }}
                                        className={classes.tunnelActionIcon}
                                    />
                                </MenuItem>
                            )}
                            {status === 'started' && (
                                <MenuItem
                                    disabled={availabilityStatus !== 'online'}
                                    onClick={handleStopClick}
                                >
                                    <StopIcon
                                        style={{ margin: '0 auto' }}
                                        className={classes.tunnelActionIcon}
                                    />
                                </MenuItem>
                            )}
                            {status === 'started' && (
                                <MenuItem
                                    disabled={availabilityStatus !== 'online'}
                                    onClick={handleStartClick}
                                >
                                    <RefreshIcon
                                        style={{ margin: '0 auto' }}
                                        className={classes.tunnelActionIcon}
                                    />
                                </MenuItem>
                            )}
                            <Authorities>
                                <MenuItem
                                    data-cmpauthkey={EDIT_TUNNEL_ACTION_AUTH}
                                    onClick={handleEditClick}
                                >
                                    <EditIcon
                                        style={{ margin: '0 auto' }}
                                        className={classes.tunnelActionIcon}
                                    />
                                </MenuItem>
                            </Authorities>
                            {!deviceName && !deviceId && (
                                <MenuItem
                                    onClick={
                                        type === 'proxy'
                                            ? handleProxyTunnelConfigurationInfoClick
                                            : () => {}
                                    }
                                >
                                    <HelpIcon
                                        style={{ margin: '0 auto' }}
                                        className={classes.tunnelActionIcon}
                                    />
                                </MenuItem>
                            )}
                            <Authorities>
                                <MenuItem
                                    data-cmpauthkey={DELETE_TUNNEL_ACTION_AUTH}
                                    onClick={handleDeleteClick}
                                >
                                    <DeleteIcon
                                        style={{ margin: '0 auto' }}
                                        className={classes.tunnelActionIcon}
                                    />
                                </MenuItem>
                            </Authorities>
                        </Menu>
                    </div>
                </div>
                {editTunnel && (
                    <AddOrEditTunnel
                        tunnel={tunnel}
                        close={closeEditTunnelModal}
                        onTunnelReady={onTunnelReady}
                        handleStartClick={restartTunnel}
                        deviceAvailabilityStatus={availabilityStatus}
                    />
                )}
                {showProxyTunnelInfo && (
                    <ProxyTunnelInfo
                        close={closeProxyTunnelInfo}
                        message={proxyTunnelInfoMessage}
                    />
                )}
                {openDeleteConfirmDialog && (
                    <Dialog
                        open
                        fullScreen={true}
                        onClose={closeDeleteTunnelConfirmation}
                        aria-labelledby="delete-device-title"
                    >
                        <DialogTitle>Delete tunnel</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure want to delete{' '}
                                <strong>{name}</strong> tunnel?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleDelete}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={closeDeleteTunnelConfirmation}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
                {!!(tunnelUsageData || startTime) && (
                    <div className={classes.tunnelItemLeftPartBottom}>
                        {status === 'started' ? (
                            <>
                                {startTime && (
                                    <div
                                        style={{
                                            marginRight: 10,
                                            fontSize: 12
                                        }}
                                    >
                                        Started {moment(startTime).fromNow()}
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {startTime && (
                                    <div
                                        style={{
                                            marginRight: 10,
                                            fontSize: 12
                                        }}
                                    >
                                        Last time started{' '}
                                        {moment(startTime).fromNow()}
                                    </div>
                                )}
                                {stopTime && (
                                    <div
                                        style={{
                                            marginRight: 10,
                                            fontSize: 12
                                        }}
                                    >
                                        Stopped {moment(stopTime).fromNow()}
                                    </div>
                                )}
                            </>
                        )}

                        {tunnelUsageData && (
                            <div style={{ marginRight: 10, fontSize: 12 }}>
                                Data: {tunnelUsageData}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

TunnelItemMobile.propTypes = {
    classes: PropTypes.object.isRequired,
    tunnel: PropTypes.shape({
        id: PropTypes.string,
        _id: PropTypes.string,
        name: PropTypes.string,
        localPort: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        handleHTTP: PropTypes.bool,
        deviceId: PropTypes.string,
        type: PropTypes.string,
        favourite: PropTypes.bool,
        engine: PropTypes.shape({
            address: PropTypes.string
        }),
        dashUrl: PropTypes.string,
        slashUrl: PropTypes.string,
        deviceName: PropTypes.string,
        clientPort: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        status: PropTypes.string,
        startTime: PropTypes.number,
        stopTime: PropTypes.number,
        options: PropTypes.object
    }).isRequired,
    tunnelDelete: PropTypes.func.isRequired,
    onTunnelReady: PropTypes.func.isRequired,
    loggedInUser: PropTypes.object.isRequired,
    reloadingTunnelsInProcess: PropTypes.bool,
    stoppingTunnelsInProcess: PropTypes.bool,
    devicesStatuses: PropTypes.object.isRequired,
    tunnelsStatuses: PropTypes.array.isRequired
};

const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser(),
    tunnelsStatuses: getTunnelStatuses()
});
export default connect(mapStateToProps)(
    withMobileDialog()(withStyles(styles)(TunnelItemMobile))
);
