// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import GeneralOptions from '../../../components/Profile/GeneralOptions';

import { wrapActionCreators } from '../../../utils/container-util';

import { updateCurrentUser } from '../../../actions/authentication-actions';

import {
    getLoggedInUser,
    selectUpdateCurrentUserLoading
} from '../../../selectors/app-selectors';

const GeneralOptionsHOC = props => <GeneralOptions {...props} />;

GeneralOptionsHOC.propTypes = {
    actions: PropTypes.shape({
        updateCurrentUser: PropTypes.func.isRequired
    }).isRequired,
    expanded: PropTypes.bool,
    onExpansionPanelChange: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    loggedInUser: PropTypes.object.isRequired,
    updateLoading: PropTypes.bool.isRequired
};

const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser(),
    updateLoading: selectUpdateCurrentUserLoading()
});

const mapDispatchToProps = wrapActionCreators({
    updateCurrentUser
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneralOptionsHOC);
