// import { filter, find, findIndex } from 'lodash';
import { combineReducers } from 'redux';
import globalInitialState from './initial-state';
import {
    GET_DEVICES_SUCCESS,
    GET_DEVICES_STATUSES_LOADING,
    GET_DEVICES_STATUSES_SUCCESS
} from '../constants/action-types';
import { singlePayloadReducer } from '../utils/reducer-util';

const initialState = globalInitialState.devices.statuses;

function data(state = initialState.data, action) {
    switch (action.type) {
        case GET_DEVICES_SUCCESS: {
            return action.payload.data.statuses;
        }
        case GET_DEVICES_STATUSES_SUCCESS: {
            return action.payload;
        }
        default:
            return state;
    }
}

export default combineReducers({
    data,
    loading: singlePayloadReducer(
        GET_DEVICES_STATUSES_LOADING,
        initialState.loading
    )
});
