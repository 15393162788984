import React from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog/withMobileDialog';
import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { clearAuthToken, urlToToken } from '../../utils/http-util';

import decodeJwt from '../../utils/jwt-utils';
import { activateOrgUser } from '../../api/users-api';
import SignUpAndApprove from './SignUpAndApprove';

const styles = theme => ({
    wrapper: {
        flex: '1 1 0%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 500,
        marginTop: 50
    },
    content: {
        padding: '20px'
    },
    actionsBar: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 0px',
        height: '40px',
        alignItems: 'inherit'
    },
    flex1: {
        flex: '1'
    },
    mainActions: {
        display: 'flex',
        flexDirection: 'row',
        height: '36px'
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    titleText: {
        marginTop: 20
    },
    colorGreen: {
        color: '#61bd4f'
    }
});
const OrganizationInvitation = ({ classes, loggedInUser, token, history }) => {
    const handleChangeAccountClick = () => {
        clearAuthToken();
        window.location.reload();
    };

    const handleGetDeviceWithCurrentAccClick = () => {
        activateOrgUser({ token: urlToToken(token) }).subscribe(() => {
            history.replace('/');
            window.location.reload();
        });
    };

    const handleHomeBtnClick = () => {
        // const { history } = props;

        window.location.reload();
    };
    const { data: dataFromToken } = decodeJwt(urlToToken(token), null, true);
    return (
        <div className={classes.wrapper}>
            {loggedInUser ? (
                <Card className={classes.root}>
                    <CardContent className={classes.content}>
                        <Typography type="title" color="inherit">
                            Accept Invitation to participate in{' '}
                            {dataFromToken.organizationName} organization
                        </Typography>
                        <div>
                            <p>
                                Invitation was sent to {dataFromToken.email}{' '}
                                email
                            </p>
                            <p>
                                You are logged in as {loggedInUser.fullName} (
                                {loggedInUser.email})
                            </p>
                            <p>
                                Do you want to Accept Invitation with current
                                account?
                            </p>
                        </div>
                    </CardContent>
                    <CardActions className={classes.actionsBar}>
                        <div className={classes.mainActions}>
                            <div className={classes.flex1} />
                            <Button
                                color="primary"
                                onClick={handleHomeBtnClick}
                            >
                                Home
                            </Button>
                            <Button
                                color="primary"
                                onClick={handleChangeAccountClick}
                            >
                                Change Account
                            </Button>
                            <Button
                                color="primary"
                                onClick={handleGetDeviceWithCurrentAccClick}
                            >
                                Accept
                            </Button>
                        </div>
                    </CardActions>
                </Card>
            ) : (
                <SignUpAndApprove
                    token={token}
                    oldOwnerEmail="asdasd"
                    newOwnerEmail="asd"
                    deviceName="asdsd"
                />
            )}
        </div>
    );
};
OrganizationInvitation.propTypes = {
    classes: PropTypes.object.isRequired,
    loggedInUser: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    history: PropTypes.shape({
        action: PropTypes.string.isRequired,
        replace: PropTypes.func.isRequired
    }).isRequired
};
export default withMobileDialog()(withStyles(styles)(OrganizationInvitation));
