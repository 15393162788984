// @flow
import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

import SnackbarContentWrapper from '../shared/Snackbar/snackbarContentWrapper';
import { GLOBAL_COOKIE_MESSAGE } from '../../constants/message-constants';

class Message extends React.Component<
    {
        actions: {
            setGlobalMessageError: (message: any) => void,
            setGlobalMessageSuccess: (message: any) => void,
            setGlobalMessageCookies: (message: any) => void
        },
        location: { search: string },
        history: { action: string, replace: (path: string) => void },
        errorMessage: string,
        successMessage: string,
        cookiesMessage: string
    },
    {
        successMessages: string[],
        errorMessages: string[]
    }
> {
    constructor(props) {
        super(props);
        const { successMessage, errorMessage } = this.props;
        this.state = {
            successMessages: successMessage ? [successMessage] : [],
            errorMessages: errorMessage ? [errorMessage] : []
        };
    }

    static getDerivedStateFromProps(props, state) {
        let { successMessages, errorMessages } = state;
        const { successMessage, errorMessage } = props;
        if (successMessage) {
            if (successMessages.indexOf(successMessage) === -1) {
                successMessages = [...successMessages, successMessage];
            } else {
                successMessages = [];
            }
        } else {
            successMessages = [];
        }
        if (errorMessage) {
            if (errorMessages.indexOf(errorMessage) === -1) {
                errorMessages = [...errorMessages, errorMessage];
            } else {
                errorMessages = [];
            }
        } else {
            errorMessages = [];
        }
        return {
            successMessages,
            errorMessages
        };
    }

    handleErrorSnackbarClose = () => {
        const {
            actions: { setGlobalMessageError }
        } = this.props;
        setGlobalMessageError('');
    };

    handleSuccessSnackbarClose = () => {
        const {
            actions: { setGlobalMessageSuccess }
        } = this.props;
        setGlobalMessageSuccess('');
    };

    handleCookiesSnackbarClose = () => {
        const {
            actions: { setGlobalMessageCookies },
            cookiesMessage
        } = this.props;
        setGlobalMessageCookies('');
        if (cookiesMessage === GLOBAL_COOKIE_MESSAGE) {
            localStorage.setItem('cookiesMessageShowed', true);
        }
    };

    render() {
        const { cookiesMessage } = this.props;
        const { successMessages, errorMessages } = this.state;
        let successMessage = '';
        let errorMessage = '';
        if (successMessages.length === 1) {
            [successMessage] = successMessages;
        } else if (successMessages.length > 1) {
            successMessage = (
                <ul>
                    {successMessages.map(el => (
                        <li key={el}>{el}</li>
                    ))}
                </ul>
            );
        }
        if (errorMessages.length === 1) {
            [errorMessage] = errorMessages;
        } else if (errorMessages.length > 1) {
            errorMessage = (
                <ul>
                    {errorMessages.map(el => (
                        <li key={el}>{el}</li>
                    ))}
                </ul>
            );
        }

        const cookiesSnackbar = (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={cookiesMessage !== ''}
                onClose={this.handleCookiesSnackbarClose}
            >
                <SnackbarContentWrapper
                    onClose={this.handleCookiesSnackbarClose}
                    variant="info"
                    message={cookiesMessage}
                />
            </Snackbar>
        );

        const errorSnackbar = (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={5000}
                open={errorMessage !== ''}
                onClose={this.handleErrorSnackbarClose}
            >
                <SnackbarContentWrapper
                    onClose={this.handleErrorSnackbarClose}
                    variant="error"
                    message={errorMessage}
                />
            </Snackbar>
        );

        const successSnackbar = (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={5000}
                open={successMessage !== ''}
                onClose={this.handleSuccessSnackbarClose}
            >
                <SnackbarContentWrapper
                    onClose={this.handleSuccessSnackbarClose}
                    variant="success"
                    message={successMessage}
                />
            </Snackbar>
        );

        return (
            <>
                {cookiesSnackbar}
                {errorSnackbar}
                {successSnackbar}
            </>
        );
    }
}

Message.propTypes = {
    errorMessage: PropTypes.node.isRequired,
    successMessage: PropTypes.node.isRequired,
    cookiesMessage: PropTypes.node.isRequired
};

export default Message;
