// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/es/Card/Card';
import Typography from '@material-ui/core/es/Typography/Typography';
import FormControl from '@material-ui/core/es/FormControl/FormControl';
import InputLabel from '@material-ui/core/es/InputLabel/InputLabel';
import Input from '@material-ui/core/es/Input/Input';
import FormHelperText from '@material-ui/core/es/FormHelperText/FormHelperText';
import CardActions from '@material-ui/core/es/CardActions/CardActions';
import Button from '@material-ui/core/es/Button/Button';
import CardContent from '@material-ui/core/es/CardContent/CardContent';

const styles = theme => ({
    wrapper: {
        flex: '1 1 0%',
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 500,
        marginTop: 50
    },
    content: {
        padding: '20px'
    },
    actionsBar: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 0px',
        height: '40px',
        alignItems: 'inherit'
    },
    flex1: {
        flex: '1'
    },
    mainActions: {
        display: 'flex',
        flexDirection: 'row',
        height: '36px'
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    titleText: {
        marginTop: 20
    },
    successMessage: {
        fontSize: 24,
        color: '#61bd4f'
    }
});

class GetSendedDevice extends React.Component<
    {
        classes: {
            wrapper: string,
            root: string,
            content: string,
            actionsBar: string,
            flex1: string,
            mainActions: string,
            formControl: string,
            titleText: string,
            successMessage: string
        },
        oldOwnerEmail: string,
        deviceName: string,
        history: { replace: (path: string) => void },
        getNewDeviceLoading: boolean,
        getNewDeviceSuccess: boolean,
        handleGetDeviceClick: (validationNumbers: string) => void
    },
    {
        validationError: string,
        validationNumbers: string,
        disableGetDeviceButton: boolean
    }
> {
    state = {
        validationError: '',
        validationNumbers: '',
        disableGetDeviceButton: false
    };

    onKeyDown = event => {
        const { validationNumbers } = this.state;
        const { handleGetDeviceClick } = this.props;
        switch (event.keyCode) {
            case 13: {
                handleGetDeviceClick(validationNumbers);
                break;
            }
            default:
                break;
        }
    };

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
        if (!event.target.value) {
            this.setState({ disableGetDeviceButton: true });
        } else {
            this.setState({ disableGetDeviceButton: false });
        }
    };

    handleGetDeviceClick = () => {
        const { validationNumbers } = this.state;
        const { handleGetDeviceClick } = this.props;
        if (validationNumbers && validationNumbers.length === 4) {
            this.setState({
                validationError: '',
                disableGetDeviceButton: false
            });
            handleGetDeviceClick(validationNumbers);
        } else {
            this.setState({
                validationError: 'Please enter four digits',
                disableGetDeviceButton: true
            });
        }
    };

    handleHomeBtnClick = () => {
        const { history } = this.props;
        history.replace('/');
        window.location.reload();
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    render() {
        const {
            deviceName,
            oldOwnerEmail,
            classes,
            getNewDeviceLoading,
            getNewDeviceSuccess
        } = this.props;
        const {
            disableGetDeviceButton,
            validationError,
            validationNumbers
        } = this.state;
        return (
            <Card className={classes.root}>
                <CardContent className={classes.content}>
                    <Typography type="title" color="inherit">
                        Change Device Owner
                    </Typography>
                    <div className={classes.titleText} color="inherit">
                        {!getNewDeviceSuccess ? (
                            <p>
                                User with {oldOwnerEmail} email wants to give
                                you `{deviceName}` device.
                            </p>
                        ) : (
                            <>
                                <div className={classes.successMessage}>
                                    Success
                                </div>
                                <p>
                                    Please click the Home button to start using
                                    your new device.
                                </p>
                            </>
                        )}
                    </div>
                    {!getNewDeviceSuccess && (
                        <FormControl
                            className={classes.formControl}
                            required
                            error={!!validationError}
                        >
                            <InputLabel htmlFor="validation-numbers-field">
                                Validation Numbers
                            </InputLabel>
                            <Input
                                id="validation-numbers-field"
                                value={validationNumbers}
                                autoFocus
                                onChange={this.handleChange(
                                    'validationNumbers'
                                )}
                                inputProps={this.inputProps}
                            />
                            {validationError && (
                                <FormHelperText error>
                                    {validationError}
                                </FormHelperText>
                            )}
                        </FormControl>
                    )}
                </CardContent>
                <CardActions className={classes.actionsBar}>
                    <div className={classes.mainActions}>
                        <div className={classes.flex1} />
                        <Button
                            color="primary"
                            disabled={getNewDeviceLoading}
                            onClick={this.handleHomeBtnClick}
                        >
                            Home
                        </Button>
                        <Button
                            color="primary"
                            disabled={
                                disableGetDeviceButton || getNewDeviceSuccess
                            }
                            onClick={this.handleGetDeviceClick}
                        >
                            Get Device
                        </Button>
                    </div>
                </CardActions>
            </Card>
        );
    }
}

GetSendedDevice.propTypes = {
    oldOwnerEmail: PropTypes.string.isRequired,
    deviceName: PropTypes.string.isRequired
};

export default withRouter(withStyles(styles)(GetSendedDevice));
