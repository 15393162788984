// @flow
import React from 'react';
import PropTypes from 'prop-types';

import ProxyOptions from '../../../components/Settings/ProxyOptions';

import {
    getFixedProxyConfig,
    updateFixedProxyConfig
} from '../../../api/user-options-api';

const ProxyOptionsHOC = (props: any) => (
    <ProxyOptions
        {...props}
        getFixedProxyConfig={getFixedProxyConfig}
        updateFixedProxyConfig={updateFixedProxyConfig}
    />
);

ProxyOptionsHOC.propTypes = {
    expanded: PropTypes.bool,
    onExpansionPanelChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default ProxyOptionsHOC;
