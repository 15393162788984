import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    openSSHTunnel,
    openVNCTunnel,
    openRDPTunnel
} from '../../api/tunnel-api';
import { wrapActionCreators } from '../../utils/container-util';
import { setGlobalMessageError } from '../../actions/app-actions';

import {
    subscribeToTopic,
    unsubscribeFromTopic,
    addListener,
    removeListener
} from '../../utils/mqtt';
import { getLoggedInUser } from '../../selectors/app-selectors';

import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';
import { MQTT_ENV } from '../../constants/api-constants';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    progress: {
        margin: theme.spacing.unit * 2
    }
});

export default () => WrappedComponent => {
    class WithTunnelCheck extends React.Component {
        static propTypes = {
            actions: PropTypes.shape({
                setGlobalMessageError: PropTypes.func.isRequired
            }).isRequired,
            tunnelType: PropTypes.string.isRequired,
            deviceId: PropTypes.string.isRequired,
            loggedInUser: PropTypes.object.isRequired,
            classes: PropTypes.shape({
                root: PropTypes.string.isRequired,
                progress: PropTypes.string.isRequired
            }).isRequired
        };

        state = {
            firstTime: true,
            loading: false,
            port: null,
            hostname: null
        };

        componentDidMount() {
            const {
                tunnelType,
                deviceId,
                loggedInUser,
                actions: { setGlobalMessageError }
            } = this.props;
            let tunnelId;
            let action;
            this.setState({ firstTime: false, loading: true });
            switch (tunnelType) {
                case 'ssh':
                    action = openSSHTunnel;
                    break;
                case 'vnc':
                    action = openVNCTunnel;
                    break;
                case 'rdp':
                    action = openRDPTunnel;
                    break;
                default:
                    return;
            }
            action(deviceId, {}).subscribe(
                data => {
                    tunnelId = data.data.tunnel.id;
                },
                error => {
                    setGlobalMessageError(
                        constructGlobalErrorMessage(parseErrorMessage(error))
                    );
                }
            );
            const topic = `user--${loggedInUser._id}/server`;
            const fn = (t, message) => {
                const action = JSON.parse(message);
                if (action && action.type === 'NOTIFY_USER') {
                    const { payload } = action;
                    const { address, port, action: actionName } = payload;
                    if (
                        tunnelId === payload.tunnelId &&
                        actionName === 'start_tunnel' &&
                        t === `${MQTT_ENV}/user--${loggedInUser._id}/server`
                    ) {
                        this.setState({
                            loading: false,
                            hostname: address,
                            port
                        });
                        removeListener(fn);
                    }
                }
            };
            addListener(fn);
            subscribeToTopic(topic);
        }

        componentWillUnmount() {
            const { loggedInUser } = this.props;
            const topic = `user--${loggedInUser._id}/server`;
            unsubscribeFromTopic(topic);
        }

        render() {
            const {
                classes: { root, progress },
                ...rest
            } = this.props;
            const { loading, firstTime, hostname, port } = this.state;

            if (loading || firstTime) {
                return (
                    <div className={root}>
                        <CircularProgress
                            className={progress}
                            color="secondary"
                        />
                    </div>
                );
            }

            return (
                <WrappedComponent {...rest} hostname={hostname} port={port} />
            );
        }
    }

    const mapStateToProps = createStructuredSelector({
        loggedInUser: getLoggedInUser()
    });

    const mapDispatchToProps = wrapActionCreators({
        setGlobalMessageError
    });

    WithTunnelCheck.displayName = `WithTunnel(${WrappedComponent.displayName ||
        WrappedComponent.name})`;
    WithTunnelCheck.WrappedComponent = WrappedComponent;
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(WithTunnelCheck));
};
