import { createAction } from 'redux-actions';
import {
    CHANGE_CURRENT_USER_PASSWORD,
    CHANGE_CURRENT_USER_PASSWORD_LOADING,
    CHANGE_CURRENT_USER_PASSWORD_SUCCESS,
    GET_LOGGED_IN_USER,
    GET_LOGGED_IN_USER_ERROR,
    GET_LOGGED_IN_USER_LOADING,
    GET_LOGGED_IN_USER_SUCCESS,
    LOGIN,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGOUT_LOADING,
    LOGOUT_SUCCESS,
    SIGNUP,
    SIGNUP_LOADING,
    SIGNUP_SUCCESS,
    UPDATE_CURRENT_USER,
    UPDATE_CURRENT_USER_LOADING,
    UPDATE_CURRENT_USER_SUCCESS,
    VERIFY_EMAIL_SUCCESS,
    REGENERATE_TWO_FACTOR_QR,
    REGENERATE_TWO_FACTOR_QR_LOADING,
    TOGGLE_TWO_FACTOR_AUTH,
    TOGGLE_TWO_FACTOR_AUTH_LOADING,
    INSUFFICIENTLY_AUTHENTICATION_DETAILS,
    CHANGE_ORGANIZATION,
    SIGNUP_ACTIVATE_ORG_USER
} from '../constants/action-types';

export const login = createAction(LOGIN);
export const loginLoading = createAction(LOGIN_LOADING);
export const loginSuccess = createAction(LOGIN_SUCCESS);

export const changeOrganization = createAction(CHANGE_ORGANIZATION);

export const logout = createAction(LOGOUT_LOADING);
export const logoutSuccess = createAction(LOGOUT_SUCCESS);

export const getLoggedInUser = createAction(GET_LOGGED_IN_USER);
export const getLoggedInUserLoading = createAction(GET_LOGGED_IN_USER_LOADING);
export const getLoggedInUserSuccess = createAction(GET_LOGGED_IN_USER_SUCCESS);
export const getLoggedInUserError = createAction(GET_LOGGED_IN_USER_ERROR);

export const updateCurrentUser = createAction(UPDATE_CURRENT_USER);
export const updateCurrentUserLoading = createAction(
    UPDATE_CURRENT_USER_LOADING
);
export const updateCurrentUserSuccess = createAction(
    UPDATE_CURRENT_USER_SUCCESS
);

export const changeCurrentUserPassword = createAction(
    CHANGE_CURRENT_USER_PASSWORD
);
export const changeCurrentUserPasswordLoading = createAction(
    CHANGE_CURRENT_USER_PASSWORD_LOADING
);
export const changeCurrentUserPasswordSuccess = createAction(
    CHANGE_CURRENT_USER_PASSWORD_SUCCESS
);
export const verifyEmailSuccess = createAction(VERIFY_EMAIL_SUCCESS);

export const signup = createAction(SIGNUP);
export const signupActivateOrgUser = createAction(SIGNUP_ACTIVATE_ORG_USER);

export const signupLoading = createAction(SIGNUP_LOADING);
export const signupSuccess = createAction(SIGNUP_SUCCESS);
export const regenerateTwoFactorAuthQR = createAction(REGENERATE_TWO_FACTOR_QR);
export const regenerateTwoFactorAuthQRLoading = createAction(
    REGENERATE_TWO_FACTOR_QR_LOADING
);
export const toggleTwoFactorAuth = createAction(TOGGLE_TWO_FACTOR_AUTH);
export const toggleTwoFactorAuthLoading = createAction(
    TOGGLE_TWO_FACTOR_AUTH_LOADING
);
export const insufficientlyAuthenticationDetails = createAction(
    INSUFFICIENTLY_AUTHENTICATION_DETAILS
);
