// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types';
import { TableCellProps } from '@material-ui/core/es/TableCell';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import { IconButton } from '@material-ui/core';
import type { IGridColumn } from '../../interface/Grid';
import Grid from '../shared/Grid/Grid';

const styles = {
    gridWrapper: {
        flex: 1
    },
    actionIcon: {
        // marginRight: 5
    }
};

const OrganizationUsers = ({
    classes,
    organizationUsers,
    handelDeleteOrgUser,
    onEditOrgUserClick,
    resendInvitation
}) => {
    const gridActionsContextRenderer: (
        cellRendererProps: TableCellProps
    ) => React.ReactNode = contextProps => {
        const data = (
            <div>
                <IconButton
                    className={classes.actionIcon}
                    onClick={() => onEditOrgUserClick(contextProps.rowData)}
                >
                    <EditIcon title="Edit" color="primary" aria-label="Edit" />
                </IconButton>
                <IconButton
                    className={classes.actionIcon}
                    onClick={() =>
                        handelDeleteOrgUser(contextProps.rowData.email)
                    }
                >
                    <DeleteIcon
                        title="Delete"
                        color="primary"
                        aria-label="Delete"
                    />
                </IconButton>
                {contextProps.rowData.status !== 'Accepted' ? (
                    <IconButton
                        className={classes.actionIcon}
                        onClick={() => {
                            const { email } = contextProps.rowData;
                            resendInvitation({
                                email
                            });
                        }}
                    >
                        <RefreshIcon
                            title="Resend Invitation"
                            color="primary"
                            aria-label="Resend Invitation"
                        />
                    </IconButton>
                ) : (
                    ''
                )}
            </div>
        );
        return data;
    };

    const gridEmailContextRenderer: (
        cellRendererProps: TableCellProps
    ) => React.ReactNode = contextProps => {
        const { invitationEmail } = contextProps.rowData;
        const data = (
            <div>
                {contextProps.cellData}
                {invitationEmail &&
                    invitationEmail !== contextProps.cellData && (
                        <span> / Invitation Email: {invitationEmail}</span>
                    )}
            </div>
        );
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={contextProps.cellData} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };

    const gridDefaultContextRenderer: (
        cellRendererProps: TableCellProps
    ) => React.ReactNode = contextProps => {
        const data = <div>{contextProps.cellData}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={contextProps.cellData} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };

    const columns: IGridColumn[] = [
        {
            dataKey: 'email',
            width: 300,
            label: 'Email',
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridEmailContextRenderer
        },
        {
            dataKey: 'username',
            width: 180,
            label: 'Username',
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: 'status',
            width: 160,
            label: 'Status',
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: 'role',
            width: 250,
            label: 'Role',
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: 'actions',
            width: 150,
            label: 'Actions',
            flexGrow: 1,
            flexShrink: 0,
            cellContentRenderer: gridActionsContextRenderer
        }
    ];

    return (
        <div className={classes.gridWrapper}>
            <Grid
                rowsPerPageOptions={[10, 25, 50]}
                data={organizationUsers}
                columns={columns}
            />
        </div>
    );
};
OrganizationUsers.propTypes = {
    classes: PropTypes.object.isRequired,
    organizationUsers: PropTypes.array.isRequired,
    handelDeleteOrgUser: PropTypes.func.isRequired,
    onEditOrgUserClick: PropTypes.func.isRequired,
    resendInvitation: PropTypes.func.isRequired
};
export default withStyles(styles)(OrganizationUsers);
