import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { Client, WebSocketTunnel } from 'apache-guacamole-common-js';

import { TERMINAL_URL } from '../../../constants/api-constants';
import { wrapActionCreators } from '../../../utils/container-util';
import { setGlobalMessageError } from '../../../actions/app-actions';

const styles = () => ({
    canvas: {
        // overflowScrolling: 'touch',
        // webkitOverflowScrolling: 'touch',
        // transform: 'translateZ(0)',
        // webkitTransform: 'translateZ(0)',
        // zIndex: 1,
        overflowX: 'auto',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            webkitAppearance: 'none',
            height: 18,
            width: 18
        },
        '&::-webkit-scrollbar-button': {
            display: 'block',
            height: 0,
            width: 0
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 4,
            backgroundColor: 'rgba(0, 0, 0, .5)'
        },
        '&::-webkit-scrollbar-track-piece': {
            background: 'rgba(0,0,0,.15)'
        },
        '&>div>div': {
            overflow: 'hidden'
        }
    }
});
// eslint-disable-next-line
class GuacCanvas extends Component<
    {
        actions: {
            setGlobalMessageError: (message: any) => void
        },
        onGuacClientReady: (guac: any) => void,
        onGuacClientStateChange: (state: any) => void,
        onGuacClientConnectCalled: () => void,
        onCanvasScroll: () => void,
        onError: (error: any) => void,
        classes: {
            canvas: string
        },
        width: number,
        height: number,
        token: string
    },
    {}
> {
    static propTypes = {
        actions: PropTypes.shape({
            setGlobalMessageError: PropTypes.func.isRequired
        }).isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        token: PropTypes.string.isRequired,
        onError: PropTypes.func,
        onGuacClientStateChange: PropTypes.func,
        onGuacClientReady: PropTypes.func,
        // eslint-disable-next-line react/no-unused-prop-types
        onGuacClientConnectCalled: PropTypes.func,
        onCanvasScroll: PropTypes.func,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        classes: PropTypes.shape({
            canvas: PropTypes.string.isRequired
        }).isRequired
    };

    state = {};

    rootDiv = null;

    guacConnected = false;

    guac = null;

    componentDidMount() {
        this.setupGuacClient();
        const { width, height } = this.props;
        if (width !== 0 && height !== 0) {
            this.connectGuacClient(this.props);
        }
    }

    componentDidUpdate(prevProps) {
        const { width: oldWidth, height: oldHeight } = prevProps;
        const { width: newWidth, height: newHeight } = this.props;
        if (this.guac && (oldWidth !== newWidth || oldHeight !== newHeight)) {
            if (newWidth !== 0 && newHeight !== 0) {
                if (!this.guacConnected) {
                    this.connectGuacClient(this.props);
                } else {
                    this.guac.sendSize(newWidth, newHeight);
                }
            }
        }
    }

    componentWillUnmount() {
        if (!this.guac) {
            return;
        }
        this.guac.disconnect();
        this.guacConnected = false;
    }

    onGuacError = () => {
        // eslint-disable-next-line
        const {
            onError,
            actions: { setGlobalMessageError }
        } = this.props;
        setGlobalMessageError('Something went wrong.');
        if (typeof onError === 'function') {
            onError();
        }
        // console.log(error);
    };

    onGuacDisconnect = state => {
        const { onGuacClientStateChange } = this.props;
        if (onGuacClientStateChange) {
            onGuacClientStateChange(state);
        }
    };

    setupGuacClient() {
        const { onGuacClientReady } = this.props;
        this.guac = new Client(new WebSocketTunnel(TERMINAL_URL));
        if (typeof onGuacClientReady === 'function') {
            onGuacClientReady(this.guac);
        }
    }

    handleRef = rootDiv => {
        this.rootDiv = rootDiv;
    };

    handleScroll = ev => {
        const { onCanvasScroll } = this.props;
        if (onCanvasScroll) {
            onCanvasScroll(
                ev.nativeEvent.srcElement.scrollTop,
                ev.nativeEvent.srcElement.scrollLeft
            );
        }
    };

    connectGuacClient(props) {
        const { onGuacClientConnectCalled, token } = props;
        let { width, height } = props;
        const element = this.guac.getDisplay().getElement();
        this.rootDiv.appendChild(element);
        this.guac.onerror = this.onGuacError;
        this.guac.onstatechange = this.onGuacDisconnect;

        if (width === 0) {
            width = 1280;
        }
        if (height === 0) {
            height = 800;
        }

        // Connect
        this.guac.connect(`token=${token}&width=${width}&height=${height}`);
        this.guacConnected = true;
        if (onGuacClientConnectCalled) {
            onGuacClientConnectCalled();
        }
    }

    render() {
        // ev.nativeEvent.srcElement.scrollTop
        const {
            classes: { canvas },
            width,
            height
        } = this.props;
        return (
            <div
                onScroll={this.handleScroll.bind(this)}
                ref={this.handleRef}
                className={canvas}
                style={{ width: `${width}px`, height: `${height}px` }}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = wrapActionCreators({
    setGlobalMessageError
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(GuacCanvas));
