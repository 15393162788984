export default (theme: any) => ({
    contentWrap: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        width: '100%'
    },
    avatarWrap: {
        width: '100px'
    },
    infoWrap: {
        flex: '1 1 0%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between'
    },
    column33: {
        flexBasis: '33.33%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15)
    },
    details: {
        alignItems: 'center'
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});
