// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { connect } from 'react-redux';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../../utils/global-message-util';
import { wrapActionCreators } from '../../../utils/container-util';
import {
    setGlobalMessageError,
    setGlobalMessageSuccess
} from '../../../actions/app-actions';

import { verifyEmailSuccess } from '../../../actions/authentication-actions';

import Loader from '../../../components/LoadingPane/Loader';
import { verifyEmail } from '../../../api/authentication-api';
import { urlToToken } from '../../../utils/http-util';

class VerifyEmail extends React.Component<
    {
        actions: {
            setGlobalMessageError: any,
            setGlobalMessageSuccess: any,
            verifyEmailSuccess: any
        },
        location: { search: string },
        history: { action: string, replace: (path: string) => void }
    },
    {}
> {
    componentDidMount() {
        const {
            location: { search },
            history: { replace },
            actions: { verifyEmailSuccess }
        } = this.props;
        const searchParams = queryString.parse(search || '');
        const tokenPart = searchParams.token;

        let headers;

        try {
            headers = {
                Authorization: `Bearer ${urlToToken(tokenPart)}`
            };
        } catch (e) {
            setGlobalMessageError(
                constructGlobalErrorMessage('The link was corrupted')
            );
            replace('/login');
        }

        verifyEmail(headers).subscribe(
            emailVerified => {
                if (emailVerified) {
                    setGlobalMessageSuccess('Email verified');
                    verifyEmailSuccess();
                    replace('/');
                }
            },
            err => {
                let message = 'Something went wrong';
                if (err) {
                    message = parseErrorMessage(err);
                }
                setGlobalMessageError(constructGlobalErrorMessage(message));
                replace('/login');
            }
        );
    }

    render() {
        return <Loader />;
    }
}

const mapDispatchToProps = wrapActionCreators({
    verifyEmailSuccess,
    setGlobalMessageError,
    setGlobalMessageSuccess
});

VerifyEmail.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    actions: PropTypes.shape({
        setGlobalMessageError: PropTypes.func.isRequired,
        setGlobalMessageSuccess: PropTypes.func.isRequired,
        verifyEmailSuccess: PropTypes.func.isRequired
    }).isRequired
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(VerifyEmail)
);
