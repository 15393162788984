import { merge, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { UPDATE_DEVICES_ORDERING } from '../../constants/action-types';
import { updateDevicesOrdering } from '../../api/device-api';
import { setConnectivityStatus } from '../../actions/app-actions';
import {
    updateDevicesOrderingLoading,
    updateDevicesOrderingSuccess,
    updateDevicesOrderingError
} from '../../actions/device-actions';
import { parseErrorMessage } from '../../utils/global-message-util';

export default action$ =>
    action$.pipe(
        ofType(UPDATE_DEVICES_ORDERING),
        map(action => action.payload),
        switchMap(devices =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(updateDevicesOrderingLoading(true)),
                updateDevicesOrdering(devices).pipe(
                    mergeMap(({ data }) =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            updateDevicesOrderingLoading(false),
                            updateDevicesOrderingSuccess(data)
                        )
                    ),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            updateDevicesOrderingLoading(false),
                            updateDevicesOrderingError(parseErrorMessage(error))
                        )
                    )
                )
            )
        )
    );
