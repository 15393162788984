import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CircularProgress from '@material-ui/core/CircularProgress';

import { getLoggedInUser } from '../../selectors/app-selectors';

import {
    loadToken,
    hasTunnelSettingsForType,
    replaceOrCreateTunnelSettingsForType,
    removeTunnelSettingsForType
} from './token-requests';
import { wrapActionCreators } from '../../utils/container-util';
import { setGlobalMessageError } from '../../actions/app-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    progress: {
        margin: theme.spacing.unit * 2
    }
});

export default (options: { type: string, defaultSettings?: any }) => (
    WrappedComponent: any
) => {
    class WithTokenCheck extends React.Component<
        {
            actions: {
                setGlobalMessageError: (message: any) => void
            },
            classes: {
                root: string,
                keyboardIcon: string,
                keyboard: string
            },
            deviceId: string,
            loggedInUser: any,
            hostname: string,
            port: number,
            deviceName: string
        },
        {
            hasConfig: boolean,
            loading: boolean,
            token: string
        }
    > {
        static propTypes = {
            actions: PropTypes.shape({
                setGlobalMessageError: PropTypes.func.isRequired
            }).isRequired,
            deviceId: PropTypes.string.isRequired,
            loggedInUser: PropTypes.object.isRequired,
            port: PropTypes.number.isRequired,
            hostname: PropTypes.string.isRequired,
            deviceName: PropTypes.string.isRequired,
            classes: PropTypes.shape({
                root: PropTypes.string.isRequired,
                progress: PropTypes.string.isRequired
            }).isRequired
        };

        state = {
            firstTime: true,
            loading: false,
            token: ''
        };

        componentDidMount() {
            const { deviceId, loggedInUser } = this.props;
            this.setState({ firstTime: false, loading: true });
            hasTunnelSettingsForType(
                options.type,
                deviceId,
                loggedInUser._id
            ).subscribe(hasConfig => {
                if (!hasConfig) {
                    this.setState({ hasConfig: false, loading: false });
                } else {
                    this.setState({ hasConfig: true });
                    this.loadToken();
                }
            });
        }

        onSettingsDataReady = (data: { saveConfig: boolean }) => {
            const { hasConfig } = this.state;
            const { saveConfig, ...rest } = data;
            const { deviceId, loggedInUser } = this.props;
            if (saveConfig) {
                const isEmpty = Object.keys(rest).length === 0;
                if (hasConfig && isEmpty) {
                    removeTunnelSettingsForType(
                        options.type,
                        deviceId,
                        loggedInUser._id
                    ).subscribe(() => {
                        this.loadToken();
                    });
                } else if (!isEmpty) {
                    replaceOrCreateTunnelSettingsForType(
                        options.type,
                        deviceId,
                        loggedInUser._id,
                        rest
                    ).subscribe(() => {
                        this.loadToken();
                    });
                } else {
                    // we don't save empty configs
                    this.loadToken();
                }
            } else {
                this.loadToken(rest);
            }
        };

        loadToken(data = {}) {
            const {
                deviceId,
                loggedInUser,
                hostname,
                port,
                actions: { setGlobalMessageError }
            } = this.props;
            const defaultSettings = options.defaultSettings || {};
            const settings = {
                ...defaultSettings,
                hostname,
                port,
                ...data
            };
            loadToken(
                options.type,
                deviceId,
                loggedInUser._id,
                settings
            ).subscribe(
                ({ data: { token } }) => {
                    this.setState({ token, loading: false });
                },
                error => {
                    this.setState({ loading: false });
                    setGlobalMessageError(
                        constructGlobalErrorMessage(parseErrorMessage(error))
                    );
                }
            );
        }

        render() {
            const {
                classes: { root, progress },
                deviceName
            } = this.props;
            const { loading, token, firstTime, hasConfig } = this.state;

            if (loading || firstTime) {
                return (
                    <div className={root}>
                        <CircularProgress
                            className={progress}
                            color="secondary"
                        />
                    </div>
                );
            }

            return (
                <WrappedComponent
                    token={token}
                    hasConfig={hasConfig}
                    deviceName={deviceName}
                    onSettingsDataReady={this.onSettingsDataReady}
                />
            );
        }
    }

    const mapStateToProps = createStructuredSelector({
        loggedInUser: getLoggedInUser()
    });

    const mapDispatchToProps = wrapActionCreators({
        setGlobalMessageError
    });

    WithTokenCheck.displayName = `WithToken(${WrappedComponent.displayName ||
        WrappedComponent.name})`;
    WithTokenCheck.WrappedComponent = WrappedComponent;
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(WithTokenCheck));
};
