export const ALL_TUNNEL_TYPES = [
    'http',
    'custom',
    'ssh',
    'rdp',
    'vnc',
    'proxy'
];
export const DEFAULT_LOCAL_PORTS = {
    http: '',
    rdp: '3389',
    ssh: '22',
    vnc: '5900',
    proxy: '3128',
    custom: ''
};
export const INFO_TEXT_ABOUT_TUNNEL_RELOAD = `The tunnel is currently started, we will reload it automatically with new settings.`;
