import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'react-router-redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import rootReducer from '../reducers';
import rootEpic from '../epics';
import browserHistory from './history';
import initialState from '../reducers/initial-state';

// Build the middleware for intercepting and dispatching navigation actions
const routeMiddleware = routerMiddleware(browserHistory);
const epicMiddleware = createEpicMiddleware();

function configureStoreProd(state = initialState) {
    const middlewares = [epicMiddleware, routeMiddleware];
    const store = createStore(
        rootReducer,
        state,
        compose(applyMiddleware(...middlewares))
    );
    epicMiddleware.run(rootEpic);
    return store;
}

function configureStoreDev(state = initialState) {
    const middlewares = [
        // Add other middleware on this line...

        // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
        reduxImmutableStateInvariant(),

        epicMiddleware,
        routeMiddleware
    ];
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        rootReducer,
        state,
        composeEnhancers(applyMiddleware(...middlewares))
    );
    epicMiddleware.run(rootEpic);

    if (module.hot && typeof module.hot.accept === 'function') {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            const nextReducer = require('../reducers').default; // eslint-disable-line global-require
            store.replaceReducer(nextReducer);
        });
    }

    return store;
}

const configureStore =
    process.env.NODE_ENV === 'production'
        ? configureStoreProd
        : configureStoreDev;

export default configureStore;
