// @flow

// eslint-disable-next-line
const MD5 = require('crypto-js/md5');

const GRAVATAR_PREFIX = 'https://gravatar.com/avatar/';
const GRAVATAR_QUERY_DEFAULT = 'd=404';

let loggedInUserHash = null;

// eslint-disable-next-line
export function getGravatarUrl(email: string, size: number = 40) {
    return `${GRAVATAR_PREFIX +
        MD5(email).toString()}?${GRAVATAR_QUERY_DEFAULT}&s=${size}`;
}

export function getLoggedInUserIdHash() {
    return loggedInUserHash;
}

export function constructLoggedInUserIdHash(id: string) {
    loggedInUserHash = MD5(id).toString();
    return loggedInUserHash;
}
