import { isValidTunnelName, isValidLocalPort } from './validations';

export const validateNameField = name => {
    let nameError = '';
    if (!name || !name.trim()) {
        nameError = 'Please enter Tunnel name';
    } else if (!isValidTunnelName(name)) {
        nameError =
            'Tunnel name should be only numbers, letters,  "-" and "_".';
    }
    return nameError;
};

export const validateDeviceField = deviceId =>
    deviceId ? '' : 'Please select device';
export const validateTypeField = type => (type ? '' : 'Please select type');
export const validateLocalPortField = localPort => {
    let localPortError = '';
    if (!localPort && localPort !== 0) {
        localPortError = 'Please enter local port';
    } else if (!isValidLocalPort(localPort)) {
        localPortError = 'Local port should be integer between 0 and 65535';
    }
    return localPortError;
};
export const validateIpAddress = value => {
    const regExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;
    return regExp.test(value);
};
