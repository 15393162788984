// @flow
import {
    api,
    makeDelete,
    makeGet,
    makePatch,
    makePost
} from '../utils/http-util';

const API_URL = {
    GET_USERS: () => api`v1/users`,
    ORG_USERS: () => api`v1/organization/users`,
    GET_USERS_BY_EMAILS: () => api`v1/users/by-emails`,
    GET_USER: id => api`v1/users/${id}`,
    MAKE_GROUP_AS_DEFAULT: () => api`v1/me`,
    SEND_ACTIVATION_EMAIL: () => api`v1/me/send-activation-email`,
    ADD_ORG_USER: () => api`v1/users/organization`,
    ACTIVATE_ORG_USER: () => api`v1/activate-org-user`,
    RESEND_INVITATION_ORG_USER: () => api`v1/users/resend-invitation`
};

export function getUsers() {
    return makeGet(API_URL.GET_USERS());
}

export function getUser(id: string) {
    return makeGet(API_URL.GET_USER(id));
}
export function getOrganizationUser() {
    return makeGet(API_URL.ORG_USERS());
}
export function deleteOrganizationUser(email: string) {
    return makeDelete(API_URL.ORG_USERS(), { email });
}

export function inviteOrgUser(data) {
    return makePost(API_URL.ADD_ORG_USER(), data);
}

export function resendInvitationOrgUser(data) {
    return makePost(API_URL.RESEND_INVITATION_ORG_USER(), data);
}

export function getUsersByEmail(emails: string[]) {
    return makePost(API_URL.GET_USERS_BY_EMAILS(), emails);
}

export function makeGroupAsDefault(_id: string) {
    return makePatch(API_URL.MAKE_GROUP_AS_DEFAULT(), {
        defaultGroup: _id
    });
}

export function sendAccountActivationEmail() {
    return makeGet(API_URL.SEND_ACTIVATION_EMAIL());
}
export function activateOrgUser(data) {
    return makePost(API_URL.ACTIVATE_ORG_USER(), data);
}
