// @flow

export default (theme: any) => ({
    buttonCls: {
        margin: 0
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center'
    }
});
