import { createSelector } from 'reselect';
import { find } from 'lodash';

export const selectLocalState = () => state => state.devices;

export const selectDevicesStatuses = () =>
    createSelector(
        selectLocalState(),
        ({ statuses }) => statuses
    );

export const selectDevicesStatusesData = () =>
    createSelector(
        selectDevicesStatuses(),
        ({ data }) => data
    );

export const selectDevicesStatusesLoading = () =>
    createSelector(
        selectDevicesStatuses(),
        ({ loading }) => loading
    );

export const selectLoading = () =>
    createSelector(
        selectLocalState(),
        ({ loading }) => loading
    );

export const getDevicesLoading = () =>
    createSelector(
        selectLoading(),
        ({ get }) => get
    );

export const addDeviceLoading = () =>
    createSelector(
        selectLoading(),
        ({ add }) => add
    );

export const updateDeviceLoading = () =>
    createSelector(
        selectLoading(),
        ({ update }) => update
    );

export const selectError = () =>
    createSelector(
        selectLocalState(),
        ({ error }) => error
    );

export const getDevicesError = () =>
    createSelector(
        selectError(),
        ({ get }) => get
    );

export const selectDevices = () =>
    createSelector(
        selectLocalState(),
        selectDevicesStatusesData(),
        ({ data }, statusesData) =>
            data.map(device => {
                const id = device._id.toString();
                const msg = statusesData[id];
                if (msg) {
                    let lastPingMessage;
                    try {
                        lastPingMessage = JSON.parse(msg);
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(
                            `Invalid ping message for device id: ${id}: message ${msg}`
                        );
                    }
                    if (lastPingMessage) {
                        return {
                            ...device,
                            socketData: lastPingMessage
                        };
                    }
                }
                return device;
            })
    );

export const getTotalCount = () =>
    createSelector(
        selectLocalState(),
        ({ count }) => count
    );

export const getLastAddedDeviceId = () =>
    createSelector(
        selectLocalState(),
        ({ lastAddedDeviceId }) => lastAddedDeviceId
    );

export const getLastAddedDevice = () =>
    createSelector(
        selectDevices(),
        getLastAddedDeviceId(),
        (data, deviceId) => find(data, { _id: deviceId })
    );
