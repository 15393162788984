// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
    Hidden,
    DialogContent,
    DialogTitle,
    Dialog,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab/Fab';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import { updateOrgUserAccess } from '../../api/organization-api';
import {
    getOrganizationUser,
    inviteOrgUser,
    deleteOrganizationUser,
    resendInvitationOrgUser
} from '../../api/users-api';
import EditInviteUser from './EditInviteUser';
import OrganizationUsers from './OrganizationUsers';
import { wrapActionCreators } from '../../utils/container-util';
import { setGlobalMessageError } from '../../actions/app-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

const styles = {
    root: {
        display: 'flex',
        // display: 'grid',
        flexDirection: 'column',
        maxHeight: `calc(100vh - 64px)`,
        height: `calc(100vh - 64px)`,
        width: '100vw'
    },
    addButton: {
        position: 'fixed',
        right: 16,
        bottom: 16
    },
    addButtonMobile: {
        position: 'fixed',
        right: 5,
        bottom: 5
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        paddingTop: 5
    },
    dialogActions: {
        padding: '13px 20px',
        margin: 0
    },
    organizationList: {
        width: 150,
        overflowY: 'auto',
        overflowX: 'none'
    },
    noUsersContent: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '28px',
        justifyContent: 'center',
        flex: 1
    },
    flex_1: {
        flex: 1
    }
};

const Organization = ({ classes, actions: { setGlobalMessageError } }) => {
    const [doGetOraganizationUsers, setDoGetOraganizationUsers] = useState(
        true
    );
    const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState(false);
    const [organizationUsers, setOrganizationUsers] = useState([]);
    const [editInviteUserPopUpMode, setEditInviteUserPopUpMode] = useState('');
    const [userForEdit, setUserForEdit] = useState({});
    const [emailForDeleteUser, setEmailForDeleteUser] = useState('');

    useEffect(
        () => {
            let canceled = false;
            getOrganizationUser().subscribe(
                data => {
                    if (!canceled) {
                        setOrganizationUsers(data.data);
                    }
                },
                error => {
                    setGlobalMessageError(
                        constructGlobalErrorMessage(parseErrorMessage(error))
                    );
                }
            );
            return () => {
                canceled = true;
            };
        },
        [doGetOraganizationUsers]
    );

    const onInviteUserSave = data => {
        setIsInviteUserDialogOpen(false);
        inviteOrgUser(data).subscribe(
            data => {
                if (data && data.data) {
                    setDoGetOraganizationUsers(!doGetOraganizationUsers);
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
    };

    const resendInvitation = data => {
        resendInvitationOrgUser(data).subscribe(
            data => {},
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
    };

    const onDeleteOrgUserClick = email => {
        setEmailForDeleteUser(email);
    };

    const handelDeleteOrgUser = () => {
        deleteOrganizationUser(emailForDeleteUser).subscribe(
            data => {
                if (data && data.data) {
                    setEmailForDeleteUser('');
                    setDoGetOraganizationUsers(!doGetOraganizationUsers);
                }
            },
            error => {
                setEmailForDeleteUser('');
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
    };

    const onEditOrgUserClick = user => {
        setUserForEdit(user);
        setEditInviteUserPopUpMode('edit');
        setIsInviteUserDialogOpen(true);
    };

    const onEditOrgUserSave = data => {
        setEditInviteUserPopUpMode('');
        setIsInviteUserDialogOpen(false);
        setUserForEdit({});
        updateOrgUserAccess({ userId: userForEdit.userId, ...data }).subscribe(
            d => {},
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
    };

    return (
        <div className={classes.root}>
            <OrganizationUsers
                organizationUsers={organizationUsers}
                resendInvitation={resendInvitation}
                handelDeleteOrgUser={onDeleteOrgUserClick}
                onEditOrgUserClick={onEditOrgUserClick}
            />
            <Hidden mdUp>
                <Fab
                    color="primary"
                    aria-label="Add"
                    className={classes.addButtonMobile}
                    onClick={() => {
                        setEditInviteUserPopUpMode('invite');
                        setIsInviteUserDialogOpen(true);
                    }}
                >
                    <AddIcon />
                </Fab>
            </Hidden>
            <Hidden smDown>
                <Fab
                    color="primary"
                    aria-label="Add"
                    className={classes.addButton}
                    onClick={() => {
                        setEditInviteUserPopUpMode('invite');
                        setIsInviteUserDialogOpen(true);
                    }}
                >
                    <AddIcon />
                </Fab>
            </Hidden>

            {isInviteUserDialogOpen && (
                <EditInviteUser
                    userForEdit={userForEdit}
                    mode={editInviteUserPopUpMode}
                    onInvite={onInviteUserSave}
                    onEdit={onEditOrgUserSave}
                    close={() => {
                        setEditInviteUserPopUpMode('');
                        setUserForEdit({});
                        setIsInviteUserDialogOpen(false);
                    }}
                />
            )}
            {emailForDeleteUser && (
                <Dialog
                    open
                    onClose={() => setEmailForDeleteUser('')}
                    aria-labelledby="delete-device-title"
                >
                    <DialogTitle>Delete user</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure want to delete user with{' '}
                            <strong>{emailForDeleteUser}</strong> email?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handelDeleteOrgUser}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setEmailForDeleteUser('')}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};
Organization.propTypes = {
    classes: PropTypes.object.isRequired,
    actions: PropTypes.shape({
        setGlobalMessageError: PropTypes.func.isRequired
    }).isRequired
};

const mapDispatchToProps = wrapActionCreators({
    setGlobalMessageError
});

export default withStyles(styles)(
    connect(
        null,
        mapDispatchToProps
    )(Organization)
);
