// @flow
import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Signup from '../../../containers/Authentication/Signup/GetDevice';
import Login from '../../../containers/Authentication/Login';

const styles = () => ({
    wrapper: {
        flex: '1 1 0%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    titleText: {
        margin: 20,
        textAlign: 'center'
    },
    cardViewChangeBtn: {
        margin: '0 10px'
    }
});

class SignUpAndChangeDeviceOwner extends React.Component<
    {
        classes: {
            wrapper: string,
            root: string,
            content: string,
            actionsBar: string,
            flex1: string,
            mainActions: string,
            formControl: string,
            titleText: string,
            privacy: string,
            contentBody: string,
            colorGreen: string,
            cardsWrapper: string,
            cardViewChangeBtn: string
        },
        newOwnerEmail: string,
        oldOwnerEmail: string,
        deviceName: string,
        token: string
    },
    {
        isSignUpView: boolean
    }
> {
    state = {
        isSignUpView: false
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    handleChangeCardViewButtonClick = () => {
        const { isSignUpView } = this.state;
        this.setState({ isSignUpView: !isSignUpView });
    };

    render() {
        const {
            classes,
            newOwnerEmail,
            deviceName,
            oldOwnerEmail,
            token
        } = this.props;
        const { isSignUpView } = this.state;
        return (
            <div className={classes.wrapper}>
                <div className={classes.titleText} color="inherit">
                    <p>
                        User with {oldOwnerEmail} email wants to give you (
                        {newOwnerEmail}) `{deviceName}` device.
                    </p>
                    <p>
                        Please
                        <Button
                            variant="text"
                            color="primary"
                            disabled={isSignUpView}
                            className={classes.cardViewChangeBtn}
                            onClick={this.handleChangeCardViewButtonClick}
                        >
                            SignUp
                        </Button>
                        or
                        <Button
                            variant="text"
                            color="primary"
                            disabled={!isSignUpView}
                            className={classes.cardViewChangeBtn}
                            onClick={this.handleChangeCardViewButtonClick}
                        >
                            Login
                        </Button>
                        to get device.
                    </p>
                </div>
                {isSignUpView ? (
                    <Signup
                        mode="forDeviceOwnership"
                        newOwnerEmail={newOwnerEmail}
                        token={token}
                    />
                ) : (
                    <Login
                        mode="forDeviceOwnership"
                        newOwnerEmail={newOwnerEmail}
                        token={token}
                    />
                )}
            </div>
        );
    }
}

export default withStyles(styles)(SignUpAndChangeDeviceOwner);
