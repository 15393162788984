// @flow
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Terminal from '../Terminal';
import WithHeader from './WithHeader';
import withAuthGuard from '../WithAuthenticationGuard';

const styles = () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    }
});

const Main = ({ classes: { root } }) => (
    <div className={root}>
        <Switch>
            <Route path="/terminal/:type" component={Terminal} />
            <Route path="/" component={WithHeader} />
        </Switch>
    </div>
);

Main.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired
    }).isRequired
};

export default withAuthGuard()(withStyles(styles)(Main));
