// @flow
import { api, makeGet, makePost } from '../utils/http-util';

const API_URL = {
    GET_COUPON: code => api`v1/coupon/${code}`,
    APPLY_COUPON: code => api`v1/coupon/activate/${code}`
};

export function getCouponCode(code) {
    return makeGet(API_URL.GET_COUPON(code));
}

export function applyCouponCode(code) {
    return makePost(API_URL.APPLY_COUPON(code));
}
