export default (theme: any) => ({
    contentWrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between'
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});
