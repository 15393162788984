export default () => ({
    paper: {
        width: '600px'
    },
    contentWrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingTop: 5
    }
});
