import { createAction } from 'redux-actions';
import {
    GET_DEVICE_USERS,
    GET_DEVICE_USERS_LOADING,
    GET_DEVICE_USERS_SUCCESS,
    UPDATE_USER_TO_DEVICE_ACCESS,
    UPDATE_USER_TO_DEVICE_ACCESS_LOADING,
    UPDATE_USER_TO_DEVICE_ACCESS_SUCCESS,
    UPDATE_USER_TO_DEVICE_ACCESS_ERROR,
    ADD_OR_REMOVE_USERS_TO_DEVICE,
    ADD_OR_REMOVE_USERS_TO_DEVICE_LOADING,
    ADD_OR_REMOVE_USERS_TO_DEVICE_SUCCESS,
    REMOVE_CURRENT_USER_FROM_DEVICE,
    REMOVE_CURRENT_USER_FROM_DEVICE_LOADING,
    REMOVE_CURRENT_USER_FROM_DEVICE_SUCCESS,
    CLEAR_DEVICE_USERS
} from '../constants/action-types';

export const clearDeviceUsers = createAction(CLEAR_DEVICE_USERS);

export const getDeviceUsers = createAction(GET_DEVICE_USERS);
export const getDeviceUsersLoading = createAction(GET_DEVICE_USERS_LOADING);
export const getDeviceUsersSuccess = createAction(GET_DEVICE_USERS_SUCCESS);

export const addOrRemoveUsersToDevice = createAction(
    ADD_OR_REMOVE_USERS_TO_DEVICE
);
export const addOrRemoveUsersToDeviceLoading = createAction(
    ADD_OR_REMOVE_USERS_TO_DEVICE_LOADING
);
export const addOrRemoveUsersToDeviceSuccess = createAction(
    ADD_OR_REMOVE_USERS_TO_DEVICE_SUCCESS
);

export const updateUserToDeviceAccess = createAction(
    UPDATE_USER_TO_DEVICE_ACCESS
);
export const updateUserToDeviceAccessLoading = createAction(
    UPDATE_USER_TO_DEVICE_ACCESS_LOADING
);
export const updateUserToDeviceAccessSuccess = createAction(
    UPDATE_USER_TO_DEVICE_ACCESS_SUCCESS
);
export const updateUserToDeviceAccessError = createAction(
    UPDATE_USER_TO_DEVICE_ACCESS_ERROR
);

export const removeCurrentUserFromDevice = createAction(
    REMOVE_CURRENT_USER_FROM_DEVICE
);

export const removeCurrentUserFromDeviceLoading = createAction(
    REMOVE_CURRENT_USER_FROM_DEVICE_LOADING
);
export const removeCurrentUserFromDeviceSuccess = createAction(
    REMOVE_CURRENT_USER_FROM_DEVICE_SUCCESS
);
