import { merge, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { GET_DEVICE_USERS } from '../../constants/action-types';
import { getDeviceUsers } from '../../api/user-device-api';
import {
    setConnectivityStatus,
    setGlobalMessageError
} from '../../actions/app-actions';
import {
    getDeviceUsersLoading,
    getDeviceUsersSuccess
} from '../../actions/user-device-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default action$ =>
    action$.pipe(
        ofType(GET_DEVICE_USERS),
        map(action => action.payload),
        switchMap(deviceId =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(getDeviceUsersLoading(true)),
                getDeviceUsers(deviceId).pipe(
                    mergeMap(userDevices =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            getDeviceUsersLoading(false),
                            getDeviceUsersSuccess(userDevices)
                        )
                    ),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            getDeviceUsersLoading(false),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            )
        )
    );
