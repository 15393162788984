// @flow
import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import decodeJwt from '../../utils/jwt-utils';
import { urlToToken } from '../../utils/http-util';

import Login from '../../containers/Authentication/Login';
import SignupHOC from './Signup';

const styles = () => ({
    wrapper: {
        flex: '1 1 0%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    titleText: {
        margin: 20,
        textAlign: 'center'
    },
    cardViewChangeBtn: {
        margin: '0 10px'
    }
});

class SignUpAndApprove extends React.Component<
    {
        classes: {
            wrapper: string,
            root: string,
            content: string,
            actionsBar: string,
            flex1: string,
            mainActions: string,
            formControl: string,
            titleText: string,
            privacy: string,
            contentBody: string,
            colorGreen: string,
            cardsWrapper: string,
            cardViewChangeBtn: string
        },
        token: string
    },
    {
        isSignUpView: boolean
    }
> {
    state = {
        isSignUpView: false
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    handleChangeCardViewButtonClick = () => {
        const { isSignUpView } = this.state;
        this.setState({ isSignUpView: !isSignUpView });
    };

    render() {
        const { classes, token } = this.props;
        const { data: dataFromToken } = decodeJwt(
            urlToToken(token),
            null,
            true
        );
        const { isSignUpView } = this.state;
        return (
            <div className={classes.wrapper}>
                <div className={classes.titleText} color="inherit">
                    Accept Invitation to participate in{' '}
                    {dataFromToken.organizationName} organization
                    <p>
                        Please
                        <Button
                            variant="text"
                            color="primary"
                            disabled={isSignUpView}
                            className={classes.cardViewChangeBtn}
                            onClick={this.handleChangeCardViewButtonClick}
                        >
                            SignUp
                        </Button>
                        or
                        <Button
                            variant="text"
                            color="primary"
                            disabled={!isSignUpView}
                            className={classes.cardViewChangeBtn}
                            onClick={this.handleChangeCardViewButtonClick}
                        >
                            Login
                        </Button>
                        to get device.
                    </p>
                </div>
                {isSignUpView ? (
                    <SignupHOC
                        mode="forDeviceOwnership"
                        newOwnerEmail={dataFromToken.email}
                        token={token}
                    />
                ) : (
                    <Login mode="forDeviceOwnership" token={token} />
                )}
            </div>
        );
    }
}

export default withStyles(styles)(SignUpAndApprove);
