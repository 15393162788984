// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Hidden from '@material-ui/core/Hidden';
import {
    getDevicesTourDetails,
    getTunnelsTourDetails,
    getLoggedInUser
} from '../../selectors/app-selectors';
import {
    logout,
    changeOrganization
} from '../../actions/authentication-actions';
import OptimizedHeader from '../../components/Header/Optimized';
import Header from '../../components/Header';
import { wrapActionCreators } from '../../utils/container-util';
import type { User } from '../../types/user';
import {
    setDevicesTourVisibility,
    setTunnelsTourVisibility,
    setGlobalMessageError
} from '../../actions/app-actions';
import { isComponentAllowed } from '../Authorities';
import {
    ROUTES_DEVICES_AUTH,
    ROUTES_TUNNELS_AUTH
} from '../../constants/authorities';

class HeaderHOC extends React.Component<
    {
        loggedInUser: User,
        actions: {
            logout: () => void,
            changeOrganization: () => void,
            setGlobalMessageError: (message: any) => void
        },
        history: { replace: (path: string) => void }
    },
    {}
> {
    static propTypes = {
        loggedInUser: PropTypes.object.isRequired,
        devicesTourDetails: PropTypes.object.isRequired,
        tunnelsTourDetails: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            logout: PropTypes.func.isRequired,
            changeOrganization: PropTypes.func.isRequired,
            setGlobalMessageError: PropTypes.func.isRequired,
            setDevicesTourVisibility: PropTypes.func.isRequired,
            setTunnelsTourVisibility: PropTypes.func.isRequired
        }),
        history: PropTypes.shape({
            replace: PropTypes.func.isRequired
        }).isRequired
    };

    constructor(props) {
        super(props);
        const locationMap = [];
        if (isComponentAllowed(ROUTES_DEVICES_AUTH, props.loggedInUser)) {
            locationMap.push('/devices');
        }
        if (isComponentAllowed(ROUTES_TUNNELS_AUTH, props.loggedInUser)) {
            locationMap.push('/tunnels');
        }
        this.state = {
            locationMap
        };
    }

    handleChange = value => {
        const { history } = this.props;
        const { locationMap } = this.state;
        // This is help page
        if (value === 2) {
            window.open('https://tunnelin.com/blog', '_blank');
        }

        history.replace(locationMap[value]);
    };

    render() {
        const {
            loggedInUser,
            actions,
            history,
            devicesTourDetails,
            tunnelsTourDetails
        } = this.props;
        const { locationMap } = this.state;
        let activeTabIndex = false;
        let menuTitle = '';
        locationMap.forEach((lc, index) => {
            if (history.location.pathname.indexOf(lc) === 0) {
                activeTabIndex = index;
                menuTitle = lc.substring(1);
            }
            return activeTabIndex !== false;
        });
        if (activeTabIndex === false) {
            if (history.location.pathname.includes('users')) {
                menuTitle = 'Users';
            } else if (history.location.pathname.includes('usage')) {
                menuTitle = 'Usage';
            }
        }
        return (
            <div>
                <Hidden mdUp>
                    <OptimizedHeader
                        loggedInUser={loggedInUser}
                        actions={actions}
                        devicesTourDetails={devicesTourDetails}
                        tunnelsTourDetails={tunnelsTourDetails}
                        changeOrganization={changeOrganization}
                        menuTitle={menuTitle}
                    />
                </Hidden>
                <Hidden smDown>
                    <Header
                        handleChange={this.handleChange}
                        loggedInUser={loggedInUser}
                        devicesTourDetails={devicesTourDetails}
                        tunnelsTourDetails={tunnelsTourDetails}
                        actions={actions}
                        activeTabIndex={activeTabIndex}
                        changeOrganization={changeOrganization}
                    />
                </Hidden>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser(),
    devicesTourDetails: getDevicesTourDetails(),
    tunnelsTourDetails: getTunnelsTourDetails()
});

const mapDispatchToProps = wrapActionCreators({
    logout,
    changeOrganization,
    setGlobalMessageError,
    setDevicesTourVisibility,
    setTunnelsTourVisibility
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(HeaderHOC)
);
