import { merge, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { DELETE_GROUP } from '../../constants/action-types';
import { deleteGroup } from '../../api/group-api';
import {
    setConnectivityStatus,
    setGlobalMessageError,
    setGlobalMessageSuccess
} from '../../actions/app-actions';
import {
    deleteGroupLoading,
    deleteGroupSuccess
} from '../../actions/group-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default (action$, state) =>
    action$.pipe(
        ofType(DELETE_GROUP),
        map(action => action.payload),
        switchMap(groupId =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(deleteGroupLoading({ id: groupId, loading: true })),
                deleteGroup(groupId).pipe(
                    mergeMap(() => {
                        const {
                            value: {
                                groups: { data: groups }
                            }
                        } = state;
                        const deletedGroup = groups.find(
                            group => group._id === groupId
                        );
                        return of(
                            setConnectivityStatus({ loading: false }),
                            deleteGroupLoading({
                                id: groupId,
                                loading: false
                            }),
                            deleteGroupSuccess(groupId),
                            setGlobalMessageSuccess(
                                deletedGroup
                                    ? `${
                                          deletedGroup.name
                                      } deleted successfully.`
                                    : ''
                            )
                        );
                    }),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            deleteGroupLoading({
                                id: groupId,
                                loading: false
                            }),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            )
        )
    );
