import React from 'react';
import { Helmet } from 'react-helmet';
import { MAIN_TITLE } from '../constants/api-constants';

function hasByName(arr = [], name) {
    return arr.some(item => item.name === name);
}

export default options => Component => {
    const opts = { ...options };
    const { og = true, twitter = true, meta = [], image, applyTitlePrefix, description, ...restOptions } = opts;
    let { title, 'og:title': ogTitle, 'og:description': ogDescription, 'twitter:title': twitterTitle, 'twitter:description': twitterDescription, ...helmetOptions } = restOptions;
    if (applyTitlePrefix !== false) {
        title = `${MAIN_TITLE}  ${title}`;
    }
    const ogMetas = [];
    const htmlAttrs = {
        lang: "en-US"
    };
    if (og) {
        if (ogDescription === undefined && description !== undefined) {
            ogDescription = description;
        }
        if (ogTitle === undefined && title !== undefined) {
            ogTitle = title;
        }
        if (!hasByName(meta, "og:locale")) {
            ogMetas.push( {name: 'og:locale', content: 'en_US'});
        }
        if (!hasByName(meta, "og:type")) {
            ogMetas.push( {name: 'og:type', content: 'site'});
        }
        if (!hasByName(meta, "og:site_name")) {
            ogMetas.push( {name: 'og:site_name', content: 'Tunnel In'});
        }
        if (image) {
            ogMetas.push(...[
                {
                    name: 'og:image',
                    content: image.src
                },
                {
                    name: 'og:image:secure_url',
                    content: image.src
                },
                {
                    name: 'og:image:width',
                    content: image.width
                },
                {
                    name: 'og:image:height',
                    content: image.height
                },
                {
                    name: 'og:image:alt',
                    content: image.alt || title
                },
                {
                    name: 'og:image:type',
                    content: image.type
                }
            ])
        }
        htmlAttrs.prefix = "og: https://ogp.me/ns#";
    }
    if (twitter) {
        if (twitterDescription === undefined && description !== undefined) {
            twitterDescription = description;
        }
        if (twitterTitle === undefined && title !== undefined) {
            twitterTitle = title;
        }
    }


    const WithHelmet = props => {
        return (
            <>
                <Helmet {...helmetOptions}>
                    <html {...htmlAttrs} />
                    <title>{title}</title>
                    {description !== undefined ?
                        <meta name="description" content={description} /> : ''
                    }
                    {twitter ? <meta name="twitter:card" content="summary_large_image" />: ''}
                    {twitterTitle !== undefined ? <meta name="twitter:title" content={twitterTitle} /> : ''}
                    {twitterDescription !== undefined ?
                        <meta name="twitter:description" content={twitterDescription} /> : ''
                    }
                    {twitter && image ? <meta name="twitter:image" content={image.src} />: ''}
                    {ogTitle !== undefined ? <meta name="og:title" content={ogTitle} /> : ''}
                    {ogDescription !== undefined ?
                        <meta name="og:description" content={ogDescription} /> : ''
                    }
                    {ogMetas.map(({ name, content }) => {
                        return <meta key={name} name={name} content={content} />
                    })}
                    {meta.map(({ name, content }) => {
                        return <meta key={name} name={name} content={content} />
                    })}
                </Helmet>
                <Component {...props} />
            </>
        );
    };
    return WithHelmet;
};
