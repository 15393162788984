import { IconButton, Theme, withStyles } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import createStyles from '@material-ui/core/styles/createStyles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import TextField from '@material-ui/core/es/TextField/TextField';
import type { IGridToolbar } from '../../../interface/Grid';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingRight: 5,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            borderBottom: '1px solid rgba(224, 224, 224, 1)'
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                      color: theme.palette.primary.dark,
                      backgroundColor: lighten(theme.palette.primary.main, 0.85)
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.secondary.dark
                  },
        spacer: {
            flex: '1 1 100%'
        },
        actions: {
            color: theme.palette.text.secondary,
            display: 'flex'
        },
        title: {
            flex: '0 0 auto'
        },
        searchTextField: {
            margin: '0 20px',
            minWidth: 200
        }
    });

const GridToolbar = ({
    classes,
    numSelected,
    title = '',
    searchValue,
    onSearchValueChange,
    selectedGridToolbarElements
}: IGridToolbar) => (
    <Toolbar
        className={classNames(classes.root, {
            [classes.highlight]: numSelected > 0
        })}
    >
        <div className={classes.title}>
            {numSelected > 0 ? (
                <Typography color="inherit" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography variant="h6" id="tableTitle">
                    {title}
                </Typography>
            )}
        </div>
        <TextField
            label="Search"
            className={classes.searchTextField}
            value={searchValue}
            onChange={onSearchValueChange}
        />
        <div className={classes.spacer} />
        <div className={classes.actions}>
            {numSelected > 0 ? (
                <>
                    {selectedGridToolbarElements}
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="Filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    </Toolbar>
);

export default withStyles(styles)(GridToolbar);
