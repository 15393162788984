import React from 'react';
import { JssProvider } from 'react-jss';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import getPageContext from '../styles/createContext';

// Apply some reset
const styles = theme => ({
    '@global': {
        html: {
            background: theme.palette.background.default,
            WebkitFontSmoothing: 'antialiased', // Antialiasing.
            MozOsxFontSmoothing: 'grayscale' // Antialiasing.
        },
        body: {
            margin: 0
        }
    }
});

let AppWrapper = props => props.children;

AppWrapper = withStyles(styles)(AppWrapper);

function withRoot(Component) {
    class WithRoot extends React.Component {
        constructor(props) {
            super(props);
            this.pageContext = getPageContext();
        }

        componentDidMount() {
            // Remove the server-side injected CSS.
            const jssStyles = document.querySelector('#jss-server-side');
            if (jssStyles && jssStyles.parentNode) {
                jssStyles.parentNode.removeChild(jssStyles);
            }
        }

        render() {
            const { pageContext } = this;
            return (
                /* Wrap every page in Jss and Theme providers */
                <JssProvider
                    registry={pageContext.sheetsRegistry}
                    generateClassName={pageContext.generateClassName}
                >
                    {/* MuiThemeProvider makes the theme available down the React
              tree thanks to React context. */}
                    <MuiThemeProvider
                        theme={pageContext.theme}
                        sheetsManager={pageContext.sheetsManager}
                    >
                        <CssBaseline />
                        <AppWrapper>
                            <Component
                                pageContext={pageContext}
                                {...this.props}
                            />
                        </AppWrapper>
                    </MuiThemeProvider>
                </JssProvider>
            );
        }
    }

    return WithRoot;
}

export default withRoot;
