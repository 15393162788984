import { useEffect, useState } from 'react';

export default function useElementSize(el) {
    const [elementWidth, setElementWidth] = useState(
        (el && el.offsetWidth) || 0
    );
    const [elementHeight, setElementHeight] = useState(
        (el && el.offsetHeight) || 0
    );

    useEffect(
        () => {
            const handleResize = () => {
                setElementWidth((el && el.offsetWidth) || 0);
                setElementHeight((el && el.offsetHeight) || 0);
            };
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        },
        [el]
    );
    useEffect(
        () => {
            setElementWidth((el && el.offsetWidth) || 0);
            setElementHeight((el && el.offsetHeight) || 0);
        },
        [el]
    );
    return { elementWidth, elementHeight };
}
