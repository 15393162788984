// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getLoggedInUser } from '../../../selectors/app-selectors';

import ActivateAccount from '../../../components/Users/ActivateAccount';
import { wrapActionCreators } from '../../../utils/container-util';
import { sendActivationEmail } from '../../../actions/users-actions';

const ActivateAccountHOC = ({ loggedInUser, actions }) => (
    <ActivateAccount loggedInUser={loggedInUser} actions={actions} />
);

ActivateAccountHOC.propTypes = {
    loggedInUser: PropTypes.object.isRequired,
    actions: PropTypes.shape({
        sendActivationEmail: PropTypes.func.isRequired
    }).isRequired
};

const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser()
});

const mapDispatchToProps = wrapActionCreators({
    sendActivationEmail
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivateAccountHOC);
