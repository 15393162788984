// @flow
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import type { User } from '../../../types/user';

import styles from './styles';
class TwoFactorAuthenticationOptions extends Component<
    {
        actions: {
            toggleTwoFactorAuth: (enabled: boolean) => void,
            regenerateTwoFactorAuthQR: () => void
        },
        loggedInUser: User,
        onCancel: () => void,
        expanded: boolean,
        onExpansionPanelChange: () => void,
        classes: any,
        toggleTwoFactorAuthLoading: boolean,
        regenerateTwoFactorAuthQRLoading: boolean
    },
    {
        enabled: boolean
    }
> {
    constructor(props) {
        super(props);
        this.state = {
            enabled:
                props.loggedInUser.twoFactorAuth &&
                props.loggedInUser.twoFactorAuth.enabled
        };
    }

    render() {
        const {
            classes,
            onCancel,
            expanded,
            onExpansionPanelChange,
            actions: { toggleTwoFactorAuth, regenerateTwoFactorAuthQR },
            loggedInUser,
            toggleTwoFactorAuthLoading,
            regenerateTwoFactorAuthQRLoading
        } = this.props;
        const { enabled } = this.state;
        return (
            <ExpansionPanel
                expanded={expanded}
                onChange={onExpansionPanelChange}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                        <Typography>
                            Two Factor Authentication Options
                        </Typography>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className={classes.twoFactorAuthPanelDetails}>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={
                                        toggleTwoFactorAuthLoading ||
                                        regenerateTwoFactorAuthQRLoading
                                    }
                                    checked={enabled}
                                    onChange={e => {
                                        this.setState({
                                            enabled: e.target.checked
                                        });
                                        toggleTwoFactorAuth(e.target.checked);
                                    }}
                                    color="primary"
                                    inputProps={{
                                        'aria-label': 'primary checkbox'
                                    }}
                                />
                            }
                            label={enabled ? 'Disable' : 'Enable'}
                            labelPlacement="start"
                        />
                        {loggedInUser.twoFactorAuth &&
                            loggedInUser.twoFactorAuth.qrUrl && (
                                <>
                                    <img
                                        alt="QR"
                                        src={loggedInUser.twoFactorAuth.qrUrl}
                                    />
                                    <Button
                                        disabled={
                                            regenerateTwoFactorAuthQRLoading ||
                                            toggleTwoFactorAuthLoading
                                        }
                                        color="primary"
                                        onClick={() =>
                                            regenerateTwoFactorAuthQR()
                                        }
                                    >
                                        ReGenerate QR
                                    </Button>
                                </>
                            )}
                    </div>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                    <Button size="small" onClick={onCancel}>
                        Close
                    </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        );
    }
}

export default withStyles(styles)(TwoFactorAuthenticationOptions);
