import { merge, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { REGENERATE_TWO_FACTOR_QR } from '../../constants/action-types';
import {
    setConnectivityStatus,
    setGlobalMessageError
} from '../../actions/app-actions';
import { regenerateTwoFactorAuthQr } from '../../api/authentication-api';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';
import {
    regenerateTwoFactorAuthQRLoading,
    updateCurrentUserSuccess
} from '../../actions/authentication-actions';

export default action$ =>
    action$.pipe(
        ofType(REGENERATE_TWO_FACTOR_QR),
        map(action => action.payload),
        switchMap(() =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(regenerateTwoFactorAuthQRLoading(true)),
                regenerateTwoFactorAuthQr().pipe(
                    mergeMap(({ data }) =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            regenerateTwoFactorAuthQRLoading(false),
                            updateCurrentUserSuccess({
                                twoFactorAuth: {
                                    ...data
                                }
                            })
                        )
                    ),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            regenerateTwoFactorAuthQRLoading(false),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            )
        )
    );
