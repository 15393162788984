// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { withStyles } from '@material-ui/core/styles';
import { validate as validateEmail } from 'email-validator';
import { Hidden } from '@material-ui/core';
import SignUpLink from '@material-ui/core/Link';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    content: {
        padding: '20px'
    },
    contentBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    actionsBar: {
        display: 'flex',
        flexDirection: 'row',
        padding: '5px 0px'
    },
    fontSizeH1: {
        fontSize: '2rem'
    },
    flex1: {
        flex: '1'
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    passwordRecoveryButton: {
        // margin: theme.spacing.unit
        marginRight: '16px'
    },
    button: {
        margin: '0px 20px'
    }
});

class PasswordRecovery extends React.Component<
    {
        classes: {
            root: string,
            content: string,
            contentBody: string,
            actionsBar: string,
            flex1: string,
            formControl: string,
            button: string,
            passwordRecoveryButton: string
        },
        handlePasswordRecovery: (email: string) => void,
        passwordRecoveryLoading: boolean,
        passwordRecoverySuccess: boolean
    },
    {
        email: string,
        emailError: string,
        disablePasswordRecoveryButton: boolean
    }
> {
    state = {
        email: '',
        emailError: '',
        disablePasswordRecoveryButton: false
    };

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handlePasswordRecoveryClick = () => {
        const { email } = this.state;
        const { handlePasswordRecovery } = this.props;
        if (validateEmail(email)) {
            this.setState({
                emailError: '',
                disablePasswordRecoveryButton: false
            });
            handlePasswordRecovery(email);
        } else {
            this.setState({
                emailError: 'Please Enter a Valid Email',
                disablePasswordRecoveryButton: true
            });
        }
    };

    onKeyDown = event => {
        const { email } = this.state;
        if (validateEmail(email)) {
            this.setState({
                emailError: '',
                disablePasswordRecoveryButton: false
            });
        }
        switch (event.keyCode) {
            case 13: {
                this.handlePasswordRecoveryClick();
                break;
            }
            default:
                break;
        }
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    render() {
        const {
            classes,
            passwordRecoveryLoading,
            passwordRecoverySuccess
        } = this.props;
        const { email, emailError, disablePasswordRecoveryButton } = this.state;
        return (
            <>
                <Card className={classes.root}>
                    <CardContent className={classes.content}>
                        <Typography type="h1" color="inherit" variant="h1" classes={{
                            h1: classes.fontSizeH1
                        }}>
                             Password Recovery
                        </Typography>
                        <div className={classes.contentBody}>
                            <FormControl
                                className={classes.formControl}
                                required
                                error={!!emailError}
                            >
                                <InputLabel htmlFor="login-email-field">
                                    Email
                                </InputLabel>
                                <Input
                                    id="login-email-field"
                                    autoComplete="email"
                                    value={email}
                                    autoFocus
                                    type="email"
                                    onChange={this.handleChange('email')}
                                    inputProps={this.inputProps}
                                />
                                {emailError && (
                                    <FormHelperText error>
                                        {emailError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </div>
                    </CardContent>
                    <CardActions className={classes.actionsBar}>
                        <Button
                            color="primary"
                            disabled={passwordRecoveryLoading}
                            className={classes.button}
                            component={props => <Link to="/login" {...props} />}
                        >
                            Go To Login
                        </Button>
                        <div className={classes.flex1} />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={
                                passwordRecoverySuccess ||
                                passwordRecoveryLoading ||
                                disablePasswordRecoveryButton
                            }
                            className={classes.passwordRecoveryButton}
                            onClick={this.handlePasswordRecoveryClick}
                        >
                            Send Recovery Link
                        </Button>
                    </CardActions>
                </Card>
                <Hidden mdUp>
                    <div
                        style={{
                            position: 'absolute',
                            right: 15,
                            bottom: 10,
                            zIndex: 1500
                        }}
                    >
                        <SignUpLink href="https://tunnelin.com/">
                            Tunnelin
                        </SignUpLink>{' '}
                        © 2020 All Right Reserved
                    </div>
                </Hidden>
                <Hidden smDown>
                    <div
                        style={{
                            position: 'absolute',
                            right: 25,
                            bottom: 10,
                            zIndex: 1500
                        }}
                    >
                        <SignUpLink href="https://tunnelin.com/">
                            Tunnelin
                        </SignUpLink>{' '}
                        © 2020 All Right Reserved
                    </div>
                </Hidden>
            </>
        );
    }
}

PasswordRecovery.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        formControl: PropTypes.string.isRequired
    }).isRequired,
    handlePasswordRecovery: PropTypes.func.isRequired,
    passwordRecoveryLoading: PropTypes.bool.isRequired,
    passwordRecoverySuccess: PropTypes.bool.isRequired
};

export default withStyles(styles)(PasswordRecovery);
