// @flow

import { createSelector } from 'reselect';

import type { InitialState } from '../types/initial-state';

export const selectLocalState = () => (state: InitialState) =>
    state.userDevices;

export const selectLoading = () =>
    createSelector(
        selectLocalState(),
        ({ loading }) => loading
    );

export const selectDeviceUsersLoading = () =>
    createSelector(
        selectLoading(),
        ({ get }) => get
    );

export const selectDeviceUsersData = () =>
    createSelector(
        selectLocalState(),
        ({ data }) => data
    );

export const selectUserDeviceRemoveLoading = () =>
    createSelector(
        selectLoading(),
        ({ remove }) => remove
    );

export const selectUserDeviceAddLoading = () =>
    createSelector(
        selectLoading(),
        ({ add }) => add
    );

export const selectUserDeviceAddingOrRemoving = () =>
    createSelector(
        selectUserDeviceRemoveLoading(),
        selectUserDeviceAddLoading(),
        (adding, removing) => adding || removing
    );
