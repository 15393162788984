import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import users from './users-reducer';
import global from './app-reducer';
import devices from './devices-reducer';
import tunnels from './tunnels-reducer';
import userDevices from './user-devices-reducer';
import groups from './groups-reducer';
import { LOGOUT_SUCCESS } from '../constants/action-types';
import initialState from './initial-state';

const appReducer = combineReducers({
    global,
    users,
    devices,
    tunnels,
    groups,
    userDevices,
    routing: routerReducer
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_SUCCESS) {
        return {
            ...initialState
        };
    }

    return appReducer(state, action);
};

export default rootReducer;
