// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import styles from './styles';

import type { User } from '../../../types/user';

class ActivateAccount extends Component<
    {
        actions: {
            sendActivationEmail: () => any
        },
        classes: any,
        loggedInUser: User
    },
    {}
> {
    static propTypes = {
        actions: PropTypes.shape({
            sendActivationEmail: PropTypes.func.isRequired
        }).isRequired,
        loggedInUser: PropTypes.object.isRequired
    };

    onSendActivationEmailButtonClick = () => {
        const {
            actions: { sendActivationEmail }
        } = this.props;
        sendActivationEmail();
    };

    render() {
        const { classes, loggedInUser } = this.props;
        const { fullName, email } = loggedInUser;

        return (
            <div className={classes.root}>
                <Typography align="center" variant="title">
                    Dear {fullName}, the last step is left: Verify your identity
                </Typography>
                <Typography align="center" variant="subheading">
                    We have sent activation link to your email: {email}. Please
                    check your spam/trash folder. If still there is no email,
                    please contact our administartor
                    <a href="mailto:admin@tunnelin.com">
                        admin [at] tunnelin dot com
                    </a>
                </Typography>
                <Button
                    className={classes.button}
                    onClick={this.onSendActivationEmailButtonClick}
                >
                    Resend Activation Link
                </Button>
            </div>
        );
    }
}

export default withStyles(styles)(ActivateAccount);
