// @flow
import {
    api,
    makeGet,
    makePost,
    makePatch,
    makeDelete
} from '../utils/http-util';

const API_URL = {
    GET_GROUPS: () => api`v1/groups`,
    ADD_GROUP: () => api`v1/groups`,
    UPDATE_GROUP: id => api`v1/groups/${id}`,
    DELETE_GROUP: id => api`v1/groups/${id}`,
    UPDATE_GROUPS_ORDERING: () => api`v1/groups/orders`
};

export function getGroups() {
    return makeGet(API_URL.GET_GROUPS());
}

export function addGroup(group: any) {
    return makePost(API_URL.ADD_GROUP(), group);
}

export function updateGroup(group: any) {
    return makePatch(API_URL.UPDATE_GROUP(group._id), group);
}

export function updateGroupsOrdering(groups: any[]) {
    return makePost(API_URL.UPDATE_GROUPS_ORDERING(), groups);
}

export function deleteGroup(_id: string) {
    return makeDelete(API_URL.DELETE_GROUP(_id));
}
