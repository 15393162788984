import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';

const styles = () => ({
    dialog: {
        '@media (min-width: 600px)': {
            height: '375px',
            minWidth: '550px',
            maxWidth: '550px'
        },
        '@media (min-height: 450px)': {
            height: '450px'
        }
    },
    dialogContent: {
        // padding: '0',
        paddingTop: '0!important',
        padding: '0px 24px',
        minHeight: '215px',
        height: '215px',
        display: 'flex'
    },
    accessingInfoWrapper: {
        padding: '0px 24px'
    },
    dialogActions: {
        padding: '13px 20px',
        margin: 0
    },
    addButton: {
        margin: '0 4px'
    }
});

function ProxyTunnelInfo(props) {
    const { classes, close, fullScreen, message } = props;
    return (
        <Dialog
            classes={{
                paper: classes.dialog
            }}
            fullScreen={fullScreen}
            open
            onClose={close}
        >
            <DialogTitle>Proxy Tunnel Info</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {message || ''}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button variant="contained" color="primary" onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
ProxyTunnelInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    message: PropTypes.string
};

export default withMobileDialog()(withStyles(styles)(ProxyTunnelInfo));
