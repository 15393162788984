import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import StartIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import {
    subscribeToTopic,
    unsubscribeFromTopic,
    removeListener,
    addListener
} from '../../utils/mqtt';
import { bytesToSize } from '../../utils/helpers';
import { getLoggedInUser } from '../../selectors/app-selectors';
import AddOrEditTunnel from '../Tunnels/Modal/AddOrEditTunnel';
import Star from './Star';
import store from '../../store/get-store';
import { setGlobalMessageError } from '../../actions/app-actions';
import { MQTT_ENV } from '../../constants/api-constants';
import { getTunnelStatuses } from '../../selectors/tunnels-selectors';
import Authorities from '../../containers/Authorities';
import {
    DELETE_TUNNEL_ACTION_AUTH,
    EDIT_TUNNEL_ACTION_AUTH
} from '../../constants/authorities';
import { openTunnel, stopTunnel, updateTunnel } from '../../api/tunnel-api';
import useUnmounted from '../../hooks/use-unmount';

const { dispatch } = store;

const styles = () => ({
    tunnelItem: {
        borderBottom: '1px dashed #cccccc'
    },
    itemWrapper: {
        flexDirection: 'row',
        display: 'flex',
        margin: '5px 0',
        minWidth: '500px',
        alignItems: 'center'
    },
    tunnelItemLeftPart: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        flex: 1,
        height: 70
    },
    tunnelItemLeftPartTop: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    tunnelItemLeftPartBottom: {
        display: 'flex',
        paddingLeft: 5
    },
    tooltipText: {
        fontSize: '11px'
    },
    sshTunnelUrl: {
        display: 'flex',
        flexDirection: 'row',
        flex: 3,
        alignItems: 'center',
        margin: 0,
        overflow: 'hidden'
    },
    sshTunnelUrlInner: {
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: '#9c27b0',
        padding: 5,
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '0.85em'
    },
    generatedNameWrapperItem: {
        display: 'flex',
        flexDirection: 'column',
        flex: 3,
        alignItems: 'baseline',
        margin: 0
    },
    generatedNameItem: {
        padding: '5px',
        textTransform: 'none'
    },
    tunnelName: {
        flex: 2,
        paddingLeft: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden'
    },
    tunnelNameInner: {
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    favouriteWrapper: {
        marginRight: 5
    },
    deviceName: {
        flex: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 5,
        overflow: 'hidden'
    },
    deviceNameInner: {
        lineHeight: '24px',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    sshTunnelUrlTooltip: {
        cursor: 'pointer'
    },
    tunnelActionButtonsWrapper: {
        width: 248,
        display: 'flex',
        flexDirection: 'row-reverse',
        paddingRight: 30,
        alignItems: 'center'
    },
    port: {
        paddingLeft: 5,
        width: 100,
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    tunnelActionIcon: {
        color: '#9c27b0'
    },
    disabledTunnelActionIcon: {
        color: '#ecb4f5'
    },
    circularWrapper: {
        padding: 8,
        width: 48,
        height: 32,
        textAlign: 'center'
    },
    colorGreen: {
        fill: '#61bd4f'
    },
    colorYellow: {
        fill: '#f2d600'
    },
    colorRed: {
        fill: '#eb5a46'
    },
    lastSeenIcon: {
        fontSize: 12,
        marginBottom: 2
    }
});

const TunnelItem = ({
    classes,
    tunnel,
    tunnel: {
        _id,
        id,
        name,
        localPort,
        handleHTTP,
        deviceId,
        type,
        favourite,
        dashUrl,
        slashUrl,
        deviceName,
        clientPort,
        status,
        startTime,
        stopTime,
        engine: { address } = {},
        options
    } = {},
    tunnelDelete,
    onTunnelReady,
    loggedInUser,
    reloadingTunnelsInProcess,
    stoppingTunnelsInProcess,
    devicesStatuses,
    tunnelsStatuses
}) => {
    const unMounted = useUnmounted();
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(
        false
    );
    const [editTunnel, setEditTunnel] = useState(false);
    const [
        changeTunnelStateInProcess,
        setChangeTunnelStateInProcess
    ] = useState(false);
    const [tooltipCopiedTime, setTooltipCopiedTime] = useState(null);
    const [tooltipTitle, setTooltipTitle] = useState(
        type === 'ssh' ? 'Copy ssh command' : 'Copy address'
    );
    const [tooltipOpenState, setTooltipOpenState] = useState(false);
    const [doRestartAfterEditing, setDoRestartAfterEditing] = useState(false);

    useEffect(
        () => () => {
            const topic = `user--${loggedInUser._id}/server`;
            unsubscribeFromTopic(topic);
        },
        [loggedInUser]
    );

    const determineAvailability = lastSeen => {
        const newDate = Date.now();

        if (!lastSeen) {
            return 'offline';
        }

        const diffMs = newDate - lastSeen;
        const diffMins = Math.round(diffMs / (1000 * 60));
        if (diffMins > 10) {
            return 'offline';
        }
        if (diffMins > 3) {
            return 'away';
        }
        return 'online';
    };
    const subscribeToTopicCallback = (editedTunnel, messageActionName) => {
        const fn = (t, message) => {
            const action = JSON.parse(message);
            if (action && action.type === 'NOTIFY_USER') {
                const { payload } = action;
                const { tunnelId, action: actionName } = payload;
                if (
                    tunnelId === id &&
                    actionName === messageActionName &&
                    t === `${MQTT_ENV}/user--${loggedInUser._id}/server`
                ) {
                    onTunnelReady({ data: { ...editedTunnel } });
                    removeListener(fn);
                    if (!unMounted.current) {
                        setChangeTunnelStateInProcess(false);
                    }
                }
            }
        };
        addListener(fn);
        const topic = `user--${loggedInUser._id}/server`;
        subscribeToTopic(topic);
    };
    const lastSeen = useMemo(
        () => {
            let ls;
            try {
                const statuses = devicesStatuses[tunnel.deviceId];
                if (statuses) {
                    const data = JSON.parse(statuses);
                    ls = data ? data.lastSeen : undefined;
                }
            } catch {}
            return ls;
        },
        [devicesStatuses, tunnel.deviceId]
    );

    const availabilityStatus = useMemo(
        () => (lastSeen ? determineAvailability(lastSeen) : 'offline'),
        [lastSeen]
    );

    const lastSeenIndicatorClassName = useMemo(
        () => {
            switch (availabilityStatus) {
                case 'online':
                    return classes.colorGreen;
                case 'away':
                    return classes.colorYellow;
                default:
                    return classes.colorRed;
            }
        },
        [availabilityStatus]
    );

    const lastSeenIndicatorTooltipMessage = useMemo(
        () => (lastSeen ? moment(lastSeen).fromNow() : 'offline'),
        [lastSeen]
    );

    const tunnelUsageData = useMemo(
        () => {
            let result = '';
            const currentTunnelStatus = tunnelsStatuses.find(
                ts => ts._id === _id
            );
            const { rx_bytes: rxBytes, tx_bytes: txBytes } =
                (currentTunnelStatus &&
                    currentTunnelStatus.info &&
                    currentTunnelStatus.info.networks &&
                    currentTunnelStatus.info.networks.eth0) ||
                {};
            if (
                (Number(rxBytes) || rxBytes === 0) &&
                (Number(txBytes) || txBytes === 0)
            ) {
                result = bytesToSize(Number(rxBytes) + Number(txBytes));
            }
            return result;
        },
        [tunnelsStatuses]
    );

    const sshTunnelUrl = address
        ? `${address}:${clientPort}`
        : 'Tunnel not started';
    const sshTunnelUrlClipboardText =
        type === 'ssh'
            ? `ssh -p ${clientPort} username@${address}`
            : `${address}:${clientPort}`;

    const handleStartClick = () => {
        setChangeTunnelStateInProcess(true);
        openTunnel({
            tunnelId: id,
            options,
            deviceId
        }).subscribe(
            res => {
                if (res && res.data && res.data.tunnel) {
                    subscribeToTopicCallback(res.data.tunnel, 'start_tunnel');
                } else {
                    dispatch(setGlobalMessageError("Can't start tunnel"));
                    if (!unMounted.current) {
                        setChangeTunnelStateInProcess(false);
                    }
                }
            },
            () => {
                if (!unMounted.current) {
                    setChangeTunnelStateInProcess(false);
                }
            }
        );
    };

    useEffect(
        () => {
            if (doRestartAfterEditing) {
                setDoRestartAfterEditing(false);
                handleStartClick();
            }
        },
        [doRestartAfterEditing]
    );

    const handleStopClick = () => {
        setChangeTunnelStateInProcess(true);
        stopTunnel(id, { deviceId, type }).subscribe(
            res => {
                if (res && res.data && res.data.tunnel) {
                    subscribeToTopicCallback(res.data.tunnel, 'stop_tunnel');
                } else {
                    dispatch(setGlobalMessageError("Can't stop tunnel"));
                    setChangeTunnelStateInProcess(false);
                }
            },
            () => {
                setChangeTunnelStateInProcess(false);
            }
        );
    };

    useEffect(
        () => {
            if (
                reloadingTunnelsInProcess &&
                status === 'started' &&
                availabilityStatus === 'online'
            ) {
                handleStartClick();
            }
            if (
                stoppingTunnelsInProcess &&
                status === 'started' &&
                availabilityStatus === 'online'
            ) {
                handleStopClick();
            }
        },
        [
            reloadingTunnelsInProcess,
            stoppingTunnelsInProcess,
            status,
            availabilityStatus
        ]
    );

    const handleEditClick = () => {
        setEditTunnel(true);
    };

    const handleFavouriteClick = () => {
        updateTunnel(id, {
            name,
            localPort,
            handleHTTP,
            deviceId,
            type,
            favourite: !favourite
        }).subscribe(
            tunnel => onTunnelReady(tunnel),
            () => {
                dispatch(setGlobalMessageError('Something went wrong.'));
            }
        );
    };

    const handleDeleteClick = () => {
        setOpenDeleteConfirmDialog(true);
    };

    const handleDelete = () => {
        tunnelDelete(tunnel.id);
    };

    const closeDeleteTunnelConfirmation = () => {
        setOpenDeleteConfirmDialog(false);
    };

    const closeEditTunnelModal = () => {
        setEditTunnel(false);
    };

    const onSshTunnelUrlCopyComplete = () => {
        setTooltipCopiedTime(Date.now());
        setTooltipTitle(
            type === 'ssh' ? 'Copied ssh command' : 'Copied address'
        );
    };

    const onTooltipClose = () => {
        setTooltipOpenState(false);
    };

    const onTooltipOpen = () => {
        let tooltipTitleData = tooltipTitle;
        if (Date.now() - tooltipCopiedTime > 3 * 1000) {
            tooltipTitleData =
                type === 'ssh' ? 'Copy ssh command' : 'Copy address';
        }
        setTooltipOpenState(true);
        setTooltipTitle(tooltipTitleData);
    };

    const restartTunnel = () => {
        setDoRestartAfterEditing(true);
    };

    return (
        <div className={classes.tunnelItem}>
            <div className={classes.itemWrapper}>
                <div className={classes.tunnelItemLeftPart}>
                    <div className={classes.tunnelItemLeftPartTop}>
                        <div className={classes.tunnelName}>
                            <div className={classes.favouriteWrapper}>
                                <Star
                                    onChange={handleFavouriteClick}
                                    active={favourite}
                                />
                            </div>
                            <div className={classes.tunnelNameInner}>
                                {name}
                            </div>
                        </div>
                        {deviceName && (
                            <div className={classes.deviceName}>
                                <Tooltip
                                    title={lastSeenIndicatorTooltipMessage}
                                    placement="bottom"
                                >
                                    <CircleIcon
                                        fontSize="small"
                                        className={`${lastSeenIndicatorClassName} ${
                                            classes.lastSeenIcon
                                        }`}
                                    />
                                </Tooltip>
                                <div className={classes.deviceNameInner}>
                                    {deviceName}
                                </div>
                            </div>
                        )}
                        {!deviceName && (
                            <div className={classes.deviceName}>
                                {type === 'proxy' ? (
                                    <Tooltip
                                        title={
                                            <div
                                                className={classes.tooltipText}
                                            >
                                                Proxy settings are fixed for all
                                                devices. You can change this
                                                option from Settings page.
                                            </div>
                                        }
                                        placement="right"
                                        className={classes.deviceNameInner}
                                    >
                                        <div
                                            className={classes.deviceNameInner}
                                        >
                                            All Devices
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <div className={classes.deviceNameInner}>
                                        Not Specified
                                    </div>
                                )}
                            </div>
                        )}
                        {type !== 'http' && (
                            <div className={classes.sshTunnelUrl}>
                                {address && type !== 'proxy' ? (
                                    <Tooltip
                                        onOpen={onTooltipOpen}
                                        onClose={onTooltipClose}
                                        open={tooltipOpenState}
                                        title={
                                            <div
                                                className={classes.tooltipText}
                                            >
                                                {tooltipTitle}
                                                <br />
                                                {sshTunnelUrlClipboardText}
                                            </div>
                                        }
                                        placement="right"
                                        className={classNames(
                                            classes.sshTunnelUrlInner,
                                            classes.sshTunnelUrlTooltip
                                        )}
                                    >
                                        <CopyToClipboard
                                            text={sshTunnelUrlClipboardText}
                                            onCopy={onSshTunnelUrlCopyComplete}
                                        >
                                            <div
                                                className={
                                                    classes.sshTunnelUrlInner
                                                }
                                            >
                                                {sshTunnelUrl}
                                            </div>
                                        </CopyToClipboard>
                                    </Tooltip>
                                ) : (
                                    <div className={classes.sshTunnelUrlInner}>
                                        {sshTunnelUrl}
                                    </div>
                                )}
                            </div>
                        )}
                        {type === 'http' && (
                            <div className={classes.generatedNameWrapperItem}>
                                {dashUrl && (
                                    <Tooltip
                                        title={
                                            <div
                                                className={classes.tooltipText}
                                            >
                                                Click to open the link
                                            </div>
                                        }
                                        placement="right"
                                        className={classNames(
                                            classes.sshTunnelUrlInner,
                                            classes.sshTunnelUrlTooltip
                                        )}
                                    >
                                        <Button
                                            className={
                                                classes.generatedNameItem
                                            }
                                            color="primary"
                                            component={props => {
                                                if (
                                                    changeTunnelStateInProcess ||
                                                    status === 'stopped'
                                                ) {
                                                    return props.children;
                                                }
                                                return (
                                                    <a
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        href={`https://${dashUrl}`}
                                                        {...props}
                                                    >
                                                        {props.children}
                                                    </a>
                                                );
                                            }}
                                        >
                                            {`https://${dashUrl}`}
                                        </Button>
                                    </Tooltip>
                                )}
                                {slashUrl && false && (
                                    <Tooltip
                                        title={
                                            <div
                                                className={classes.tooltipText}
                                            >
                                                Click to open the link
                                            </div>
                                        }
                                        placement="right"
                                        className={classNames(
                                            classes.sshTunnelUrlInner,
                                            classes.sshTunnelUrlTooltip
                                        )}
                                    >
                                        <Button
                                            className={
                                                classes.generatedNameItem
                                            }
                                            color="primary"
                                            component={props => (
                                                <a
                                                    rel="noopener noreferrer"
                                                    className={
                                                        classes.generatedNameItem
                                                    }
                                                    target="_blank"
                                                    href={`https://${slashUrl}`}
                                                    {...props}
                                                >
                                                    {props.children}
                                                </a>
                                            )}
                                        >
                                            {`https://${slashUrl}`}
                                        </Button>
                                    </Tooltip>
                                )}
                            </div>
                        )}
                    </div>
                    {!!(tunnelUsageData || startTime) && (
                        <div className={classes.tunnelItemLeftPartBottom}>
                            {status === 'started' ? (
                                <>
                                    {startTime && (
                                        <div
                                            style={{
                                                marginRight: 10,
                                                fontSize: 14
                                            }}
                                        >
                                            Started{' '}
                                            {moment(startTime).fromNow()}
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {startTime && (
                                        <div
                                            style={{
                                                marginRight: 10,
                                                fontSize: 14
                                            }}
                                        >
                                            Last time started{' '}
                                            {moment(startTime).fromNow()}
                                        </div>
                                    )}
                                    {stopTime && (
                                        <div
                                            style={{
                                                marginRight: 10,
                                                fontSize: 14
                                            }}
                                        >
                                            Stopped {moment(stopTime).fromNow()}
                                        </div>
                                    )}
                                </>
                            )}
                            {tunnelUsageData && (
                                <div
                                    style={{
                                        marginRight: 10,
                                        fontSize: 14
                                    }}
                                >
                                    Data transferred: {tunnelUsageData}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={classes.port}>{localPort}</div>
                <div
                    className={classes.tunnelActionButtonsWrapper}
                    data-tut="tunnels__step_buttons"
                >
                    <Authorities>
                        <IconButton
                            data-cmpauthkey={DELETE_TUNNEL_ACTION_AUTH}
                            onClick={handleDeleteClick}
                        >
                            <DeleteIcon className={classes.tunnelActionIcon} />
                        </IconButton>
                    </Authorities>
                    <Authorities>
                        <IconButton
                            data-cmpauthkey={EDIT_TUNNEL_ACTION_AUTH}
                            onClick={handleEditClick}
                        >
                            <EditIcon className={classes.tunnelActionIcon} />
                        </IconButton>
                    </Authorities>
                    {changeTunnelStateInProcess ? (
                        <div className={classes.circularWrapper}>
                            <CircularProgress
                                size={18}
                                className={classes.tunnelActionIcon}
                            />
                        </div>
                    ) : (
                        <>
                            {(status === 'stopped' || !status) && (
                                <IconButton
                                    disabled={availabilityStatus !== 'online'}
                                    onClick={handleStartClick}
                                >
                                    <StartIcon
                                        className={classNames({
                                            [classes.tunnelActionIcon]:
                                                availabilityStatus === 'online',
                                            [classes.disabledTunnelActionIcon]:
                                                availabilityStatus !== 'online'
                                        })}
                                    />
                                </IconButton>
                            )}
                            {status === 'started' && (
                                <>
                                    <IconButton
                                        disabled={
                                            availabilityStatus !== 'online'
                                        }
                                        onClick={handleStartClick}
                                    >
                                        <RefreshIcon
                                            className={classNames({
                                                [classes.tunnelActionIcon]:
                                                    availabilityStatus ===
                                                    'online',
                                                [classes.disabledTunnelActionIcon]:
                                                    availabilityStatus !==
                                                    'online'
                                            })}
                                        />
                                    </IconButton>
                                    <IconButton
                                        disabled={
                                            availabilityStatus !== 'online'
                                        }
                                        onClick={handleStopClick}
                                    >
                                        <StopIcon
                                            className={classNames({
                                                [classes.tunnelActionIcon]:
                                                    availabilityStatus ===
                                                    'online',
                                                [classes.disabledTunnelActionIcon]:
                                                    availabilityStatus !==
                                                    'online'
                                            })}
                                        />
                                    </IconButton>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            {editTunnel && (
                <AddOrEditTunnel
                    tunnel={tunnel}
                    close={closeEditTunnelModal}
                    onTunnelReady={onTunnelReady}
                    handleStartClick={restartTunnel}
                    deviceAvailabilityStatus={availabilityStatus}
                />
            )}
            {openDeleteConfirmDialog && (
                <Dialog
                    open
                    onClose={closeDeleteTunnelConfirmation}
                    aria-labelledby="delete-device-title"
                >
                    <DialogTitle>Delete tunnel</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure want to delete <strong>{name}</strong>{' '}
                            tunnel?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDelete}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={closeDeleteTunnelConfirmation}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};
const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser(),
    tunnelsStatuses: getTunnelStatuses()
});

TunnelItem.propTypes = {
    classes: PropTypes.object.isRequired,
    tunnel: PropTypes.shape({
        id: PropTypes.string,
        _id: PropTypes.string,
        name: PropTypes.string,
        localPort: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        handleHTTP: PropTypes.bool,
        deviceId: PropTypes.string,
        type: PropTypes.string,
        favourite: PropTypes.bool,
        engine: PropTypes.shape({
            address: PropTypes.string
        }),
        dashUrl: PropTypes.string,
        slashUrl: PropTypes.string,
        deviceName: PropTypes.string,
        clientPort: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        status: PropTypes.string,
        startTime: PropTypes.number,
        stopTime: PropTypes.number,
        options: PropTypes.object
    }).isRequired,
    tunnelDelete: PropTypes.func.isRequired,
    onTunnelReady: PropTypes.func.isRequired,
    loggedInUser: PropTypes.object.isRequired,
    reloadingTunnelsInProcess: PropTypes.bool,
    stoppingTunnelsInProcess: PropTypes.bool,
    devicesStatuses: PropTypes.object.isRequired,
    tunnelsStatuses: PropTypes.array.isRequired
};

export default connect(mapStateToProps)(
    withMobileDialog()(withStyles(styles)(TunnelItem))
);
