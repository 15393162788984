import { createSelector } from 'reselect';

export const selectLocalState = () => state => state.global;

export const getLoggedInUser = () =>
    createSelector(
        selectLocalState(),
        ({ loggedInUser }) => loggedInUser
    );

export const getDevicesTourDetails = () =>
    createSelector(
        selectLocalState(),
        ({ devicesTourDetails }) => devicesTourDetails
    );

export const getTunnelsTourDetails = () =>
    createSelector(
        selectLocalState(),
        ({ tunnelsTourDetails }) => tunnelsTourDetails
    );

export const isAuthenticated = () =>
    createSelector(
        getLoggedInUser(),
        user => !!user
    );

export const isEmailVerified = () =>
    createSelector(
        getLoggedInUser(),
        user => {
            if (!user) {
                return false;
            }
            return user.emailVerified;
        }
    );

export const selectLoading = () =>
    createSelector(
        selectLocalState(),
        ({ loading }) => loading
    );

export const loginInProcess = () =>
    createSelector(
        selectLoading(),
        ({ login }) => login
    );

export const signupInProgress = () =>
    createSelector(
        selectLoading(),
        ({ signup }) => signup
    );

export const selectGlobalMessage = () =>
    createSelector(
        selectLocalState(),
        ({ message }) => message
    );

export const getErrorMessage = () =>
    createSelector(
        selectGlobalMessage(),
        ({ error }) => error
    );

export const getSuccessMessage = () =>
    createSelector(
        selectGlobalMessage(),
        ({ success }) => success
    );

export const getCookiesMessage = () =>
    createSelector(
        selectGlobalMessage(),
        ({ cookies }) => cookies
    );

export const selectUpdateCurrentUserLoading = () =>
    createSelector(
        selectLoading(),
        ({ updatingCurrentUser }) => updatingCurrentUser
    );

export const selectChangeCurrentUserPasswordLoading = () =>
    createSelector(
        selectLoading(),
        ({ changingPassword }) => changingPassword
    );

export const isSigningUp = () =>
    createSelector(
        selectLocalState(),
        ({ isSigningUp }) => isSigningUp
    );

export const selectToggleTwoFactorAuthLoading = () =>
    createSelector(
        selectLoading(),
        ({ toggleTwoFactorAuth }) => toggleTwoFactorAuth
    );
export const selectRegenerateTwoFactorAuthQRLoading = () =>
    createSelector(
        selectLoading(),
        ({ regenerateTwoFactorAuthQR }) => regenerateTwoFactorAuthQR
    );
export const insufficientlyAuthenticationDetails = () =>
    createSelector(
        selectLocalState(),
        ({ insufficientlyAuthenticationDetails }) =>
            insufficientlyAuthenticationDetails
    );
