// @flow
import React from 'react';
import PropTypes from 'prop-types';
import SignUpAndChangeDeviceOwner from '../../../components/Devices/SignUpOrLoginForGetDevice';

class SignUpAndChangeDeviceOwnerHOC extends React.Component<
    {
        newOwnerEmail: string,
        oldOwnerEmail: string,
        deviceName: string,
        token: string
    },
    {}
> {
    render() {
        const { newOwnerEmail, oldOwnerEmail, deviceName, token } = this.props;
        return (
            <SignUpAndChangeDeviceOwner
                newOwnerEmail={newOwnerEmail}
                oldOwnerEmail={oldOwnerEmail}
                deviceName={deviceName}
                token={token}
            />
        );
    }
}

SignUpAndChangeDeviceOwnerHOC.propTypes = {
    newOwnerEmail: PropTypes.string.isRequired,
    oldOwnerEmail: PropTypes.string.isRequired,
    deviceName: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired
};

export default SignUpAndChangeDeviceOwnerHOC;
