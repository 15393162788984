// @flow
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import base64Url from 'base64-url';
import { BASE_DOMAIN } from '../constants/api-constants';

export function parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
    );

    return JSON.parse(jsonPayload);
}

export function getTokens() {
    let tokens;
    try {
        tokens = JSON.parse(localStorage.getItem('tokens') || '{}');
    } catch (e) {
        tokens = {};
    }
    return tokens || {};
}

export function saveAuthToken(authToken: string) {
    const tokens = getTokens();
    tokens.authToken = authToken;
    localStorage.setItem('tokens', JSON.stringify(tokens));
}

export function clearAuthToken() {
    const tokens = getTokens();
    delete tokens.authToken;
    if (Object.keys(tokens).length) {
        localStorage.setItem('tokens', JSON.stringify(tokens));
    } else {
        localStorage.removeItem('tokens');
    }
}
export function getAuthToken() {
    const { authToken } = getTokens();
    if (authToken) {
        try {
            const info = parseJwt(authToken);
            if (!info.organizationId) {
                clearAuthToken();
                return false;
            }
        } catch (e) {
            //
        }
    }
    return authToken || false;
}

export function getBearer() {
    const authToken = getAuthToken();

    if (!authToken) {
        return false;
    }
    return {
        Authorization: `Bearer ${authToken}`
    };
}

export function getHeaders() {
    const bearer = getBearer();
    if (!bearer) {
        return {};
    }

    return {
        ...bearer,
        'Content-Type': 'application/json'
    };
}

export function getBaseUrl() {
    return `${BASE_DOMAIN}/`;
}

export const api = (strings: string[], ...interpolations: any): string =>
    strings.reduce((result, current, index) => {
        // eslint-disable-next-line
        result += current;

        // eslint-disable-next-line
        if (interpolations.hasOwnProperty(index)) {
            // eslint-disable-next-line
            result += interpolations[index];
        }

        return result;
    }, getBaseUrl());

export const request = (
    url: string,
    body: any,
    headers: any,
    method: string = 'GET'
) => {
    const payload: any = {
        url,
        crossDomain: true,
        responseType: 'json',
        headers: {
            ...getHeaders(),
            ...headers
        },
        method
    };

    if (body) {
        payload.body = body;
    }

    // todo:: add generic error handlers
    return ajax(payload).pipe(map(res => res.response));
};

export const makePost = (url: string, body: any, headers: any) =>
    request(url, body, headers, 'POST');

export const makeGet = (url: string, headers: any) =>
    request(url, null, headers, 'GET');

export const makePut = (url: string, body: any, headers: any) =>
    request(url, body, headers, 'PUT');

export const makePatch = (url: string, body: any, headers: any) =>
    request(url, body, headers, 'PATCH');

export const makeDelete = (url: string, body: any, headers: any) =>
    request(url, body, headers, 'DELETE');

export const makeGetPromise = (url: string) => makeGet(url).toPromise();

export function urlToToken(url, decoder = 'base64decode') {
    let base64 = url;
    if (decoder === 'base64decode') {
        base64 = base64Url.unescape(base64);
    }
    if (decoder === 'native') {
        base64 = decodeURIComponent(base64);
    }
    return Buffer.from(base64, 'base64').toString('latin1');
}
