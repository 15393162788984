// @flow

import { combineReducers } from 'redux';
import globalInitialState from './initial-state';
import {
    CHANGE_CURRENT_USER_PASSWORD_LOADING,
    GET_LOGGED_IN_USER_SUCCESS,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    MAKE_GROUP_AS_DEFAULT_SUCCESS,
    SIGNUP_LOADING,
    SIGNUP_SUCCESS,
    UPDATE_CURRENT_USER_SUCCESS,
    UPDATE_CURRENT_USER_LOADING,
    SET_GLOBAL_MESSAGE_ERROR,
    SET_GLOBAL_MESSAGE_SUCCESS,
    SET_GLOBAL_MESSAGE_COOKIES,
    GET_LOGGED_IN_USER_LOADING,
    VERIFY_EMAIL_SUCCESS,
    INSUFFICIENTLY_AUTHENTICATION_DETAILS,
    TOGGLE_TWO_FACTOR_AUTH_LOADING,
    REGENERATE_TWO_FACTOR_QR_LOADING,
    SET_DEVICES_TOUR_STEP,
    SET_DEVICES_TOUR_VISIBILITY,
    SET_TUNNELS_TOUR_STEP,
    SET_TUNNELS_TOUR_VISIBILITY
} from '../constants/action-types';
import { singlePayloadReducer } from '../utils/reducer-util';
import { constructLoggedInUserIdHash } from '../utils/user-utils';

const initialState = globalInitialState.global;

function loggedInUser(state = initialState.loggedInUser, action) {
    switch (action.type) {
        case LOGOUT_SUCCESS:
            return null;
        case GET_LOGGED_IN_USER_SUCCESS:
        case LOGIN_SUCCESS:
        case SIGNUP_SUCCESS: {
            constructLoggedInUserIdHash(action.payload._id);
            return action.payload;
        }
        case MAKE_GROUP_AS_DEFAULT_SUCCESS: {
            const _id = action.payload;
            return { ...state, defaultGroup: _id };
        }
        case UPDATE_CURRENT_USER_SUCCESS: {
            return {
                ...state,
                ...action.payload
            };
        }
        case VERIFY_EMAIL_SUCCESS: {
            return state
                ? {
                      ...state,
                      emailVerified: true
                  }
                : {};
        }
        default:
            return state;
    }
}

function loginLoading(state = initialState.loading.login, action) {
    switch (action.type) {
        case GET_LOGGED_IN_USER_LOADING:
        case LOGIN_LOADING:
            return action.payload;
        default:
            return state;
    }
}

function insufficientlyAuthenticationDetails(state = initialState, action) {
    if (action.type === INSUFFICIENTLY_AUTHENTICATION_DETAILS) {
        return action.payload || [];
    }
    return state;
}

function devicesTourDetails(state = initialState.devicesTourDetails, action) {
    switch (action.type) {
        case SET_DEVICES_TOUR_VISIBILITY: {
            return { ...state, visibility: action.payload };
        }
        case SET_DEVICES_TOUR_STEP: {
            return {
                ...state,
                step: action.payload
            };
        }
        default:
            return state;
    }
}

function tunnelsTourDetails(state = initialState.tunnelsTourDetails, action) {
    switch (action.type) {
        case SET_TUNNELS_TOUR_VISIBILITY: {
            return { ...state, visibility: action.payload };
        }
        case SET_TUNNELS_TOUR_STEP: {
            return {
                ...state,
                step: action.payload
            };
        }
        default:
            return state;
    }
}

export default combineReducers({
    loggedInUser,
    loading: combineReducers({
        login: loginLoading,
        changeOrganization: loginLoading,
        signup: singlePayloadReducer(
            SIGNUP_LOADING,
            initialState.loading.signup
        ),
        updatingCurrentUser: singlePayloadReducer(
            UPDATE_CURRENT_USER_LOADING,
            initialState.loading.updatingCurrentUser
        ),
        changingPassword: singlePayloadReducer(
            CHANGE_CURRENT_USER_PASSWORD_LOADING,
            initialState.loading.changingPassword
        ),
        toggleTwoFactorAuth: singlePayloadReducer(
            TOGGLE_TWO_FACTOR_AUTH_LOADING,
            initialState.loading.toggleTwoFactorAuth
        ),
        regenerateTwoFactorAuthQR: singlePayloadReducer(
            REGENERATE_TWO_FACTOR_QR_LOADING,
            initialState.loading.regenerateTwoFactorAuthQR
        )
    }),
    message: combineReducers({
        cookies: singlePayloadReducer(
            SET_GLOBAL_MESSAGE_COOKIES,
            initialState.message.cookies
        ),
        error: singlePayloadReducer(
            SET_GLOBAL_MESSAGE_ERROR,
            initialState.message.error
        ),
        success: singlePayloadReducer(
            SET_GLOBAL_MESSAGE_SUCCESS,
            initialState.message.success
        )
    }),
    insufficientlyAuthenticationDetails,
    devicesTourDetails,
    tunnelsTourDetails
});
