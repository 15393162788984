// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import SetNewPassword from '../../../components/Authentication/SetNewPassword';

import {
    getLoggedInUser,
    setNewPasswordWithRecoveryToken
} from '../../../api/authentication-api';
import { saveAuthToken, urlToToken } from '../../../utils/http-util';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../../utils/global-message-util';
import {
    setGlobalMessageError,
    setGlobalMessageSuccess
} from '../../../actions/app-actions';
import { wrapActionCreators } from '../../../utils/container-util';

class SetNewPasswordHOC extends React.Component<
    {
        history: any,
        location: any,
        actions: {
            setGlobalMessageError: any,
            setGlobalMessageSuccess: any
        }
    },
    {
        changePasswordInProgress: boolean,
        changePasswordSuccess: boolean,
        userFullName: string,
        getMeError: string
    }
> {
    state = {
        changePasswordInProgress: false,
        changePasswordSuccess: false,
        userFullName: '',
        getMeError: ''
    };

    componentDidMount() {
        const {
            location: { search },
            history: { replace },
            actions: { setGlobalMessageError }
        } = this.props;
        const searchParams = queryString.parse(search || '');
        const tokenPart = searchParams.password_recovery_token;
        let headers;

        try {
            headers = {
                Authorization: `Bearer ${urlToToken(tokenPart)}`
            };
        } catch (e) {
            setGlobalMessageError(
                constructGlobalErrorMessage('The link was corrupted')
            );
            replace('/login');
        }

        getLoggedInUser(headers).subscribe(
            user => {
                if (user && user.data && user.data.fullName) {
                    this.setState({ userFullName: user.data.fullName });
                }
            },
            err => {
                let message = 'Something went wrong';
                if (err) {
                    if (err.status === 401 || err.status === 403) {
                        message = 'The link was corrupted';
                        replace('/login');
                    } else {
                        message = parseErrorMessage(err);
                    }
                }
                setGlobalMessageError(constructGlobalErrorMessage(message));
                this.setState({ getMeError: message });
            }
        );
    }

    handleSetNewPasswordPassword = (newPassword: string) => {
        const {
            location: { search },
            history: { replace },
            actions: { setGlobalMessageError, setGlobalMessageSuccess }
        } = this.props;
        const searchParams = queryString.parse(search || '');
        const changePasswordRecoveryToken =
            searchParams.password_recovery_token;
        const headers = {
            Authorization: `Bearer ${urlToToken(changePasswordRecoveryToken)}`
        };
        this.setState({
            changePasswordInProgress: true,
            changePasswordSuccess: false
        });
        setNewPasswordWithRecoveryToken(newPassword, headers).subscribe(
            authToken => {
                setGlobalMessageSuccess('Please Log In with new password.');
                this.setState({
                    changePasswordInProgress: false,
                    changePasswordSuccess: true
                });
                let token;
                try {
                    token = atob(decodeURIComponent(authToken.data));
                } catch (e) {
                    // invalid url just do logout;
                    setGlobalMessageError('Invalid Token');
                }
                if (token) {
                    saveAuthToken(token);
                    replace('/');
                    window.location.reload();
                }
            },
            err => {
                let message = 'Something went wrong';
                if (err) {
                    message = parseErrorMessage(err);
                }
                setGlobalMessageError(constructGlobalErrorMessage(message));
                this.setState({
                    changePasswordInProgress: false
                });
            }
        );
    };

    render() {
        const {
            changePasswordInProgress,
            changePasswordSuccess,
            userFullName,
            getMeError
        } = this.state;
        const {
            history: { replace }
        } = this.props;
        return (
            <SetNewPassword
                replace={replace}
                userFullName={userFullName}
                getMeError={getMeError}
                handleSetNewPasswordPassword={this.handleSetNewPasswordPassword}
                changePasswordInProgress={changePasswordInProgress}
                changePasswordSuccess={changePasswordSuccess}
            />
        );
    }
}

SetNewPasswordHOC.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    actions: PropTypes.shape({
        setGlobalMessageError: PropTypes.func.isRequired,
        setGlobalMessageSuccess: PropTypes.func.isRequired
    }).isRequired
};

const mapDispatchToProps = wrapActionCreators({
    setGlobalMessageError,
    setGlobalMessageSuccess
});

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(SetNewPasswordHOC)
);
