// @flow

import deepPurple from '@material-ui/core/colors/deepPurple';

export default (theme: any) => ({
    root: {
        width: '100vw'
    },
    tabs: {
        // display: 'flex',
        flex: 1,
        overflowX: 'overlay',
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    toolbar: {
        minHeight: '64px',
        height: '64px'
    },
    tab: {
        // '@media (min-width: 600px)': {
        //     height: '64px',
        //     minHeight: '64px'
        // },
        height: '64px',
        minHeight: '64px',
        minWidth: '130px',
        paddingTop: 0
    },
    purpleAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepPurple[500]
    },
    avatarButton: {
        color: '#fff'
    }
});
