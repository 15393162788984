import { createStyles, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import React, { useEffect, useState } from 'react';
import { ColumnProps, TableCellProps } from 'react-virtualized';
import MuiVirtualizedTable from './VirtualizedTable';
import { IGridProps } from '../../../interface/Grid';
import TablePaginationActions from './PaginationActions';
import GridToolbar from './Toolbar';

const styles = () =>
    createStyles({
        pagination: {
            position: 'absolute',
            right: 10,
            bottom: 10,
            padding: 0,
            border: 0
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            padding: '30px'
        }
    });

export interface IGridColumn extends ColumnProps {
    flexGrow?: number;
    label: string;
    dataKey: string;
    cellContentRenderer?: (
        cellRendererProps: TableCellProps
    ) => React.ReactNode;
    numeric?: boolean;
}

const Grid = ({
    classes,
    data,
    isDataLoading,
    columns,
    allDataCount = 0,
    enablePagination = false,
    rowsPerPage = 0,
    rowsPerPageOptions,
    page = 0,
    onChangePage = () => {},
    onChangeRowsPerPage,
    gridToolbarTitle,
    searchValueChange,
    searchValueChangeTimeOutForCall = 1000,
    labelRowsPerPage = 'Rows Per Page',
    showCheckBoxes = false,
    providedTableRowHeight,
    selectedGridToolbarElements,
    rowSelectionChange,
    enableToolbar = false,
    style = {
        height: '100%',
        width: '100%'
    },
    paper = false
}: IGridProps) => {
    const [isEverSearchValueChanged, setIsEverSearchValueChanged] = useState(
        false
    );
    const [selected, setSelected] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [tableRowHeight, setTableRowHeight] = useState(50);
    useEffect(
        () => {
            const handleResize = () => {
                if (providedTableRowHeight) {
                    setTableRowHeight(providedTableRowHeight);
                } else if (window.innerWidth < 768) {
                    setTableRowHeight(35);
                } else {
                    setTableRowHeight(50);
                }
            };
            handleResize();
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        },
        [providedTableRowHeight]
    );

    useEffect(
        () => {
            let canceled = false;
            let tId: any;
            if (isEverSearchValueChanged) {
                tId = setTimeout(() => {
                    if (!canceled) {
                        searchValueChange(searchValue.toLowerCase());
                    }
                }, searchValueChangeTimeOutForCall);
            }
            return () => {
                if (tId) {
                    clearTimeout(tId);
                }
                canceled = true;
            };
        },
        [
            isEverSearchValueChanged,
            searchValue,
            searchValueChange,
            searchValueChangeTimeOutForCall
        ]
    );
    useEffect(
        () => {
            setSelected([]);
        },
        [data]
    );

    const rowGetter = ({ index }: { index: number }) => data[index];
    const rowClick = () => {};
    function selectRow(props: TableCellProps) {
        const { rowIndex } = props;
        const selectedIndex = selected.indexOf(rowIndex);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, rowIndex);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        if (rowSelectionChange && typeof rowSelectionChange === 'function') {
            rowSelectionChange(props.rowData);
        }
    }

    const checkboxCellRenderer: (
        cellRendererProps: TableCellProps
    ) => React.ReactNode = props => {
        const { rowIndex } = props;
        return (
            <Checkbox
                checked={selected.indexOf(rowIndex) > -1}
                onChange={() => selectRow(props)}
                value="checkedB"
                color="primary"
            />
        );
    };

    const firstColumn: IGridColumn = {
        dataKey: 'checkboxes',
        width: 100,
        label: '',
        cellContentRenderer: checkboxCellRenderer
    };

    const paddingBottom = enablePagination ? '60px' : 0;
    const paddingTop = enableToolbar ? '64px' : 0;
    const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setSearchValue(val);
        setIsEverSearchValueChanged(true);
    };

    if (!paper) {
        return (
            <div style={{ ...style, paddingBottom, paddingTop }}>
                {enableToolbar && (
                    <GridToolbar
                        numSelected={selected.length}
                        title={gridToolbarTitle}
                        searchValue={searchValue}
                        onSearchValueChange={onSearchValueChange}
                        selectedGridToolbarElements={
                            selectedGridToolbarElements
                        }
                    />
                )}
                {isDataLoading ? (
                    <div className={classes.loaderWrapper}>
                        <CircularProgress size={50} />
                    </div>
                ) : (
                    <MuiVirtualizedTable
                        rowHeight={tableRowHeight}
                        headerHeight={tableRowHeight}
                        rowCount={data.length}
                        rowGetter={rowGetter}
                        onRowClick={rowClick}
                        columns={
                            showCheckBoxes ? [firstColumn, ...columns] : columns
                        }
                    />
                )}
                {enablePagination && (
                    <table>
                        <tbody>
                            <tr>
                                <TablePagination
                                    labelRowsPerPage={labelRowsPerPage}
                                    className={classes.pagination}
                                    rowsPerPageOptions={
                                        rowsPerPageOptions || [5, 10, 25]
                                    }
                                    colSpan={3}
                                    count={allDataCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangeRowsPerPage={onChangeRowsPerPage}
                                    onChangePage={onChangePage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </tr>
                        </tbody>
                    </table>
                )}
            </div>
        );
    }

    return (
        <Paper style={{ ...style, paddingBottom, paddingTop }}>
            {enableToolbar && (
                <GridToolbar
                    numSelected={selected.length}
                    title={gridToolbarTitle}
                    searchValue={searchValue}
                    onSearchValueChange={onSearchValueChange}
                    selectedGridToolbarElements={selectedGridToolbarElements}
                />
            )}
            {isDataLoading ? (
                <div className={classes.loaderWrapper}>
                    <CircularProgress size={50} />
                </div>
            ) : (
                <MuiVirtualizedTable
                    rowHeight={tableRowHeight}
                    headerHeight={tableRowHeight}
                    rowCount={data.length}
                    rowGetter={rowGetter}
                    onRowClick={rowClick}
                    columns={
                        showCheckBoxes ? [firstColumn, ...columns] : columns
                    }
                />
            )}
            {enablePagination && (
                <table>
                    <tbody>
                        <tr>
                            <TablePagination
                                labelRowsPerPage={labelRowsPerPage}
                                className={classes.pagination}
                                rowsPerPageOptions={
                                    rowsPerPageOptions || [5, 10, 25]
                                }
                                colSpan={3}
                                count={allDataCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                onChangePage={onChangePage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </tr>
                    </tbody>
                </table>
            )}
        </Paper>
    );
};

export default withStyles(styles)(Grid);
