// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import SignUpLink from '@material-ui/core/Link';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckedIcon from '@material-ui/icons/CheckOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';

import { Hidden } from '@material-ui/core';
import {
    validateUsername,
    validateEmail,
    validateFullname
} from '../../../utils/validations';
import {
    getSuggestedUsername,
    checkUsernameExisting
} from '../../../api/authentication-api';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    content: {
        padding: '20px'
    },
    contentBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    actionsBar: {
        display: 'flex',
        flexDirection: 'row',
        padding: '5px 20px'
    },
    fontSizeH1: {
        fontSize: '2rem'
    },
    flex1: {
        flex: '1'
    },
    formControl: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    button: {
        // margin: theme.spacing.unit
        marginRight: 0,
        marginLeft: 0
    },
    privacy: {
        marginTop: '15px'
    },
    userNameAdornment: {
        marginRight: 12
    }
});

class Signup extends React.Component<
    {
        classes: {
            root: string,
            content: string,
            contentBody: string,
            actionsBar: string,
            flex1: string,
            formControl: string,
            button: string,
            privacy: string
        },
        actions: {
            signup: ({
                fullName: string,
                email: string,
                username: string,
                password: string,
                confirmPassword: string
            }) => void
        },
        signupInProgress: boolean,
        mode: string,
        newOwnerEmail: string
    },
    {
        email: string,
        username: string,
        password: string,
        showPassword: boolean,
        confirmPassword: string,
        showConfirmPassword: boolean,
        fullName: string,
        fullNameError: string,
        emailError: string,
        usernameError: string,
        passwordError: string,
        confirmPasswordError: string,
        isCheckingUsername: boolean,
        userNameNotExists: boolean
    }
    > {
    checkUserName = debounce(() => {
        const { username } = this.state;
        this.setState({
            isCheckingUsername: true,
            userNameNotExists: false
        });
        checkUsernameExisting({
            username
        }).subscribe(d => {
            const { data } = d;
            if (!data) {
                this.setState({
                    isCheckingUsername: false,
                    userNameNotExists: true,
                    usernameError: ''
                });
            } else {
                this.setState({
                    isCheckingUsername: false,
                    usernameError: 'Username exists'
                });
            }
        });
    }, 100);

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            email: props.newOwnerEmail || '',
            username: '',
            password: '',
            confirmPassword: '',
            showPassword: false,
            showConfirmPassword: false,
            fullNameError: '',
            usernameError: '',
            emailError: '',
            passwordError: '',
            confirmPasswordError: '',
            isCheckingUsername: false,
            userNameNotExists: false
        };
    }

    onKeyDown = event => {
        switch (event.keyCode) {
            case 13: {
                this.handleSignup();
                break;
            }
            default:
                break;
        }
    };

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
        if (prop === 'username') {
            this.setState({ isCheckingUsername: true });
            this.checkUserName();
        }
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleClickShowPassword = prop => () => {
        const { [prop]: stateProp } = this.state;
        this.setState({ [prop]: !stateProp });
    };

    handleSignup = () => {
        const {
            actions: { signup },
            token
        } = this.props;
        const {
            fullName,
            email,
            username,
            password,
            confirmPassword,
            isCheckingUsername,
            userNameNotExists
        } = this.state;
        if (
            this.validateFormFields() &&
            !isCheckingUsername &&
            userNameNotExists
        ) {
            signup({
                fullName: fullName.trim(),
                email: email.trim(),
                username: username.trim(),
                password: password.trim(),
                confirmPassword: confirmPassword.trim(),
                token
            });
        }
    };

    validateFormFields = (setToState: boolean = true) => {
        const { fullName, email, username } = this.state;
        let errors = {
            fullNameError: '',
            emailError: '',
            usernameError: '',
            passwordError: '',
            confirmPasswordError: ''
        };
        let isValid = true;

        const {
            isValid: isValidFullname,
            errors: fullNameErrors
        } = validateFullname(fullName);
        isValid = isValid && isValidFullname;
        errors = {
            ...errors,
            ...fullNameErrors
        };

        const { isValid: isValidEmail, errors: emailErrors } = validateEmail(
            email
        );
        isValid = isValid && isValidEmail;
        errors = {
            ...errors,
            ...emailErrors
        };

        const {
            isValid: isValidUserName,
            errors: userNameErrors
        } = validateUsername(username);
        isValid = isValid && isValidUserName;
        errors = {
            ...errors,
            ...userNameErrors
        };

        const {
            isValid: areValidPasswords,
            errors: passwordsErrors
        } = this.validatePasswords();
        isValid = isValid && areValidPasswords;
        errors = {
            ...errors,
            ...passwordsErrors
        };

        if (setToState && Object.keys(errors).length) {
            this.setState(errors);
        }
        return isValid;
    };

    validatePasswords = (fromField: null) => {
        const { password, confirmPassword, confirmPasswordError } = this.state;
        let isValid = true;
        const errors = {
            passwordError: '',
            confirmPasswordError
        };
        if (password.trim() === '') {
            isValid = false;
            errors.passwordError = 'Please enter password';
        } else if (password.trim().length < 8) {
            isValid = false;
            errors.passwordError = 'Passwords should be at least 8 chars';
        }
        if (fromField === 'signup-password-field') {
            if (
                confirmPassword.trim() &&
                confirmPassword.trim() !== password.trim()
            ) {
                isValid = false;
                errors.confirmPasswordError =
                    "Password and confirm password don't match";
            } else if (confirmPassword.trim()) {
                errors.confirmPasswordError = '';
            }
        } else if (confirmPassword.trim() !== password.trim()) {
            isValid = false;
            errors.confirmPasswordError =
                "Password and confirm password don't match";
        } else {
            errors.confirmPasswordError = '';
        }
        return {
            isValid,
            errors
        };
    };

    _getSuggestedUsername = () => {
        const { fullName, email } = this.state;
        this.setState({
            isCheckingUsername: true,
            userNameNotExists: false
        });
        getSuggestedUsername({
            fullName: fullName.trim(),
            email: email.trim()
        }).subscribe(username => {
            if (username && username.data) {
                this.setState({
                    username: username.data,
                    isCheckingUsername: false,
                    userNameNotExists: true
                });
                this.checkUserName();
            }
        });
    };

    onBlur = event => {
        const { username, fullName, email } = this.state;
        if (event.target.id === 'signup-fullName-field') {
            const { isValid, errors } = validateFullname(fullName);
            if (!isValid) {
                this.setState(errors);
            } else {
                this.setState(errors);
                if (!username && fullName) {
                    this._getSuggestedUsername();
                }
            }
        }
        if (event.target.id === 'signup-email-field') {
            const { isValid, errors } = validateEmail(email);
            if (!isValid) {
                this.setState(errors);
            } else {
                this.setState(errors);
                if (!username && fullName) {
                    this._getSuggestedUsername();
                }
            }
        }
        if (event.target.id === 'signup-username-field') {
            const { errors } = validateUsername(username);
            this.setState(errors);
        }
        if (
            event.target.id === 'signup-confirm-password-field' ||
            event.target.id === 'signup-password-field'
        ) {
            const { errors } = this.validatePasswords(event.target.id);
            this.setState(errors);
        }
    };

    render() {
        const { classes, signupInProgress, mode } = this.props;
        const {
            fullName,
            email,
            username,
            password,
            confirmPassword,
            showPassword,
            showConfirmPassword,
            fullNameError,
            usernameError,
            emailError,
            passwordError,
            confirmPasswordError,
            isCheckingUsername,
            userNameNotExists
        } = this.state;
        return (
            <>
                <Card className={classes.root}>
                    <CardContent className={classes.content}>
                        <Typography type="h1" color="inherit" variant="h1" classes={{
                            h1: classes.fontSizeH1
                        }}>
                            Sign up
                        </Typography>
                        <div className={classes.contentBody}>
                            <FormControl
                                className={classes.formControl}
                                required
                                error={!!fullNameError}
                                onBlur={this.onBlur}
                            >
                                <InputLabel htmlFor="signup-fullName-field">
                                    Full Name
                                </InputLabel>
                                <Input
                                    id="signup-fullName-field"
                                    autoComplete="name"
                                    value={fullName}
                                    autoFocus
                                    onChange={this.handleChange('fullName')}
                                    inputProps={this.inputProps}
                                />
                                {fullNameError && (
                                    <FormHelperText>
                                        {fullNameError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                className={classes.formControl}
                                required
                                error={!!emailError}
                                onBlur={this.onBlur}
                            >
                                <InputLabel htmlFor="signup-email-field">
                                    Email
                                </InputLabel>
                                <Input
                                    id="signup-email-field"
                                    autoComplete="email"
                                    value={email}
                                    type="email"
                                    onChange={this.handleChange('email')}
                                    inputProps={this.inputProps}
                                />
                                {emailError && (
                                    <FormHelperText>
                                        {emailError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                className={classes.formControl}
                                required
                                error={!!usernameError}
                                onBlur={this.onBlur}
                            >
                                <InputLabel htmlFor="signup-username-field">
                                    Username
                                </InputLabel>
                                <Input
                                    id="signup-username-field"
                                    value={username}
                                    onChange={this.handleChange('username')}
                                    inputProps={this.inputProps}
                                    endAdornment={
                                        username && (
                                            <InputAdornment
                                                className={
                                                    classes.userNameAdornment
                                                }
                                                position="end"
                                            >
                                                {isCheckingUsername ? (
                                                    <CircularProgress
                                                        size={18}
                                                    />
                                                ) : userNameNotExists &&
                                                    !usernameError ? (
                                                            <CheckedIcon color="secondary" />
                                                        ) : (
                                                            <ErrorIcon color="error" />
                                                        )}
                                            </InputAdornment>
                                        )
                                    }
                                />
                                {usernameError && (
                                    <FormHelperText>
                                        {usernameError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                className={classes.formControl}
                                required
                                error={!!passwordError}
                                onBlur={this.onBlur}
                            >
                                <InputLabel htmlFor="signup-password-field">
                                    Password
                                </InputLabel>
                                <Input
                                    id="signup-password-field"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    autoComplete="new-password"
                                    onChange={this.handleChange('password')}
                                    inputProps={this.inputProps}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={this.handleClickShowPassword(
                                                    'showPassword'
                                                )}
                                                tabIndex="-1"
                                                onMouseDown={
                                                    this.handleMouseDownPassword
                                                }
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                        <Visibility />
                                                    )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {passwordError && (
                                    <FormHelperText>
                                        {passwordError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                className={classes.formControl}
                                required
                                error={!!confirmPasswordError}
                                onBlur={this.onBlur}
                            >
                                <InputLabel htmlFor="signup-confirm-password-field">
                                    Confirm Password
                                </InputLabel>
                                <Input
                                    id="signup-confirm-password-field"
                                    type={
                                        showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    value={confirmPassword}
                                    autoComplete="new-password"
                                    onChange={this.handleChange(
                                        'confirmPassword'
                                    )}
                                    inputProps={this.inputProps}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                tabIndex="-1"
                                                onClick={this.handleClickShowPassword(
                                                    'showConfirmPassword'
                                                )}
                                                onMouseDown={
                                                    this.handleMouseDownPassword
                                                }
                                            >
                                                {showConfirmPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                        <Visibility />
                                                    )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {confirmPasswordError && (
                                    <FormHelperText>
                                        {confirmPasswordError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </div>
                        <Typography type="caption" className={classes.privacy}>
                            By registering an account, you agree with our Terms
                            of Use and Privacy Policy.
                        </Typography>
                    </CardContent>
                    <CardActions className={classes.actionsBar}>
                        {mode !== 'forDeviceOwnership' && (
                            <Button
                                color="primary"
                                className={classes.button}
                                component={props => (
                                    <Link to="/login" {...props} />
                                )}
                            >
                                Go To Login
                            </Button>
                        )}
                        <div className={classes.flex1} />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            disabled={signupInProgress}
                            onClick={this.handleSignup}
                        >
                            Join
                        </Button>
                    </CardActions>
                </Card>
                <Hidden mdUp>
                    <div
                        style={{
                            position: 'absolute',
                            right: 15,
                            bottom: 10,
                            zIndex: 1500
                        }}
                    >
                        <SignUpLink href="https://tunnelin.com/">
                            Tunnelin
                        </SignUpLink>{' '}
                        © 2020 All Right Reserved
                    </div>
                </Hidden>
                <Hidden smDown>
                    <div
                        style={{
                            position: 'absolute',
                            right: 25,
                            bottom: 10,
                            zIndex: 1500
                        }}
                    >
                        <SignUpLink href="https://tunnelin.com/">
                            Tunnelin
                        </SignUpLink>{' '}
                        © 2020 All Right Reserved
                    </div>
                </Hidden>
            </>
        );
    }
}

Signup.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        formControl: PropTypes.string.isRequired
    }).isRequired,
    actions: PropTypes.shape({
        signup: PropTypes.func.isRequired
    }).isRequired,
    signupInProgress: PropTypes.bool.isRequired,
    mode: PropTypes.string,
    newOwnerEmail: PropTypes.string
};

export default withStyles(styles)(Signup);
