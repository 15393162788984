// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { updateGroupsOrdering } from '../../../../actions/group-actions';
import { wrapActionCreators } from '../../../../utils/container-util';
import OrderGroups from '../../../../components/Devices/Groups/Order';

const OrderGroupsHOC = ({
    actions,
    close,
    groups
}: {
    actions: {
        updateGroupsOrdering: (groups: any[]) => void
    },
    close: () => void,
    groups: any[]
}) => <OrderGroups actions={actions} close={close} groups={groups} />;

OrderGroupsHOC.propTypes = {
    actions: PropTypes.shape({
        updateGroupsOrdering: PropTypes.func.isRequired
    }).isRequired,
    close: PropTypes.func.isRequired,
    groups: PropTypes.array.isRequired
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = wrapActionCreators({
    updateGroupsOrdering
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderGroupsHOC);
