import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import PasswordField from '../../../components/PasswordField';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh'
    },
    card: {
        maxWidth: 345
    },
    formControl: {
        margin: theme.spacing.unit
    },
    fontSizeH1: {
        fontSize: '2rem'
    }
});

class VNCSettings extends React.Component<
    {
        classes: {
            card: string,
            formControl: string,
            root: string
        },
        onDataReady: (password: string) => void
    },
    {
        password: string,
        saveConfig: boolean
    }
> {
    static propTypes = {
        classes: PropTypes.shape({
            card: PropTypes.string.isRequired,
            formControl: PropTypes.string.isRequired,
            root: PropTypes.string.isRequired
        }).isRequired,
        onDataReady: PropTypes.func.isRequired,
        onCloseClick: PropTypes.func,
        closable: PropTypes.bool
    };

    state = {
        password: '',
        saveConfig: false
    };

    onKeyDown = event => {
        switch (event.keyCode) {
            case 13: {
                this.handleOKClick();
                break;
            }
            default:
                break;
        }
    };

    handleChange = (password: string) => {
        this.setState({ password });
    };

    handleOKClick = () => {
        const { password, saveConfig } = this.state;
        const { onDataReady } = this.props;
        onDataReady({ password, saveConfig });
    };

    handleSaveConfigChange = event => {
        this.setState({ saveConfig: event.target.checked });
    };

    handleCloseClick = () => {
        const { onCloseClick } = this.props;
        if (onCloseClick) {
            onCloseClick();
        }
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    render() {
        const {
            classes: { card, formControl, root, fontSizeH1 },
            closable
        } = this.props;
        const { password, saveConfig } = this.state;
        return (
            <div className={root}>
                <Card className={card}>
                    <CardContent>
                        <Typography type="h1" color="inherit" variant="h1" classes={{
                            h1: fontSizeH1
                        }}>
                            VNC setting
                        </Typography>
                        <PasswordField
                            isPasswordRequired={false}
                            label="Password"
                            type="vnc-terminal-settings-password"
                            autoComplete="current-password"
                            password={password}
                            onChange={this.handleChange}
                            onEnter={this.handleOKClick}
                            className={formControl}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={saveConfig}
                                    onChange={this.handleSaveConfigChange}
                                    value="saveConfig"
                                />
                            }
                            label="Save Configuration (all configs will be encrypted)"
                        />
                    </CardContent>
                    <CardActions>
                        <Button
                            size="small"
                            color="primary"
                            onClick={this.handleOKClick}
                        >
                            OK
                        </Button>
                        {closable && (
                            <Button
                                size="small"
                                color="primary"
                                onClick={this.handleCloseClick}
                            >
                                Close
                            </Button>
                        )}
                    </CardActions>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(VNCSettings);
