// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Signup from '../../../components/Authentication/Signup';

import { setGlobalMessageError } from '../../../actions/app-actions';
import { signupForGetDevice } from '../../../api/authentication-api';
import { saveAuthToken, urlToToken } from '../../../utils/http-util';
import { signupInProgress } from '../../../selectors/app-selectors';
import store from '../../../store/get-store';

import {
    signupLoading,
    signupSuccess
} from '../../../actions/authentication-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../../utils/global-message-util';

const { dispatch } = store;

class SignupGetDeviceHOC extends React.Component<
    {
        mode: string,
        newOwnerEmail: string,
        token: string,
        signupInProgress: any
    },
    {}
> {
    handleSignupForGetDevice = data => {
        const { token } = this.props;
        const headers = {
            Authorization: `Bearer ${urlToToken(token)}`
        };
        dispatch(signupLoading(true));
        signupForGetDevice(data, headers).subscribe(
            data => {
                const { authToken, user } = data.data;
                saveAuthToken(authToken);
                dispatch(signupLoading(false));
                dispatch(signupSuccess(user));
            },
            err => {
                let message = 'Something went wrong';
                if (err) {
                    message = parseErrorMessage(err);
                }
                dispatch(signupLoading(false));
                dispatch(
                    setGlobalMessageError(constructGlobalErrorMessage(message))
                );
            }
        );
    };

    render() {
        const { actions, mode, newOwnerEmail, signupInProgress } = this.props;
        return (
            <Signup
                actions={{
                    ...actions,
                    signup: this.handleSignupForGetDevice
                }}
                signupInProgress={signupInProgress}
                mode={mode}
                newOwnerEmail={newOwnerEmail}
            />
        );
    }
}

SignupGetDeviceHOC.propTypes = {
    mode: PropTypes.string,
    newOwnerEmail: PropTypes.string,
    token: PropTypes.string.isRequired,
    signupInProgress: PropTypes.bool.isRequired
};

const mapStateToProps = createStructuredSelector({
    signupInProgress: signupInProgress()
});

export default connect(
    mapStateToProps,
    null
)(SignupGetDeviceHOC);
