import { merge, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { CHANGE_CURRENT_USER_PASSWORD } from '../../constants/action-types';
import {
    setConnectivityStatus,
    setGlobalMessageError
} from '../../actions/app-actions';
import {
    changeCurrentUserPasswordLoading,
    logout
} from '../../actions/authentication-actions';
import { changePassword } from '../../api/authentication-api';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default action$ =>
    action$.pipe(
        ofType(CHANGE_CURRENT_USER_PASSWORD),
        map(action => action.payload),
        switchMap(({ oldPassword, newPassword }) =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(changeCurrentUserPasswordLoading(true)),
                changePassword(oldPassword, newPassword).pipe(
                    mergeMap(() =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            changeCurrentUserPasswordLoading(false),
                            logout()
                        )
                    ),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            changeCurrentUserPasswordLoading(false),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            )
        )
    );
