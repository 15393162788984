import { createSelector } from 'reselect';
import { ALL_TUNNEL_TYPES } from '../constants/tunnels';

export const selectLocalState = () => state => state.tunnels;

export const getSearchQuery = () =>
    createSelector(
        selectLocalState(),
        ({ searchQuery }) => searchQuery
    );

export const getFilters = () =>
    createSelector(
        selectLocalState(),
        ({ filters }) => filters
    );

export const getUiFilters = () =>
    createSelector(
        selectLocalState(),
        ({ uiFilters }) => uiFilters
    );

export const getNoFilters = () =>
    createSelector(
        selectLocalState(),
        tunnels => {
            const filters = tunnels.filters.find(f => f.property === 'type');
            const uiFilters = tunnels.uiFilters.find(f => f.value !== 'all');
            const searchFilter = tunnels.searchQuery;
            return !!(
                !searchFilter &&
                !uiFilters &&
                (filters.length === 0 ||
                    filters.length === ALL_TUNNEL_TYPES.length)
            );
        }
    );
export const getTunnelStatuses = () =>
    createSelector(
        selectLocalState(),
        ({ statuses }) => statuses.data
    );
