import { combineEpics } from 'redux-observable';
import authenticationEpics from './authentication-epic';
import appEpics from './app-epic';
import deviceEpic from './device-epic';
import groupEpic from './group-epic';
import usersEpic from './users-epic';
import tunnelEpic from './tunnel-epic';

export default combineEpics(
    appEpics,
    authenticationEpics,
    deviceEpic,
    groupEpic,
    usersEpic,
    tunnelEpic
);
