import React from 'react';

export default (WrappedComponent: any) => {
    class ClientWrapper extends React.Component<{}, {}> {
        onGuacClientReady = guac => {
            this.guac = guac;
            // this.setupGuacMouse();
            this.clipboardSyncInterval = setInterval(
                this.onClipboardSync,
                2000
            );
        };

        onClipboardSync = () => {
            if (navigator.clipboard) {
                navigator.clipboard
                    .readText()
                    .then(text => {
                        const oldClipboard = this.guac._getClipboard();
                        if (text && text !== oldClipboard) {
                            this.guac._setClipboard(text);
                            this.guac._setGuacClipboard(text);
                            this.guac.setClipboard(text);
                        }
                    })
                    .catch(err => {});
            }
        };

        onUnMount = () => {
            if (this.clipboardSyncInterval) {
                clearInterval(this.clipboardSyncInterval);
            }
        };

        render() {
            return (
                <WrappedComponent
                    onGuacClientReady={this.onGuacClientReady}
                    onUnMount={this.onUnMount}
                    {...this.props}
                />
            );
        }
    }

    ClientWrapper.WrappedComponent = WrappedComponent;
    return ClientWrapper;
};
