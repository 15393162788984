import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Select from '@material-ui/core/Select/Select';
import { IconButton, Divider, Typography, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/HelpOutline';
import RemoveIcon from '@material-ui/icons/Remove';
import LockIcon from '@material-ui/icons/Lock';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { ALL_TUNNEL_TYPES } from '../../../constants/tunnels';
import { getLoggedInUser } from '../../../selectors/app-selectors';
import PasswordField from '../../PasswordField';

const styles = () => ({
    addOrEditFormWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    section: {
        display: 'flex',
        flexDirection: 'column'
    },
    sectionHeader: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10
    },
    divider: {
        flex: 1,
        marginLeft: 10
    },
    sectionRow: {
        display: 'flex',
        alignItems: 'baseline',
        paddingLeft: 20,
        marginTop: 5,
        marginBottom: 5,
        '@media (max-width: 959px)': {
            flexDirection: 'column',
            justifyContent: 'center'
        }
    },
    menuItem: {
        maxWidth: 250
    },
    devicesMenuItemInner: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    leftSide: {
        flex: 1,
        margin: '0 10px 0 0',
        '@media (max-width: 959px)': {
            margin: 0
        }
    },
    rightSide: {
        flex: 1,
        margin: '0 0 0 10px',
        '@media (max-width: 959px)': {
            margin: 0
        }
    },
    connectionsRateLimiting: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginRight: 16,
        '@media (max-width: 959px)': {
            // margin: '0 0 0 -40px'
        }
    },
    initializationTimeLimiting: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 5,
        '@media (max-width: 959px)': {
            marginLeft: 0
        }
    },
    httpUrlsWrapper: {
        marginLeft: 10,
        fontSize: 14,
        display: 'flex',
        flexDirection: 'column',
        color: '#9c27b0'
    }
});

const AddOrEditTunnelForm = ({
    classes,
    mode,
    loggedInUser,
    formData: {
        name,
        onNameChange,
        devices,
        deviceId,
        onDeviceIdChange,
        type,
        onTypeChange,
        localPort,
        onLocalPortChange,
        handleHTTP,
        setHandleHTTP,
        connectionsRateLimiting,
        setConnectionsRateLimiting,
        initializationTimeLimiting,
        setInitializationTimeLimiting,
        allowConnectionsFrom,
        setAllowConnectionsFrom,
        ipAddresses,
        onIpAddressesChange,
        autoClose,
        setAutoClose,
        nameError,
        deviceIdError,
        typeError,
        localPortError,
        ipAddressErrors,
        onAddIpAddress,
        onRemoveIpAddress,
        showPassword,
        tunnelPassword,
        setTunnelPassword,
        setShowPassword
    }
}) => (
    <div className={classes.addOrEditFormWrapper}>
        <div className={classes.section}>
            <div className={classes.sectionHeader}>
                <div>Basic Options</div>
                <Divider className={classes.divider} />
            </div>
            <div className={classes.sectionRow}>
                <FormControl
                    fullWidth
                    className={classes.leftSide}
                    required
                    error={!!nameError}
                >
                    <InputLabel htmlFor="tunnel-name">Name</InputLabel>
                    <Input
                        id="tunnel-name"
                        value={name}
                        onChange={onNameChange}
                    />
                    {nameError && <FormHelperText>{nameError}</FormHelperText>}
                </FormControl>
                <FormControl
                    fullWidth
                    className={classes.rightSide}
                    required
                    error={!!deviceIdError}
                >
                    <InputLabel htmlFor="devices">Device</InputLabel>
                    <Tooltip
                        disableFocusListener={mode === 'add'}
                        disableHoverListener={mode === 'add'}
                        disableTouchListener={mode === 'add'}
                        title={
                            mode === 'edit' &&
                            'If you need a tunnel to a different device, please create a new one.'
                        }
                        placement="top"
                    >
                        <Select
                            disabled={mode === 'edit'}
                            id="devices"
                            onChange={onDeviceIdChange}
                            autoWidth={true}
                            value={deviceId}
                        >
                            {devices.map(device => (
                                <MenuItem
                                    className={classes.menuItem}
                                    value={device._id}
                                    key={device._id}
                                >
                                    <span
                                        className={classes.devicesMenuItemInner}
                                    >
                                        {device.name}
                                    </span>
                                </MenuItem>
                            ))}
                        </Select>
                    </Tooltip>
                    {deviceIdError && (
                        <FormHelperText>{deviceIdError}</FormHelperText>
                    )}
                </FormControl>
            </div>
            <div className={classes.sectionRow}>
                <FormControl
                    fullWidth
                    className={classes.leftSide}
                    required
                    error={!!typeError}
                >
                    <InputLabel htmlFor="type">Type</InputLabel>
                    <Tooltip
                        disableFocusListener={mode === 'add'}
                        disableHoverListener={mode === 'add'}
                        disableTouchListener={mode === 'add'}
                        title={
                            mode === 'edit' &&
                            'If you need a tunnel with a different type, please create a new one.'
                        }
                        placement="top"
                    >
                        <Select
                            disabled={mode === 'edit'}
                            id="type"
                            onChange={onTypeChange}
                            autoWidth={true}
                            value={type}
                        >
                            {ALL_TUNNEL_TYPES.map(t => (
                                <MenuItem
                                    className={classes.menuItem}
                                    value={t}
                                    key={t}
                                >
                                    <span
                                        className={classes.devicesMenuItemInner}
                                    >
                                        {t.toUpperCase()}
                                    </span>
                                </MenuItem>
                            ))}
                        </Select>
                    </Tooltip>
                    {typeError && <FormHelperText>{typeError}</FormHelperText>}
                </FormControl>
                <FormControl
                    fullWidth
                    className={classes.rightSide}
                    required
                    error={!!localPortError}
                >
                    <InputLabel htmlFor="tunnel-local-port">
                        Local Port
                    </InputLabel>
                    <Input
                        type="number"
                        id="tunnel-local-port"
                        value={localPort}
                        onChange={onLocalPortChange}
                    />
                    {localPortError && (
                        <FormHelperText>{localPortError}</FormHelperText>
                    )}
                </FormControl>
            </div>
        </div>
        <div className={classes.section} style={{ marginTop: 10 }}>
            <div className={classes.sectionHeader}>
                <div>Security Options</div>
                <Divider className={classes.divider} />
            </div>
            {!type && (
                <div className={classes.sectionRow}>
                    <Typography>First please select type.</Typography>
                </div>
            )}
            {type === 'http' && (
                <>
                    <div className={classes.sectionRow}>
                        <FormControl className={classes.leftSide}>
                            <InputLabel htmlFor="type">Scheme</InputLabel>
                            <Select
                                id="scheme"
                                onChange={e => setHandleHTTP(e.target.value)}
                                autoWidth={true}
                                value={handleHTTP}
                            >
                                <MenuItem
                                    className={classes.menuItem}
                                    value={false}
                                >
                                    https://
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuItem}
                                    value={true}
                                >
                                    https:// + http://
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <div className={classes.rightSide} />
                    </div>
                    <div className={classes.sectionRow}>
                        <div
                            className={classes.leftSide}
                            style={{ height: 30, marginTop: 10 }}
                        >
                            {name && !nameError ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div>URL:</div>
                                    <div className={classes.httpUrlsWrapper}>
                                        <i
                                            style={{ marginBottom: 0 }}
                                        >{`https://${name}-${
                                            loggedInUser.username
                                        }.p.tunnelin.com`}</i>

                                        {// TODO:: this is temporary fix and should be removed
                                        false && (
                                            <i>
                                                {`https://${
                                                    loggedInUser.username
                                                }.p.tunnelin.com/${name}`}
                                            </i>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    Please type a valid name to generate urls
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            height: 64
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: 10
                            }}
                        >
                            <FormControlLabel
                                style={{ marginLeft: 15, marginRight: 5 }}
                                control={
                                    <Checkbox
                                        checked={showPassword}
                                        onChange={e =>
                                            setShowPassword(e.target.checked)
                                        }
                                        value="autoClose"
                                    />
                                }
                                label="Set tunnel password"
                            />

                            <Tooltip
                                title=" Set a password to protect the HTTP tunnel."
                                placement="bottom"
                            >
                                <HelpIcon fontSize="small" color="primary" />
                            </Tooltip>
                        </div>
                        {showPassword && (
                            <PasswordField
                                autoComplete="off"
                                showPassword={true}
                                type="tunnelPass"
                                password={tunnelPassword}
                                onChange={setTunnelPassword}
                            />
                        )}
                    </div>
                    {type === 'http' && !handleHTTP && (
                        <div className={classes.sectionHeader}>
                            <LockIcon
                                style={{
                                    marginLeft: 17,
                                    marginRight: 5,
                                    color: '#00e676'
                                }}
                            />

                            <Typography>
                                This is most secure configurations
                            </Typography>
                        </div>
                    )}
                </>
            )}
            {type && type !== 'http' && (
                <>
                    <div className={classes.sectionRow}>
                        <div className={classes.connectionsRateLimiting}>
                            <FormControlLabel
                                style={{ marginRight: 5 }}
                                control={
                                    <Checkbox
                                        checked={connectionsRateLimiting}
                                        onChange={e =>
                                            setConnectionsRateLimiting(
                                                e.target.checked
                                            )
                                        }
                                        value="connectionsRateLimiting"
                                    />
                                }
                                label="Connections rate limiting"
                            />
                            <Tooltip
                                title="At most 6 connections are allowed in any 30 seconds window"
                                placement="bottom"
                            >
                                <HelpIcon fontSize="small" color="primary" />
                            </Tooltip>
                        </div>
                        <div className={classes.initializationTimeLimiting}>
                            <FormControlLabel
                                style={{ marginRight: 5 }}
                                control={
                                    <Checkbox
                                        checked={initializationTimeLimiting}
                                        onChange={e =>
                                            setInitializationTimeLimiting(
                                                e.target.checked
                                            )
                                        }
                                        value="initializationTimeLimiting"
                                    />
                                }
                                label="Initialization time limiting"
                            />
                            <Tooltip
                                title="After starting the tunnel system gives 180
                                    seconds to establish connection to tunnel,
                                    once that time expires the system will not
                                    accept new connections, however the
                                    connections established within first 180
                                    seconds will keep working."
                                placement="bottom"
                            >
                                <HelpIcon fontSize="small" color="primary" />
                            </Tooltip>
                        </div>
                    </div>
                    <div className={classes.sectionRow}>
                        <FormControlLabel
                            style={{ flex: 1 }}
                            control={
                                <Checkbox
                                    checked={autoClose}
                                    onChange={e =>
                                        setAutoClose(e.target.checked)
                                    }
                                    value="autoClose"
                                />
                            }
                            label="Automatically close tunnel when not in use"
                        />
                    </div>
                    <div className={classes.sectionRow}>
                        <FormControl className={classes.leftSide} fullWidth>
                            <InputLabel htmlFor="allow-connections-from">
                                Allow Connections From
                            </InputLabel>
                            <Select
                                id="allow-connections-from"
                                onChange={e =>
                                    setAllowConnectionsFrom(e.target.value)
                                }
                                autoWidth={true}
                                value={allowConnectionsFrom}
                            >
                                <MenuItem value="anywhere">Anywhere</MenuItem>
                                <MenuItem value="myIp">My ip</MenuItem>
                                <MenuItem value="custom">Custom</MenuItem>
                            </Select>
                        </FormControl>
                        <div
                            className={classes.rightSide}
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {allowConnectionsFrom === 'custom' && (
                                <>
                                    {ipAddresses.map((ip, i) => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                marginBottom: 2,
                                                alignItems: 'center'
                                            }}
                                            key={`ip-${i}`}
                                        >
                                            <FormControl
                                                fullWidth
                                                required
                                                error={
                                                    ipAddresses[i] !== '' &&
                                                    ipAddressErrors[i]
                                                }
                                            >
                                                <InputLabel htmlFor="ip-address">
                                                    Source ip address
                                                </InputLabel>
                                                <Input
                                                    id="ip-address"
                                                    value={ipAddresses[i]}
                                                    onChange={e =>
                                                        onIpAddressesChange(
                                                            e,
                                                            i
                                                        )
                                                    }
                                                />
                                            </FormControl>
                                            <IconButton
                                                disabled={
                                                    i !== ipAddresses.length - 1
                                                }
                                                onClick={onAddIpAddress}
                                                style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    width: 32,
                                                    height: 32
                                                }}
                                            >
                                                <AddIcon
                                                    fontSize="small"
                                                    color="primary"
                                                />
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    onRemoveIpAddress(i)
                                                }
                                                disabled={
                                                    ipAddresses.length === 1 &&
                                                    i === 0
                                                }
                                                style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    width: 32,
                                                    height: 32
                                                }}
                                            >
                                                <RemoveIcon
                                                    fontSize="small"
                                                    color="primary"
                                                />
                                            </IconButton>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
            {type &&
                (type !== 'http' &&
                    connectionsRateLimiting &&
                    initializationTimeLimiting &&
                    allowConnectionsFrom === 'myIp' &&
                    autoClose) && (
                    <div className={classes.sectionHeader}>
                        <LockIcon
                            style={{
                                marginLeft: 17,
                                marginRight: 5,
                                color: '#00e676'
                            }}
                        />
                        <Typography>
                            This is most secure configurations
                        </Typography>
                    </div>
                )}
        </div>
    </div>
);
AddOrEditTunnelForm.propTypes = {
    classes: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired,
    loggedInUser: PropTypes.object.isRequired,
    formData: PropTypes.shape({
        name: PropTypes.string,
        onNameChange: PropTypes.func.isRequired,
        devices: PropTypes.array,
        deviceId: PropTypes.string,
        onDeviceIdChange: PropTypes.func.isRequired,
        type: PropTypes.string,
        onTypeChange: PropTypes.func.isRequired,
        localPort: PropTypes.string,
        onLocalPortChange: PropTypes.func.isRequired,
        handleHTTP: PropTypes.bool,
        setHandleHTTP: PropTypes.func.isRequired,
        connectionsRateLimiting: PropTypes.bool,
        setConnectionsRateLimiting: PropTypes.func.isRequired,
        initializationTimeLimiting: PropTypes.bool,
        setInitializationTimeLimiting: PropTypes.func.isRequired,
        allowConnectionsFrom: PropTypes.string,
        setAllowConnectionsFrom: PropTypes.func.isRequired,
        ipAddresses: PropTypes.array,
        onIpAddressesChange: PropTypes.func.isRequired,
        autoClose: PropTypes.bool,
        setAutoClose: PropTypes.func.isRequired,
        nameError: PropTypes.string.isRequired,
        deviceIdError: PropTypes.string.isRequired,
        typeError: PropTypes.string.isRequired,
        localPortError: PropTypes.string.isRequired,
        ipAddressErrors: PropTypes.array.isRequired,
        onAddIpAddress: PropTypes.func.isRequired,
        onRemoveIpAddress: PropTypes.func.isRequired
    })
};

const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser()
});

export default withStyles(styles)(
    connect(mapStateToProps)(AddOrEditTunnelForm)
);
