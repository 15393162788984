import { createAction } from 'redux-actions';
import {
    INIT_APP,
    SET_CONNECTIVITY_STATUS,
    SET_GLOBAL_LOADER,
    SET_GLOBAL_MESSAGE_ERROR,
    SET_GLOBAL_MESSAGE_SUCCESS,
    SET_GLOBAL_MESSAGE_COOKIES,
    SET_DEVICES_TOUR_VISIBILITY,
    SET_DEVICES_TOUR_STEP,
    SET_TUNNELS_TOUR_VISIBILITY,
    SET_TUNNELS_TOUR_STEP
} from '../constants/action-types';

export const setConnectivityStatus = createAction(SET_CONNECTIVITY_STATUS);

export const setGlobalLoader = createAction(SET_GLOBAL_LOADER);

export const setGlobalMessageError = createAction(SET_GLOBAL_MESSAGE_ERROR);

export const setGlobalMessageSuccess = createAction(SET_GLOBAL_MESSAGE_SUCCESS);

export const setGlobalMessageCookies = createAction(SET_GLOBAL_MESSAGE_COOKIES);

export const initApp = createAction(INIT_APP);

export const setDevicesTourVisibility = createAction(
    SET_DEVICES_TOUR_VISIBILITY
);

export const setDevicesTourStep = createAction(SET_DEVICES_TOUR_STEP);

export const setTunnelsTourVisibility = createAction(
    SET_TUNNELS_TOUR_VISIBILITY
);

export const setTunnelsTourStep = createAction(SET_TUNNELS_TOUR_STEP);
