// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import { validateEmail } from '../../../utils/validations';

import LoadingPane from '../../LoadingPane';

import type { UserDevice } from '../../../types/user-device';

const styles = theme => ({
    listItemText: {
        color: 'blue'
    },
    listItemTextRemoved: {
        textDecoration: 'line-through',
        color: '#d9d9d9'
    },
    dialogContent: {
        width: '600px'
    },
    formControl: {
        margin: theme.spacing.unit
    }
});

export type ShareModalPropTypes = {
    classes: {
        listItemTextRemoved: string,
        listItemText: string,
        formControl: string,
        dialogContent: string
    },
    fullScreen: boolean,
    close: () => void,
    deviceUsers: UserDevice[],
    deviceUsersLoading: boolean,
    deviceId: string,
    addingOrRemovingUsersToDevice: boolean,
    addOrRemoveUsersToDevice: ({
        id: string,
        emails: string[],
        removedUsers: string[]
    }) => void
};

class ShareModal extends Component<
    ShareModalPropTypes,
    {
        removedUsers: string[],
        emailValues: string
    }
> {
    static propTypes = {
        classes: PropTypes.shape({
            listItemTextRemoved: PropTypes.string.isRequired,
            listItemText: PropTypes.string.isRequired,
            dialogContent: PropTypes.string.isRequired,
            formControl: PropTypes.string.isRequired
        }).isRequired,
        deviceId: PropTypes.string.isRequired,
        fullScreen: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired,
        addOrRemoveUsersToDevice: PropTypes.func.isRequired,
        deviceUsers: PropTypes.array.isRequired,
        deviceUsersLoading: PropTypes.bool.isRequired,
        addingOrRemovingUsersToDevice: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            removedUsers: [],
            emailValues: ''
        };
    }

    componentDidUpdate(prevProps: ShareModalPropTypes) {
        const { deviceUsers: newDeviceUsers } = this.props;
        const { deviceUsers: oldDeviceUsers } = prevProps;
        if (oldDeviceUsers !== newDeviceUsers) {
            this.setState({ removedUsers: [], emailValues: '' });
        }
    }

    onSave = () => {
        const emails = this.getEnteredEmails();
        const { removedUsers } = this.state;
        const { addOrRemoveUsersToDevice, deviceId: id } = this.props;
        if (emails.length || removedUsers.length) {
            addOrRemoveUsersToDevice({
                id,
                emails,
                removedUsers
            });
        }
    };

    onClose = () => {
        const { close } = this.props;
        close();
    };

    onEmailChange = e => {
        this.setState({ emailValues: e.target.value.toLowerCase() });
    };

    onToggleUserDeviceIconClick = (userDevice: UserDevice) => {
        const { removedUsers } = this.state;
        const { user } = userDevice;
        const { _id } = user;
        const index = removedUsers.indexOf(_id);
        if (index === -1) {
            this.setState({ removedUsers: [...removedUsers, _id] });
        } else {
            const newUserDevices = [...removedUsers];
            newUserDevices.splice(index, 1);
            this.setState({ removedUsers: newUserDevices });
        }
    };

    getEnteredEmails = () => {
        const { emailValues } = this.state;
        return emailValues
            .split(',')
            .map(email => email.trim())
            .filter(email => email !== '');
    };

    renderUserListItem = (userDevice: UserDevice) => {
        const {
            classes: { listItemTextRemoved, listItemText }
        } = this.props;
        const {
            user: { _id, fullName },
            device: { owner }
        } = userDevice;
        const { removedUsers } = this.state;
        const isRemoved = removedUsers.indexOf(_id) !== -1;

        return (
            <ListItem key={_id} dense button divider>
                <Avatar>{fullName.charAt(0)}</Avatar>
                <ListItemText
                    primary={fullName}
                    className={classNames(listItemText, {
                        [listItemTextRemoved]: isRemoved
                    })}
                />
                {owner === _id ? (
                    'Owner'
                ) : (
                    <ListItemSecondaryAction>
                        <IconButton
                            aria-label="Remove Access"
                            onClick={() =>
                                this.onToggleUserDeviceIconClick(userDevice)
                            }
                        >
                            {isRemoved ? <AddIcon /> : <CloseIcon />}
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
        );
    };

    validateEmails = emails => {
        for (let i = 0; i < emails.length; i += 1) {
            const { isValid } = validateEmail(emails[i]);
            if (!isValid) {
                return isValid;
            }
        }
        return true;
    };

    render() {
        const {
            fullScreen,
            close,
            deviceUsers,
            deviceUsersLoading,
            addingOrRemovingUsersToDevice,
            classes
        } = this.props;
        const { emailValues, removedUsers } = this.state;
        const emails = this.getEnteredEmails();
        const canSave =
            (emails.length !== 0 || removedUsers.length !== 0) &&
            !addingOrRemovingUsersToDevice &&
            this.validateEmails(emails);
        return (
            <Dialog
                fullScreen={fullScreen}
                open
                classes={{
                    paper: classes.dialogContent
                }}
                onClose={close}
                aria-labelledby="share-device-dialog-title"
            >
                <DialogTitle id="share-device-dialog-title">
                    Share with others
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>Who has access</DialogContentText>
                    <LoadingPane loading={deviceUsersLoading}>
                        <List>
                            {deviceUsers.map(deviceUser =>
                                this.renderUserListItem(deviceUser)
                            )}
                        </List>
                    </LoadingPane>
                    <DialogContentText>People</DialogContentText>
                    <div>
                        <FormControl
                            fullWidth
                            margin="dense"
                            className={classes.formControl}
                            disabled={addingOrRemovingUsersToDevice}
                            error={false}
                            aria-describedby="share-device-dialog-email-text"
                        >
                            <InputLabel htmlFor="share-device-dialog-email-text">
                                Email Address
                            </InputLabel>
                            <Input
                                autoFocus
                                type="email"
                                value={emailValues}
                                onChange={this.onEmailChange}
                            />
                            {!canSave && emails.length ? (
                                <FormHelperText id="share-device-dialog-email-text">
                                    Not a valid email (emails).
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onSave}
                        disabled={!canSave}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(ShareModal));
