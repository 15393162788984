// @flow
import { api, makeGet, makePost } from '../utils/http-util';

const API_URL = {
    GET_ORG: () => api`v1/organizations`,
    ADD_ORG: () => api`v1/admin/organization`,
    UPDATE_ORG_USERS_ACCESS: () => api`v1/organization/access`
};

export function getOrganizations() {
    return makeGet(API_URL.GET_ORG());
}
export function createOrganization(data) {
    return makePost(API_URL.ADD_ORG(), data);
}
export function updateOrgUserAccess(data) {
    return makePost(API_URL.UPDATE_ORG_USERS_ACCESS(), data);
}
