import { merge, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { MAKE_GROUP_AS_DEFAULT } from '../../constants/action-types';
import { makeGroupAsDefault } from '../../api/users-api';
import {
    setConnectivityStatus,
    setGlobalMessageError,
    setGlobalMessageSuccess
} from '../../actions/app-actions';
import {
    makeGroupAsDefaultLoading,
    makeGroupAsDefaultSuccess
} from '../../actions/users-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default (action$, state) =>
    action$.pipe(
        ofType(MAKE_GROUP_AS_DEFAULT),
        map(action => action.payload),
        switchMap(groupId =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(makeGroupAsDefaultLoading({ id: groupId, loading: true })),
                makeGroupAsDefault(groupId).pipe(
                    mergeMap(() => {
                        const {
                            value: {
                                groups: { data: groups }
                            }
                        } = state;
                        const defaultGroup = groups.find(
                            group => group._id === groupId
                        );
                        return of(
                            setConnectivityStatus({ loading: false }),
                            makeGroupAsDefaultLoading({
                                id: groupId,
                                loading: false
                            }),
                            makeGroupAsDefaultSuccess(groupId),
                            setGlobalMessageSuccess(
                                defaultGroup
                                    ? `Maked ${
                                          defaultGroup.name
                                      } group as default.`
                                    : ''
                            )
                        );
                    }),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            makeGroupAsDefaultLoading({
                                id: groupId,
                                loading: false
                            }),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            )
        )
    );
