// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Signup from '../../../components/Authentication/Signup';

import { wrapActionCreators } from '../../../utils/container-util';
import { signup } from '../../../actions/authentication-actions';
import { signupInProgress } from '../../../selectors/app-selectors';

const SignupHOC = ({ actions, signupInProgress }) => (
    <Signup actions={actions} signupInProgress={signupInProgress} />
);

SignupHOC.propTypes = {
    actions: PropTypes.shape({
        signup: PropTypes.func.isRequired
    }).isRequired,
    signupInProgress: PropTypes.bool.isRequired
};

const mapStateToProps = createStructuredSelector({
    signupInProgress: signupInProgress()
});

const mapDispatchToProps = wrapActionCreators({
    signup
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignupHOC);
