// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ChangePasswordForm from '../../ChangePasswordForm';

import styles from './styles';

class PasswordOptions extends Component<
    {
        actions: {
            changePassword: ({
                oldPassword: string,
                newPassword: string
            }) => void
        },
        isPasswordRequired: boolean,
        type: string,
        title: string,
        expanded: boolean,
        onExpansionPanelChange: () => void,
        classes: any,
        onCancel: () => void,
        changePasswordLoading: boolean
    },
    {
        password: {
            old: string,
            new: string,
            confirmNew: string
        }
    }
> {
    static propTypes = {
        actions: PropTypes.shape({
            changePassword: PropTypes.func.isRequired
        }).isRequired,
        isPasswordRequired: PropTypes.bool,
        expanded: PropTypes.bool,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        onExpansionPanelChange: PropTypes.func,
        onCancel: PropTypes.func.isRequired,
        changePasswordLoading: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isPasswordRequired: true
    };

    constructor(props) {
        super(props);
        this.state = {
            password: {
                old: '',
                new: '',
                confirmNew: ''
            }
        };
    }

    onKeyDown = event => {
        switch (event.keyCode) {
            case 13: {
                this.handleSaveButtonClick();
                break;
            }
            default:
                break;
        }
    };

    validateFields = (setToState: boolean = true) => {
        let isValid = true;
        if (
            this.changePasswordForm &&
            !this.changePasswordForm.validate(setToState)
        ) {
            isValid = false;
        }
        return isValid;
    };

    handleSaveButtonClick = () => {
        const { password } = this.state;
        const {
            actions: { changePassword }
        } = this.props;
        if (this.validateFields()) {
            if (password.old !== password.new)
                changePassword({
                    oldPassword: password.old,
                    newPassword: password.new
                });
        }
    };

    changePasswordForm = null;

    handleChangePasswordRef = ref => {
        this.changePasswordForm = ref;
    };

    handleChangePasswordFormFieldsChange = ({
        oldPassword,
        newPassword,
        confirmNewPassword
    }) => {
        this.setState({
            password: {
                old: oldPassword,
                new: newPassword,
                confirmNew: confirmNewPassword
            }
        });
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    render() {
        const {
            classes,
            onCancel,
            changePasswordLoading,
            expanded,
            onExpansionPanelChange,
            title,
            type,
            isPasswordRequired
        } = this.props;

        return (
            <ExpansionPanel
                expanded={expanded}
                onChange={onExpansionPanelChange}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div className={classes.column33}>
                        <Typography className={classes.heading}>
                            {title}
                        </Typography>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.contentWrap}>
                    <ChangePasswordForm
                        isPasswordRequired={isPasswordRequired}
                        type={type}
                        ref={this.handleChangePasswordRef}
                        onEnter={this.handleSaveButtonClick}
                        onChange={this.handleChangePasswordFormFieldsChange}
                    />
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                    <Button size="small" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        color="primary"
                        onClick={this.handleSaveButtonClick}
                        disabled={changePasswordLoading}
                    >
                        Save
                    </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        );
    }
}

export default withStyles(styles)(PasswordOptions);
