import { merge, of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { UPDATE_GROUPS_ORDERING } from '../../constants/action-types';
import { updateGroupsOrdering } from '../../api/group-api';
import {
    setConnectivityStatus,
    setGlobalMessageError
} from '../../actions/app-actions';
import {
    updateGroupsOrderingLoading,
    updateGroupsOrderingSuccess
} from '../../actions/group-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default action$ =>
    action$.pipe(
        ofType(UPDATE_GROUPS_ORDERING),
        map(action => action.payload),
        switchMap(groups =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(updateGroupsOrderingLoading(true)),
                updateGroupsOrdering(groups).pipe(
                    mergeMap(({ data }) =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            updateGroupsOrderingLoading(false),
                            updateGroupsOrderingSuccess(data)
                        )
                    ),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            updateGroupsOrderingLoading(false),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            )
        )
    );
