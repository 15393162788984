// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const styles = () => ({
    paper: {
        width: '600px'
    },
    formControl: {
        width: 180
    }
});

class AddDeviceGroup extends Component<
    {
        classes: {
            formControl: string
        },
        fullScreen: boolean,
        close: () => void,
        add: (group: any) => void,
        groupsLength: number
    },
    {
        value: string
    }
> {
    static propTypes = {
        fullScreen: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired,
        add: PropTypes.func.isRequired,
        groupsLength: PropTypes.number.isRequired
    };

    state = {
        value: ''
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    handleChange = event => {
        this.setState({ value: event.target.value });
    };

    handleAdd = () => {
        const { value } = this.state;
        const { groupsLength } = this.props;
        if (value && value.trim() !== '') {
            this.props.add({
                name: value,
                order: groupsLength - 1
            });
            this.props.close();
        }
    };

    onKeyDown = event => {
        switch (event.keyCode) {
            case 13: {
                this.handleAdd();
                break;
            }
            default:
                break;
        }
    };

    render() {
        const { fullScreen, close, classes } = this.props;
        const { value } = this.state;
        return (
            <Dialog fullScreen={fullScreen} open onClose={close}>
                <DialogContent>
                    <FormControl
                        className={classes.formControl}
                        required
                        aria-describedby="group-name-field"
                    >
                        <InputLabel htmlFor="group-name-field">
                            Group Name
                        </InputLabel>
                        <Input
                            id="group-name-field"
                            autoFocus
                            value={value}
                            onChange={this.handleChange}
                            inputProps={this.inputProps}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleAdd}
                    >
                        Add
                    </Button>
                    <Button variant="contained" color="primary" onClick={close}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(AddDeviceGroup));
