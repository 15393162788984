import { createAction } from 'redux-actions';
import {
    MAKE_GROUP_AS_DEFAULT,
    MAKE_GROUP_AS_DEFAULT_LOADING,
    MAKE_GROUP_AS_DEFAULT_SUCCESS,
    SEND_ACTIVATION_EMAIL,
    SEND_ACTIVATION_EMAIL_LOADING
} from '../constants/action-types';

export const makeGroupAsDefault = createAction(MAKE_GROUP_AS_DEFAULT);
export const makeGroupAsDefaultLoading = createAction(
    MAKE_GROUP_AS_DEFAULT_LOADING
);
export const makeGroupAsDefaultSuccess = createAction(
    MAKE_GROUP_AS_DEFAULT_SUCCESS
);

export const sendActivationEmail = createAction(SEND_ACTIVATION_EMAIL);
export const sendActivationEmailLoading = createAction(
    SEND_ACTIVATION_EMAIL_LOADING
);
