// @flow
import React from 'react';
import PropTypes from 'prop-types';
import GuacClient from '../GuacClient';
import Settings from './Settings';

type RDPTerminalPropTypes = {
    deviceName: string,
    token: string,
    onSettingsDataReady: (data: any) => void
};

class RDPTerminal extends React.Component<
    RDPTerminalPropTypes,
    {
        showSettings: boolean
    }
> {
    static propTypes = {
        deviceName: PropTypes.string,
        token: PropTypes.string.isRequired,
        onSettingsDataReady: PropTypes.func.isRequired
    };

    constructor(props: RDPTerminalPropTypes) {
        super(props);
        this.state = {
            showSettings: props.token === ''
        };
    }

    componentDidUpdate(prevProps: RDPTerminalPropTypes) {
        const { token: newToken } = this.props;
        const { token: oldToken } = prevProps;
        if (oldToken !== newToken) {
            this.setState({ showSettings: newToken === '' });
        }
    }

    onSettingsIconClick = () => {
        this.setState({ showSettings: true });
    };

    onSettingCloseClick = () => {
        this.setState({ showSettings: false });
    };

    onGuacClienError = () => {
        this.setState({ showSettings: true });
    };

    render() {
        const { token, onSettingsDataReady } = this.props;
        const { showSettings } = this.state;
        if (showSettings) {
            return (
                <Settings
                    onDataReady={onSettingsDataReady}
                    onCloseClick={this.onSettingCloseClick}
                    closable={token !== ''}
                />
            );
        }

        return (
            <GuacClient
                token={token}
                onError={this.onGuacClienError}
                onSettingsIconClick={this.onSettingsIconClick}
            />
        );
    }
}

export default RDPTerminal;
