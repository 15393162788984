// @flow
import { combineReducers } from 'redux';
import { find } from 'lodash';

import globalInitialState from './initial-state';
import {
    ADD_GROUP_SUCCESS,
    DELETE_GROUP_SUCCESS,
    GET_GROUPS_SUCCESS,
    UPDATE_GROUPS_ORDERING_SUCCESS,
    UPDATE_GROUP_SUCCESS
} from '../constants/action-types';

const initialState = globalInitialState.groups;

function data(state = initialState.data, action) {
    switch (action.type) {
        case GET_GROUPS_SUCCESS: {
            return action.payload;
        }
        case ADD_GROUP_SUCCESS: {
            const data = [...state];
            const shared = state.find(g => g._id === '-1');
            if (shared) {
                const sharedIndex = state.indexOf(shared);
                return [
                    ...data.slice(0, sharedIndex),
                    ...data.slice(sharedIndex + 1),
                    action.payload,
                    shared
                ];
            }
            return [...state, action.payload];
        }
        case UPDATE_GROUPS_ORDERING_SUCCESS: {
            const shared = state.find(g => g._id === '-1');
            if (shared) {
                return [...action.payload, shared];
            }
            return action.payload;
        }
        case DELETE_GROUP_SUCCESS: {
            const _id = action.payload;
            const data = [...state];
            const datum = find(data, { _id });
            if (!datum) {
                return state;
            }
            const indexOf = data.indexOf(datum);
            const storageActiveGroup = localStorage.getItem('activeGroup');
            if (storageActiveGroup === _id) {
                localStorage.removeItem('activeGroup');
            }
            return [...data.slice(0, indexOf), ...data.slice(indexOf + 1)];
        }
        case UPDATE_GROUP_SUCCESS: {
            const { _id } = action.payload;
            const data = [...state];
            let datum = find(data, { _id });
            if (!datum) {
                return state;
            }
            const indexOf = data.indexOf(datum);
            datum = {
                ...datum,
                ...action.payload
            };

            return [
                ...data.slice(0, indexOf),
                datum,
                ...data.slice(indexOf + 1)
            ];
        }
        default:
            return state;
    }
}

export default combineReducers({
    data
});
