// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import styles from './styles';

class PasswordField extends Component<
    {
        classes: {
            formControl: string
        },
        password: string,
        error: string,
        type: string,
        autoComplete: string,
        isPasswordRequired: boolean,
        disabled: boolean,
        label: any,
        onChange: (value: string) => void,
        onEnter: () => void
    },
    {
        showPassword: boolean,
        password: string
    }
> {
    static propTypes = {
        classes: PropTypes.shape({
            formControl: PropTypes.string.isRequired
        }).isRequired,
        password: PropTypes.string,
        error: PropTypes.string,
        isPasswordRequired: PropTypes.bool,
        disabled: PropTypes.bool,
        type: PropTypes.string.isRequired,
        autoComplete: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        onEnter: PropTypes.func,
        label: PropTypes.node,
        showPassword: PropTypes.bool
    };

    static defaultProps = {
        isPasswordRequired: true,
        label: 'Password',
        disabled: false
    };

    constructor(props) {
        super(props);
        this.state = {
            password: props.password,
            showPassword: props.showPassword
        };
    }

    componentDidUpdate(prevProps) {
        const { password: oldPassword } = prevProps;
        const { password } = this.props;
        if (password !== oldPassword) {
            this.setState({ password });
        }
    }

    onKeyDown = event => {
        switch (event.keyCode) {
            case 13: {
                const { onEnter } = this.props;
                if (typeof onEnter === 'function') {
                    onEnter();
                }
                break;
            }
            default:
                break;
        }
    };

    handleChange = e => {
        this.setState(
            {
                password: e.target.value
            },
            () => {
                const { password } = this.state;
                const { onChange } = this.props;
                if (typeof onChange === 'function') {
                    onChange(password);
                }
            }
        );
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    inputProps = {
        onKeyDown: this.onKeyDown
    };

    render() {
        const { autoComplete, isPasswordRequired, label, type, error, classes, disabled } = this.props;
        const { showPassword, password } = this.state;

        return (
            <FormControl required={isPasswordRequired} className={classes.formControl} disabled={disabled}>
                <InputLabel htmlFor={`${type}-password-field`}>{label}</InputLabel>
                <Input
                    id={`${type}-password-field`}
                    type={showPassword ? 'text' : 'password'}
                    autoComplete={autoComplete}
                    value={password}
                    onChange={this.handleChange}
                    inputProps={this.inputProps}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={this.handleClickShowPassword} tabIndex="-1" onMouseDown={this.handleMouseDownPassword}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
        );
    }
}

export default withStyles(styles)(PasswordField);
