// @flow
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import TextField from '@material-ui/core/es/TextField/TextField';
import DialogActions from '@material-ui/core/es/DialogActions/DialogActions';
import Button from '@material-ui/core/es/Button/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/es/FormControl/FormControl';
import InputLabel from '@material-ui/core/es/InputLabel/InputLabel';
import Select from '@material-ui/core/es/Select/Select';
import MenuItem from '@material-ui/core/es/MenuItem/MenuItem';
import Input from '@material-ui/core/es/Input/Input';
import PropTypes from 'prop-types';
import { Checkbox, ListItemText, Popover, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux';
import HelpIcon from '@material-ui/icons/HelpOutline';
import getOrganizationRoles from '../../api/role-api';
import { getDevices } from '../../api/device-api';
import { getTunnels } from '../../api/tunnel-api';
import { wrapActionCreators } from '../../utils/container-util';
import { setGlobalMessageError } from '../../actions/app-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';
import { validateEmail } from '../../utils/validations';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        minWidth: 500
    },
    formControl: {
        flex: 1,
        marginLeft: 5,
        marginRight: 5
    },
    dialogActions: {
        padding: '13px 20px',
        margin: 0
    }
};
const EditInviteUser = ({
    classes,
    close,
    mode,
    onInvite,
    onEdit,
    userForEdit,
    actions: { setGlobalMessageError }
}) => {
    const [email, setEmail] = useState(userForEdit.email || '');
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState(userForEdit.roleId || '');
    const [devices, setDevices] = useState([]);
    const [tunnels, setTunnels] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState(
        userForEdit.devices || []
    );
    const [selectedTunnels, setSelectedTunnels] = useState(
        userForEdit.tunnels || []
    );
    const [accessAllDevices, setAccessAllDevices] = useState(
        userForEdit.accessAllDevices || false
    );
    const [accessAllTunnels, setAccessAllTunnels] = useState(
        userForEdit.accessAllTunnels || false
    );
    const [helpAnchorEl, setHelpAnchorEl] = useState(null);

    useEffect(() => {
        let canceled = false;
        getOrganizationRoles().subscribe(
            data => {
                if (!canceled) {
                    setRoles(data.data);
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
        return () => {
            canceled = true;
        };
    }, []);
    useEffect(() => {
        let canceled = false;
        getDevices().subscribe(
            data => {
                if (!canceled) {
                    setDevices(data.data.devices);
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
        return () => {
            canceled = true;
        };
    }, []);
    useEffect(() => {
        let canceled = false;
        getTunnels().subscribe(
            data => {
                if (!canceled) {
                    setTunnels(data.data.tunnels);
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
        return () => {
            canceled = true;
        };
    }, []);
    const handleEmailChange = event => {
        setEmail(event.target.value);
    };

    const handleSave = () => {
        if (mode === 'invite') {
            onInvite({
                email,
                roleId: role,
                devices: selectedDevices,
                tunnels: selectedTunnels,
                accessAllDevices,
                accessAllTunnels
            });
        } else {
            onEdit({
                email,
                roleId: role,
                tunnels: selectedTunnels,
                devices: selectedDevices,
                accessAllDevices,
                accessAllTunnels
            });
        }
    };
    const renderTunnelsMenuItems = () => {
        const allItem = (
            <MenuItem key="all">
                <Checkbox
                    color="primary"
                    checked={selectedTunnels.length === tunnels.length}
                />
                <ListItemText primary="All" />
            </MenuItem>
        );
        const items = tunnels.map(t => (
            <MenuItem key={t._id} value={t._id}>
                <Checkbox
                    color="primary"
                    checked={selectedTunnels.indexOf(t._id) > -1}
                />
                <ListItemText primary={`${t.name} (${t.deviceName})`} />
            </MenuItem>
        ));
        items.unshift(allItem);
        return items;
    };
    const renderDevicesMenuItems = () => {
        const allItem = (
            <MenuItem key="all">
                <Checkbox
                    color="primary"
                    checked={selectedDevices.length === devices.length}
                />
                <ListItemText primary="All" />
            </MenuItem>
        );
        const items = devices.map(d => (
            <MenuItem key={d._id} value={d._id}>
                <Checkbox
                    color="primary"
                    checked={selectedDevices.indexOf(d._id) > -1}
                />
                <ListItemText primary={d.name} />
            </MenuItem>
        ));
        items.unshift(allItem);
        return items;
    };
    return (
        <Dialog
            fullScreen={false}
            open
            disableEnforceFocus={true}
            onClose={close}
            aria-labelledby="profile-dialog-title"
        >
            <DialogTitle id="profile-dialog-title">
                {mode === 'invite' ? `Invite user` : `Edit user`}
            </DialogTitle>
            <DialogContent>
                <div>
                    <form
                        className={classes.container}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl className={classes.formControl}>
                            <TextField
                                disabled={mode === 'edit'}
                                label="Email"
                                value={email}
                                onChange={handleEmailChange}
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl
                            className={classes.formControl}
                            style={{ marginTop: 16 }}
                        >
                            <InputLabel htmlFor="role-helper">Role</InputLabel>
                            <Select
                                value={role}
                                onChange={e => setRole(e.target.value)}
                                input={<Input name="Role" id="role-helper" />}
                            >
                                {roles.map(r => (
                                    <MenuItem key={r._id} value={r._id}>
                                        {r.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </form>
                    <div className={classes.container}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="devices">Devices</InputLabel>
                            <Select
                                disabled={accessAllDevices}
                                input={<Input id="devices" />}
                                multiple
                                value={selectedDevices}
                                renderValue={selected =>
                                    `${selected.length} devices selected`
                                }
                                onChange={(e, child) => {
                                    if (child.key === 'all') {
                                        if (
                                            selectedDevices.length !==
                                            devices.length
                                        ) {
                                            setSelectedDevices(
                                                devices.map(d => d._id)
                                            );
                                        } else {
                                            setSelectedDevices([]);
                                        }
                                    } else {
                                        setSelectedDevices(e.target.value);
                                    }
                                }}
                            >
                                {renderDevicesMenuItems()}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="tunnels">Tunnels</InputLabel>
                            <Select
                                disabled={accessAllTunnels}
                                input={<Input id="tunnels" />}
                                multiple
                                value={selectedTunnels}
                                renderValue={selected =>
                                    `${selected.length} tunnels selected`
                                }
                                onChange={(e, child) => {
                                    if (child.key === 'all') {
                                        if (
                                            selectedTunnels.length !==
                                            tunnels.length
                                        ) {
                                            setSelectedTunnels(
                                                tunnels.map(t => t._id)
                                            );
                                        } else {
                                            setSelectedTunnels([]);
                                        }
                                    } else {
                                        setSelectedTunnels(e.target.value);
                                    }
                                }}
                            >
                                {renderTunnelsMenuItems()}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.container}>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                margin: '0 5px'
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={accessAllDevices}
                                        onChange={(e, checked) => {
                                            setAccessAllDevices(checked);
                                        }}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Access to all devices"
                            />
                            <HelpIcon
                                style={{
                                    cursor: 'pointer',
                                    color: '#9c27b0'
                                }}
                                onClick={e => setHelpAnchorEl(e.currentTarget)}
                                id="devices"
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                margin: '0 5px'
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={accessAllTunnels}
                                        onChange={(e, checked) => {
                                            setAccessAllTunnels(checked);
                                        }}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Access to all tunnels"
                            />
                            <HelpIcon
                                id="tunnels"
                                style={{
                                    cursor: 'pointer',
                                    color: '#9c27b0'
                                }}
                                onClick={e => setHelpAnchorEl(e.currentTarget)}
                            />
                        </div>
                        {!!helpAnchorEl && (
                            <Popover
                                open={!!helpAnchorEl}
                                anchorEl={helpAnchorEl}
                                onClose={() => setHelpAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                }}
                            >
                                <Typography style={{ padding: 15 }}>
                                    {`If checked, all ${
                                        helpAnchorEl.id
                                    } (including which will be added later) will be allowed.`}
                                </Typography>
                            </Popover>
                        )}
                    </div>
                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    onClick={handleSave}
                    color="primary"
                    disabled={!email || !validateEmail(email).isValid || !role}
                >
                    Save
                </Button>
                <Button onClick={close} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
EditInviteUser.propTypes = {
    classes: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    onInvite: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    userForEdit: PropTypes.object.isRequired,
    actions: PropTypes.shape({
        setGlobalMessageError: PropTypes.func.isRequired
    }).isRequired
};

const mapDispatchToProps = wrapActionCreators({
    setGlobalMessageError
});

export default withMobileDialog()(
    withStyles(styles)(
        connect(
            null,
            mapDispatchToProps
        )(EditInviteUser)
    )
);
