// @flow
import { api, makeGet, makePatch } from '../utils/http-util';

const API_URL = {
    UPDATE_FIXED_PROXY_CONFIG: () => api`v1/user-options`,
    GET_FIXED_PROXY_CONFIG: () => api`v1/user-options`
};

// eslint-disable-next-line
export function updateFixedProxyConfig(checked: boolean) {
    return makePatch(API_URL.UPDATE_FIXED_PROXY_CONFIG(), {
        fixedProxy: checked
    });
}

export function getFixedProxyConfig() {
    return makeGet(API_URL.GET_FIXED_PROXY_CONFIG());
}
