// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Tab from '@material-ui/core/Tab/Tab';
import Tabs from '@material-ui/core/Tabs/Tabs';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import { PYTHON_CLIENT_URL } from '../../../constants/api-constants';
import {
    LinuxConsoleIcon,
    LinuxIcon,
    MacIcon,
    WindowsIcon,
    RaspberryPiIcon
} from '../../../icons';
import ClipboardCopy from '../../ClipboardCopy';

const styles = theme => ({
    paper: {
        maxWidth: '910px',
        '@media (min-width: 1024px)': {
            minWidth: '900px'
        }
    },
    linuxCommand: {
        fontWeight: 'bold',
        cursor: 'pointer',
        color: theme.palette.primary['500']
    },
    tab: {
        paddingTop: 0
    },
    content: {
        height: 150,
        paddingTop: theme.spacing.unit * 2
    }
});

class SetupInstructions extends Component<
    {
        classes: {
            paper: string,
            linuxCommand: string,
            tab: string,
            tabs: string,
            content: string
        },
        fullScreen: boolean,
        close: () => void
    },
    {
        copied: boolean,
        activeTab: Number
    }
> {
    static propTypes = {
        fullScreen: PropTypes.bool.isRequired,
        classes: PropTypes.object.isRequired,
        close: PropTypes.func.isRequired
    };

    state = {
        activeTab: -1
    };

    componentDidMount() {
        let agent = navigator.userAgent;
        agent = agent && agent.toLocaleLowerCase();
        const activeTab =
            agent.indexOf('windows') !== -1
                ? 0
                : agent.indexOf('mac') !== -1
                ? 1
                : agent.indexOf('linux') !== -1
                ? 2
                : 3;
        this.setState({ activeTab });
    }

    setupLinuxCommand = `sudo sh -c "$(curl -s ${PYTHON_CLIENT_URL})"`;

    handleActiveTabChange = (event, value) => {
        this.setState({ activeTab: value });
    };

    render() {
        const {
            classes: { tabs, tab, content, linuxCommand, ...rest },
            fullScreen,
            close
        } = this.props;
        const { activeTab } = this.state;
        return (
            <Dialog
                classes={rest}
                style={{}}
                fullScreen={fullScreen}
                open
                onClose={close}
                aria-labelledby="add-device-setup-instructions-title"
            >
                <DialogTitle>Add Device</DialogTitle>

                <DialogContent>
                    <Tabs
                        value={activeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="off"
                        onChange={this.handleActiveTabChange}
                        className={tabs}
                    >
                        <Tab
                            className={tab}
                            icon={WindowsIcon}
                            label="Windows"
                        />
                        <Tab className={tab} icon={MacIcon} label="Mac" />
                        <Tab className={tab} icon={LinuxIcon} label="Linux" />
                        <Tab
                            className={tab}
                            icon={LinuxConsoleIcon}
                            label="Linux Console"
                        />
                        <Tab
                            className={tab}
                            icon={RaspberryPiIcon}
                            label="Raspberry Pi"
                        />
                    </Tabs>
                    {activeTab === 0 && (
                        <div className={content}>
                            <DialogContentText>
                                <div style={{ color: 'black' }}>
                                    To setup Tunnel In for Windows!
                                </div>
                                <div style={{ marginLeft: 7 }}>
                                    1. Please download the application.
                                </div>
                                <div style={{ marginLeft: 7, marginBottom: 7 }}>
                                    2. Execute it, and follow the instructions.
                                </div>
                            </DialogContentText>
                            <Button
                                variant="contained"
                                color="primary"
                                href="https://cdn.tunnelin.com/installers/windows/TunnelIn/TunnelIn.exe"
                            >
                                Download
                            </Button>
                        </div>
                    )}
                    {activeTab === 1 && (
                        <div className={content}>
                            <DialogContentText>
                                <div style={{ color: 'black' }}>
                                    To setup Tunnel In for mac OS!
                                </div>
                                <div style={{ marginLeft: 7 }}>
                                    1. Please download the application.
                                </div>
                                <div style={{ marginLeft: 7, marginBottom: 7 }}>
                                    2. Execute it, and follow the instructions.
                                </div>
                            </DialogContentText>
                            <Button
                                variant="contained"
                                color="primary"
                                href="https://cdn.tunnelin.com/installers/macos/TunnelIn/TunnelIn-mac.zip"
                            >
                                Download
                            </Button>
                        </div>
                    )}
                    {activeTab === 2 && (
                        <div className={content}>
                            <DialogContentText>
                                <div style={{ color: 'black' }}>
                                    To setup Tunnel In for Linux!
                                </div>
                                <div style={{ marginLeft: 7 }}>
                                    1. Please download the application.
                                </div>
                                <div style={{ marginLeft: 7 }}>
                                    2. Make it executable by running{'  '}
                                    <ClipboardCopy text=" chmod +x TunnelIn.AppImage" />
                                </div>
                                <div style={{ marginLeft: 7, marginBottom: 7 }}>
                                    3. Execute it, and follow the instructions.
                                </div>
                            </DialogContentText>
                            <Button
                                variant="contained"
                                color="primary"
                                href="https://cdn.tunnelin.com/installers/linux/TunnelIn/TunnelIn.AppImage"
                            >
                                Download
                            </Button>
                        </div>
                    )}
                    {activeTab === 3 && (
                        <div className={content}>
                            <DialogContentText>
                                <div style={{ color: 'black' }}>
                                    To setup Tunnel In for Linux Console!
                                </div>
                                <div style={{ marginLeft: 7 }}>
                                    1. Please download the application by
                                    running
                                    <ClipboardCopy text=" wget https://cdn.tunnelin.com/installers/linux/console/tunnelin" />
                                </div>
                                <div style={{ marginLeft: 7 }}>
                                    2. Make it executable by running{'  '}
                                    <ClipboardCopy text="  chmod +x tunnelin" />
                                </div>
                                <div style={{ marginLeft: 7, marginBottom: 7 }}>
                                    3. Execute it, and follow the instructions
                                    by running {'  '}
                                    <ClipboardCopy text="  sudo ./tunnelin " />
                                </div>
                            </DialogContentText>
                        </div>
                    )}
                    {activeTab === 4 && (
                        <div className={content}>
                            <DialogContentText>
                                <div style={{ color: 'black' }}>
                                    To setup Tunnel In for Raspberry Pi OS
                                    (Raspbian)!
                                </div>
                                <div style={{ marginLeft: 7 }}>
                                    1. Please download the application by
                                    running
                                    <ClipboardCopy text=" wget https://cdn.tunnelin.com/installers/raspberry/console/tunnelin" />
                                </div>
                                <div style={{ marginLeft: 7 }}>
                                    2. Make it executable by running{'  '}
                                    <ClipboardCopy text="  chmod +x tunnelin" />
                                </div>
                                <div style={{ marginLeft: 7, marginBottom: 7 }}>
                                    3. Execute it, and follow the instructions
                                    by running {'  '}
                                    <ClipboardCopy text="  sudo ./tunnelin " />
                                </div>
                            </DialogContentText>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={close}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(SetupInstructions));
