import { merge, of } from 'rxjs';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { SEND_ACTIVATION_EMAIL } from '../../constants/action-types';
import { sendAccountActivationEmail } from '../../api/users-api';
import {
    setConnectivityStatus,
    setGlobalMessageError,
    setGlobalMessageSuccess
} from '../../actions/app-actions';
import { sendActivationEmailLoading } from '../../actions/users-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default action$ =>
    action$.pipe(
        ofType(SEND_ACTIVATION_EMAIL),
        switchMap(() =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(sendActivationEmailLoading(true)),
                sendAccountActivationEmail().pipe(
                    mergeMap(() =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            sendActivationEmailLoading(false),
                            setGlobalMessageSuccess('Activation Email Resent')
                        )
                    ),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            sendActivationEmailLoading(false),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            )
        )
    );
