import { merge, of } from 'rxjs';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { GET_TUNNEL_STATUSES } from '../../constants/action-types';
import { getTunnelStatuses } from '../../api/tunnel-api';
import {
    setConnectivityStatus,
    setGlobalMessageError
} from '../../actions/app-actions';
import { getTunnelStatusesSuccess } from '../../actions/tunnel-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default action$ =>
    action$.pipe(
        ofType(GET_TUNNEL_STATUSES),
        switchMap(() =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                getTunnelStatuses().pipe(
                    mergeMap(({ data }) =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            getTunnelStatusesSuccess(data)
                        )
                    ),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            )
        )
    );
