import { merge, of } from 'rxjs';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { GET_GROUPS } from '../../constants/action-types';
import { getGroups } from '../../api/group-api';
import {
    setConnectivityStatus,
    setGlobalMessageError
} from '../../actions/app-actions';
import {
    getGroupsLoading,
    getGroupsSuccess
} from '../../actions/group-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';

export default action$ =>
    action$.pipe(
        ofType(GET_GROUPS),
        switchMap(group =>
            merge(
                of(setConnectivityStatus({ loading: true })),
                of(getGroupsLoading(true)),
                getGroups(group).pipe(
                    mergeMap(({ data }) =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            getGroupsLoading(false),
                            getGroupsSuccess([
                                ...data,
                                { _id: '-1', name: 'Shared' }
                            ])
                        )
                    ),
                    catchError(error =>
                        of(
                            setConnectivityStatus({ loading: false }),
                            getGroupsLoading(false),
                            setGlobalMessageError(
                                constructGlobalErrorMessage(
                                    parseErrorMessage(error)
                                )
                            )
                        )
                    )
                )
            )
        )
    );
