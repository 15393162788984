import React from 'react';
import PropTypes from 'prop-types';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import SettingsIcon from '@material-ui/icons/Settings';
import KeyboardHideIcon from '@material-ui/icons/KeyboardHide';

import {
    StringReader,
    Mouse as GuacMouse,
    Keyboard as GuacKeyboard,
    OnScreenKeyboard
} from 'apache-guacamole-common-js';

import onScreenKeyboardLayout from '../../../keyboard-layouts/en-us-qwerty';

import copyTextToClipboard from '../../../utils/clipboard-util';

const styles = () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'relative'
    },
    canvasWrapper: {
        overflow: 'auto',
        flex: '3 1 0%'
    },
    keyboardIcon: {
        position: 'absolute',
        right: '5px',
        bottom: '75px'
    },
    settingsIcon: {
        position: 'absolute',
        right: '5px',
        bottom: '150px'
    },
    keyboard: {
        flex: '1 1 0%'
    }
});

export default options => (WrappedComponent: any) => {
    const opts = {
        mouse: true,
        keyboard: true,
        touch: true,
        onScreenKeyboard: true,
        settingsIcon: true,
        ...options
    };
    class WithOptions extends React.Component<
        {
            classes: {
                root: string,
                keyboardIcon: string,
                settingsIcon: string,
                canvasWrapper: string,
                keyboard: string
            },
            onSettingsIconClick: () => void,
            ignoreMouse: boolean
        },
        {
            keyboardOpen: boolean
        }
    > {
        static propTypes = {
            classes: PropTypes.shape({
                root: PropTypes.string.isRequired,
                keyboardIcon: PropTypes.string.isRequired,
                settingsIcon: PropTypes.string.isRequired,
                canvasWrapper: PropTypes.string.isRequired,
                keyboard: PropTypes.string.isRequired
            }).isRequired,
            onSettingsIconClick: PropTypes.func,
            ignoreMouse: PropTypes.bool
        };

        state = {
            keyboardOpened: false
        };

        componentWillUnmount() {
            const { onUnMount } = this.props;
            if (this.guacKeyboard) {
                this.guacKeyboard.destroyListeners();
            }
            if (onUnMount) {
                onUnMount();
            }
        }

        onRefReady = terminalInstance => {
            this.terminalInstance = terminalInstance;
        };

        onGuacClientReady = (guac: any) => {
            const { onGuacClientReady } = this.props;
            this.guac = guac;
            this.setupGuacListeners();
            if (onGuacClientReady) {
                onGuacClientReady({
                    sendMouseState: this.guac.sendMouseState.bind(this.guac),
                    setClipboard: this.guac.setClipboard.bind(this.guac),
                    getDisplay: this.guac.getDisplay.bind(this.guac),
                    _setClipboard: this._setClipboard.bind(this),
                    _getClipboard: this._getClipboard.bind(this),
                    _setGuacClipboard: this._setGuacClipboard.bind(this),
                    _getGuacClipboard: this._getGuacClipboard.bind(this)
                });
            }
        };

        _setClipboard = (clipboard: any) => {
            this.clipboard = clipboard;
        };

        _getClipboard = () => this.clipboard;

        _setGuacClipboard = (clipboard: any) => {
            this.guacClipboard = clipboard;
        };

        _getGuacClipboard = () => this.guacClipboard;

        onGuacClientConnectCalled = () => {
            const { ignoreMouse } = this.props;
            if (opts.mouse && !ignoreMouse) {
                this.setupGuacMouse();
            }
            if (opts.keyboard) {
                this.setupGuacKeyboard();
            }
            if (opts.touch) {
                this.setupGuacTouch();
            }
            if (opts.onScreenKeyboard) {
                this.setupOnScreenKeyboardLayout();
            }
        };

        onGuacMouseDownEvent = mouseState => {
            if (mouseState.left) {
                this.mouseLeftKeyDownState = {
                    ...mouseState,
                    x: mouseState.x + this.scrollLeft,
                    y: mouseState.y + this.scrollTop
                };
            }
            if (mouseState.right) {
                this.mouseRightKeyDownState = {
                    ...mouseState,
                    x: mouseState.x + this.scrollLeft,
                    y: mouseState.y + this.scrollTop
                };
            }
            this.guac.sendMouseState({
                ...mouseState,
                x: mouseState.x + this.scrollLeft,
                y: mouseState.y + this.scrollTop
            });
        };

        onGuacMouseMoveEvent = origMoveState => {
            if (navigator.clipboard) {
                this.guac.sendMouseState({
                    ...origMoveState,
                    x: origMoveState.x + this.scrollLeft,
                    y: origMoveState.y + this.scrollTop
                });
            } else if (this.mouseLeftKeyDownState) {
                const upState = {
                    ...origMoveState,
                    x: origMoveState.x + this.scrollLeft,
                    y: origMoveState.y + this.scrollTop,
                    left: false
                };
                this.guac.sendMouseState(upState);
                // Magic here, don't DELETE these two calls, it don't work with one :)
                this.guac.sendMouseState(this.mouseLeftKeyDownState);
                this.guac.sendMouseState(this.mouseLeftKeyDownState);
                this.guac.sendMouseState({
                    ...origMoveState,
                    x: origMoveState.x + this.scrollLeft,
                    y: origMoveState.y + this.scrollTop,
                    left: true
                });
            } else {
                this.guac.sendMouseState({
                    ...origMoveState,
                    x: origMoveState.x + this.scrollLeft,
                    y: origMoveState.y + this.scrollTop
                });
            }
        };

        onGuacMouseUpEvent = mouseState => {
            this.guac.sendMouseState({
                ...mouseState,
                x: mouseState.x + this.scrollLeft,
                y: mouseState.y + this.scrollTop
            });
        };

        onGuacTouchDownEvent = mouseState => {
            this.guac.sendMouseState(mouseState);
        };

        onGuacTouchMoveEvent = origMoveState => {
            this.guac.sendMouseState(origMoveState);
        };

        onGuacTouchUpEvent = mouseState => {
            this.guac.sendMouseState(mouseState);
        };

        onGuacKeyDown = keysym => {
            this.guac.sendKeyEvent(1, keysym);
        };

        onGuacKeyUp = keysym => {
            this.guac.sendKeyEvent(0, keysym);
        };

        onGuacClipboardChange = (stream, mimeType) => {
            if (mimeType === 'text/plain') {
                const reader = new StringReader(stream);
                reader.ontext = this.onGuacClipboardTextChange;
            }
        };

        onGuacClipboardTextChange = (text: string) => {
            this.clipboardText = text;
            this.guacClipboard = text;
            if (!navigator.clipboard) {
                copyTextToClipboard(text);
            } else {
                navigator.clipboard.writeText(text);
            }
            this.clipboard = text;
        };

        onCanvasScroll = (scrollTop, scrollLeft) => {
            if (this.guacTouch && this.guacTouch.updateClientScroll) {
                this.guacTouch.updateClientScroll(scrollLeft, scrollTop);
            }
            this.scrollTop = scrollTop;
            this.scrollLeft = scrollLeft;
        };

        scrollTop = 0;

        scrollLeft = 0;

        onScreenKeyboardDiv = null;

        terminalInstance = null;

        rootDiv = null;

        guac = null;

        guacMouse = null;

        guacTouch = null;

        guacKeyboard = null;

        guacOnScreenKeyboard = null;

        guacClipboard = '';

        clipboard = '';

        mouseLeftKeyDownState = null;

        mouseRightKeyDownState = null;

        clipboardText = '';

        setupGuacListeners() {
            this.guac.onclipboard = this.onGuacClipboardChange;
        }

        setupGuacMouse() {
            const element = this.guac.getDisplay().getElement();

            this.guacMouse = new GuacMouse(element);

            this.guacMouse.onmousedown = this.onGuacMouseDownEvent;
            this.guacMouse.onmouseup = this.onGuacMouseUpEvent;
            this.guacMouse.onmousemove = this.onGuacMouseMoveEvent;
        }

        setupGuacKeyboard() {
            this.guacKeyboard = new GuacKeyboard(document);
            this.guacKeyboard.onkeydown = this.onGuacKeyDown;
            this.guacKeyboard.onkeyup = this.onGuacKeyUp;
        }

        setupGuacTouch() {
            const element = this.guac.getDisplay().getElement();
            // eslint-disable-next-line
            this.guacTouch = new GuacMouse.Touchscreen(element);

            this.guacTouch.onmousedown = this.onGuacTouchDownEvent;
            this.guacTouch.onmouseup = this.onGuacTouchUpEvent;
            this.guacTouch.onmousemove = this.onGuacTouchMoveEvent;
        }

        setupOnScreenKeyboardLayout() {
            // eslint-disable-next-line
            this.guacOnScreenKeyboard = new OnScreenKeyboard(
                onScreenKeyboardLayout
            );
            this.guacOnScreenKeyboard.onkeydown = this.onGuacKeyDown;
            this.guacOnScreenKeyboard.onkeyup = this.onGuacKeyUp;
        }

        handleOnScreenKeyboardClick = () => {
            const { keyboardOpened } = this.state;
            this.setState({ keyboardOpened: !keyboardOpened });
        };

        handleSettingsIconClick = () => {
            const { onSettingsIconClick } = this.props;
            if (onSettingsIconClick) {
                onSettingsIconClick();
            }
        };

        handleKeyboardRef = onScreenKeyboardDiv => {
            this.onScreenKeyboardDiv = onScreenKeyboardDiv;
            if (onScreenKeyboardDiv) {
                onScreenKeyboardDiv.appendChild(
                    this.guacOnScreenKeyboard.getElement()
                );
                this.guacOnScreenKeyboard.resize(400);
            }
        };

        render() {
            const {
                classes: {
                    root,
                    keyboardIcon,
                    settingsIcon,
                    keyboard,
                    canvasWrapper
                },
                // eslint-disable-next-line
                onSettingsIconClick,
                ...rest
            } = this.props;
            const { keyboardOpened } = this.state;

            return (
                <div className={root}>
                    <div className={canvasWrapper}>
                        <AutoSizer>
                            {({ width, height }) => (
                                <WrappedComponent
                                    {...rest}
                                    width={width}
                                    height={height}
                                    onGuacClientConnectCalled={
                                        this.onGuacClientConnectCalled
                                    }
                                    onGuacClientReady={this.onGuacClientReady}
                                    onCanvasScroll={this.onCanvasScroll}
                                    ref={this.onRefReady}
                                />
                            )}
                        </AutoSizer>
                    </div>

                    {opts.onScreenKeyboard && keyboardOpened && (
                        <div
                            ref={this.handleKeyboardRef}
                            className={keyboard}
                        />
                    )}
                    {opts.onScreenKeyboard && (
                        <IconButton
                            color="primary"
                            className={keyboardIcon}
                            aria-label="On Screen Keyboard"
                            onClick={this.handleOnScreenKeyboardClick}
                        >
                            {keyboardOpened ? (
                                <KeyboardHideIcon />
                            ) : (
                                <KeyboardIcon />
                            )}
                        </IconButton>
                    )}
                    {opts.settingsIcon && (
                        <IconButton
                            color="primary"
                            className={settingsIcon}
                            aria-label="Settings"
                            onClick={this.handleSettingsIconClick}
                        >
                            <SettingsIcon />
                        </IconButton>
                    )}
                </div>
            );
        }
    }

    WithOptions.displayName = `WithOptions(${WrappedComponent.displayName ||
        WrappedComponent.name})`;
    WithOptions.WrappedComponent = WrappedComponent;
    return withStyles(styles)(WithOptions);
};
