// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import ChangeDeviceOwnerModal from '../../../components/Devices/Modal/ChangeDeviceOwnerModal';

import { changeDeviceOwner } from '../../../api/device-api';
import { getTunnels } from '../../../api/tunnel-api';
import { wrapActionCreators } from '../../../utils/container-util';
import {
    setGlobalMessageError,
    setGlobalMessageSuccess
} from '../../../actions/app-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../../utils/global-message-util';

class ChangeDeviceOwnerModalHOC extends React.Component<
    {
        deviceId: string,
        close: () => void,
        actions: {
            setGlobalMessageError: any,
            setGlobalMessageSuccess: any
        }
    },
    {
        tunnels?: any[],
        changeDeviceOwnerLoading: boolean,
        changeDeviceOwnerSuccess: string,
        getTunnelsLoading: boolean,
        getTunnelsError: string
    }
> {
    state = {
        tunnels: [],
        changeDeviceOwnerLoading: false,
        changeDeviceOwnerSuccess: '',
        getTunnelsLoading: false,
        getTunnelsError: ''
    };

    componentDidMount() {
        this.doGetTunnels('');
    }

    doGetTunnels = () => {
        const {
            deviceId,
            actions: { setGlobalMessageError }
        } = this.props;
        const filters = [
            {
                property: 'deviceId',
                values: [deviceId]
            }
        ];
        this.setState({ getTunnelsLoading: true });
        getTunnels(null, filters).subscribe(
            tunnels => {
                this.setState({
                    tunnels: tunnels.data.tunnels,
                    getTunnelsError: '',
                    getTunnelsLoading: false
                });
            },
            err => {
                let message = 'Something went wrong';
                if (err) {
                    message = parseErrorMessage(err);
                }
                setGlobalMessageError(constructGlobalErrorMessage(message));
                this.setState({
                    getTunnelsError: message,
                    getTunnelsLoading: false
                });
            }
        );
    };

    handleChangeDeviceOwner = (email: string, tunnelsToMove: string[]) => {
        const {
            deviceId,
            actions: { setGlobalMessageError, setGlobalMessageSuccess }
        } = this.props;
        this.setState({
            changeDeviceOwnerLoading: true,
            changeDeviceOwnerSuccess: ''
        });
        changeDeviceOwner(deviceId, email, tunnelsToMove).subscribe(
            verificationKey => {
                const message = (
                    <div>
                        <div>Owner changed successfully!</div>
                        New owner needs 4 digit verification code below to get
                        the device. <br /> Verification code:{' '}
                        <strong>{verificationKey.data}</strong>
                    </div>
                );
                setGlobalMessageSuccess(message);
                this.setState({
                    changeDeviceOwnerLoading: false,
                    changeDeviceOwnerSuccess: verificationKey.data
                });
            },
            err => {
                let message = 'Something went wrong';
                if (err) {
                    message = parseErrorMessage(err);
                }
                setGlobalMessageError(constructGlobalErrorMessage(message));
                this.setState({
                    changeDeviceOwnerLoading: false
                });
            }
        );
    };

    render() {
        const {
            changeDeviceOwnerLoading,
            changeDeviceOwnerSuccess,
            tunnels,
            getTunnelsError,
            getTunnelsLoading
        } = this.state;
        const { close } = this.props;
        return (
            <ChangeDeviceOwnerModal
                close={close}
                handleChangeDeviceOwner={this.handleChangeDeviceOwner}
                changeDeviceOwnerLoading={changeDeviceOwnerLoading}
                changeDeviceOwnerSuccess={changeDeviceOwnerSuccess}
                tunnels={tunnels}
                getTunnelsError={getTunnelsError}
                getTunnelsLoading={getTunnelsLoading}
            />
        );
    }
}

ChangeDeviceOwnerModalHOC.propTypes = {
    deviceId: PropTypes.string,
    close: PropTypes.func,
    actions: PropTypes.shape({
        setGlobalMessageError: PropTypes.func.isRequired,
        setGlobalMessageSuccess: PropTypes.func.isRequired
    }).isRequired
};

const mapDispatchToProps = wrapActionCreators({
    setGlobalMessageError,
    setGlobalMessageSuccess
});

export default connect(
    null,
    mapDispatchToProps
)(ChangeDeviceOwnerModalHOC);
