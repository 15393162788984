// getNetDataReport
import { api, makeGet } from '../utils/http-util';

const API_URL = {
    GET_NET_DATA_REPORT: (type, query) =>
        api`v1/reports/data/net/${type}?${query}`
};

export default function getNetDataReport(type, filters, byType) {
    // type: 'devices' | 'tunnels' | 'organizations'
    // query wiil be 'filters[0][property]=type&filters[0][value][0]=http&filters[0][value][1]=rdp&'
    let query = filters.reduce((total, el, i) => {
        const q = `filters[${i}][property]=${el[0]}&filters[${i}][value]=${
            el[1]
        }&`;
        return total + q;
    }, '');
    if (query.charAt(query.length - 1) === '&')
        query = query.slice(0, query.length - 1);
    if (byType) {
        query += `&byType=${byType}`;
    }
    return makeGet(API_URL.GET_NET_DATA_REPORT(type, query));
}
